import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { imageURL } from '../../../imageURL/imageURL';
// import '../dashboard/AdminDashboard.css';
import '../AppAdmin.css';
import Apd from '../boardOfDirectorsSetup/Apd';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";


const EditAboutBangladeshSetup = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [editInputField, setEditInputField] = useState({
        description: "",
        image: null,
    });


    const inputsHandler = (e) => {
        setEditInputField({ ...editInputField, [e.target.name]: e.target.value });
    };

    const fetchData = async (id) => {
        try {
            const res = await axios.get(`/edit-about-bangladesh/${id}`);
            console.log("edit-about-bangladesh: ", res)
            setEditInputField(res.data.data)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData(id);
    }, []);

    const [imageNew, setImageNew] = useState();

    const handleImageChange = (e) => {
        setEditInputField({
            ...editInputField,
            image: e.target.files[0]
        });
        setImageNew(e.target.files[0])
    };
    const updateFormData = async (e) => {
        e.preventDefault();
        console.log("updateFormData: ", editInputField);

        const formData = new FormData();
        formData.append("description", editInputField.description)
        // formData.append("image", editInputField.image, editInputField.image.name);
        // formData.append("image", imageNew ? imageNew : editInputField.image, editInputField.image.name);
        formData.append("image", imageNew ? imageNew : editInputField.image);

        const url = `/update-about-bangladesh/${id}`;
        // console.log("update-about-bangladesh", editInputField.image, "mmm", editInputField.image.name);
        try {
            let res = await axios.post(url, formData);
            console.log("update-about-bangladesh: ", res)
            if (res.status == 200) {
                alert("Successfull", formData);
                setEditInputField({
                    description: "",
                    image: null,

                });
                document.getElementById("InputPhotoUpload").value = '';
                navigate("/dashboard/about-bangladesh-setup");
            } else {
                alert("Failed")
            }
        } catch (error) {
            console.log(error);
        }
    };


    const inputPhotoUploadClose = () => {
        setEditInputField({ ...editInputField, image: '' });
        document.getElementById("InputPhotoUpload").value = '';
        setImageNew();

    };



    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Edit About Bangladesh Setup
                        <Link to='/dashboard/about-bangladesh-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        {/* <Apd /> */}
                        <form className="mt-1" encType='multipart/form-data'
                            onSubmit={updateFormData}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">

                                    <div class="row mb-2">
                                        <div className="col-md-6">
                                            <label htmlFor="DescriptionFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
                                                Description <span className="text-danger">*</span></label>
                                            <textarea className="form-control" id="DescriptionFormControlTextarea1" rows="5"
                                                name="description"
                                                value={editInputField.description}
                                                onChange={inputsHandler}
                                            ></textarea>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >
                                                Card Photo Upload <span className="text-danger">*</span></label>
                                            <input className="form-control form-control-sm form__ptb" id="InputPhotoUpload"
                                                type="file"
                                                // multiple
                                                name="image"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                            />
                                            <div className="pt-4">
                                                {imageNew && (
                                                    <div className="d-flex input__img">
                                                        <img src={URL.createObjectURL(imageNew)} alt="Preview" />
                                                        <span className="close__icon"
                                                            onClick={inputPhotoUploadClose}>
                                                            <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                    </div>
                                                )}
                                                {!imageNew && editInputField.image && (
                                                    <div className="d-flex input__img">
                                                        <img src={`${imageURL}/images/about_bangla_folder/${editInputField.image}`} alt="Preview" />
                                                        <span className="close__icon"
                                                            onClick={inputPhotoUploadClose}>
                                                            <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditAboutBangladeshSetup;

