import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import '../dashboard/AdminDashboard.css';
import '../AppAdmin.css';
import Apd from '../boardOfDirectorsSetup/Apd';

const AddBoardOfDirectorsSetup = () => {

    const [inputField, setInputField] = useState({
        designation_category: "",
        title_name: "",
        rank_name: "",
        org_name: "",
        web_link: "",
        email: "",
        position_of_org: "",
        address: "",
        cell: "",
        mobile: "",
        description: "",
        image: null,
    });

    const handleImageChange = (e) => {
        setInputField({
            ...inputField,
            image: e.target.files[0]
        })
    };

    const inputsHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        console.log("submitHandler: ", inputField);


        const formData = new FormData();
        formData.append("image", inputField.image, inputField.image.name);
        formData.append("designation_category", inputField.designation_category)
        formData.append("title_name", inputField.title_name)
        formData.append("rank_name", inputField.rank_name)
        formData.append("org_name", inputField.org_name)
        formData.append("position_of_org", inputField.position_of_org)
        formData.append("email", inputField.email)
        formData.append("address", inputField.address)
        formData.append("web_link", inputField.web_link)
        formData.append("cell", inputField.cell)
        formData.append("mobile", inputField.mobile)
        formData.append("description", inputField.description)

        const url = "/add-board-of-directors";
        console.log("mm", inputField.image, "mmm", inputField.image.name);
        try {
            let response = await axios.post(url, formData);
            if (response.status == 200) {
                alert("Are you Added  toData", formData);
                setInputField({
                    designation_category: "",
                    title_name: "",
                    rank_name: "",
                    org_name: "",
                    web_link: "",
                    email: "",
                    position_of_org: "",
                    address: "",
                    cell: "",
                    mobile: "",
                    description: "",
                    image: null,
                });
                document.getElementById("InputPhotoUpload").value = '';
            } else {
                alert("Failed")
            }
        } catch (error) {
            console.log(error);
        }
    };

    const inputPhotoUploadClose = () => {
        setInputField({ ...inputField, image: '' });
        document.getElementById("InputPhotoUpload").value = ''
    };



    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Add Board Of Directors Setup
                        <Link to='/dashboard/our-board-members-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        {/* <Apd /> */}
                        <form className="mt-1" encType='multipart/form-data'
                            onSubmit={submitHandler}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <div className="row g-3 mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="inputDesignationCategory" className="col-form-label col-form-label-sm d-block">
                                                Designation Category <span className="text-danger">*</span></label>
                                            <select id="inputDesignationCategory" className="form-select form-select-sm"
                                                name="designation_category"
                                                value={inputField.designation_category}
                                                onChange={inputsHandler}>
                                                <option value="">Please select a Designation</option>
                                                <option value="Chairman">Chairman</option>
                                                <option value="SeniorViceChairman">Senior Vice Chairman</option>
                                                <option value="ViceChairman">Vice Chairman</option>
                                                <option value="Director">Director</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputDesignationName" className="col-form-label col-form-label-sm d-block " >
                                            Designation Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputDesignationName"
                                                name="rank_name"
                                                value={inputField.rank_name}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputBannerTitle" className="col-form-label col-form-label-sm d-block " >
                                                Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputBannerTitle"
                                                name="title_name"
                                                value={inputField.title_name}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputOrganizationName" className="col-form-label col-form-label-sm d-block " >
                                                Organization Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputOrganizationName"
                                                name="org_name"
                                                value={inputField.org_name}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputPositionofOrganization" className="col-form-label col-form-label-sm d-block " >
                                                Position of Organization <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputPositionofOrganization"
                                                name="position_of_org"
                                                value={inputField.position_of_org}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputWebLink" className="col-form-label col-form-label-sm d-block " >
                                                Web Link </label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputWebLink"
                                                name="web_link"
                                                value={inputField.web_link}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputEmail" className="col-form-label col-form-label-sm d-block " >
                                                Email <span className="text-danger">*</span></label>
                                            <input type="email" className="form-control form-control-sm form__ptb " id="InputEmail"
                                                name="email"
                                                value={inputField.email}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputAddress" className="col-form-label col-form-label-sm d-block " >
                                                Address <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputAddress"
                                                name="address"
                                                value={inputField.address}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputCell" className="col-form-label col-form-label-sm d-block " >
                                                Cell </label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputCell"
                                                name="cell"
                                                value={inputField.cell}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputMobileNo" className="col-form-label col-form-label-sm d-block " >
                                                Mobile No <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputMobileNo"
                                                name="mobile"
                                                value={inputField.mobile}
                                                onChange={inputsHandler}
                                            />
                                        </div>

                                    </div>
                                    <div class="row mb-2">
                                        <div className="col-md-6">
                                            <label htmlFor="DescriptionFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
                                                Description <span className="text-danger">*</span></label>
                                            <textarea className="form-control" id="DescriptionFormControlTextarea1" rows="3"
                                                name="description"
                                                value={inputField.description}
                                                onChange={inputsHandler}
                                            ></textarea>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >
                                                Card Photo Upload <span className="text-danger">*</span></label>
                                            <input className="form-control form-control-sm form__ptb" id="InputPhotoUpload"
                                                type="file"
                                                // multiple
                                                name="image"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                            />
                                            <div className="pt-4">
                                                {inputField.image && (
                                                    <div className="d-flex input__img">
                                                        <img src={URL.createObjectURL(inputField.image)} alt="Preview" />

                                                        <span className="close__icon"
                                                            onClick={inputPhotoUploadClose}>
                                                            <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddBoardOfDirectorsSetup;



// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';


// const AddBoardOfDirectorsSetup = () => {

//     const categories = [
//         {
//             id: 1,
//             name: 'Home Page',
//             subcategories: [
//                 { name: 'Our Events' },
//                 { name: 'Tourism News' },
//                 { name: 'TDAB Gallery' }
//             ]
//         },
//         {
//             id: 2,
//             name: 'All Airlines',
//             subcategories: [
//                 { name: 'Banner' },
//             ]
//         },
//         {
//             id: 3,
//             name: 'Travel Agent & Tour Operator',
//             subcategories: [
//                 { name: 'Banner' },
//             ]
//         },
//         {
//             id: 4,
//             name: 'Contact',
//             subcategories: [
//                 { name: 'Banner' },
//             ]
//         }
//     ];

//     const [selectedCategory, setSelectedCategory] = useState(null);
//     const [selectedSubcategory, setSelectedSubcategory] = useState([]);



//     return (
//         <div className="container pt-4">
//             <div className="card">
//                 <div className="card-header">
//                     <h4 className="header__text  pt-1">Add Board Of Directors Setup
//                         <Link to='/dashboard/our-board-members-setup' className="back__btn  float-end"> Back</Link>
//                     </h4>
//                 </div>
//                 <div className="bg__from__color">
//                     <div className="card-body">
//                         <form className="mt-1">
//                             <div className="row g-0">
//                                 <div className="col-md-12">
//                                     <div className="row g-3 mb-3">
//                                         <div className="col-md-6">
//                                             <label htmlFor="inputPageName" className="col-form-label col-form-label-sm d-block">
//                                                 Page Name <span className="text-danger">*</span></label>
//                                             <select
//                                                 className="form-select form-select-sm" id="inputPageName"
//                                                 value={selectedCategory ? selectedCategory?.name : ''}
//                                                 onChange={e => {
//                                                     setSelectedCategory(categories[e.target.value]);
//                                                 }}
//                                             >
//                                                 <option value='' disabled>
//                                                     Choose a Page Name
//                                                 </option>
//                                                 {
//                                                     categories.map((category, index) => (
//                                                         <option key={category.name} value={index}>
//                                                             {category.name}
//                                                         </option>
//                                                     ))
//                                                 }
//                                             </select>
//                                         </div>

//                                         <div className="col-md-6">
//                                             <label htmlFor="inputSectionName" className="col-form-label col-form-label-sm d-block">
//                                                 Section Name <span className="text-danger">*</span></label>

//                                             <select
//                                                 className="form-select form-select-sm" id="inputSectionName"
//                                                 value={selectedSubcategory ? selectedSubcategory?.name : ''}
//                                                 onChange={e => setSelectedSubcategory(selectedCategory?.subcategories[e.target.value])}
//                                             >
//                                                 <option selected value='' disabled>
//                                                     Choose a Section Name
//                                                 </option>
//                                                 {
//                                                     selectedCategory?.subcategories?.map((subcategory, index) => (
//                                                         <option key={subcategory.name} value={index}>
//                                                             {subcategory.name}
//                                                         </option>
//                                                     ))
//                                                 }
//                                             </select>

//                                         </div>
//                                     </div>
//                                     <div className="row mb-2 d-flex">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputBannerTitle" className="col-form-label col-form-label-sm d-block " >
//                                                 Title Name <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputBannerTitle"
//                                                 name="max__prn__dose"
//                                             // value={regularMedicineEight.max__prn__dose}
//                                             // onChange={inputRegularMedicineChangeEight}
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputSubTitle" className="col-form-label col-form-label-sm d-block " >Sub Title Name</label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputSubTitle"
//                                                 name="max__prn__dose"
//                                             // value={regularMedicineEight.max__prn__dose}
//                                             // onChange={inputRegularMedicineChangeEight}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div className="row mb-2 d-flex">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputDate" className="col-form-label col-form-label-sm d-block " >
//                                                 Date <span className="text-danger">*</span></label>

//                                             <input type="cell" className="form-control form-control-sm form__ptb " id="InputDate"
//                                                 name="max__prn__dose"
//                                             // value={regularMedicineEight.max__prn__dose}
//                                             // onChange={inputRegularMedicineChangeEight}
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >
//                                                 Photo Upload <span className="text-danger">*</span></label>
//                                             <input className="form-control form-control-sm form__ptb " id="InputPhotoUpload"
//                                                 type="file"
//                                                 multiple
//                                                 name="max__prn__dose"
//                                             // value={regularMedicineEight.max__prn__dose}
//                                             // onChange={inputRegularMedicineChangeEight}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div class="row mb-2">
//                                         <div className="col-12">
//                                             <label htmlFor="DescriptionFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
//                                                 Description <span className="text-danger">*</span></label>
//                                             <textarea className="form-control" id="DescriptionFormControlTextarea1" rows="3"></textarea>
//                                         </div>
//                                     </div>

//                                 </div>
//                             </div>
//                             <div className="row">
//                                 <div className="col-12">
//                                     <div className="float-right pt-4 mr-0">
//                                         <button className="btn__save mx-3"
//                                         // onClick={saveMedicationChartPartOneData}
//                                         >
//                                             <b>Save</b>
//                                         </button>
//                                         <button className="btn__cancel"
//                                         // onClick={props.closeModal}
//                                         >
//                                             <b>Cancel</b>
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </form>

//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddBoardOfDirectorsSetup;