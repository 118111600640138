import axios from 'axios';
import React, { useState } from 'react';
import contact_img1 from "../../images/contact_img1.png";
import GoogleMap from '../tabs/GoogleMap';


const Contact = () => {
    const [inputField, setInputField] = useState({
        full_name: '',
        email: '',
        contact_no: '',
        message: '',
        checkbox_message: false,
    });

    const inputsHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value })
    }

    const [result, setResult] = useState(null);

    const sendForm = (e) => {
        e.preventDefault();
        // alert(inputField.first_name);
        console.log("submittedData", inputField);

        axios.post("/send", { ...inputField })
            .then(res => {
                setResult(res.data);
                setInputField({
                    full_name: '',
                    email: '',
                    contact_no: '',
                    message: '',
                    checkbox_message: false,
                });
            })
            .catch(() => {
                setResult({
                    success: false,
                    message: "Something went wrong Try again later"
                });
            })
    }


    return (
        <div className="bg__body">
            <div className="banner_img">
                <img src={contact_img1} className="img-fluid" alt="contact_img1" />
                <div className="banner_img_text">
                    <h3 className="text-white">Contact</h3>
                </div>
            </div>
            <div className="container mb-5 pt-4" >
                <div className="row g-4 ">
                    <div className="col-md-5">
                        <div className="contact_bg">
                            <h3 className="header_contact mb-2">Contact Us</h3>
                            <div className="card">
                                <div className="card-body">
                                    <ul className="list-unstyled">
                                        <li>
                                            <span className="d-flex align-items-start ">
                                                <i className="fa-solid fa-phone"></i>
                                                <p>+880-222 336 4471</p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="d-flex align-items-start ">
                                                <i className="fa-solid fa-mobile"></i>
                                                <p>+8801611-685570</p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="d-flex align-items-start" onClick={() => { window.location.href = 'mailto:info.tdab@gmail.com'; }}>
                                                <i className="fa-solid fa-envelope"></i>
                                                <p>info@tdabbd.com</p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="d-flex align-items-start ">
                                                <i className="fa-solid fa-location-dot"></i>
                                                <p>8, Link Road, (2nd Floor), Banglamotor, Dhaka-1000, Bangladesh.</p>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer_social text-center">
                                    <a href="https://www.facebook.com/people/Tourism-Developers-Association-of-Bangladesh-TDAB/100066275122335/" target='_blank' className="facebook"><i className="fab fa-facebook-f"></i></a>
                                    <a href="https://api.whatsapp.com/send?phone=+8801611-685570&text=test" target='_blank' className="whatsapp"><i className="fab fa-whatsapp"></i></a>
                                    {/* <a href="#" className="twitter"><i className="fab fa-twitter"></i></a>
                                    <a href="#" className="linkedin"><i className="fab fa-linkedin-in"></i></a>
                                    <a href="#" className="instagram"><i className="fab fa-instagram"></i></a>
                                    <a href="#" className="pinterest"><i className="fab fa-pinterest-p"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <h3 className="header_text_bold text-center mb-2">Enquiry Form</h3>
                        <div>
                            {result && (
                                <p className={`${result.success ? 'success' : 'error'}`}>
                                    {result.message}
                                </p>
                            )}
                        </div>
                        <form className="contact_enquiry" encType='multipart/form-data'
                            onSubmit={sendForm}>
                            <div className="mb-1">
                                <label htmlFor="exampleInputEmail1" className="col-form-label col-form-label-sm">Name</label>
                                <input type="text" className="form-control form-control-sm" id="name" aria-describedby="text"
                                    name="full_name"
                                    value={inputField.full_name}
                                    onChange={inputsHandler}
                                />
                            </div>
                            <div className="mb-1">
                                <label htmlFor="exampleInputEmail1" className="col-form-label col-form-label-sm">Email address</label>
                                <input type="email" className="form-control form-control-sm" id="exampleInputEmail1" aria-describedby="emailHelp"
                                    name="email"
                                    value={inputField.email}
                                    onChange={inputsHandler}
                                />
                            </div>
                            <div className="mb-1">
                                <label htmlFor="exampleInputPassword1" className="col-form-label col-form-label-sm">Phone</label>
                                <input type="phone" className="form-control form-control-sm" id="exampleInputPassword1"
                                    name="contact_no"
                                    value={inputField.contact_no}
                                    onChange={inputsHandler} />
                            </div>
                            <div className="mb-1 ">
                                <label htmlFor="exampleInputPassword1" className="col-form-label col-form-label-sm">Your Comments</label>
                                <textarea className="form-control form-control-sm" placeholder="Leave a comment here" style={{ height: '100px' }}
                                    name="message"
                                    value={inputField.message}
                                    onChange={inputsHandler}
                                ></textarea>
                            </div>
                            <div className="mt-2 form-check">
                                <input className="form-check-input" id="exampleCheck1"
                                    type="checkbox"
                                    name="checkbox_message"
                                    checked={inputField.checkbox_message === true}
                                    value={true}
                                    onChange={() => setInputField({ ...inputField, checkbox_message: !inputField.checkbox_message })}
                                />
                                <label className="form-check-label" htmlFor="exampleCheck1">I agree to the Privacy Policy and Terms of Service.</label>
                            </div>
                            <div className="pt-3">
                                <button type="submit" className="btn__submit">
                                    <span>Send an Enquiry</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="pt-4">
                <GoogleMap />
            </div>
        </div>
    );
};

export default Contact;



// import React, { useState } from 'react';
// import contact_img1 from "../../images/contact_img1.png";
// import GoogleMap from '../tabs/GoogleMap';


// const Contact = () => {
//     const [inputField, setInputField] = useState({
//         full_name: '',
//         email: '',
//         contact_no: '',
//         message: '',
//         checkbox_message: false,
//     });

//     const inputsHandler = (e) => {
//         setInputField({ ...inputField, [e.target.name]: e.target.value })
//     }

//     const [result, setResult] = useState(null);

//     const sendForm = (e) => {
//         e.preventDefault();
//         // alert(inputField.first_name);
//         console.log("submittedData", inputField);
//         setInputField({
//             full_name: '',
//             email: '',
//             contact_no: '',
//             message: '',
//             checkbox_message: false,
//         })
//     }


//     return (
//         <div className="bg__body">
//             <div className="banner_img">
//                 <img src={contact_img1} className="img-fluid" alt="contact_img1" />
//                 <div className="banner_img_text">
//                     <h3 className="text-white">Contact</h3>
//                 </div>
//             </div>
//             <div className="container mb-5 pt-4" >
//                 <div className="row g-4 ">
//                     <div className="col-md-5">
//                         <div className="contact_bg">
//                             <h3 className="header_contact mb-2">Contact Us</h3>
//                             <div className="card">
//                                 <div className="card-body">
//                                     <ul className="list-unstyled">
//                                         <li>
//                                             <span className="d-flex align-items-start ">
//                                                 <i className="fa-solid fa-phone"></i>
//                                                 <p>+8802-9675471-72</p>
//                                             </span>
//                                         </li>
//                                         <li>
//                                             <span className="d-flex align-items-start ">
//                                                 <i className="fa-solid fa-envelope"></i>
//                                                 <p>info.tdab@gmail.com</p>
//                                             </span>
//                                         </li>
//                                         <li>
//                                             <span className="d-flex align-items-start ">
//                                                 <i className="fa-solid fa-location-dot"></i>
//                                                 <p>8, Link Road, (2nd Floor), Banglamotor, Dhaka-1000, Bangladesh.</p>
//                                             </span>
//                                         </li>
//                                     </ul>
//                                 </div>
//                                 <div className="footer_social text-center">
//                                     <a href="#" className="facebook"><i className="fab fa-facebook-f"></i></a>
//                                     <a href="#" className="twitter"><i className="fab fa-twitter"></i></a>
//                                     <a href="#" className="linkedin"><i className="fab fa-linkedin-in"></i></a>
//                                     <a href="#" className="whatsapp"><i className="fab fa-whatsapp"></i></a>
//                                     <a href="#" className="instagram"><i className="fab fa-instagram"></i></a>
//                                     <a href="#" className="pinterest"><i className="fab fa-pinterest-p"></i></a>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-md-7">
//                         <h3 className="header_text_bold text-center mb-2">Enquiry Form</h3>
//                         <form className="contact_enquiry" onSubmit={sendForm}>
//                             <div className="mb-1">
//                                 <label htmlFor="exampleInputEmail1" className="col-form-label col-form-label-sm">Name</label>
//                                 <input type="text" className="form-control form-control-sm" id="name" aria-describedby="text"
//                                     name="full_name"
//                                     value={inputField.full_name}
//                                     onChange={inputsHandler}
//                                 />
//                             </div>
//                             <div className="mb-1">
//                                 <label htmlFor="exampleInputEmail1" className="col-form-label col-form-label-sm">Email address</label>
//                                 <input type="email" className="form-control form-control-sm" id="exampleInputEmail1" aria-describedby="emailHelp"
//                                     name="email"
//                                     value={inputField.email}
//                                     onChange={inputsHandler}
//                                 />
//                             </div>
//                             <div className="mb-1">
//                                 <label htmlFor="exampleInputPassword1" className="col-form-label col-form-label-sm">Phone</label>
//                                 <input type="phone" className="form-control form-control-sm" id="exampleInputPassword1"
//                                     name="contact_no"
//                                     value={inputField.contact_no}
//                                     onChange={inputsHandler} />
//                             </div>
//                             <div className="mb-1 ">
//                                 <label htmlFor="exampleInputPassword1" className="col-form-label col-form-label-sm">Your Comments</label>
//                                 <textarea className="form-control form-control-sm" placeholder="Leave a comment here" style={{ height: '100px' }}
//                                     name="message"
//                                     value={inputField.message}
//                                     onChange={inputsHandler}
//                                 ></textarea>
//                             </div>
//                             <div className="mt-2 form-check">
//                                 <input className="form-check-input" id="exampleCheck1"
//                                     type="checkbox"
//                                     name="checkbox_message"
//                                     checked={inputField.checkbox_message === true}
//                                     value={true}
//                                     onChange={() => setInputField({ ...inputField, checkbox_message: !inputField.checkbox_message })}
//                                 />
//                                 <label className="form-check-label" htmlFor="exampleCheck1">I agree to the Privacy Policy and Terms of Service.</label>
//                             </div>
//                             <div className="pt-3">
//                                 <button type="submit" className="btn__submit">
//                                     <span>Send an Enquiry</span>
//                                 </button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//             <div className="pt-4">
//                 <GoogleMap />
//             </div>
//         </div>
//     );
// };

// export default Contact;