import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { Slide } from 'react-slideshow-image';
import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { imageURL } from '../../imageURL/imageURL';

import biriani_chicken from "../../images/biriani_chicken.jpg";
import seasonal_tastes from "../../images/seasonal_tastes.jpg";
import coffee from "../../images/coffee.jpg";
import chicken_lolipop from "../../images/chicken_lolipop.jpg";



const FoodBeverage = () => {

    const [foodBeverageList, setFoodBeverageList] = useState([]);

    const fetchData = async () => {
        try {
            const res = await axios.get("/food-beverage");
            // console.log("food-beverage data: ", res.data.data);
            setFoodBeverageList(res.data.data);
        } catch (error) {
            console.log(error)
        }
    };
    useEffect(() => {
        fetchData();
    }, []);


    const foodBeverageListFilteringData = foodBeverageList.sort((a, b) => a.food_name !== b.food_name ? a.food_name < b.food_name ? -1 : 1 : 0);
    // console.log("foodBeverageListingData", foodBeverageListingData);
    // // Alternative/another way
    // const foodBeverageListingData = dataList.sort((a, b) => a.food_name.localeCompare(b.food_name, 'es', {sensitivity: 'base'}))
    // console.log("foodBeverageListingData", foodBeverageListingData);

    const [searchQuery, setSearchQuery] =useState('');
    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        const searchList = foodBeverageListFilteringData.filter((item) => {
            return item.food_name.toLowerCase().indexOf(query.toLowerCase()) !==-1;
        });

        setFoodBeverageList(searchList);
    }


    const [modalFoodBeverageIsOpen, setModalFoodBeverageIsOpen] = useState(false);
    const [arr, setArr] = useState([]);
    // console.log("arr foodBeverageList", arr);

    function openModalFoodBeverage() {
        // alert("mmm")
        setModalFoodBeverageIsOpen(true);
    }
    function closeModalFoodBeverage(e) {
        setModalFoodBeverageIsOpen(false);

    }
    const responsiveSettings = [
        {
            breakpoint: 800,
            autoplay: "true",
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ];

    return (
        <div className="container mb-5">
            <h3 className="mb-3 text-center">
                <span className="header_text_normal_bm header_text_bold">Food</span><span className="header_text_bold"> & Beverage</span>
            </h3>
            <div className="row g-3 pt-3">
                <div className="col-md-6">
                    <div className="row mb-3">
                        <label htmlFor="inputSearch" className="col-sm-2 col-form-label col-form-label-sm">Search</label>
                        <div className="col-sm-10">
                            <div className="accom__search" id="inputSearch">
                                <TextField
                                    fullWidth
                                    id="standard-bare"
                                    variant="outlined"
                                    placeholder="Search"
                                    food_name="search"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <IconButton>
                                                <SearchOutlined />
                                            </IconButton>
                                        ),
                                    }}
                                  
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row g-3 pt-2 d-flex justify-content-center">
                {
                    foodBeverageListFilteringData.length > 0 && foodBeverageListFilteringData.map((item, id) => {
                        return (
                            <div key={id} className="col-md-4">
                                <div className="accom__card">
                                    <div className="card text-center">
                                        <div className="accom__card__img">
                                            <img src={`${imageURL}/${item.card_image}`} className="card-img-top" alt="images" loading='lazy' />
                                        </div>
                                        <div className="card-body">
                                            <h6 className="accom__card__title">{item.food_name}</h6>
                                            <h6 className="accom__card__subtitle">{item.place_name}</h6>
                                            <p className="accom__card__discription pt-2">{item.description}</p>
                                            <div className="pt-3">
                                                <button type="button" className="btn btn__accom"
                                                    onClick={() => {
                                                        openModalFoodBeverage();
                                                        setArr(item)
                                                    }}
                                                >View Details</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <div className="row pt-5">
                    <div className="col-md-12">
                        <div className="btn__accom__more text-center">
                            <button type="button" className="btn btn__more">Load More</button>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={modalFoodBeverageIsOpen}
                    onRequestClose={closeModalFoodBeverage}
                    className="mymodal"
                    overlayClassName="myoverlay"
                    contentLabel="Example Modal"
                >
                    <div className='card-body modal__body'>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <span className='float-end' style={{ fontSize: "20px", cursor: "pointer" }} onClick={closeModalFoodBeverage}><i className="fa-solid fa-xmark close_btn"></i></span>
                                    <h5 className="header__consultation__text"><b>Place Information</b></h5>
                                </div>
                            </div>

                            <div className="row g-2 mb-3">
                                <div className="col-6">
                                    <div className="accom__slider">
                                        <Slide slidesToScroll={2} slidesToShow={1} indicators={true} responsive={responsiveSettings}>
                                            {
                                                arr.length === undefined && arr.image_list.map((item, id) => {
                                                    return (
                                                        <div key={id} className="accom__modal__img">
                                                            <img src={`${imageURL}/${item}`} alt="image" loading='lazy' />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Slide>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="ms-3 accom__modal__info">
                                        <h5 className="board__title">{arr.food_name}</h5>
                                        <p className="board__desc">{arr.place_name}</p>
                                        <p className="board__desc"><b>Price:</b> {arr.price}</p>
                                        <p className="board__desc"><b>Cell:</b> {arr.phone}</p>
                                        <p className="board__desc"><b>Address:</b> {arr.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default FoodBeverage;



// import React, { useState } from 'react';
// import Modal from 'react-modal';
// import { Slide } from 'react-slideshow-image';
// import { TextField, IconButton } from '@material-ui/core';
// import { SearchOutlined } from '@material-ui/icons';

// import biriani_chicken from "../../images/biriani_chicken.jpg";
// import seasonal_tastes from "../../images/seasonal_tastes.jpg";
// import coffee from "../../images/coffee.jpg";
// import chicken_lolipop from "../../images/chicken_lolipop.jpg";


// const foodBeverageList = [
//     {
//         id: 1, image: biriani_chicken, food_name: "Hazir Biriani", place_name: "Asian, Bangladeshi",
//         price: "1200 Tk",
//         phone: "897584590", address: "#8 of 274 Asian in Dhaka City",
//         describtion: "This fine business hotel, sits right in the diplomatic enclave",
//         modal_detials: [
//             {
//                 id: 1, food_name: "Hazir Biriani", place_name: "Dhaka", price: "1200 Tk",
//                 phone: "897584590", address: "#8 of 274 Asian in Dhaka City",
//                 image_list: [
//                     { id: 1, image: biriani_chicken },
//                     { id: 2, image: seasonal_tastes },
//                     { id: 3, image: biriani_chicken },
//                     { id: 4, image: seasonal_tastes },
//                 ]
//             }
//         ]
//     },
//     {
//         id: 2, image: coffee, food_name: "Coffee", place_name: "Asian, Dhaka, Lalbug Bangladeshi",
//         price: "1200 Tk",
//         phone: "897584590", address: "#8 of 274 Asian in Dhaka City",
//         describtion: "This fine business hotel, sits right in the diplomatic enclave",
//         modal_detials: [
//             {
//                 id: 1, food_name: "Coffee", place_name: "Dhaka", price: "1200 Tk",
//                 phone: "897584590", address: "#8 of 274 Asian in Dhaka City",
//                 image_list: [
//                     { id: 1, image: coffee },
//                     { id: 2, image: seasonal_tastes },
//                     { id: 3, image: biriani_chicken },
//                     { id: 4, image: seasonal_tastes },
//                 ]
//             }
//         ]
//     },
//     {
//         id: 3, image: chicken_lolipop, food_name: "Gloria Jean's Coffees", place_name: "American, Asian, Grill, Mid...",
//         price: "1200 Tk",
//         phone: "897584590", address: "Gulshan Avenue, House 35 Road 71, Dhaka City Bangladesh",
//         describtion: "This fine business hotel, sits right in the diplomatic enclave",
//         modal_detials: [
//             {
//                 id: 1, food_name: "Gloria Jean's Coffees", place_name: "American, Asian, Grill, Mid...", price: "1200 Tk",
//                 phone: "897584590", address: "Gulshan Avenue, House 35 Road 71, Dhaka City Bangladesh",
//                 image_list: [
//                     { id: 1, image: chicken_lolipop },
//                     { id: 2, image: seasonal_tastes },
//                     { id: 3, image: biriani_chicken },
//                     { id: 4, image: seasonal_tastes },
//                 ]
//             }
//         ]
//     },
// ]

// const FoodBeverage = () => {
//     const foodBeverageListFilteringData = foodBeverageList.sort((a, b) => a.food_name !== b.food_name ? a.food_name < b.food_name ? -1 : 1 : 0);
//     // console.log("foodBeverageListFilteringData", foodBeverageListFilteringData);
//     // // Alternative/another way
//     // const foodBeverageListFilteringData = dataList.sort((a, b) => a.food_name.localeCompare(b.food_name, 'es', {sensitivity: 'base'}))
//     // console.log("foodBeverageListFilteringData", foodBeverageListFilteringData);

//     const [foodBeverageListFilter, setFoodBeverageListFilter] = useState(foodBeverageListFilteringData);
//     const [searchQuery, setSearchQuery] =useState('');
//     const handleSearch = (e) => {
//         const query = e.target.value;
//         setSearchQuery(query);

//         const searchList = foodBeverageListFilteringData.filter((item) => {
//             return item.food_name.toLowerCase().indexOf(query.toLowerCase()) !==-1;
//         });

//         setFoodBeverageListFilter(searchList);
//     }


//     const [modalFoodBeverageIsOpen, setModalFoodBeverageIsOpen] = useState(false);
//     const [arr, setArr] = useState([]);
    
//     function openModalFoodBeverage() {
//         // alert("mmm")
//         setModalFoodBeverageIsOpen(true);
//     }
//     function closeModalFoodBeverage(e) {
//         setModalFoodBeverageIsOpen(false);

//     }
//     const responsiveSettings = [
//         {
//             breakpoint: 800,
//             autoplay: "true",
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1
//             }
//         },
//         {
//             breakpoint: 500,
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1
//             }
//         }
//     ];

//     return (
//         <div className="container mb-5">
//             <h3 className="mb-3 text-center">
//                 <span className="header_text_normal_bm header_text_bold">Food</span><span className="header_text_bold"> & Beverage</span>
//             </h3>
//             <div className="row g-3 pt-3">
//                 <div className="col-md-6">
//                     <div className="row mb-3">
//                         <label htmlFor="inputSearch" className="col-sm-2 col-form-label col-form-label-sm">Search</label>
//                         <div className="col-sm-10">
//                             <div className="accom__search" id="inputSearch">
//                                 <TextField
//                                     fullWidth
//                                     id="standard-bare"
//                                     variant="outlined"
//                                     placeholder="Search"
//                                     food_name="search"
//                                     value={searchQuery}
//                                     onChange={handleSearch}
//                                     InputProps={{
//                                         startAdornment: (
//                                             <IconButton>
//                                                 <SearchOutlined />
//                                             </IconButton>
//                                         ),
//                                     }}
                                  
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="row g-3 pt-2 d-flex justify-content-center">
//                 {
//                     foodBeverageListFilter.length > 0 && foodBeverageListFilter.map((item, id) => {
//                         return (
//                             <div key={id} className="col-md-4">
//                                 <div className="accom__card">
//                                     <div className="card text-center">
//                                         <div className="accom__card__img">
//                                             <img src={item.image} className="card-img-top" alt="images" />
//                                         </div>
//                                         <div className="card-body">
//                                             <h6 className="accom__card__title">{item.food_name}</h6>
//                                             <h6 className="accom__card__subtitle">{item.place_name}</h6>
//                                             <p className="accom__card__discription pt-2">{item.describtion}</p>
//                                             <div className="pt-3">
//                                                 <button type="button" className="btn btn__accom"
//                                                     onClick={() => {
//                                                         openModalFoodBeverage();
//                                                         setArr(item.modal_detials)
//                                                     }}
//                                                 >View Details</button>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         )
//                     })
//                 }
//                 <div className="row pt-5">
//                     <div className="col-md-12">
//                         <div className="btn__accom__more text-center">
//                             <button type="button" className="btn btn__more">Load More</button>
//                         </div>
//                     </div>
//                 </div>

//                 <Modal
//                     isOpen={modalFoodBeverageIsOpen}
//                     onRequestClose={closeModalFoodBeverage}
//                     className="mymodal"
//                     overlayClassName="myoverlay"
//                     contentLabel="Example Modal"
//                 >
//                     <div className='card-body modal__body'>
//                         <div className="container">
//                             <div className="row">
//                                 <div className="col-12">
//                                     <span className='float-end' style={{ fontSize: "20px", cursor: "pointer" }} onClick={closeModalFoodBeverage}><i className="fa-solid fa-xmark close_btn"></i></span>
//                                     <h5 className="header__consultation__text"><b>Place Information</b></h5>
//                                 </div>
//                             </div>
//                             {
//                                 arr.length > 0 && arr.map((item, id) => {
//                                     return (
//                                         <div key={id} className="row g-2 mb-3">
//                                             <div className="col-6">
//                                                 <div className="accom__slider">
//                                                     <Slide slidesToScroll={2} slidesToShow={1} indicators={true} responsive={responsiveSettings}>
//                                                         {
//                                                             item.image_list.length > 0 && item.image_list.map((item, id) => {
//                                                                 return (
//                                                                     <div key={id} className="accom__modal__img">
//                                                                         <img src={item.image} alt="image" />
//                                                                     </div>
//                                                                 )
//                                                             })
//                                                         }
//                                                     </Slide>
//                                                 </div>
//                                             </div>
//                                             <div className="col-6">
//                                                 <div className="ms-3 accom__modal__info">
//                                                     <h5 className="board__title">{item.food_name}</h5>
//                                                     <p className="board__desc">{item.place_name}</p>
//                                                     <p className="board__desc"><b>Price:</b> {item.price}</p>
//                                                     <p className="board__desc"><b>Cell:</b> {item.phone}</p>
//                                                     <p className="board__desc"><b>Address:</b> {item.address}</p>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     )
//                                 })
//                             }
//                         </div>
//                     </div>
//                 </Modal>
//             </div>
//         </div>
//     );
// };

// export default FoodBeverage;