import axios from 'axios';
import React, { useEffect, useState } from 'react';
import chairman_img from "../../images/chairman_img.png";
import { imageURL } from '../../imageURL/imageURL';


// const presMessage = [
//     {
//         id: 1, image: chairman_img, title_name: "Syed Habib Ali", designation: "Chairman", email: "sundarbanplus@gmail.com", address: "fdfg",
//         description: "ABOUT YOU gebruikt cookies en andere technologieën, zodat wij onze pagina's veilig en betrouwbaar aanbieden, de performance controleren en uw gebruikerservaring inclusief relevante inhoud en gepersonaliseerde reclame zowel op onze als ook op pagina's van derden verbeteren te kunnen. Hiervoor gebruiken wij informatie, waaronder gegevens over het gebruik van de pagina's en eindapparatuur. Met een klik op „OK“ gaat u akkoord met het gebruik van cookies en andere technologieën voor verwerking van uw gegevens, inclusief de verzending aan onze marketingpartner (derde). Onze partner gebruikt ook cookies en andere technologieën voor personalisering."
//     }
// ];

const ChairmanMessage = () => {

    // const [ele, setEle] = useState(presMessage[0]);
    const [ele, setEle] = useState([]);
    // console.log("presMessage", ele);
    const fetchData = async () => {
        try {
            const res = await axios.get("/chairman-message");
            // console.log("ChairmanMessage res: ", res);
            setEle(res.data.data);
        } catch (error) {
            console.log(error)
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="container pt-2 mb-5">
            <h3 className="mb-3 text-center">
                <span className="header_text_normal_bm header_text_bold">Chair</span><span className="header_text_bold">man Message</span>
            </h3>
            <div className="row g-3 pt-3">
                <div className="col-md-4">
                    <div className="pres__message__img">
                        <img src={`${imageURL}/images/chairman_message_folder/${ele[0]?.image}`} className="card-img-top" alt="images" />
                    </div>
                    {/* <div className="btn__accom__more text-center pt-3">
                            <button type="button" className="btn btn__more">Load More</button>
                        </div> */}
                </div>
                <div className="col-md-8">
                    <div className="ms-3 ">
                        <h5 className="board__title">{ele[0]?.title_name}</h5>
                        <h5 className="board__desc">{ele[0]?.designation_name}</h5>
                        <p className="board__desc"><i class="fa-solid fa-envelope"></i> {ele[0]?.email}</p>
                        <p className="board__desc"><i class="fas fa-house-chimney"></i> {ele[0]?.address}</p>
                        <p className="board__desc">{ele[0]?.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChairmanMessage;


// import React, { useState } from 'react';
// import chairman_img from "../../images/chairman_img.png";


// const presMessage = [
//     {
//         id: 1, photo: chairman_img, title: "Syed Habib Ali", designation: "Chairman", email: "sundarbanplus@gmail.com",
//         describtion: "ABOUT YOU gebruikt cookies en andere technologieën, zodat wij onze pagina's veilig en betrouwbaar aanbieden, de performance controleren en uw gebruikerservaring inclusief relevante inhoud en gepersonaliseerde reclame zowel op onze als ook op pagina's van derden verbeteren te kunnen. Hiervoor gebruiken wij informatie, waaronder gegevens over het gebruik van de pagina's en eindapparatuur. Met een klik op „OK“ gaat u akkoord met het gebruik van cookies en andere technologieën voor verwerking van uw gegevens, inclusief de verzending aan onze marketingpartner (derde). Onze partner gebruikt ook cookies en andere technologieën voor personalisering."
//     }
// ];

// const ChairmanMessage = () => {

//     const [ele, setEle] = useState(presMessage[0]);
//     // console.log("presMessage", ele);


//     return (
//         <div className="container pt-2 mb-5">
//             <h3 className="mb-3 text-center">
//                 <span className="header_text_normal_bm header_text_bold">Chair</span><span className="header_text_bold">man Message</span>
//             </h3>
//             <div className="row g-3 pt-3">
//                 <div className="col-md-4">
//                     <div className="pres__message__img">
//                         <img src={ele?.photo} className="card-img-top" alt="images" />
//                     </div>
//                     {/* <div className="btn__accom__more text-center pt-3">
//                             <button type="button" className="btn btn__more">Load More</button>
//                         </div> */}
//                 </div>
//                 <div className="col-md-8">
//                     <div className="ms-3 ">
//                         <h5 className="board__title">{ele?.title}</h5>
//                         <h5 className="board__desc">{ele?.designation}</h5>
//                         <p className="board__desc"><i class="fa-solid fa-envelope"></i> {ele?.email}</p>
//                         <p className="board__desc">{ele?.describtion}</p>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default ChairmanMessage;