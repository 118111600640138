import axios from 'axios';
import React, { useState, useEffect } from 'react';


const TouristPolice = () => {

    const [touristPoliceData, setTouristPoliceData] = useState([]);
    const fetchData = async () => {
        try {
            const res = await axios.get("/tourist-police");
            // console.log("res frontend:", res.data.data);
            setTouristPoliceData(res.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    // Tourist Police Headquarters
    const filtertouristPoliceHeadquartersData = touristPoliceData.filter((empolyeeType) => {
        return empolyeeType.tourist_police_category === "Tourist Police Headquarters";
    });
    // console.log("filtertouristPoliceHeadquartersData: ", filtertouristPoliceHeadquartersData);

    // Divisions
    const filterDivisionsData = touristPoliceData.filter((empolyeeType) => {
        return empolyeeType.tourist_police_category === "Divisions";
    });
    // console.log("filterDivisionsData: ", filterDivisionsData);

    // Regions
    const filterRegionsData = touristPoliceData.filter((empolyeeType) => {
        return empolyeeType.tourist_police_category === "Regions";
    });
    // console.log("filterRegionsData: ", filterRegionsData);

    // Zone
    const filterZoneData = touristPoliceData.filter((empolyeeType) => {
        return empolyeeType.tourist_police_category === "Zone";
    });
    // console.log("filterZoneData: ", filterZoneData);



    return (
        <div>
            <div className="container pt-2 mb-5">
                <h3 className="header_text_bold text-center mb-3">Tourist Police</h3>
                <div className="tourist__nav">
                    <ul className="nav nav-pills nav-fill nav__bg" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link tourist__link active" id="pills-by-TouristPoliceHeadquarters-tab" data-bs-toggle="pill" data-bs-target="#pills-by-TouristPoliceHeadquarters" type="button" role="tab" aria-controls="pills-by-TouristPoliceHeadquarters" aria-selected="true">Tourist Police Headquarters</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link tourist__link text-nowrap" id="pills-Divisions-tab" data-bs-toggle="pill" data-bs-target="#pills-Divisions" type="button" role="tab" aria-controls="pills-Divisions" aria-selected="false">Divisions</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link tourist__link text-nowrap" id="pills-Regions-tab" data-bs-toggle="pill" data-bs-target="#pills-Regions" type="button" role="tab" aria-controls="pills-Regions" aria-selected="false">Regions</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link tourist__link text-nowrap" id="pills-Zone-tab" data-bs-toggle="pill" data-bs-target="#pills-Zone" type="button" role="tab" aria-controls="pills-Zone" aria-selected="false">Zone</button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-by-TouristPoliceHeadquarters" role="tabpanel" aria-labelledby="pills-by-TouristPoliceHeadquarters-tab">
                        <table class="table table-bordered tourist__table">
                            <thead>
                                <tr>
                                    <th className="th__sr">Sr. No.</th>
                                    <th>Rank</th>
                                    <th>Designation</th>
                                    <th className="th__uadd">Unit Address</th>
                                    <th className="th__cn">Contact Number</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filtertouristPoliceHeadquartersData!==undefined && filtertouristPoliceHeadquartersData.map((item, i) => {
                                        return (
                                            <tr key={i} className="tr__body">
                                                <td>{i}.</td>
                                                <td>
                                                    <p>{item.rank}</p>
                                                </td>
                                                <td>
                                                    <p>{item.designation}</p>
                                                </td>
                                                <td>
                                                    <p>{item.unit_address}</p>
                                                </td>
                                                <td>
                                                    <p>{item.contact}</p>
                                                </td>
                                                <td>
                                                    <a className="text-decoration-none"><p onClick={() => { window.location.href = `mailto:${item.email}`; }}>{item.email}</p></a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="tab-pane fade" id="pills-Divisions" role="tabpanel" aria-labelledby="pills-Divisions-tab">
                        <table class="table table-bordered tourist__table">
                            <thead>
                                <tr>
                                    <th className="th__sr">Sr. No.</th>
                                    <th>Rank</th>
                                    <th>Designation</th>
                                    <th className="th__uadd">Unit Address</th>
                                    <th className="th__cn">Contact Number</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filterDivisionsData.length > 0 && filterDivisionsData.map((item, i) => {
                                        return (
                                            <tr key={i} className="tr__body">
                                                <td>{i}.</td>
                                                <td>
                                                    <p>{item.rank}</p>
                                                </td>
                                                <td>
                                                    <p>{item.designation}</p>
                                                </td>
                                                <td>
                                                    <p>{item.unit_address}</p>
                                                </td>
                                                <td>
                                                    <p>{item.contact}</p>
                                                </td>
                                                <td>
                                                    <a className="text-decoration-none"><p onClick={() => { window.location.href = `mailto:${item.email}`; }}>{item.email}</p></a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="tab-pane fade" id="pills-Regions" role="tabpanel" aria-labelledby="pills-Regions-tab">
                        <table class="table table-bordered tourist__table">
                            <thead>
                                <tr>
                                    <th className="th__sr">Sr. No.</th>
                                    <th>Rank</th>
                                    <th>Designation</th>
                                    <th className="th__uadd">Unit Address</th>
                                    <th className="th__cn">Contact Number</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filterRegionsData.length > 0 && filterRegionsData.map((item, i) => {
                                        return (
                                            <tr key={i} className="tr__body">
                                                <td>{i}.</td>
                                                <td>
                                                    <p>{item.rank}</p>
                                                </td>
                                                <td>
                                                    <p>{item.designation}</p>
                                                </td>
                                                <td>
                                                    <p>{item.unit_address}</p>
                                                </td>
                                                <td>
                                                    <p>{item.contact}</p>
                                                </td>
                                                <td>
                                                    <a className="text-decoration-none"><p onClick={() => { window.location.href = `mailto:${item.email}`; }}>{item.email}</p></a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="tab-pane fade" id="pills-Zone" role="tabpanel" aria-labelledby="pills-Zone-tab">
                        <table class="table table-bordered tourist__table">
                            <thead>
                                <tr>
                                    <th className="th__sr">Sr. No.</th>
                                    <th>Rank</th>
                                    <th>Designation</th>
                                    <th className="th__uadd">Unit Address</th>
                                    <th className="th__cn">Contact Number</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filterZoneData.length > 0 && filterZoneData.map((item, i) => {
                                        return (
                                            <tr key={i} className="tr__body">
                                                <td>{i}.</td>
                                                <td>
                                                    <p>{item.rank}</p>
                                                </td>
                                                <td>
                                                    <p>{item.designation}</p>
                                                </td>
                                                <td>
                                                    <p>{item.unit_address}</p>
                                                </td>
                                                <td>
                                                    <p>{item.contact}</p>
                                                </td>
                                                <td>
                                                    <a className="text-decoration-none"><p onClick={() => { window.location.href = `mailto:${item.email}`; }}>{item.email}</p></a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TouristPolice;


// import React from 'react';
// import { useState } from 'react';
// import under_construction from "../../images/under_construction.jpg";


// const TouristPolice = () => {
//     const [touristPoliceHeadquartersData, setTouristPoliceHeadquartersData] = useState([
//         { id: 1, rank: "Additional IGP", designation: "Additional IGP", unit_address: "House #34, Road # 04, Block # J, Banasree, Rampura, Dhaka-1219", contact: "01320157600, +88-02224405721", email: "addligpep@police.gov.bd" },
//         { id: 2, rank: "DIG", designation: "DIG, Headquarter", unit_address: "House #34, Road # 04, Block # J, Banasree, Rampura, Dhaka-1219", contact: "01320157600, +88-02224405721", email: "digtp@police.gov.bd" },
//         { id: 3, rank: "Additional IGP", designation: "Additional IGP", unit_address: "House #34, Road # 04, Block # J, Banasree, Rampura, Dhaka-1219", contact: "01320157600, +88-02224405721", email: "addligpep@police.gov.bd" },
//         { id: 4, rank: "Additional IGP", designation: "Additional IGP", unit_address: "House #34, Road # 04, Block # J, Banasree, Rampura, Dhaka-1219", contact: "01320157600, +88-02224405721", email: "addligpep@police.gov.bd" },
//         { id: 5, rank: "Additional IGP", designation: "Additional IGP", unit_address: "House #34, Road # 04, Block # J, Banasree, Rampura, Dhaka-1219", contact: "01320157600, +88-02224405721", email: "addligpep@police.gov.bd" },
//     ]);
//     const [touristPoliceDivisionsData, setTouristPoliceDivisionsData] = useState([
//         { id: 1, rank: "Additional IGP", designation: "Addl. DIG Dhaka-Sylhet-Mymensingh Division-1", unit_address: "তাহমিনা মঞ্জিল, বাড়ি নং-৯/৫, ব্লক-জে, বনশ্রী, ঢাকা।", contact: "01320157600, +88-02224405721", email: "addligpep@police.gov.bd" },
//         { id: 2, rank: "DIG", designation: "Addl. DIG(Chattagram Division -2)", unit_address: "House #34, Road # 04, Block # J, Banasree, Rampura, Dhaka-1219", contact: "01320157600, +88-02224405721", email: "digtp@police.gov.bd" },
//         { id: 3, rank: "Additional IGP", designation: "Incharge (Saint Martin Sub-Zone)", unit_address: "০২ নং আব্দুল মালেক সড়ক, দক্ষিন খুলশী, আ/এ, পাহাড়তলী,-৪২০২, চট্টগ্রাম।", contact: "01320157600, +88-02224405721", email: "addligpep@police.gov.bd" },
//         { id: 4, rank: "Additional IGP", designation: "Addl. DIG(Chattagram Division -2)", unit_address: "House #34, Road # 04, Block # J, Banasree, Rampura, Dhaka-1219", contact: "01320157600, +88-02224405721", email: "addligpep@police.gov.bd" },
//         { id: 5, rank: "Additional IGP", designation: "Additional IGP", unit_address: "House #34, Road # 04, Block # J, Banasree, Rampura, Dhaka-1219", contact: "01320157600, +88-02224405721", email: "addligpep@police.gov.bd" },
//     ]);
//     const [touristPoliceRegionsData, setTouristPoliceRegionsData] = useState([
//         { id: 1, rank: "Superintendent of police", designation: "SP(Dhaka Region)", unit_address: "তাহমিনা মঞ্জিল, বাড়ি নং-৯/৫, ব্লক-জে, বনশ্রী, ঢাকা।", contact: "01320157600, +88-02224405721", email: "spddtp@police.gov.bd" },
//         { id: 2, rank: "Superintendent of police", designation: "SP(Rangpur Region)", unit_address: "House #34, Road # 04, Block # J, Banasree, Rampura, Dhaka-1219", contact: "01320157600, +88-02224405721", email: "digtp@police.gov.bd" },
//         { id: 3, rank: "Superintendent of police", designation: "SP(Dinajpur Region)", unit_address: "০২ নং আব্দুল মালেক সড়ক, দক্ষিন খুলশী, আ/এ, পাহাড়তলী,-৪২০২, চট্টগ্রাম।", contact: "01320157600, +88-02224405721", email: "spddtp@police.gov.bd" },
//         { id: 4, rank: "Superintendent of police", designation: "SP(Rangpur Region)", unit_address: "House #34, Road # 04, Block # J, Banasree, Rampura, Dhaka-1219", contact: "01320157600, +88-02224405721", email: "addligpep@police.gov.bd" },
//         { id: 5, rank: "Superintendent of police", designation: "Additional IGP", unit_address: "House #34, Road # 04, Block # J, Banasree, Rampura, Dhaka-1219", contact: "01320157600, +88-02224405721", email: "addligpep@police.gov.bd" },
//     ]);
//     const [touristPoliceZoneData, setTouristPoliceZoneData] = useState([
//         { id: 1, rank: "Incharge", designation: "Incharge (Teknaf Zone)", unit_address: "তাহমিনা মঞ্জিল, বাড়ি নং-৯/৫, ব্লক-জে, বনশ্রী, ঢাকা।", contact: "01320157600, +88-02224405721", email: "spddtp@police.gov.bd" },
//         { id: 2, rank: "Incharge", designation: "Incharge (Teknaf Zone)", unit_address: "House #34, Road # 04, Block # J, Banasree, Rampura, Dhaka-1219", contact: "01320157600, +88-02224405721", email: "digtp@police.gov.bd" },
//         { id: 3, rank: "Incharge", designation: "Incharge (Dinajpur Region)", unit_address: "০২ নং আব্দুল মালেক সড়ক, দক্ষিন খুলশী, আ/এ, পাহাড়তলী,-৪২০২, চট্টগ্রাম।", contact: "01320157600, +88-02224405721", email: "spddtp@police.gov.bd" },
//         { id: 4, rank: "Incharge", designation: "Incharge (Rangpur Region)", unit_address: "House #34, Road # 04, Block # J, Banasree, Rampura, Dhaka-1219", contact: "01320157600, +88-02224405721", email: "addligpep@police.gov.bd" },
//         { id: 5, rank: "Incharge", designation: "Incharge", unit_address: "House #34, Road # 04, Block # J, Banasree, Rampura, Dhaka-1219", contact: "01320157600, +88-02224405721", email: "addligpep@police.gov.bd" },
//     ]);


//     return (
//         <div>
//             <div className="container pt-2 mb-5">
//                 <h3 className="header_text_bold text-center mb-3">Tourist Police</h3>
//                 <div className="tourist__nav">
//                     <ul className="nav nav-pills mb-3 nav-fill nav__bg" id="pills-tab" role="tablist">
//                         <li className="nav-item" role="presentation">
//                             <button className="nav-link tourist__link active" id="pills-by-TouristPoliceHeadquarters-tab" data-bs-toggle="pill" data-bs-target="#pills-by-TouristPoliceHeadquarters" type="button" role="tab" aria-controls="pills-by-TouristPoliceHeadquarters" aria-selected="true">Tourist Police Headquarters</button>
//                         </li>
//                         <li className="nav-item" role="presentation">
//                             <button className="nav-link tourist__link text-nowrap" id="pills-Divisions-tab" data-bs-toggle="pill" data-bs-target="#pills-Divisions" type="button" role="tab" aria-controls="pills-Divisions" aria-selected="false">Divisions</button>
//                         </li>
//                         <li className="nav-item" role="presentation">
//                             <button className="nav-link tourist__link text-nowrap" id="pills-Regions-tab" data-bs-toggle="pill" data-bs-target="#pills-Regions" type="button" role="tab" aria-controls="pills-Regions" aria-selected="false">Regions</button>
//                         </li>
//                         <li className="nav-item" role="presentation">
//                             <button className="nav-link tourist__link text-nowrap" id="pills-Zone-tab" data-bs-toggle="pill" data-bs-target="#pills-Zone" type="button" role="tab" aria-controls="pills-Zone" aria-selected="false">Zone</button>
//                         </li>
//                     </ul>
//                 </div>
//                 <div className="tab-content" id="pills-tabContent">
//                     <div className="tab-pane fade show active" id="pills-by-TouristPoliceHeadquarters" role="tabpanel" aria-labelledby="pills-by-TouristPoliceHeadquarters-tab">
//                         <table class="table table-bordered tourist__table">
//                             <thead>
//                                 <tr>
//                                     <th className="th__sr">Sr. No.</th>
//                                     <th>Rank</th>
//                                     <th>Designation</th>
//                                     <th className="th__uadd">Unit Address</th>
//                                     <th className="th__cn">Contact Number</th>
//                                     <th>Email</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {
//                                     touristPoliceHeadquartersData.length > 0 && touristPoliceHeadquartersData.map((item, id) => {
//                                         return (
//                                             <tr key={id} className="tr__body">
//                                                 <td>{item.id}.</td>
//                                                 <td>
//                                                     <p>{item.rank}</p>
//                                                 </td>
//                                                 <td>
//                                                     <p>{item.designation}</p>
//                                                 </td>
//                                                 <td>
//                                                     <p>{item.unit_address}</p>
//                                                 </td>
//                                                 <td>
//                                                     <p>{item.contact}</p>
//                                                 </td>
//                                                 <td>
//                                                     <a className="text-decoration-none"><p onClick={() => { window.location.href = `mailto:${item.email}`; }}>{item.email}</p></a>
//                                                 </td>
//                                             </tr>
//                                         )
//                                     })
//                                 }
//                             </tbody>
//                         </table>
//                     </div>
//                     <div className="tab-pane fade" id="pills-Divisions" role="tabpanel" aria-labelledby="pills-Divisions-tab">
//                         <table class="table table-bordered tourist__table">
//                             <thead>
//                                 <tr>
//                                     <th className="th__sr">Sr. No.</th>
//                                     <th>Rank</th>
//                                     <th>Designation</th>
//                                     <th className="th__uadd">Unit Address</th>
//                                     <th className="th__cn">Contact Number</th>
//                                     <th>Email</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {
//                                     touristPoliceDivisionsData.length > 0 && touristPoliceDivisionsData.map((item, id) => {
//                                         return (
//                                             <tr key={id} className="tr__body">
//                                                 <td>{item.id}.</td>
//                                                 <td>
//                                                     <p>{item.rank}</p>
//                                                 </td>
//                                                 <td>
//                                                     <p>{item.designation}</p>
//                                                 </td>
//                                                 <td>
//                                                     <p>{item.unit_address}</p>
//                                                 </td>
//                                                 <td>
//                                                     <p>{item.contact}</p>
//                                                 </td>
//                                                 <td>
//                                                     <a className="text-decoration-none"><p onClick={() => { window.location.href = `mailto:${item.email}`; }}>{item.email}</p></a>
//                                                 </td>
//                                             </tr>
//                                         )
//                                     })
//                                 }
//                             </tbody>
//                         </table>
//                     </div>
//                     <div className="tab-pane fade" id="pills-Regions" role="tabpanel" aria-labelledby="pills-Regions-tab">
//                         <table class="table table-bordered tourist__table">
//                             <thead>
//                                 <tr>
//                                     <th className="th__sr">Sr. No.</th>
//                                     <th>Rank</th>
//                                     <th>Designation</th>
//                                     <th className="th__uadd">Unit Address</th>
//                                     <th className="th__cn">Contact Number</th>
//                                     <th>Email</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {
//                                     touristPoliceRegionsData.length > 0 && touristPoliceRegionsData.map((item, id) => {
//                                         return (
//                                             <tr key={id} className="tr__body">
//                                                 <td>{item.id}.</td>
//                                                 <td>
//                                                     <p>{item.rank}</p>
//                                                 </td>
//                                                 <td>
//                                                     <p>{item.designation}</p>
//                                                 </td>
//                                                 <td>
//                                                     <p>{item.unit_address}</p>
//                                                 </td>
//                                                 <td>
//                                                     <p>{item.contact}</p>
//                                                 </td>
//                                                 <td>
//                                                     <a className="text-decoration-none"><p onClick={() => { window.location.href = `mailto:${item.email}`; }}>{item.email}</p></a>
//                                                 </td>
//                                             </tr>
//                                         )
//                                     })
//                                 }
//                             </tbody>
//                         </table>
//                     </div>
//                     <div className="tab-pane fade" id="pills-Zone" role="tabpanel" aria-labelledby="pills-Zone-tab">
//                         <table class="table table-bordered tourist__table">
//                             <thead>
//                                 <tr>
//                                     <th className="th__sr">Sr. No.</th>
//                                     <th>Rank</th>
//                                     <th>Designation</th>
//                                     <th className="th__uadd">Unit Address</th>
//                                     <th className="th__cn">Contact Number</th>
//                                     <th>Email</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {
//                                     touristPoliceZoneData.length > 0 && touristPoliceZoneData.map((item, id) => {
//                                         return (
//                                             <tr key={id} className="tr__body">
//                                                 <td>{item.id}.</td>
//                                                 <td>
//                                                     <p>{item.rank}</p>
//                                                 </td>
//                                                 <td>
//                                                     <p>{item.designation}</p>
//                                                 </td>
//                                                 <td>
//                                                     <p>{item.unit_address}</p>
//                                                 </td>
//                                                 <td>
//                                                     <p>{item.contact}</p>
//                                                 </td>
//                                                 <td>
//                                                     <a className="text-decoration-none"><p onClick={() => { window.location.href = `mailto:${item.email}`; }}>{item.email}</p></a>
//                                                 </td>
//                                             </tr>
//                                         )
//                                     })
//                                 }
//                             </tbody>
//                         </table>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default TouristPolice;