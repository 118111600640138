import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import '../dashboard/AdminDashboard.css';
// import '../AppAdmin.css';
import "../../AppAdmin.css"

const AddHomeBannerSetup = () => {

    const [inputField, setInputField] = useState({
        image_list: [],
    });

    const handleMultipleImageChange = (e) => {
        setInputField({
            ...inputField,
            image_list: e.target.files,
        })
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        console.log("submitHandler: ", inputField);

        const formData = new FormData();
        Array.from(inputField.image_list).forEach(item => {
            formData.append("image_list", item)
        })

        const url = "/add-home-banner";
        console.log("image_list", inputField.image_list, "image_list m", inputField.image_list.name);
        try {
            let response = await axios.post(url, formData);
            if (response.status == 201) {
                alert("Are you Added  toData", formData);
                setInputField({
                    image_list: [],
                })
                document.getElementById("InputMultipleImageUpload").value = '';
            } else {
                alert("Failed")
            }
        } catch (error) {
            console.log(error);
        }

    };

    const inputMultipleImageUploadClose = (index) => {
        const newMultipleImage = [...inputField.image_list];
        newMultipleImage.splice(index, 1);
        setInputField({ ...inputField, image_list: newMultipleImage });

    };



    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Add Home Banner Setup
                        <Link to='/dashboard/home-banner-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        <form className="mt-1" encType='multipart/form-data'
                            onSubmit={submitHandler}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <label htmlFor="InputMultipleImageUpload" className="col-form-label col-form-label-sm d-block" >
                                        Modal Photo Upload <span className="text-danger">*</span></label>
                                    <input className="form-control form-control-sm form__ptb" id="InputMultipleImageUpload"
                                        type="file"
                                        multiple
                                        name="image_list"
                                        accept="image/*"
                                        onChange={handleMultipleImageChange}
                                    />

                                    <div className="row g-3 pt-4">

                                        {Array.from(inputField.image_list).map(item => {
                                            return (
                                                <div className="col-md-2">
                                                    <div className="d-flex input__img">
                                                        <img src={item ? URL.createObjectURL(item) : null} alt="Preview" />
                                                        <span className="close__icon"
                                                            onClick={inputMultipleImageUploadClose}>
                                                            <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddHomeBannerSetup;





