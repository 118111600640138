
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
// import '../dashboard/AdminDashboard.css';
import '../AppAdmin.css';
import Apd from '../boardOfDirectorsSetup/Apd';

const AddMemberListSetup = () => {


    const [inputField, setInputField] = useState({
        general_member_list: "",
        title_name: "",
        organization_name: "",
        address: "",
        card_link: "",
        cell: "",
        email: "",
        tel: "",
        web_link: "",
        description: "",
        image: null,
    });

    const handleImageChange = (e) => {
        setInputField({
            ...inputField,
            image: e.target.files[0]
        })
    };

    const inputsChange = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });

    };

    const submitFormData = async (e) => {
        e.preventDefault();
        console.log("submitFormData: ", inputField);

        const formData = new FormData();
        formData.append("image", inputField.image, inputField.image.name);
        formData.append("general_member_list", inputField.general_member_list)
        formData.append("title_name", inputField.title_name)
        formData.append("organization_name", inputField.organization_name)
        formData.append("email", inputField.email)
        formData.append("address", inputField.address)
        formData.append("card_link", inputField.card_link)
        formData.append("web_link", inputField.web_link)
        formData.append("cell", inputField.cell)
        formData.append("tel", inputField.tel)
        formData.append("description", inputField.description)


        const url = "/add-member-list";
        // console.log("mm", inputField.image, "mmm", inputField.image.name);
        try {
            let response = await axios.post(url, formData);
            if (response.status == 200) {
                alert("Are you Added  toData", formData);
                setInputField({
                    general_member_list: "",
                    title_name: "",
                    organization_name: "",
                    address: "",
                    card_link: "",
                    cell: "",
                    email: "",
                    tel: "",
                    web_link: "",
                    description: "",
                    image: null,
                });
                document.getElementById("InputPhotoUpload").value = '';
            } else {
                alert("Failed")
            }
        } catch (error) {
            console.log(error);
        }

        // const url = "/add-member-list";
        // axios.post(url, formData)
        //     .then(response => {
        //         console.log(response, "dff");
        //         if (response.status === 201) {
        //             alert("Are you Added  to Data", formData);
        //             setInputField({
        //                 title_name: "",
        //                 organization_name: "",
        //                 address: "",
        //                 card_link: "",
        //                 cell: "",
        //                 email: "",
        //                 fax: "",
        //                 tel: "",
        //                 web_link: "",
        //             });
        //         } else {
        //             alert("Failed")
        //         }
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     })

    };


    const inputPhotoUploadClose = () => {
        setInputField({ ...inputField, image: '' });
        document.getElementById("InputPhotoUpload").value = ''
    };


    // const submitFormData = (e) => {
    //     e.preventDefault();
    //     console.log("submitFormData: ", inputField);
    //     setInputField({
    //         title_name: "",
    //         organization_name: "",
    //         address: "",
    //         card_link: "",
    //         cell: "",
    //         email: "",
    //         fax: "",
    //         tel: "",
    //         web_link: "",
    //     });
    //     const url = "/add-member-list";
    //     axios.post(url, inputField)
    //         .then(response => {
    //             const result = response.data;
    //             const { status, message, data, } = result;
    //             if (response.data.status === 200) {
    //                 alert({ message, status })
    //             } else {
    //                 alert(message)
    //             }
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         })
    // };

    // const submitFormData = (e) => {
    //     e.preventDefault();
    //     console.log("submitFormData: ", inputField);
    //     fetchData();
    // };
    // const [data, setData] = useState([]);
    // const fetchData = async () => {
    //     try {
    //         const response = await axios.post("/member-list", inputField);
    //         console.log("response:", response);
    //         setData(response.data);
    //         setInputField({
    //             title_name: "",
    //             organization_name: "",
    //             address: "",
    //             card_link: "",
    //             cell: "",
    //             email: "",
    //             fax: "",
    //             tel: "",
    //             web_link: "",
    //         });
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }





    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Add Member List Setup
                        <Link to='/dashboard/member-list-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        {/* <Apd /> */}
                        <form className="mt-1"
                            onSubmit={submitFormData}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <div className="row g-3 mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="inputGeneralMemberList" className="col-form-label col-form-label-sm d-block">
                                            General Member List Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm form__ptb " id="inputGeneralMemberList"
                                                name="general_member_list"
                                                value={inputField.general_member_list}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="inputTitleName" className="col-form-label col-form-label-sm d-block">
                                                Title Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm form__ptb " id="inputTitleName"
                                                name="title_name"
                                                value={inputField.title_name}
                                                onChange={inputsChange}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="inputOrganizationName" className="col-form-label col-form-label-sm d-block">
                                                Organization Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="inputOrganizationName"
                                                name="organization_name"
                                                value={inputField.organization_name}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputCardLink" className="col-form-label col-form-label-sm d-block " >
                                                Card Link <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputCardLink"
                                                name="card_link"
                                                value={inputField.card_link}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputWebLink" className="col-form-label col-form-label-sm d-block " >
                                                Web Link <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputWebLink"
                                                name="web_link"
                                                value={inputField.web_link}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputEmail" className="col-form-label col-form-label-sm d-block " >
                                                Email <span className="text-danger">*</span></label>

                                            <input type="email" className="form-control form-control-sm form__ptb " id="InputEmail"
                                                name="email"
                                                value={inputField.email}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputAddress" className="col-form-label col-form-label-sm d-block " >
                                                Address <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputAddress"
                                                name="address"
                                                value={inputField.address}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-6">
                                            <label htmlFor="InputCell" className="col-form-label col-form-label-sm d-block " >Cell <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputCell"
                                                name="cell"
                                                value={inputField.cell}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputTelephone" className="col-form-label col-form-label-sm d-block " >
                                                Telephone </label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputTelephone"
                                                name="tel"
                                                value={inputField.tel}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-2">
                                        <div className="col-md-6">
                                            <label htmlFor="DescriptionFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
                                                Description <span className="text-danger">*</span></label>
                                            <textarea className="form-control" id="DescriptionFormControlTextarea1" rows="3"
                                                name="description"
                                                value={inputField.description}
                                                onChange={inputsChange}
                                            ></textarea>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >
                                                Card Photo Upload <span className="text-danger">*</span></label>
                                            <input className="form-control form-control-sm form__ptb" id="InputPhotoUpload"
                                                type="file"
                                                // multiple
                                                name="image"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                            />
                                            <div className="pt-4">
                                                {inputField.image && (
                                                    <div className="d-flex input__img">
                                                        <img src={URL.createObjectURL(inputField.image)} alt="Preview" />

                                                        <span className="close__icon"
                                                            onClick={inputPhotoUploadClose}>
                                                            <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddMemberListSetup;



// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import axios from "axios";
// // import '../dashboard/AdminDashboard.css';
// import '../AppAdmin.css';
// import Apd from '../boardOfDirectorsSetup/Apd';

// const AddMemberListSetup = () => {


//     const [inputField, setInputField] = useState({
//         title_name: "",
//         organization_name: "",
//         address: "",
//         card_link: "",
//         cell: "",
//         email: "",
//         fax: "",
//         tel: "",
//         web_link: "",
//     });

//     const inputsChange = (e) => {
//         setInputField({ ...inputField, [e.target.name]: e.target.value });

//     };

//     const submitFormData = (e) => {
//         e.preventDefault();
//         console.log("submitFormData: ", inputField);
//         setInputField({
//             title_name: "",
//             organization_name: "",
//             address: "",
//             card_link: "",
//             cell: "",
//             email: "",
//             fax: "",
//             tel: "",
//             web_link: "",
//         });
//         const url = "/add-member-list";
//         axios.post(url, inputField)
//             .then(response => {
//                 const result = response.data;
//                 const { status, message, data, } = result;
//                 if (response.data.status === 200) {
//                     alert({ message, status })
//                 } else {
//                     alert(message)
//                 }
//             })
//             .catch(error => {
//                 console.log(error);
//             })
//     };

//     // const submitFormData = (e) => {
//     //     e.preventDefault();
//     //     console.log("submitFormData: ", inputField);
//     //     fetchData();
//     // };
//     // const [data, setData] = useState([]);
//     // const fetchData = async () => {
//     //     try {
//     //         const response = await axios.post("/member-list", inputField);
//     //         console.log("response:", response);
//     //         setData(response.data);
//     //         setInputField({
//     //             title_name: "",
//     //             organization_name: "",
//     //             address: "",
//     //             card_link: "",
//     //             cell: "",
//     //             email: "",
//     //             fax: "",
//     //             tel: "",
//     //             web_link: "",
//     //         });
//     //     } catch (error) {
//     //         console.error(error);
//     //     }
//     // }





//     return (
//         <div className="container pt-4">
//             <div className="card">
//                 <div className="card-header">
//                     <h4 className="header__text  pt-1">Add Member List Setup
//                         <Link to='/dashboard/member-list-setup' className="back__btn  float-end"> Back</Link>
//                     </h4>
//                 </div>
//                 <div className="bg__from__color">
//                     <div className="card-body">
//                         {/* <Apd /> */}
//                         <form className="mt-1"
//                             onSubmit={submitFormData}
//                         >
//                             <div className="row g-0">
//                                 <div className="col-md-12">
//                                     <div className="row g-3 mb-3">
//                                         <div className="col-md-6">
//                                             <label htmlFor="inputTitleName" className="col-form-label col-form-label-sm d-block">
//                                                 Title Name <span className="text-danger">*</span></label>
//                                             <input type="text" className="form-control form-control-sm form__ptb " id="inputTitleName"
//                                                 name="title_name"
//                                                 value={inputField.title_name}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>

//                                         <div className="col-md-6">
//                                             <label htmlFor="inputOrganizationName" className="col-form-label col-form-label-sm d-block">
//                                                 Organization Name <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="inputOrganizationName"
//                                                 name="organization_name"
//                                                 value={inputField.organization_name}
//                                                 onChange={inputsChange}
//                                             />

//                                         </div>
//                                     </div>
//                                     <div className="row mb-2 d-flex">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputCardLink" className="col-form-label col-form-label-sm d-block " >
//                                                 Card Link <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputCardLink"
//                                                 name="card_link"
//                                                 value={inputField.card_link}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputWebLink" className="col-form-label col-form-label-sm d-block " >
//                                                 Web Link <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputWebLink"
//                                                 name="web_link"
//                                                 value={inputField.web_link}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div className="row mb-2 d-flex">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputEmail" className="col-form-label col-form-label-sm d-block " >
//                                                 Email <span className="text-danger">*</span></label>

//                                             <input type="email" className="form-control form-control-sm form__ptb " id="InputEmail"
//                                                 name="email"
//                                                 value={inputField.email}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputFax" className="col-form-label col-form-label-sm d-block " >
//                                                 Fax <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputFax"
//                                                 name="fax"
//                                                 value={inputField.fax}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div className="row mb-2">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputCell" className="col-form-label col-form-label-sm d-block " >Cell</label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputCell"
//                                                 name="cell"
//                                                 value={inputField.cell}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputTelephone" className="col-form-label col-form-label-sm d-block " >
//                                                 Telephone <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputTelephone"
//                                                 name="tel"
//                                                 value={inputField.tel}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div className="row mb-2">
//                                         <div className="col-md-6">
//                                             <label htmlFor="AddressFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
//                                                 Address <span className="text-danger">*</span></label>
//                                             <textarea className="form-control" id="AddressFormControlTextarea1" rows="3"
//                                                 name="address"
//                                                 value={inputField.address}
//                                                 onChange={inputsChange}
//                                             ></textarea>
//                                         </div>
//                                     </div>

//                                 </div>
//                             </div>
//                             <div className="row">
//                                 <div className="col-12">
//                                     <div className="float-right pt-4 mr-0">
//                                         <button type="submit" className="btn__save mx-3"
//                                         // onClick={saveMedicationChartPartOneData}
//                                         >
//                                             <b>Save</b>
//                                         </button>
//                                         <button className="btn__cancel"
//                                         // onClick={props.closeModal}
//                                         >
//                                             <b>Cancel</b>
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </form>

//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddMemberListSetup;

// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import axios from "axios";
// // import '../dashboard/AdminDashboard.css';
// import '../AppAdmin.css';
// import Apd from '../boardOfDirectorsSetup/Apd';

// const AddMemberListSetup = () => {


//     const [inputField, setInputField] = useState({
//         title_name: "",
//         organization_name: "",
//         address: "",
//         card_link: "",
//         cell: "",
//         email: "",
//         fax: "",
//         tel: "",
//         web_link: "",
//     });

//     const inputsChange = (e) => {
//         setInputField({ ...inputField, [e.target.name]: e.target.value });

//     };

//     const submitFormData = async (e) => {
//         e.preventDefault();
//         console.log("submitHandler: ", inputField);


//         const formData = new FormData();
//         formData.append("title_name", inputField.title_name)
//         formData.append("organization_name", inputField.organization_name)
//         formData.append("org_name", inputField.org_name)
//         formData.append("address", inputField.address)
//         formData.append("card_link", inputField.position_of_org)
//         formData.append("web_link", inputField.web_link)
//         formData.append("cell", inputField.cell)
//         formData.append("email", inputField.email)
//         formData.append("tel", inputField.tel)
//         formData.append("fax", inputField.fax)


//         const url = "/add-member-list";
//         try {
//             let response = await axios.post(url, formData);
//             if (response.status == 201) {
//                 alert("Successfull", formData);
//                 setInputField({
//                     title_name: "",
//                     organization_name: "",
//                     address: "",
//                     card_link: "",
//                     cell: "",
//                     email: "",
//                     fax: "",
//                     tel: "",
//                     web_link: "",
//                 });
//             } else {
//                 alert("Failed")
//             }
//         } catch (error) {

//         }
//     };

//     // const submitFormData = (e) => {
//     //     e.preventDefault();
//     //     console.log("submitFormData: ", inputField);
//     //     fetchData();
//     // };
//     // const [data, setData] = useState([]);
//     // const fetchData = async () => {
//     //     try {
//     //         const response = await axios.post("/member-list", inputField);
//     //         console.log("response:", response);
//     //         setData(response.data);
//     //         setInputField({
//     //             title_name: "",
//     //             organization_name: "",
//     //             address: "",
//     //             card_link: "",
//     //             cell: "",
//     //             email: "",
//     //             fax: "",
//     //             tel: "",
//     //             web_link: "",
//     //         });
//     //     } catch (error) {
//     //         console.error(error);
//     //     }
//     // }





//     return (
//         <div className="container pt-4">
//             <div className="card">
//                 <div className="card-header">
//                     <h4 className="header__text  pt-1">Add Member List Setup
//                         <Link to='/dashboard/member-list-setup' className="back__btn  float-end"> Back</Link>
//                     </h4>
//                 </div>
//                 <div className="bg__from__color">
//                     <div className="card-body">
//                         {/* <Apd /> */}
//                         <form className="mt-1"
//                             onSubmit={submitFormData}
//                         >
//                             <div className="row g-0">
//                                 <div className="col-md-12">
//                                     <div className="row g-3 mb-3">
//                                         <div className="col-md-6">
//                                             <label htmlFor="inputTitleName" className="col-form-label col-form-label-sm d-block">
//                                                 Title Name <span className="text-danger">*</span></label>
//                                             <input type="text" className="form-control form-control-sm form__ptb " id="inputTitleName"
//                                                 name="title_name"
//                                                 value={inputField.title_name}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>

//                                         <div className="col-md-6">
//                                             <label htmlFor="inputOrganizationName" className="col-form-label col-form-label-sm d-block">
//                                                 Organization Name <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="inputOrganizationName"
//                                                 name="organization_name"
//                                                 value={inputField.organization_name}
//                                                 onChange={inputsChange}
//                                             />

//                                         </div>
//                                     </div>
//                                     <div className="row mb-2 d-flex">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputCardLink" className="col-form-label col-form-label-sm d-block " >
//                                                 Card Link <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputCardLink"
//                                                 name="card_link"
//                                                 value={inputField.card_link}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputWebLink" className="col-form-label col-form-label-sm d-block " >
//                                                 Web Link <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputWebLink"
//                                                 name="web_link"
//                                                 value={inputField.web_link}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div className="row mb-2 d-flex">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputEmail" className="col-form-label col-form-label-sm d-block " >
//                                                 Email <span className="text-danger">*</span></label>

//                                             <input type="email" className="form-control form-control-sm form__ptb " id="InputEmail"
//                                                 name="email"
//                                                 value={inputField.email}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputFax" className="col-form-label col-form-label-sm d-block " >
//                                                 Fax <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputFax"
//                                                 name="fax"
//                                                 value={inputField.fax}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div className="row mb-2">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputCell" className="col-form-label col-form-label-sm d-block " >Cell</label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputCell"
//                                                 name="cell"
//                                                 value={inputField.cell}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputTelephone" className="col-form-label col-form-label-sm d-block " >
//                                                 Telephone <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputTelephone"
//                                                 name="tel"
//                                                 value={inputField.tel}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div className="row mb-2">
//                                         <div className="col-md-6">
//                                             <label htmlFor="AddressFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
//                                                 Address <span className="text-danger">*</span></label>
//                                             <textarea className="form-control" id="AddressFormControlTextarea1" rows="3"
//                                                 name="address"
//                                                 value={inputField.address}
//                                                 onChange={inputsChange}
//                                             ></textarea>
//                                         </div>
//                                     </div>

//                                 </div>
//                             </div>
//                             <div className="row">
//                                 <div className="col-12">
//                                     <div className="float-right pt-4 mr-0">
//                                         <button type="submit" className="btn__save mx-3"
//                                         // onClick={saveMedicationChartPartOneData}
//                                         >
//                                             <b>Save</b>
//                                         </button>
//                                         <button className="btn__cancel"
//                                         // onClick={props.closeModal}
//                                         >
//                                             <b>Cancel</b>
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </form>

//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddMemberListSetup;