import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { imageURL } from '../../../../imageURL/imageURL';
// import '../dashboard/AdminDashboard.css';
import '../../AppAdmin.css';

const EditHomeBannerSetup = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [editInputField, setEditInputField] = useState({
        image_list: [],
    });


    const fetchData = async (id) => {
        try {
            const res = await axios.get(`/edit-home-banner/${id}`);
            console.log("edit-home-banner: ", res)
            setEditInputField(res.data.data)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData(id);
    }, []);

    const handleMultipleImageChange = (e) => {
        const existingImg = [...editInputField.image_list];
        const newImg = e.target.files;
        console.log(newImg, "dd")
        Array.from(newImg).forEach(img => {
            existingImg.push(img)
        })
        setEditInputField({
            ...editInputField,
            image_list: existingImg,
        })
    };



    const updateFormData = async (e) => {
        e.preventDefault();
        console.log("updateFormData: ", editInputField);

        const formData = new FormData();formData.append("card_image", editInputField.card_image);
        Array.from(editInputField.image_list).forEach(item => {
            formData.append("image_list", item)
        });

        const url = `/update-home-banner/${id}`;
        // console.log("image_list", editInputField.image_list, "image_list m", editInputField.image_list.name);
        // console.log("update-home-banner", editInputField.image, "mmm", editInputField.image.name);
        try {
            let res = await axios.put(url, formData);
            console.log("update-home-banner: ", res)
            if (res.status == 200) {
                alert("Successfull", formData);
                setEditInputField({
                    image_list: [],

                });
                document.getElementById("InputPhotoUpload").value = '';
                navigate("/dashboard/home-banner-setup");
            } else {
                alert("Failed")
            }
        } catch (error) {
            console.log(error);
        }
    };


    const multipleImageRemove = (index) => {
        setEditInputField({
            ...editInputField,
            image_list: editInputField.image_list.filter((item, i) => i !== index),
        });
    };
    console.log(editInputField, "editInputField")

    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Edit Home Banner Setup
                        <Link to='/dashboard/home-banner-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        <form className="mt-1" encType='multipart/form-data'
                            onSubmit={updateFormData}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <label htmlFor="InputModalPhotoUpload" className="col-form-label col-form-label-sm d-block" >
                                        Modal Photo Upload <span className="text-danger">*</span></label>
                                    <input className="form-control form-control-sm form__ptb" id="InputPhotoUpload"
                                        type="file"
                                        multiple
                                        name="image_list"
                                        accept="image/*"
                                        onChange={handleMultipleImageChange}
                                    />
                                    <div className="row g-3 pt-4">
                                        {Array.from(editInputField.image_list).map((item, i) => {
                                            return (
                                                <div key={i} className="col-md-2">
                                                    <div className="d-flex input__img">
                                                        {
                                                            typeof (item) === 'string' &&
                                                            <img src={`${imageURL}/${item}`} alt="Preview" />

                                                        }
                                                        {
                                                            typeof (item) === 'object' &&
                                                            <img src={URL.createObjectURL(item)} alt="Preview" />
                                                        }
                                                        <span className="close__icon"
                                                            onClick={() => multipleImageRemove(i)}>
                                                            <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        )}
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditHomeBannerSetup;