import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { imageURL } from '../../../imageURL/imageURL';
// import '../dashboard/AdminDashboard.css';
import '../AppAdmin.css';
import Apd from '../boardOfDirectorsSetup/Apd';

const EditEmbassiesInBangladeshSetup = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [editInputField, setEditInputField] = useState({
        title_name: "",
        web_link: "",
        email: "",
        address: "",
        phone: "",
        fax: "",
        office_hour: "",
        image: null,
    });


    const inputsHandler = (e) => {
        setEditInputField({ ...editInputField, [e.target.name]: e.target.value });
    };

    const fetchData = async (id) => {
        try {
            const res = await axios.get(`/edit-embassies-in-bangladesh/${id}`);
            console.log("edit-embassies-in-bangladesh: ", res)
            setEditInputField(res.data.data)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData(id);
    }, []);

    const [imageNew, setImageNew] = useState();

    const handleImageChange = (e) => {
        setEditInputField({
            ...editInputField,
            image: e.target.files[0]
        });
        setImageNew(e.target.files[0])
    };
    const updateFormData = async (e) => {
        e.preventDefault();
        console.log("updateFormData: ", editInputField);

        const formData = new FormData();
        formData.append("title_name", editInputField.title_name)
        formData.append("email", editInputField.email)
        formData.append("address", editInputField.address)
        formData.append("web_link", editInputField.web_link)
        formData.append("phone", editInputField.phone)
        formData.append("fax", editInputField.fax)
        formData.append("office_hour", editInputField.office_hour)
        // formData.append("image", editInputField.image, editInputField.image.name);
        formData.append("image", imageNew ? imageNew : editInputField.image);

        const url = `/update-embassies-in-bangladesh/${id}`;
        // console.log("update-embassies-in-bangladesh", editInputField.image, "mmm", editInputField.image.name);
        try {
            let res = await axios.post(url, formData);
            console.log("update-embassies-in-bangladesh: ", res)
            if (res.status == 200) {
                alert("Successfull", formData);
                setEditInputField({
                    title_name: "",
                    web_link: "",
                    email: "",
                    address: "",
                    phone: "",
                    fax: "",
                    office_hour: "",
                    image: null,

                });
                document.getElementById("InputPhotoUpload").value = '';
                navigate("/dashboard/embassies-in-bangladesh-setup");
            } else {
                alert("Failed")
            }
        } catch (error) {
            console.log(error);
        }
    };


    const inputPhotoUploadClose = () => {
        setEditInputField({ ...editInputField, image: '' });
        document.getElementById("InputPhotoUpload").value = '';
        setImageNew();

    };



    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Edit Embassies In Bangladesh Setup
                        <Link to='/dashboard/embassies-in-bangladesh-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        {/* <Apd /> */}
                        <form className="mt-1" encType='multipart/form-data'
                            onSubmit={updateFormData}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">

                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputBannerTitle" className="col-form-label col-form-label-sm d-block " >
                                                Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputBannerTitle"
                                                name="title_name"
                                                value={editInputField.title_name}
                                                onChange={inputsHandler}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="InputWebLink" className="col-form-label col-form-label-sm d-block " >
                                                Web Link <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputWebLink"
                                                name="web_link"
                                                value={editInputField.web_link}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputEmail" className="col-form-label col-form-label-sm d-block " >
                                                Email <span className="text-danger">*</span></label>
                                            <input type="email" className="form-control form-control-sm form__ptb " id="InputEmail"
                                                name="email"
                                                value={editInputField.email}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputAddress" className="col-form-label col-form-label-sm d-block " >
                                                Address <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputAddress"
                                                name="address"
                                                value={editInputField.address}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="Inputphone" className="col-form-label col-form-label-sm d-block " >
                                                Phone No. </label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="Inputphone"
                                                name="phone"
                                                value={editInputField.phone}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputFaxNo" className="col-form-label col-form-label-sm d-block " >
                                                Fax No. <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputFaxNo"
                                                name="fax"
                                                value={editInputField.fax}
                                                onChange={inputsHandler}
                                            />
                                        </div>

                                    </div>
                                    <div class="row mb-2">
                                        <div className="col-md-6">
                                            <label htmlFor="InputOfficeAddress" className="col-form-label col-form-label-sm d-block " >
                                                Office Address <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputOfficeAddress"
                                                name="office_hour"
                                                value={editInputField.office_hour}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >
                                                Card Photo Upload <span className="text-danger">*</span></label>
                                            <input className="form-control form-control-sm form__ptb" id="InputPhotoUpload"
                                                type="file"
                                                // multiple
                                                name="image"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                            />
                                            <div className="pt-4">
                                                {imageNew && (
                                                    <div className="d-flex input__img">
                                                        <img src={URL.createObjectURL(imageNew)} alt="Preview" />
                                                        <span className="close__icon"
                                                            onClick={inputPhotoUploadClose}>
                                                            <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                    </div>
                                                )}
                                                {!imageNew && editInputField.image && (
                                                    <div className="d-flex input__img">
                                                        <img src={`${imageURL}/images/embassies_folder/${editInputField.image}`} alt="Preview" />
                                                        <span className="close__icon"
                                                            onClick={inputPhotoUploadClose}>
                                                            <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditEmbassiesInBangladeshSetup;

