import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Slide } from 'react-slideshow-image';
import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import axios from 'axios';
import { imageURL } from '../../imageURL/imageURL';

import under_construction from "../../images/under_construction.jpg";
import captionTea__img from '../../images/captionTea__img.jpg';
import caption__img from '../../images/caption__img.jpg';
import BogaVhobon__img from '../../images/BogaVhobon__img.jpg';


const tourismSpot = [
    {
        image: captionTea__img, id: 1,
        tourism_name: "Biman Bangladesh Travel & Tourism Fair",
        price: "5000 Tk", place_name: "Dhaka, Bangladesh",
        trip_type: "By travel trips",
        description: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",
        multipleImg: [
            { id: 1, image: captionTea__img, caption: "slide" },
            { id: 2, image: under_construction, caption: "slide" },
            { id: 3, image: under_construction, caption: "slide" },
        ],
    },
    {
        image: caption__img, id: 2,
        tourism_name: "Urvelling Visit Nepat Year 2020 Campaign",
        price: "5000 Tk", place_name: "Dhaka, Bangladesh",
        trip_type: "By travel trips",
        description: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",
        multipleImg: [
            { id: 1, image: caption__img, caption: "slide" },
            { id: 2, image: under_construction, caption: "slide" },
            { id: 3, image: under_construction, caption: "slide" },
        ],
    },
    {
        image: BogaVhobon__img, id: 3,
        tourism_name: "TDAB PICNIC 2021",
        price: "5000 Tk", place_name: "Dhaka, Bangladesh",
        trip_type: "By travel trips",
        description: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",
        multipleImg: [
            { id: 1, image: BogaVhobon__img, caption: "slide" },
            { id: 2, image: under_construction, caption: "slide" },
            { id: 3, image: under_construction, caption: "slide" },
        ],
    },
    {
        image: caption__img, id: 4,
        tourism_name: "Pre-Budget Discussion",
        price: "5000 Tk", place_name: "Dhaka, Bangladesh",
        trip_type: "By travel trips",
        description: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",
        multipleImg: [
            { id: 1, image: caption__img, caption: "slide" },
            { id: 2, image: under_construction, caption: "slide" },
            { id: 3, image: under_construction, caption: "slide" },
        ],
    },
    {
        image: caption__img, id: 5,
        tourism_name: "Pre-Budget Discussion",
        price: "5000 Tk", place_name: "Dhaka, Bangladesh",
        trip_type: "By travel trips",
        description: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",
        multipleImg: [
            { id: 1, image: caption__img, caption: "slide" },
            { id: 2, image: under_construction, caption: "slide" },
            { id: 3, image: under_construction, caption: "slide" },
        ],
    },

]


const TourismSpot = () => {
    const [tourismSpot, setTourismSpot] = useState([]);

    const fetchData = async () => {
        try {
            const res = await axios.get("/tourism-spot");
            // console.log("tourism-spot data: ", res.data.data);
            setTourismSpot(res.data.data);
        } catch (error) {
            console.log(error)
        }
    };
    useEffect(() => {
        fetchData();
    }, []);


    const tourismSpotFilteringData = tourismSpot.sort((a, b) => a.tourism_name !== b.tourism_name ? a.tourism_name < b.tourism_name ? -1 : 1 : 0);
    // console.log("tourismSpotFilteringData", tourismSpotingData);
    // // Alternative/another way
    // const tourismSpotingData = dataList.sort((a, b) => a.title_name.localeCompare(b.title_name, 'es', {sensitivity: 'base'}))
    // console.log("tourismSpotingData", tourismSpotingData);

    const [searchQuery, setSearchQuery] = useState('')

    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        const searchList = tourismSpotFilteringData.filter((item) => {
            return item.tourism_name.toLowerCase().indexOf(query.toLowerCase()) !==-1;
        });
        setTourismSpot(searchList);
    }

    const [modalTourismSpotIsOpen, setModalTourismSpotIsOpen] = useState(false);
    function openModalTourismSpot() {
        // alert("mmm")
        setModalTourismSpotIsOpen(true);
    }
    function closeModalTourismSpot(e) {
        setModalTourismSpotIsOpen(false);

    }
    const responsiveSettings = [
        {
            breakpoint: 800,
            autoplay: "true",
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ];
    const [arr, setArr] = useState([]);


    return (
        <div className="container pt-2 mb-5">
            <h3 className="mb-1 text-center">
                <span className="header_text_normal_bm">Tour</span><span className="header_text_normal">ism</span>
                <span className="header_text_bold"> Spot</span>
            </h3>

            <div className="row g-3 pt-4">
                <div className="col-md-6">
                    <div className="row mb-3">
                        <label htmlFor="inputSearch" className="col-sm-2 col-form-label col-form-label-sm">Search</label>
                        <div className="col-sm-10">
                            <div className="accom__search" id="inputSearch">
                                <TextField
                                    fullWidth
                                    id="standard-bare"
                                    variant="outlined"
                                    placeholder="Search"
                                    tourism_name= "search"
                                    value={searchQuery}
                                    onChange= {handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <IconButton>
                                                <SearchOutlined />
                                            </IconButton>
                                        ),
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-3 pt-2 d-flex justify-content-center">
                {
                    tourismSpotFilteringData.length > 0 && tourismSpotFilteringData.map((item, id) => {
                        return (
                            <div key={id} className="col-md-4">
                                <div className="tourism__spot__box_card"
                                    onClick={() => {
                                        openModalTourismSpot();
                                        setArr(item)
                                    }}
                                >
                                    <div className="card">
                                        <div className="tourism__spot__img">
                                            <img src={`${imageURL}/${item.card_image}`}  className="card-img-top" alt="image" loading="lazy" />
                                        </div>
                                        <div className="card-body">
                                            <h6 className="tourism__spot__card_title">{item.tourism_name}</h6>
                                            <p className="tourism__spot__card__discription">{item.place_name}</p>
                                            <div className="btn__tourism__more">
                                                <button type="button" className="btn btn__accom">View Details</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                <div className="row pt-5">
                    <div className="col-md-12">
                        <div className="btn__accom__more text-center">
                            <button type="button" className="btn btn__more">Load More</button>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={modalTourismSpotIsOpen}
                    onRequestClose={closeModalTourismSpot}
                    className="mymodal g-doc-scroll"
                    overlayClassName="myoverlay"
                    contentLabel="Example Modal"
                >
                    <div className='card-body modal__body '>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 pt-1">
                                    <span className='float-end' style={{ fontSize: "20px", cursor: "pointer" }} onClick={closeModalTourismSpot}><i className="fa-solid fa-xmark close_btn"></i></span>
                                    <h6 className="header__consultation__text">Tourism Spot</h6>
                                </div>
                            </div>
                            <div className="">
                                <Slide slidesToScroll={2} slidesToShow={1} indicators={true} responsive={responsiveSettings}>
                                    {
                                        arr.image_list !== undefined && arr.image_list.map((itemModal, id) =>
                                            < div key={id} className="slide_img" >
                                                <img src={`${imageURL}/${itemModal}`}  alt="image" loading='lazy'/>
                                            </div>
                                        )
                                    }
                                </Slide>
                                <div className="card-body">
                                    <h6 className="tourism__spot__card_title mb-2">{arr.tourism_name}</h6>
                                    <p className="tourism__spot__modal__subtitle">{arr.trip_type}</p>
                                    <p className="tourism__spot__modal__discription">{arr.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div >
        </div >
    );
};

export default TourismSpot;



// import React, { useState } from 'react';
// import Modal from 'react-modal';
// import { Slide } from 'react-slideshow-image';
// import { TextField, IconButton } from '@material-ui/core';
// import { SearchOutlined } from '@material-ui/icons';

// import under_construction from "../../images/under_construction.jpg";
// import captionTea__img from '../../images/captionTea__img.jpg';
// import caption__img from '../../images/caption__img.jpg';
// import BogaVhobon__img from '../../images/BogaVhobon__img.jpg';


// const tourismSpot = [
//     {
//         image: captionTea__img, id: 1,
//         tourism_name: "Biman Bangladesh Travel & Tourism Fair",
//         price: "5000 Tk", place_name: "Dhaka, Bangladesh",
//         trip_type: "By travel trips",
//         desc: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",
//         multipleImg: [
//             { id: 1, image: captionTea__img, caption: "slide" },
//             { id: 2, image: under_construction, caption: "slide" },
//             { id: 3, image: under_construction, caption: "slide" },
//         ],
//     },
//     {
//         image: caption__img, id: 2,
//         tourism_name: "Urvelling Visit Nepat Year 2020 Campaign",
//         price: "5000 Tk", place_name: "Dhaka, Bangladesh",
//         trip_type: "By travel trips",
//         desc: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",
//         multipleImg: [
//             { id: 1, image: caption__img, caption: "slide" },
//             { id: 2, image: under_construction, caption: "slide" },
//             { id: 3, image: under_construction, caption: "slide" },
//         ],
//     },
//     {
//         image: BogaVhobon__img, id: 3,
//         tourism_name: "TDAB PICNIC 2021",
//         price: "5000 Tk", place_name: "Dhaka, Bangladesh",
//         trip_type: "By travel trips",
//         desc: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",
//         multipleImg: [
//             { id: 1, image: BogaVhobon__img, caption: "slide" },
//             { id: 2, image: under_construction, caption: "slide" },
//             { id: 3, image: under_construction, caption: "slide" },
//         ],
//     },
//     {
//         image: caption__img, id: 4,
//         tourism_name: "Pre-Budget Discussion",
//         price: "5000 Tk", place_name: "Dhaka, Bangladesh",
//         trip_type: "By travel trips",
//         desc: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",
//         multipleImg: [
//             { id: 1, image: caption__img, caption: "slide" },
//             { id: 2, image: under_construction, caption: "slide" },
//             { id: 3, image: under_construction, caption: "slide" },
//         ],
//     },
//     {
//         image: caption__img, id: 5,
//         tourism_name: "Pre-Budget Discussion",
//         price: "5000 Tk", place_name: "Dhaka, Bangladesh",
//         trip_type: "By travel trips",
//         desc: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",
//         multipleImg: [
//             { id: 1, image: caption__img, caption: "slide" },
//             { id: 2, image: under_construction, caption: "slide" },
//             { id: 3, image: under_construction, caption: "slide" },
//         ],
//     },

// ]


// const TourismSpot = () => {
//     const tourismSpotFilteringData = tourismSpot.sort((a, b) => a.tourism_name !== b.tourism_name ? a.tourism_name < b.tourism_name ? -1 : 1 : 0);
//     // console.log("tourismSpotFilteringData", tourismSpotFilteringData);
//     // // Alternative/another way
//     // const tourismSpotFilteringData = dataList.sort((a, b) => a.title_name.localeCompare(b.title_name, 'es', {sensitivity: 'base'}))
//     // console.log("tourismSpotFilteringData", tourismSpotFilteringData);

//     const [tourismSpotFilter, setTourismSpotFilter] = useState(tourismSpotFilteringData);
//     const [searchQuery, setSearchQuery] = useState('')

//     const handleSearch = (e) => {
//         const query = e.target.value;
//         setSearchQuery(query);

//         const searchList = tourismSpotFilteringData.filter((item) => {
//             return item.tourism_name.toLowerCase().indexOf(query.toLowerCase()) !==-1;
//         });
//         setTourismSpotFilter(searchList);
//     }

//     const [modalTourismSpotIsOpen, setModalTourismSpotIsOpen] = useState(false);
//     function openModalTourismSpot() {
//         // alert("mmm")
//         setModalTourismSpotIsOpen(true);
//     }
//     function closeModalTourismSpot(e) {
//         setModalTourismSpotIsOpen(false);

//     }
//     const responsiveSettings = [
//         {
//             breakpoint: 800,
//             autoplay: "true",
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1
//             }
//         },
//         {
//             breakpoint: 500,
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1
//             }
//         }
//     ];
//     const [arr, setArr] = useState([]);


//     return (
//         <div className="container pt-2 mb-5">
//             <h3 className="mb-1 text-center">
//                 <span className="header_text_normal_bm">Tour</span><span className="header_text_normal">ism</span>
//                 <span className="header_text_bold"> Spot</span>
//             </h3>

//             <div className="row g-3 pt-4">
//                 <div className="col-md-6">
//                     <div className="row mb-3">
//                         <label htmlFor="inputSearch" className="col-sm-2 col-form-label col-form-label-sm">Search</label>
//                         <div className="col-sm-10">
//                             <div className="accom__search" id="inputSearch">
//                                 <TextField
//                                     fullWidth
//                                     id="standard-bare"
//                                     variant="outlined"
//                                     placeholder="Search"
//                                     tourism_name= "search"
//                                     value={searchQuery}
//                                     onChange= {handleSearch}
//                                     InputProps={{
//                                         startAdornment: (
//                                             <IconButton>
//                                                 <SearchOutlined />
//                                             </IconButton>
//                                         ),
//                                     }}

//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="row g-3 pt-2 d-flex justify-content-center">
//                 {
//                     tourismSpotFilter.length > 0 && tourismSpotFilter.map((item, id) => {
//                         return (
//                             <div key={id} className="col-md-4">
//                                 <div className="tourism__spot__box_card"
//                                     onClick={() => {
//                                         openModalTourismSpot();
//                                         setArr(item)
//                                     }}
//                                 >
//                                     <div className="card">
//                                         <div className="tourism__spot__img">
//                                             <img src={item.image} className="card-img-top" alt="image" loading="lazy" />
//                                         </div>
//                                         <div className="card-body">
//                                             <h6 className="tourism__spot__card_title">{item.tourism_name}</h6>
//                                             <p className="tourism__spot__card__discription">{item.place_name}</p>
//                                             <div className="btn__tourism__more">
//                                                 <button type="button" className="btn btn__accom">View Details</button>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         )
//                     })
//                 }

//                 <div className="row pt-5">
//                     <div className="col-md-12">
//                         <div className="btn__accom__more text-center">
//                             <button type="button" className="btn btn__more">Load More</button>
//                         </div>
//                     </div>
//                 </div>

//                 <Modal
//                     isOpen={modalTourismSpotIsOpen}
//                     onRequestClose={closeModalTourismSpot}
//                     className="mymodal g-doc-scroll"
//                     overlayClassName="myoverlay"
//                     contentLabel="Example Modal"
//                 >
//                     <div className='card-body modal__body '>
//                         <div className="container">
//                             <div className="row">
//                                 <div className="col-12 pt-1">
//                                     <span className='float-end' style={{ fontSize: "20px", cursor: "pointer" }} onClick={closeModalTourismSpot}><i className="fa-solid fa-xmark close_btn"></i></span>
//                                     <h6 className="header__consultation__text">Tourism Spot</h6>
//                                 </div>
//                             </div>
//                             <div className="">
//                                 <Slide slidesToScroll={2} slidesToShow={1} indicators={true} responsive={responsiveSettings}>
//                                     {
//                                         arr.multipleImg !== undefined && arr.multipleImg.map((itemModal, id) =>
//                                             < div key={id} className="slide_img" >
//                                                 <img src={itemModal.image} alt="image" />
//                                             </div>
//                                         )
//                                     }
//                                 </Slide>
//                                 <div className="card-body">
//                                     <h6 className="tourism__spot__card_title mb-2">{arr.tourism_name}</h6>
//                                     <p className="tourism__spot__modal__subtitle">{arr.trip_type}</p>
//                                     <p className="tourism__spot__modal__discription">{arr.desc}</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </Modal>
//             </div >
//         </div >
//     );
// };

// export default TourismSpot;