
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
// import '../dashboard/AdminDashboard.css';
import '../AppAdmin.css';
import Apd from '../boardOfDirectorsSetup/Apd';

const AddPoliceSetup = () => {

    const [inputField, setInputField] = useState({
        police_station_name: "",
        address: "",
        cell: "",
    });

    const inputsChange = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });

    };

    const submitFormData = (e) => {
        e.preventDefault();
        console.log("submitFormData: ", inputField);

        const url = "/add-police";
        axios.post(url, inputField)
            .then(response => {
                console.log(response, "dff");
                if (response.status === 200) {
                    alert("Are you Added  to Data");
                    setInputField({
                        police_station_name: "",
                        address: "",
                        cell: "",
                    });
                } else {
                    alert("Failed")
                }
            })
            .catch(error => {
                console.log(error);
            })
    };

    // const submitFormData = (e) => {
    //     e.preventDefault();
    //     console.log("submitFormData: ", inputField);
    //     fetchData();
    // };
    // const [data, setData] = useState([]);
    // const fetchData = async () => {
    //     try {
    //         const response = await axios.post("/police", inputField);
    //         console.log("response:", response);
    //         setData(response.data);
    //         setInputField({
    //             police_station_name: "",
    //             organization_name: "",
    //             address: "",
    //             card_link: "",
    //             cell: "",
    //             email: "",
    //             fax: "",
    //             tel: "",
    //             web_link: "",
    //         });
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }





    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Add Police Setup
                        <Link to='/dashboard/police-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        {/* <Apd /> */}
                        <form className="mt-1"
                            onSubmit={submitFormData}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <div className="row g-3 mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="inputTitleName" className="col-form-label col-form-label-sm d-block">
                                                Title Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm form__ptb " id="inputTitleName"
                                                name="police_station_name"
                                                value={inputField.police_station_name}
                                                onChange={inputsChange}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="InputCell" className="col-form-label col-form-label-sm d-block " >Cell</label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputCell"
                                                name="cell"
                                                value={inputField.cell}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-2">
                                        <div className="col-md-6">
                                            <label htmlFor="AddressFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
                                                Address <span className="text-danger">*</span></label>
                                            <textarea className="form-control" id="AddressFormControlTextarea1" rows="3"
                                                name="address"
                                                value={inputField.address}
                                                onChange={inputsChange}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPoliceSetup;

// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import axios from "axios";
// // import '../dashboard/AdminDashboard.css';
// import '../AppAdmin.css';
// import Apd from '../boardOfDirectorsSetup/Apd';

// const AddPoliceSetup = () => {


//     const [inputField, setInputField] = useState({
//         police_station_name: "",
//         organization_name: "",
//         address: "",
//         card_link: "",
//         cell: "",
//         email: "",
//         fax: "",
//         tel: "",
//         web_link: "",
//     });

//     const inputsChange = (e) => {
//         setInputField({ ...inputField, [e.target.name]: e.target.value });

//     };

//     const submitFormData = async (e) => {
//         e.preventDefault();
//         console.log("submitHandler: ", inputField);


//         const formData = new FormData();
//         formData.append("police_station_name", inputField.police_station_name)
//         formData.append("organization_name", inputField.organization_name)
//         formData.append("org_name", inputField.org_name)
//         formData.append("address", inputField.address)
//         formData.append("card_link", inputField.position_of_org)
//         formData.append("web_link", inputField.web_link)
//         formData.append("cell", inputField.cell)
//         formData.append("email", inputField.email)
//         formData.append("tel", inputField.tel)
//         formData.append("fax", inputField.fax)


//         const url = "/add-police";
//         try {
//             let response = await axios.post(url, formData);
//             if (response.status == 201) {
//                 alert("Successfull", formData);
//                 setInputField({
//                     police_station_name: "",
//                     organization_name: "",
//                     address: "",
//                     card_link: "",
//                     cell: "",
//                     email: "",
//                     fax: "",
//                     tel: "",
//                     web_link: "",
//                 });
//             } else {
//                 alert("Failed")
//             }
//         } catch (error) {

//         }
//     };

//     // const submitFormData = (e) => {
//     //     e.preventDefault();
//     //     console.log("submitFormData: ", inputField);
//     //     fetchData();
//     // };
//     // const [data, setData] = useState([]);
//     // const fetchData = async () => {
//     //     try {
//     //         const response = await axios.post("/police", inputField);
//     //         console.log("response:", response);
//     //         setData(response.data);
//     //         setInputField({
//     //             police_station_name: "",
//     //             organization_name: "",
//     //             address: "",
//     //             card_link: "",
//     //             cell: "",
//     //             email: "",
//     //             fax: "",
//     //             tel: "",
//     //             web_link: "",
//     //         });
//     //     } catch (error) {
//     //         console.error(error);
//     //     }
//     // }





//     return (
//         <div className="container pt-4">
//             <div className="card">
//                 <div className="card-header">
//                     <h4 className="header__text  pt-1">Add Police Setup
//                         <Link to='/dashboard/police-setup' className="back__btn  float-end"> Back</Link>
//                     </h4>
//                 </div>
//                 <div className="bg__from__color">
//                     <div className="card-body">
//                         {/* <Apd /> */}
//                         <form className="mt-1"
//                             onSubmit={submitFormData}
//                         >
//                             <div className="row g-0">
//                                 <div className="col-md-12">
//                                     <div className="row g-3 mb-3">
//                                         <div className="col-md-6">
//                                             <label htmlFor="inputTitleName" className="col-form-label col-form-label-sm d-block">
//                                                 Title Name <span className="text-danger">*</span></label>
//                                             <input type="text" className="form-control form-control-sm form__ptb " id="inputTitleName"
//                                                 name="police_station_name"
//                                                 value={inputField.police_station_name}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>

//                                         <div className="col-md-6">
//                                             <label htmlFor="inputOrganizationName" className="col-form-label col-form-label-sm d-block">
//                                                 Organization Name <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="inputOrganizationName"
//                                                 name="organization_name"
//                                                 value={inputField.organization_name}
//                                                 onChange={inputsChange}
//                                             />

//                                         </div>
//                                     </div>
//                                     <div className="row mb-2 d-flex">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputCardLink" className="col-form-label col-form-label-sm d-block " >
//                                                 Card Link <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputCardLink"
//                                                 name="card_link"
//                                                 value={inputField.card_link}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputWebLink" className="col-form-label col-form-label-sm d-block " >
//                                                 Web Link <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputWebLink"
//                                                 name="web_link"
//                                                 value={inputField.web_link}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div className="row mb-2 d-flex">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputEmail" className="col-form-label col-form-label-sm d-block " >
//                                                 Email <span className="text-danger">*</span></label>

//                                             <input type="email" className="form-control form-control-sm form__ptb " id="InputEmail"
//                                                 name="email"
//                                                 value={inputField.email}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputFax" className="col-form-label col-form-label-sm d-block " >
//                                                 Fax <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputFax"
//                                                 name="fax"
//                                                 value={inputField.fax}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div className="row mb-2">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputCell" className="col-form-label col-form-label-sm d-block " >Cell</label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputCell"
//                                                 name="cell"
//                                                 value={inputField.cell}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputTelephone" className="col-form-label col-form-label-sm d-block " >
//                                                 Telephone <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputTelephone"
//                                                 name="tel"
//                                                 value={inputField.tel}
//                                                 onChange={inputsChange}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div className="row mb-2">
//                                         <div className="col-md-6">
//                                             <label htmlFor="AddressFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
//                                                 Address <span className="text-danger">*</span></label>
//                                             <textarea className="form-control" id="AddressFormControlTextarea1" rows="3"
//                                                 name="address"
//                                                 value={inputField.address}
//                                                 onChange={inputsChange}
//                                             ></textarea>
//                                         </div>
//                                     </div>

//                                 </div>
//                             </div>
//                             <div className="row">
//                                 <div className="col-12">
//                                     <div className="float-right pt-4 mr-0">
//                                         <button type="submit" className="btn__save mx-3"
//                                         // onClick={saveMedicationChartPartOneData}
//                                         >
//                                             <b>Save</b>
//                                         </button>
//                                         <button className="btn__cancel"
//                                         // onClick={props.closeModal}
//                                         >
//                                             <b>Cancel</b>
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </form>

//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddPoliceSetup;