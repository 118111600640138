import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import '../dashboard/AdminDashboard.css';
import '../AppAdmin.css';

const EditTouristPoliceSetup = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [editInputField, setEditInputField] = useState({
        tourist_police_category: "",
        rank: "",
        designation: "",
        unit_address: "",
        email: "",
        contact: "",
    });




    const inputsHandler = (e) => {
        setEditInputField({ ...editInputField, [e.target.name]: e.target.value });
    };

    const fetchData = async (id) => {
        try {
            const res = await axios.get(`/edit-tourist-police/${id}`);
            console.log("res edit-tourist-police: ", res.data.data);
            setEditInputField(res.data.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData(id);
    }, []);


    const updateFormData = async (e) => {
        e.preventDefault();
        console.log("updateFormData: ", editInputField);


        const url = `/update-tourist-police/${id}`;
        axios.post(url, editInputField)
            .then(response => {
                // console.log(response, "edit dff");
                if (response.status === 200) {
                    alert("Are you Edit  to Data");
                    setEditInputField({
                        tourist_police_category: "",
                        rank: "",
                        designation: "",
                        unit_address: "",
                        email: "",
                        contact: "",
                    });
                } else {
                    alert("Failed")
                }
                navigate("/dashboard/tourist-police-setup");
            })
            .catch(error => {
                console.log(error);
            });

    };




    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Edit Tourist Police Setup
                        <Link to='/dashboard/tourist-police-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        <form className="mt-1" encType='multipart/form-data'
                            onSubmit={updateFormData}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <div className="row g-3 mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="inputTouristPoliceCategory" className="col-form-label col-form-label-sm d-block">
                                                Tourist Police Category <span className="text-danger">*</span></label>
                                            <select id="inputTouristPoliceCategory" className="form-select form-select-sm"
                                                name="tourist_police_category"
                                                value={editInputField.tourist_police_category}
                                                onChange={inputsHandler}>
                                                <option value="">Please select a Tourist Police</option>
                                                <option value="Tourist Police Headquarters">Tourist Police Headquarters</option>
                                                <option value="Divisions">Divisions</option>
                                                <option value="Regions">Regions</option>
                                                <option value="Zone">Zone</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputRank" className="col-form-label col-form-label-sm d-block " >
                                                Rank Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputRank"
                                                name="rank"
                                                value={editInputField.rank}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputDesignation" className="col-form-label col-form-label-sm d-block " >
                                                Designation <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputDesignation"
                                                name="designation"
                                                value={editInputField.designation}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputContact" className="col-form-label col-form-label-sm d-block " >
                                                Contact <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputContact"
                                                name="contact"
                                                value={editInputField.contact}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputEmail" className="col-form-label col-form-label-sm d-block " >
                                                Email <span className="text-danger">*</span></label>
                                            <input type="email" className="form-control form-control-sm form__ptb " id="InputEmail"
                                                name="email"
                                                value={editInputField.email}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="UnitAddressFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
                                                Unit Address <span className="text-danger">*</span></label>
                                            <textarea className="form-control" id="UnitAddressFormControlTextarea1" rows="3"
                                                name="unit_address"
                                                value={editInputField.unit_address}
                                                onChange={inputsHandler}
                                            ></textarea>
                                        </div>
                                    </div>



                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditTouristPoliceSetup;

