import React from 'react';

const Logout = () => {
    return (
        <div className="container">
            <h2 className="text-muted text-center pt-3">Logout Dashboard</h2>
        </div>
    );
};

export default Logout;