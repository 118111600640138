import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import '../dashboard/AdminDashboard.css';
import './BMForeignCountrySetup.css';
import '../AppAdmin.css';
import Apd from '../boardOfDirectorsSetup/Apd';

const AddBMForeignCountrySetup = () => {

    const [inputField, setInputField] = useState({
        continent_title: '',
        card_link: '',
        continent_area: [{ country_name: '', web_link: '' }],
    });

    const handleChange1 = (event, index) => {
        const { name, value } = event.target;
        if (name === 'country_name' || name === 'web_link') {
            const continentArea = [...inputField.continent_area];
            continentArea[index] = { ...continentArea[index], [name]: value };
            setInputField({ ...inputField, continent_area: continentArea });
        } else {
            setInputField({ ...inputField, [name]: value });
        }
    };

    const handleSubmitFormData = async (event) => {
        event.preventDefault();
        console.log("inputField: ", inputField)
        try {
            await axios.post('add-bmforeign-country', inputField)
            .then(res=>{
                console.log("add-bmforeign-country:", res);
                if (res.status === 200) {
                    inputField.continent_area.map((area, index) => {
                        axios.post("/add-bmforeign-continent-area", {
                            continent_area_id: res.data.bmf_country.id,
                            country_name: area.country_name,
                            web_link: area.web_link,
                        }).then(res => console.log("error", res))
                    })
                }
            })
            console.log("inputField: ", inputField)
            setInputField({
                continent_title: '',
                card_link: '',
                continent_area: [{ country_name: '', web_link: '' }],
            });
            alert('New continent added successfully!');
        } catch (error) {
            console.log(error);
            alert('Failed to add new continent!');
        }
    };

    const addCountry = () => {
        setInputField({
            ...inputField,
            continent_area: [...inputField.continent_area, { country_name: '', web_link: '' }],
        });
    };

    const removeCountry = (index) => {
        const continentArea = [...inputField.continent_area];
        continentArea.splice(index, 1);
        setInputField({ ...inputField, continent_area: continentArea });
    };




    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Add Bangladesh mission foreign country Setup
                        <Link to='/dashboard/bmforeign-country-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        {/* <Apd /> */}
                        <form onSubmit={handleSubmitFormData}>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label htmlFor="InputContinentTitle" className="col-form-label col-form-label-sm d-block" >Continent Name: <span className="text-danger">*</span></label>
                                    <input id="InputContinentTitle" className="form-control form-control-sm form__ptb "
                                        type="text"
                                        name="continent_title"
                                        value={inputField.continent_title}
                                        onChange={handleChange1}
                                        required />
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="Inputcard_Link" className="col-form-label col-form-label-sm d-block">Card Link: <span className="text-danger">*</span></label>
                                    <input id="Inputcard_Link" className="form-control form-control-sm form__ptb "
                                        type="text"
                                        name="card_link"
                                        value={inputField.card_link}
                                        onChange={handleChange1}
                                        required />
                                </div>
                            </div>
                            {inputField.continent_area.map((area, index) => (
                                <div className="row g-3" key={index}>
                                    <div className="col-md-6">
                                        <label htmlFor="InputCountryName" className="col-form-label col-form-label-sm d-block" >Country Name: <span className="text-danger">*</span></label>
                                        <input id="InputCountryName" className="form-control form-control-sm form__ptb "
                                            type="text"
                                            name="country_name"
                                            value={area.country_name}
                                            onChange={(event) => handleChange1(event, index)}
                                            required />
                                    </div>
                                    <div className="col-md-5">
                                        <label htmlFor="InputWebLink" className="col-form-label col-form-label-sm d-block" >Web Link: <span className="text-danger">*</span></label>
                                        <input id="InputWebLink" className="form-control form-control-sm form__ptb "
                                            type="text"
                                            name="web_link"
                                            value={area.web_link}
                                            onChange={(event) => handleChange1(event, index)}
                                            required />
                                    </div>
                                    <div className="col-md-1">
                                        {index > 0 && (
                                            <div className="btn__bmfc">
                                                <span className="add__btn__bmfc"
                                                    type="button"
                                                    onClick={() => removeCountry(index)}>
                                                    <i class="text-danger fa-regular fa-trash-can"></i>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-start pt-4 mr-0">
                                        <button className="btn__save"
                                            type="button"
                                            onClick={addCountry}
                                        >
                                           Add Country & Link
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddBMForeignCountrySetup;

// import axios from 'axios';
// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// // import '../dashboard/AdminDashboard.css';
// import '../AppAdmin.css';
// import Apd from '../boardOfDirectorsSetup/Apd';

// const AddBMForeignCountrySetup = () => {

//     // const [inputField, setInputField] = useState({
//     //     continent_title: '',
//     //     card_link: '',
//     //     continent_area: [{ country_name: '', web_link: '' }],
//     // });

//     // const inputsHandler = (e) => {
//     //     setInputField({ ...inputField, [e.target.name]: e.target.value });
//     // };

//     // const submitHandler = async (e) => {
//     //     e.preventDefault();
//     //     console.log("submitHandler: ", inputField);

//     //     const url = "/add-bmforeign-country";
//     //     axios.post(url, inputField)
//     //         .then(res => {
//     //             console.log(res, "dff");
//     //             if (res.status === 201) {
//     //                 alert("Are you Added  to Data");
//     //                 setInputField({
//     //                     bmforeign_country_category: "",
//     //                     title_name: "",
//     //                     email: "",
//     //                     contact: "",
//     //                 });
//     //             } else {
//     //                 alert("Failed")
//     //             }
//     //         })
//     //         .catch(error => {
//     //             console.log(error);
//     //         });
//     // };


//     const [inputField, setInputField] = useState({
//         continent_title: '',
//         card_link: '',
//         continent_area: [
//             {
//                 country_name: '',
//                 web_link: ''
//             }
//         ]
//     });

//     const handleContinentInputChange = (event) => {
//         setInputField({ ...inputField, [event.target.name]: event.target.value });
//     };

//     const handleCountryInputChange = (event, index) => {
//         const { name, value } = event.target;
//         const area = [...inputField.continent_area];
//         area[index][name] = value;
//         setInputField({ ...inputField, continent_area: area });
//     };

//     const handleFormSubmit = (event) => {
//         event.preventDefault();
//         console.log("continent inputField", inputField);
     

//         const url = "/add-bmforeign-country";
//         axios.post(url, inputField)
//             .then(res => {
//                 console.log("add-bmforeign-country: ", res);
//                 if (res.status === 201) {
//                     alert("Are you Added  to Data");
//                     setInputField({
//                         continent_title: '',
//                         card_link: '',
//                         continent_area: [
//                             {
//                                 country_name: '',
//                                 web_link: ''
//                             }
//                         ]
//                     });
//                 } else {
//                     alert("Failed")
//                 }
//             })
//             .catch(error => {
//                 console.log(error);
//             });
// };


// return (
//     <div className="container pt-4">
//         <div className="card">
//             <div className="card-header">
//                 <h4 className="header__text  pt-1">Add Bangladesh mission foreign country Setup
//                     <Link to='/dashboard/bmforeign-country-setup' className="back__btn  float-end"> Back</Link>
//                 </h4>
//             </div>
//             <div className="bg__from__color">
//                 <div className="card-body">
//                     {/* <Apd /> */}

//                     <form onSubmit={handleFormSubmit}>
//                         <div className="row g-3">
//                             <div className="col-md-6">
//                                 <label htmlFor="InputContinentTitle" className="col-form-label col-form-label-sm d-block" >Continent Name: <span className="text-danger">*</span></label>
//                                 <input className="form-control form-control-sm form__ptb " id="InputContinentTitle"
//                                     type="text"
//                                     name="continent_title"
//                                     value={inputField.continent_title}
//                                     // onChange={(e) => setContinent({ ...inputField, continent_title: e.target.value })}
//                                     onChange={handleContinentInputChange}
//                                 />

//                             </div>
//                             <div className="col-md-6">

//                                 <label htmlFor="Inputcard_Link" className="col-form-label col-form-label-sm d-block">Card Link: <span className="text-danger">*</span></label>
//                                 <input className="form-control form-control-sm form__ptb " id="Inputcard_Link"
//                                     type="text"
//                                     name="card_link"
//                                     value={inputField.card_link}
//                                     // onChange={(e) => setContinent({ ...inputField, card_link: e.target.value })}
//                                     onChange={handleContinentInputChange}
//                                 />
//                             </div>
//                         </div>


//                         {inputField.continent_area.map((area, index) => (
//                             <div key={index} className="row g-3">
//                                 <div className="col-md-6">
//                                     <label htmlFor="InputCountryName" className="col-form-label col-form-label-sm d-block" >Country Name: <span className="text-danger">*</span></label>
//                                     <input className="form-control form-control-sm form__ptb " id="InputCountryName"
//                                         type="text"
//                                         name="country_name"
//                                         value={area.country_name}
//                                         onChange={(e) => handleCountryInputChange(e, index)} />
//                                 </div>
//                                 <div className="col-md-6">
//                                     <label htmlFor="InputWebLink" className="col-form-label col-form-label-sm d-block" >Web Link: <span className="text-danger">*</span></label>
//                                     <input className="form-control form-control-sm form__ptb " id="InputWebLink"
//                                         type="text"
//                                         name="web_link"
//                                         value={area.web_link}
//                                         onChange={(e) => handleCountryInputChange(e, index)} />
//                                 </div>
//                             </div>
//                         ))}

//                         <div className="row">
//                             <div className="col-12">
//                                 <div className="float-right pt-4 mr-0">
//                                     <button type="submit" className="btn__save mx-3"
//                                     // onClick={saveMedicationChartPartOneData}
//                                     >
//                                         <b>Save</b>
//                                     </button>
//                                     <button className="btn__cancel"
//                                     // onClick={props.closeModal}
//                                     >
//                                         <b>Cancel</b>
//                                     </button>
//                                 </div>
//                             </div>
//                         </div>
//                     </form>

//                 </div>
//             </div>
//         </div>
//     </div>
// );
// };

// export default AddBMForeignCountrySetup;

