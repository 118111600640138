import React, { useState, useEffect } from 'react';
import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import axios from 'axios';


const Police = () => {

    const [dataList, setDataList] = useState([]);
    const [searchQuery, setsearchQuery] = useState('');

    const fetchData = async () => {
        try {
            const res = await axios.get("/police");
            console.log("police Data res :", res);
            setDataList(res.data.data);
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const alphabeticallyFilteringData = dataList.sort((a, b) => a.police_station_name !== b.police_station_name ? a.police_station_name < b.police_station_name ? -1 : 1 : 0);
    // console.log("alphabeticallyFilteringData", alphabeticallyFilteringData);

    const handleSearch = (e)=> {
        const query = e.target.value;
        setsearchQuery(query);

        const searchList = alphabeticallyFilteringData.filter((item) => {
            return item.police_station_name.toLowerCase().includes(query);
        });
        setDataList(searchList);
    }

    return (
        <div>
            <div className="container pt-2 mb-5">
                <h3 className="mb-1 text-center">
                    <span className="header_text_normal_bm">Pol</span><span className="header_text_normal">ice</span>
                </h3>
                <div className="row g-3 pt-4">
                    <div className="col-md-6">
                        <div className="row mb-3">
                            <label htmlFor="inputSearch" className="col-sm-2 col-form-label col-form-label-sm">Search</label>
                            <div className="col-sm-10">
                                <div className="accom__search" id="inputSearch">
                                    <TextField
                                        fullWidth
                                        id="standard-bare"
                                        variant="outlined"
                                        placeholder="Search"
                                        tourism_name="search"
                                        value={searchQuery}
                                        onChange={handleSearch}
                                        InputProps={{
                                            startAdornment: (
                                                <IconButton>
                                                    <SearchOutlined />
                                                </IconButton>
                                            ),
                                        }}

                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-3 gx-md-4 pt-2 d-flex justify-content-center">
                    {
                        dataList!==undefined && dataList.map((item, id) => {
                            return (
                                <div key={id} className="col-md-3">
                                    <div class="police__card">
                                        <div class="card">
                                            <h6 class="police__title pt-3 mx-2">{item.police_station_name}</h6>
                                            <div class="card-body">
                                                <div className="d-flex">
                                                    <p class="police__desc me-2"><i class="fas fa-house-chimney"></i></p>
                                                    <p class="police__desc">{item.address}</p>
                                                </div>
                                                <div className="d-flex">
                                                    <p class="police__desc me-2"><i class="fas fa-phone"></i></p>
                                                    <p class="police__desc">{item.cell}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <div className="pt-4 d-flex justify-content-center mb-1">
                            <nav className="allroom_pagination" aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item px-1">
                                        <a className="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true">&lt;</span>
                                        </a>
                                    </li>
                                    <li className="page-item px-1"><a className="page-link link_no" href="#">1</a></li>
                                    <li className="page-item px-1"><a className="page-link link_no" href="#">2</a></li>
                                    <li className="page-item px-1"><a className="page-link link_no" href="#">...</a></li>
                                    <li className="page-item px-1"><a className="page-link link_no" href="#">3</a></li>
                                    <li className="page-item px-1">
                                        <a className="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true">&gt;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Police;




// import React from 'react';
// import { useState } from 'react';
// import { TextField, IconButton } from '@material-ui/core';
// import { SearchOutlined } from '@material-ui/icons';
// import axios from 'axios';

// const dataList = [
//     { id: 1, police_station_name: "OC, Taltali Police station, Rangpur", address: "Rangpur, Bangladesh Rangpur, Bangladesh", cell: "+88-64761", division: "" },
//     { id: 2, police_station_name: "ASP, Taltali Police station, Rangpur", address: "Rangpur, Bangladesh", cell: "+88-64761", division: "" },
//     { id: 3, police_station_name: "OC, Taltali Police station, Rangpur", address: "Rangpur, Bangladesh", cell: "+88-64761", division: "" },
//     { id: 4, police_station_name: "ASP, Taltali Police station, Rangpur", address: "Rangpur, Bangladesh", cell: "+88-64761", division: "" },
//     { id: 5, police_station_name: "OC, Taltali Police station, Rangpur", address: "Rangpur, Bangladesh", cell: "+88-64761", division: "" },
//     { id: 6, police_station_name: "OC, Taltali Police station, Rangpur", address: "Rangpur, Bangladesh", cell: "+88-64761", division: "" },
//     { id: 7, police_station_name: "OC, Taltali Police station, Rangpur", address: "Rangpur, Bangladesh", cell: "+88-64761", division: "" },
// ];



// const Police = () => {


//     const dataListFilteringData = dataList.sort((a, b) => a.police_station_name !== b.police_station_name ? a.police_station_name < b.police_station_name ? -1 : 1 : 0);
//     // console.log("dataListFilteringData", dataListFilteringData);
//     // // Alternative/another way
//     // const dataListFilteringData = dataList.sort((a, b) => a.police_station_name.localeCompare(b.police_station_name, 'es', {sensitivity: 'base'}))
//     // console.log("dataListFilteringData", dataListFilteringData);



//     const [dataListFilter, setDataListFilter] = useState(dataListFilteringData);
//     const [searchQuery, setsearchQuery] = useState('');

    
//     const handleSearch = (e)=> {
//         const query = e.target.value;
//         setsearchQuery(query);

//         const searchList = dataList.filter((item) => {
//             return item.police_station_name.toLowerCase().indexOf(query.toLowerCase()) !==-1;
//         });
//         setDataListFilter(searchList);

//     }

//     return (
//         <div>
//             <div className="container pt-2 mb-5">
//                 <h3 className="mb-1 text-center">
//                     <span className="header_text_normal_bm">Pol</span><span className="header_text_normal">ice</span>
//                 </h3>
//                 <div className="row g-3 pt-4">
//                     <div className="col-md-6">
//                         <div className="row mb-3">
//                             <label htmlFor="inputSearch" className="col-sm-2 col-form-label col-form-label-sm">Search</label>
//                             <div className="col-sm-10">
//                                 <div className="accom__search" id="inputSearch">
//                                     <TextField
//                                         fullWidth
//                                         id="standard-bare"
//                                         variant="outlined"
//                                         placeholder="Search"
//                                         tourism_name="search"
//                                         value={searchQuery}
//                                         onChange={handleSearch}
//                                         InputProps={{
//                                             startAdornment: (
//                                                 <IconButton>
//                                                     <SearchOutlined />
//                                                 </IconButton>
//                                             ),
//                                         }}

//                                     />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="row g-3 gx-md-4 pt-2 d-flex justify-content-center">
//                     {
//                         dataListFilter.length > 0 && dataListFilter.map((item, id) => {
//                             return (
//                                 <div key={id} className="col-md-3">
//                                     <div class="police__card">
//                                         <div class="card">
//                                             <h6 class="police__title pt-3 mx-2">{item.police_station_name}</h6>
//                                             <div class="card-body">
//                                                 <div className="d-flex">
//                                                     <p class="police__desc me-2"><i class="fas fa-house-chimney"></i></p>
//                                                     <p class="police__desc">{item.address}</p>
//                                                 </div>
//                                                 <div className="d-flex">
//                                                     <p class="police__desc me-2"><i class="fas fa-phone"></i></p>
//                                                     <p class="police__desc">{item.cell}</p>
//                                                 </div>

//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             )
//                         })
//                     }
//                 </div>

//                 <div className="row pt-5">
//                     <div className="col-12">
//                         <div className="pt-4 d-flex justify-content-center mb-1">
//                             <nav className="allroom_pagination" aria-label="Page navigation example">
//                                 <ul className="pagination">
//                                     <li className="page-item px-1">
//                                         <a className="page-link" href="#" aria-label="Previous">
//                                             <span aria-hidden="true">&lt;</span>
//                                         </a>
//                                     </li>
//                                     <li className="page-item px-1"><a className="page-link link_no" href="#">1</a></li>
//                                     <li className="page-item px-1"><a className="page-link link_no" href="#">2</a></li>
//                                     <li className="page-item px-1"><a className="page-link link_no" href="#">...</a></li>
//                                     <li className="page-item px-1"><a className="page-link link_no" href="#">3</a></li>
//                                     <li className="page-item px-1">
//                                         <a className="page-link" href="#" aria-label="Next">
//                                             <span aria-hidden="true">&gt;</span>
//                                         </a>
//                                     </li>
//                                 </ul>
//                             </nav>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Police;