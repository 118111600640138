import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import redison_img from "../../images/redison_img.jpg";
import room_img from "../../images/room_img.jpg";
import room_img2 from "../../images/room_img2.jpg";
import { imageURL } from '../../imageURL/imageURL';

const FestivalsDetails = () => {
    const [festivalsList, setFestivalsList] = useState([]);
    const { id } = useParams(); // extract the id parameter from the URL
    console.log("param", id)

    const fetchData = async () => {
        try {
            const res = await axios.get("/festivals");
            console.log("festivals data: ", res.data.data);
            setFestivalsList(res.data.data);
        } catch (error) {
            console.log(error)
        }
    };
    
    useEffect(() => {
        fetchData();
    }, []);

    const festival = festivalsList.find(item => item.id === parseInt(id)); // find the festival object that matches the extracted id parameter
    
    console.log( "doc", id);

    return (
        <div className="container pt-2 mb-5">
            <h3 className="mb-1 text-center">
                <span className="header_text_normal_bm">Fest</span><span className="header_text_normal">ivals Details</span>
            </h3>
            <div className="row g-3 pt-4">
                <div className="col-12">
                    <h5>{festival?.title_name}</h5>
                    <div className="d-flex justify-content-start">
                        <p>
                            <span className="me-2">{festival?.date}</span>
                            <span className="me-2"> || </span>
                            <span className="me-2">{festival?.source_name}</span>
                        </p>
                    </div>
                    <div className="festivals__img pt-2  d-flex justify-content-center">
                        <img src={`${imageURL}/images/festivals_folder/${festival?.image}`} className="card-img-top" alt="images" />
                    </div>
                </div>
                <div className="col-12">
                    <p className="news__desc pt-2">{festival?.describtion}</p>
                </div>
            </div>
        </div>
    );
};

export default FestivalsDetails;


// import axios from 'axios';
// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';

// import redison_img from "../../images/redison_img.jpg";
// import room_img from "../../images/room_img.jpg";
// import room_img2 from "../../images/room_img2.jpg";
// import { imageURL } from '../../imageURL/imageURL';


// const FestivalsDetails = () => {

//     const [festivalsList, setFestivalsList] = useState([]);
//     const {id} = useParams();
//     console.log("param", id)

//     const fetchData = async () => {
//         try {
//             const res = await axios.get("/festivals");
//             console.log("festivals data: ", res.data.data);
//             setFestivalsList(res.data.data);
//         } catch (error) {
//             console.log(error)
//         }
//     };
//     useEffect(() => {
//         fetchData();
//     }, []);
//     // const festivals = festivalsList.find(item => item.id === param.id);
//     const festivals = festivalsList.find(item => item.id === parseInt(id)); // find the festival object that matches the extracted id parameter
    
//     // console.log( "doc", param.id);
//     // console.log(festivals, "festivals ff");


//     return (
//         <div className="container pt-2 mb-5">
//             <h3 className="mb-1 text-center">
//                 <span className="header_text_normal_bm">Fest</span><span className="header_text_normal">ivals Details</span>
//             </h3>
//             <div className="row g-3 pt-4">
//                 <div className="col-12">
//                     <h5>{festivals?.title_name}</h5>
//                     <div className="d-flex justify-content-start">
//                         <p>
//                             <span className="me-2">{festivals?.date}</span>
//                             <span className="me-2"> || </span>
//                             <span className="me-2">{festivals?.source_name}</span>
//                         </p>
//                     </div>
//                     <div className="festivals__img pt-2  d-flex justify-content-center">
//                         <img src={`${imageURL}/images/festivals_folder/${festivals?.image}`} className="card-img-top" alt="images" />
//                     </div>
//                 </div>
//                 <div className="col-12">
//                     <p className="news__desc pt-2">{festivals?.describtion}</p>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default FestivalsDetails;


// import React, { useState } from 'react';
// import { useParams } from 'react-router-dom';

// import redison_img from "../../images/redison_img.jpg";
// import room_img from "../../images/room_img.jpg";
// import room_img2 from "../../images/room_img2.jpg";


// const FestivalsDetails = () => {

//     const [festivalsList, setAccommaodationList] = useState([
//         {
//             id: 1, photo: redison_img, title_name: "Shaheed Day (21-Feb-2022)", source_name: "Daily Star",
//             date: "23-Decmber-2022",
//             describtion: "World Tourism Day 2017 presents a unique opportunity to raise awareness on the contribution of sustainable tourism to development among public and private sector decision-makers and the international community, while mobilising all stakeholders to work together in making tourism a catalyst for positive change, says the United Nations World Tourism Organization (UNWTO). The government and different organizations have chalked out weeklong programs to celebrate the World Tourism Day with the global theme ‘Sustainable Tourism – a Tool for Development’. Habib Ali Syed Chairman of Tourism Developers Association of Bangladesh (TDAB) also participate the rally organized by TDAB in the Capital on Wednesday along with TDAB BOD & Members. Md. Zamiul Ahmed Former Chairman of TDAB also Present at The Rally. Civil Aviation and Tourism Minister Rashed Khan Menon, Chairman of Bangladesh Parjatan Corporation (BPC) AKhtaruzzaman Khan Kabir and CEO of BTB Dr. Md Nasir Uddin and secretary to civil aviation and tourism ministry were present at the Rally at Dhaka today.",
//         },
//         {
//             id: 2, photo: redison_img, title_name: "Radisson Blu Dhaka Water Garden", source_name: "Dainik Inkilab",
//             date: "23-Decmber-2022",
//             describtion: "World Tourism Day 2017 presents a unique opportunity to raise awareness on the contribution of sustainable tourism to development among public and private sector decision-makers and the international community, while mobilising all stakeholders to work together in making tourism a catalyst for positive change, says the United Nations World Tourism Organization (UNWTO). The government and different organizations have chalked out weeklong programs to celebrate the World Tourism Day with the global theme ‘Sustainable Tourism – a Tool for Development’. Habib Ali Syed Chairman of Tourism Developers Association of Bangladesh (TDAB) also participate the rally organized by TDAB in the Capital on Wednesday along with TDAB BOD & Members. Md. Zamiul Ahmed Former Chairman of TDAB also Present at The Rally. Civil Aviation and Tourism Minister Rashed Khan Menon, Chairman of Bangladesh Parjatan Corporation (BPC) AKhtaruzzaman Khan Kabir and CEO of BTB Dr. Md Nasir Uddin and secretary to civil aviation and tourism ministry were present at the Rally at Dhaka today.",
//         },
//         {
//             id: 3, photo: redison_img, title_name: "Sheik Mujibur Birthday Celebration", source_name: "Independent",
//             date: "23-Decmber-2022",
//             describtion: "World Tourism Day 2017 presents a unique opportunity to raise awareness on the contribution of sustainable tourism to development among public and private sector decision-makers and the international community, while mobilising all stakeholders to work together in making tourism a catalyst for positive change, says the United Nations World Tourism Organization (UNWTO). The government and different organizations have chalked out weeklong programs to celebrate the World Tourism Day with the global theme ‘Sustainable Tourism – a Tool for Development’. Habib Ali Syed Chairman of Tourism Developers Association of Bangladesh (TDAB) also participate the rally organized by TDAB in the Capital on Wednesday along with TDAB BOD & Members. Md. Zamiul Ahmed Former Chairman of TDAB also Present at The Rally. Civil Aviation and Tourism Minister Rashed Khan Menon, Chairman of Bangladesh Parjatan Corporation (BPC) AKhtaruzzaman Khan Kabir and CEO of BTB Dr. Md Nasir Uddin and secretary to civil aviation and tourism ministry were present at the Rally at Dhaka today.",
//         },
//         {
//             id: 4, photo: redison_img, title_name: "Radisson Blu Dinajpur Water Garden", source_name: "Dinajpur",
//             date: "23-Decmber-2022",
//             describtion: "World Tourism Day 2017 presents a unique opportunity to raise awareness on the contribution of sustainable tourism to development among public and private sector decision-makers and the international community, while mobilising all stakeholders to work together in making tourism a catalyst for positive change, says the United Nations World Tourism Organization (UNWTO). The government and different organizations have chalked out weeklong programs to celebrate the World Tourism Day with the global theme ‘Sustainable Tourism – a Tool for Development’. Habib Ali Syed Chairman of Tourism Developers Association of Bangladesh (TDAB) also participate the rally organized by TDAB in the Capital on Wednesday along with TDAB BOD & Members. Md. Zamiul Ahmed Former Chairman of TDAB also Present at The Rally. Civil Aviation and Tourism Minister Rashed Khan Menon, Chairman of Bangladesh Parjatan Corporation (BPC) AKhtaruzzaman Khan Kabir and CEO of BTB Dr. Md Nasir Uddin and secretary to civil aviation and tourism ministry were present at the Rally at Dhaka today.",
//         },
//     ]);
//     const param = useParams();
//     const festivals = festivalsList.find(item => item.id === Number(param.id));
//     console.log(festivals, "doc");


//     return (
//         <div className="container pt-2 mb-5">
//             <h3 className="mb-1 text-center">
//                 <span className="header_text_normal_bm">Fest</span><span className="header_text_normal">ivals Details</span>
//             </h3>
//             <div className="row g-3 pt-4">
//                 <div className="col-12">
//                     <h5>{festivals?.title_name}</h5>
//                     <div className="d-flex justify-content-start">
//                         <p>
//                             <span className="me-2">{festivals?.date}</span>
//                             <span className="me-2"> || </span>
//                             <span className="me-2">{festivals?.source_name}</span>
//                         </p>
//                     </div>
//                     <div className="festivals__img pt-2  d-flex justify-content-center">
//                         <img src={festivals?.photo} className="card-img-top" alt="images" />
//                     </div>
//                 </div>
//                 <div className="col-12">
//                     <p className="news__desc pt-2">{festivals?.describtion}</p>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default FestivalsDetails;