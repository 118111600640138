import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import news_img from "../../images/news_img.jpg";
import news_eng_img from "../../images/news_eng_img.jpg";

const TourismNews = () => {
    const [newsBangaData, setNewsBangaData] = useState([
        {
            id: 1, title: "বর্ণাঢ্য আয়োজনে টিডাবের বিশ্ব পর্যটন দিবস-২০১৮ উদযাপন", photo: news_img, source_link: "https://sarabangla.net",
            desc1: "জনসাধারণের মাঝে পর্যটন সচেতনতা বৃদ্ধি এবং পর্যটনের অর্থনৈতিক-সামাজিক-সাংস্কৃতিক গুরুত্ব তুলে ধরতে এক বর্ণাঢ্য অনুষ্ঠানের মাধ্যমে বিশ্ব পর্যটন দিবস-২০১৮ উদযাপন করে ‘ট্যুরিজম ডেভেলপার্স অ্যাসোসিয়েশন অব বাংলাদেশ (টিডাব)’।",
            desc2: "বিশ্ব পর্যটন দিবস ও টিডিআরের ২১তম প্রতিষ্ঠাবার্ষিকী উপলক্ষে রাজধানীর ধানমন্ডি ক্লাবে এক বর্ণাঢ্য অনুষ্ঠানের আয়োজন করা হয়। সেখানে বিভিন্ন কর্মসূচি নিয়ে নতুন পুরাতন সদস্য এবং আমন্ত্রিত অতিথিদের মিলন মেলা বসেছিল।",
            desc3: "সন্ধ্যার পর থেকে মাঝ রাত পর্যন্ত চলে পর্যায়ক্রমে কেক কাটা, পরিচিতি পর্ব, আলোচনা, গান-বাজনা এবং খাওয়া-দাওয়া। সংগঠনের কর্মকর্তারা দিবসটির তাৎপর্য তুলে ধরে দেশের পর্যটন বিকাশে সকলকে একযোগে কাজ করার পরামর্শ দেন।"
        },
    ]);
    const [newsEnglishData, setNewsEnglishData] = useState([
        {
            id: 1, title: "TDAB Celebrate World Tourism Day 2017 & 20th Anniversary of TDAB.", photo: news_eng_img, source_link: "https://sarabangla.net",
            desc1: "Tourism Developers Association of Bangladesh (TDAB), Celebrate the World tourism Day every Year on 27th September,",
            desc2: "World Tourism Day 2017 presents a unique opportunity to raise awareness on the contribution of sustainable tourism to development among public and private sector decision-makers and the international community, while mobilising all stakeholders to work together in making tourism a catalyst for positive change, says the United Nations World Tourism Organization (UNWTO).",
            desc3: "The government and different organizations have chalked out weeklong programs to celebrate the World Tourism Day with the global theme ‘Sustainable Tourism – a Tool for Development’. Habib Ali Syed Chairman of Tourism Developers Association of Bangladesh (TDAB) also participate the rally organized by TDAB in the Capital on Wednesday along with TDAB BOD & Members. Md. Zamiul Ahmed Former Chairman of TDAB also Present at The Rally. Civil Aviation and Tourism Minister Rashed Khan Menon, Chairman of Bangladesh Parjatan Corporation (BPC) AKhtaruzzaman Khan Kabir and CEO of BTB Dr. Md Nasir Uddin and secretary to civil aviation and tourism ministry were present at the Rally at Dhaka today."
        },
    ]);




    return (
        <div className="container pt-2 mb-5">
            <h3 className="mb-1 text-center">
                <span className="header_text_normal_bm">Tour</span><span className="header_text_normal">ism</span>
                <span className="header_text_bold"> News</span>
            </h3>
            <div className="row g-3 gx-md-5 pt-4">
                <div className="col-md-6">
                    {
                        newsBangaData.length > 0 && newsBangaData.map((newsItem, id) => {
                            return (
                                <div key={id}>
                                    <h5>{newsItem.title}</h5>
                                    <p className="margin__top">
                                        <span className="me-2">by TDAB</span>
                                        <span className="me-2">|</span>
                                        <span className="me-2">Sep 30, 2018</span>
                                        <span className="me-2">|</span>
                                        <span className="me-2">Blog</span>
                                        <span className="me-2">|</span>
                                        <span className="me-2">2 comments</span>
                                    </p>
                                    <div className="news__img margin__top">
                                        <img src={newsItem.photo} alt="image" />
                                    </div>
                                    <div className="margin__top">
                                        <p className="news__desc">{newsItem.desc1}</p>
                                        <p className="news__desc">{newsItem.desc2}</p>
                                        <p className="news__desc">{newsItem.desc3}</p>
                                        <p className="news__desc">
                                            News Source:  <a href="https://sarabangla.net" target="_blank" className="text-decoration-none">{newsItem.source_link}</a>
                                        </p>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
                <div className="col-md-6">
                    {
                        newsEnglishData.length > 0 && newsEnglishData.map((newsItem, id) => {
                            return (
                                <div key={id}>
                                    <h5>{newsItem.title}</h5>
                                    <p className="margin__top">
                                        <span className="me-2">by TDAB</span>
                                        <span className="me-2">|</span>
                                        <span className="me-2">Sep 30, 2018</span>
                                        <span className="me-2">|</span>
                                        <span className="me-2">Blog</span>
                                        <span className="me-2">|</span>
                                        <span className="me-2">2 comments</span>
                                    </p>
                                    <div className="news__img margin__top">
                                        <img src={newsItem.photo} alt="image" />
                                    </div>
                                    <div className="margin__top">
                                        <p className="news__desc">{newsItem.desc1}</p>
                                        <p className="news__desc">{newsItem.desc2}</p>
                                        <p className="news__desc">{newsItem.desc3}</p>
                                        <p className="news__desc">
                                            News Source:  <a href="https://sarabangla.net" target="_blank" className="text-decoration-none">{newsItem.source_link}</a>
                                        </p>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default TourismNews;