import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import '../dashboard/AdminDashboard.css';
import '../AppAdmin.css';

const AddImmigrationSetup = () => {

    const [inputField, setInputField] = useState({
        immigration_category: "",
        title_name: "",
        email: "",
        contact: "",
    });

    const inputsHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        console.log("submitHandler: ", inputField);

        const url = "/add-immigration";
        axios.post(url, inputField)
            .then(response => {
                console.log(response, "dff");
                if (response.status === 200) {
                    alert("Are you Added  to Data");
                    setInputField({
                        immigration_category: "",
                        title_name: "",
                        email: "",
                        contact: "",
                    });
                } else {
                    alert("Failed")
                }
            })
            .catch(error => {
                console.log(error);
            })

        // const formData = new FormData();
        // formData.append("immigration_category", inputField.immigration_category)
        // formData.append("title_name", inputField.title_name)
        // formData.append("email", inputField.email)
        // formData.append("contact", inputField.contact)

        // const url = "/add-immigration";
        // try {
        //     let res = await axios.post(url, formData);
        //     console.log("dd",res)
        //     if (res.status == 201) {
        //         alert("Are you Added  toData", formData);
        //         setInputField({
        //             immigration_category: "",
        //             title_name: "",
        //             email: "",
        //             contact: "",
        //         });
        //     } else {
        //         alert("Failed")
        //     }
        // } catch (error) {
        //     console.log(error);
        // }
    };


    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Add Immigration Setup
                        <Link to='/dashboard/immigration-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        <form className="mt-1" encType='multipart/form-data'
                            onSubmit={submitHandler}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <div className="row g-3 mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="inputImmigrationCategory" className="col-form-label col-form-label-sm d-block">
                                                Immigration Category <span className="text-danger">*</span></label>
                                            <select id="inputImmigrationCategory" className="form-select form-select-sm"
                                                name="immigration_category"
                                                value={inputField.immigration_category}
                                                onChange={inputsHandler}>
                                                <option value="">Please select a Immigration</option>
                                                <option value="Emergency Phone Numbers">Emergency Phone Numbers</option>
                                                <option value="Emergency Email Address">Emergency Email Address</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputImmigration" className="col-form-label col-form-label-sm d-block " >
                                            Immigration Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputImmigration"
                                                name="title_name"
                                                value={inputField.title_name}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputContact" className="col-form-label col-form-label-sm d-block " >
                                                Contact </label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputContact"
                                                name="contact"
                                                value={inputField.contact}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputEmail" className="col-form-label col-form-label-sm d-block " >
                                                Email </label>
                                            <input type="email" className="form-control form-control-sm form__ptb " id="InputEmail"
                                                name="email"
                                                value={inputField.email}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddImmigrationSetup;

