import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const EditAdvisorsSetup = () => {

    const categories = [
        {
            id: 1,
            name: 'Home Page',
            subcategories: [
                { name: 'Our Events' },
                { name: 'Tourism News' },
                { name: 'TDAB Gallery' }
            ]
        },
        {
            id: 2,
            name: 'All Airlines',
            subcategories: [
                { name: 'Banner' },
            ]
        },
        {
            id: 3,
            name: 'Travel Agent & Tour Operator',
            subcategories: [
                { name: 'Banner' },
            ]
        },
        {
            id: 4,
            name: 'Contact',
            subcategories: [
                { name: 'Banner' },
            ]
        }
    ];

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState([]);



    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Edit Cards
                        <Link to='/cards' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        <form className="mt-1">
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <div className="row g-3 mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="inputPageName" className="col-form-label col-form-label-sm d-block">
                                                Page Name <span className="text-danger">*</span></label>
                                            <select
                                                className="form-select form-select-sm" id="inputPageName"
                                                value={selectedCategory ? selectedCategory?.name : ''}
                                                onChange={e => {
                                                    setSelectedCategory(categories[e.target.value]);
                                                }}
                                            >
                                                <option value='' disabled>
                                                    Choose a Page Name
                                                </option>
                                                {
                                                    categories.map((category, index) => (
                                                        <option key={category.name} value={index}>
                                                            {category.name}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="inputSectionName" className="col-form-label col-form-label-sm d-block">
                                                Section Name <span className="text-danger">*</span></label>

                                            <select
                                                className="form-select form-select-sm" id="inputSectionName"
                                                value={selectedSubcategory ? selectedSubcategory?.name : ''}
                                                onChange={e => setSelectedSubcategory(selectedCategory?.subcategories[e.target.value])}
                                            >
                                                <option selected value='' disabled>
                                                    Choose a Section Name
                                                </option>
                                                {
                                                    selectedCategory?.subcategories?.map((subcategory, index) => (
                                                        <option key={subcategory.name} value={index}>
                                                            {subcategory.name}
                                                        </option>
                                                    ))
                                                }
                                            </select>

                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputBannerTitle" className="col-form-label col-form-label-sm d-block " >
                                                Title Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputBannerTitle"
                                                name="max__prn__dose"
                                            // value={regularMedicineEight.max__prn__dose}
                                            // onChange={inputRegularMedicineChangeEight} 
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputSubTitle" className="col-form-label col-form-label-sm d-block " >Sub Title Name</label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputSubTitle"
                                                name="max__prn__dose"
                                            // value={regularMedicineEight.max__prn__dose}
                                            // onChange={inputRegularMedicineChangeEight} 
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputDate" className="col-form-label col-form-label-sm d-block " >
                                                Date <span className="text-danger">*</span></label>

                                            <input type="date" className="form-control form-control-sm form__ptb " id="InputDate"
                                                name="max__prn__dose"
                                            // value={regularMedicineEight.max__prn__dose}
                                            // onChange={inputRegularMedicineChangeEight} 
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >
                                                Photo Upload <span className="text-danger">*</span></label>
                                            <input className="form-control form-control-sm form__ptb " id="InputPhotoUpload"
                                                type="file"
                                                multiple
                                                name="max__prn__dose"
                                            // value={regularMedicineEight.max__prn__dose}
                                            // onChange={inputRegularMedicineChangeEight} 
                                            />
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div className="col-12">
                                            <label htmlFor="DescriptionFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
                                                Description <span className="text-danger">*</span></label>
                                            <textarea className="form-control" id="DescriptionFormControlTextarea1" rows="3"></textarea>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditAdvisorsSetup;
