import React, { useState, useEffect } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import "./SliderImage.css";
import home_img from '../../images/home_img.png';
import axios from 'axios';
import { imageURL } from '../../imageURL/imageURL';

const SliderImage = () => {

    const responsiveSettings = [
        {
            breakpoint: 800,
            autoplay: "true",
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ];
    // const slideImages = [
    //     {
    //         id: 1,
    //         image: home_img,
    //         caption: 'Slide 1'
    //     },
    //     {
    //         id: 2,
    //         image: home_img,
    //         caption: 'Slide 1'
    //     },
    // ];
    // // console.log("first", arr);

    const [slideImages, setSlideImages] = useState([]);

    const fetchData = async () => {
        try {
            const res = await axios.get("/home-banner");
            console.log("SlideImages data: ", res.data.data);
            setSlideImages(res.data.data[0].image_list);
        } catch (error) {
            console.log(error)
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

console.log("slideImages", slideImages);
    return (
        <div>
            {
                slideImages !== undefined &&
                <Slide slidesToScroll={2} slidesToShow={1} indicators={true} responsive={responsiveSettings}>
                    {
                         slideImages.map((item, id) => {
                            return (
                                <div key={id} className="slide_img">
                                    <img src={`${imageURL}/${item}`} alt="image" loading='lazy' />
                                </div>
                            )
                        })
                    }
                </Slide>
            }
        </div>
    );
};

export default SliderImage;