import React from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import LOGO_01 from '../../images/LOGO_01.png';
import '../header/Header.css';

const Header = () => {
    const navigate = useNavigate();
    const headerClickHandler = e => {
        e.preventDefault()
        // navigate(-1);
        navigate("/")
    };
    const location = useLocation();

    return (
        <nav className="navbar navbar-expand-lg  header__navbar sticky-top" >
            <div className="container container-fluid" >
                <Link to="/" className={`navbar-brand`} >
                    <div className="logo_img">
                        <img src={LOGO_01} className="img-fluid" alt="LOGO_01" border="0" />
                    </div>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse navbar__justify" id="navbarNav">
                    <span onClick={headerClickHandler}></span>

                    <ul className="navbar-nav menu3">
                        {/* <li className="nav-item">
                            <Link to='/' className={`navbar-brand nav-link ${location.pathname === "/" && "active-nav"}`} aria-current="page"><span>Home</span></Link>
                        </li> */}
                        <li className="nav-item dropdown mx-0">
                            <Link to='/' onClick={() => navigate("/")} className={`navbar-brand nav-link ${location.pathname === "" && "active-nav"}`} id="navbarDropdown13" role="button" data-bs-toggle="dropdown" aria-expanded="false" aria-current="page"><span>Home <i className="drop__icon__i fa-solid fa-angle-down"></i></span></Link>

                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown13">
                                {/* <li className="nav-item">
                                    <Link to='/' className={`navbar-brand nav-link ${location.pathname === "/" && "active-nav"}`} >
                                        <span>Home</span>
                                    </Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link to='board-of-directors' className={`navbar-brand nav-link ${location.pathname === "/board-of-directors" && "active-nav"}`} >
                                        <span>Board of Directors</span>
                                    </Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link to="committees" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/committees" && "active-nav"}`}>
                                        <span>Committees</span>
                                    </Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link to="advisors" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/advisors" && "active-nav"}`}>
                                        <span>Advisors</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="secretariat" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/secretariat" && "active-nav"}`}>
                                        <span>Secretariat</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="chairman-message" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/presidential-message" && "active-nav"}`}>
                                        <span>Chairman Message</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown mx-0">
                            <Link to='#' className={`navbar-brand nav-link ${location.pathname === "" && "active-nav"}`} id="navbarDropdown13" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span>Home Affairs <i className="drop__icon__i fa-solid fa-angle-down"></i></span></Link>

                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown13">
                                <li className="nav-item">
                                    <Link to="police" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/ploice" && "active-nav"}`}>
                                        <span>Police</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="tourist-police" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/tourist-police" && "active-nav"}`}>
                                        <span>Tourist Police</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="immigration" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/immigration" && "active-nav"}`}>
                                        <span>Immigration</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown mx-0">
                            <Link to='#' className={`navbar-brand nav-link ${location.pathname === "" && "active-nav"}`} id="navbarDropdown12" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span>Membership <i className="drop__icon__i fa-solid fa-angle-down"></i></span></Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown12">
                                <li className="nav-item">
                                    <Link to="member-list" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/member-list" && "active-nav"}`}>
                                        <span>General Member</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="member-list-associate" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/member-list" && "active-nav"}`}>
                                        <span>Associate Member</span>
                                    </Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link to="membership" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/membership" && "active-nav"}`}>
                                        <span>Membership</span>
                                    </Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link to="membership-criteria-fees" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/membership-criteria-fees" && "active-nav"}`}>
                                        <span>Membership Criteria & Fees</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="membership-application" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/membership-application" && "active-nav"}`}>
                                        <span>Membership Application</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown mx-0">
                            <Link to='#' className={`navbar-brand nav-link ${location.pathname === "" && "active-nav"}`} id="navbarDropdown13" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span>Foriegn Mission <i className="drop__icon__i fa-solid fa-angle-down"></i></span></Link>

                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown13">
                                <li className="nav-item">
                                    <Link to="ministry-info" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/foreign-mission-bangladesh" && "active-nav"}`}>
                                        <span>Ministry Information</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to='/embassies' className={`navbar-brand dropdown-item nav-link ${location.pathname === "/embassies" && "active-nav"}`} >
                                        <span>Embassies in Bangladesh</span>
                                    </Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link to="foreign-mission-bangladesh" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/foreign-mission-bangladesh" && "active-nav"}`}>
                                        <span>Foreign Mission in Bangladesh</span>
                                    </Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link to="bangladesh-mission-foreign-country" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/bangladesh-mission-foreign-country" && "active-nav"}`}>
                                        <span>Bangladesh Mission in Foreign Country</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown mx-0">
                            <Link to='#' className={`navbar-brand nav-link ${location.pathname === "" && "active-nav"}`} id="navbarDropdown14" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span>Transportation <i className="drop__icon__i fa-solid fa-angle-down"></i></span></Link>

                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown14">
                                <li className="nav-item">
                                    <Link to='/all-airlines' className={`navbar-brand nav-link ${location.pathname === "/all-airlines" && "active-nav"}`} >
                                        <span>All Airlines</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="accommodation" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/accommodation" && "active-nav"}`}>
                                        <span>Accommodation</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="travel-agent-tour-operator" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/travel-agent-tour-operator" && "active-nav"}`}>
                                        <span>Travel Agent & Tour Operator</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="food-beverage" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/food-beverage" && "active-nav"}`}>
                                        <span>Food & Beverage</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown mx-0">
                            <Link to='#' className={`navbar-brand nav-link ${location.pathname === "" && "active-nav"}`} id="navbarDropdown14" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span>Tourism <i className="drop__icon__i fa-solid fa-angle-down"></i></span></Link>

                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown14">
                                <li className="nav-item">
                                    <Link to="tourism-spot" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/tourism-spot" && "active-nav"}`}>
                                        <span>Tourism Spot</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="tourism-news" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/tourism-news" && "active-nav"}`}>
                                        <span>Tourism News</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>




                        {/* <li className="nav-item">
                            <Link to='/tourism-spot' className={`navbar-brand nav-link ${location.pathname === "/tourism-spot" && "active-nav"}`} ><span>Tourism Spot</span></Link>
                        </li> */}
                        <li className="nav-item">
                            <Link to='/festivals' className={`navbar-brand nav-link ${location.pathname === "/festivals" && "active-nav"}`} ><span>Festivals</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/gallery' className={`navbar-brand nav-link ${location.pathname === "/gallery" && "active-nav"}`} ><span>Gallery</span></Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to='/embassies' className={`navbar-brand nav-link ${location.pathname === "/embassies" && "active-nav"}`} ><span>Embassies</span></Link>
                        </li> */}

                        <li className="nav-item">
                            <Link to='/about-bangladesh' className={`navbar-brand nav-link ${location.pathname === "/about-bangladesh" && "active-nav"}`}  ><span>Bangladesh</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/contact' className={`navbar-brand nav-link ${location.pathname === "/contact" && "active-nav"}`}  ><span>Contact</span></Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to='/login' className="nav-link btn_learn_more nav_link_pay_online">Login</Link>
                        </li> */}
                        {/* <li className="nav-item dropdown mx-0" id="drop_down">
                            <Link to='#' className={`navbar-brand nav-link ${location.pathname === "" && "active-nav"}`} id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span className="pt-1"><i className="fa-solid fa-bars"></i></span></Link>
                            <ul className="dropdown-menu tabs__drop" aria-labelledby="navbarDropdown1">
                                <li className="nav-item">
                                    <Link to="forein-ministry" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/forein-ministry" && "active-nav"}`}>
                                        <span>Foreign Ministry in Bangladesh</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="bangladesh-map" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/bangladesh-map" && "active-nav"}`}>
                                        <span>Bangladesh Map</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="bangladesh-mission" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/bangladesh-mission" && "active-nav"}`}>
                                        <span>Bangladesh Mission in Foreign Countries</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="ministry-info" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/ministry-info" && "active-nav"}`}>
                                        <span>Ministry Information</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="members" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/members" && "active-nav"}`}>
                                        <span>Members</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="gallery" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/gallery" && "active-nav"}`}>
                                        <span>Gallery</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="about-bangladesh" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/about-bangladesh" && "active-nav"}`}>
                                        <span>About Bangladesh</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="tourism-news" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/tourism-news" && "active-nav"}`}>
                                        <span>Tourism News</span>
                                    </Link>
                                </li>
                            </ul>
                        </li> */}

                        {/* 
                        <li className="nav-item dropdown mx-0">
                            <Link to='#' className={`navbar-brand nav-link ${location.pathname === "" && "active-nav"}`} id="navbarDropdown1487" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span>Admin <i className="drop__icon__i fa-solid fa-angle-down"></i></span></Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown1487">
                                <li className="nav-item">
                                    <Link to="/login" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/login" && "active-nav"}`}>
                                        <span>Dashboard</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/dashboard/logout" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/logout" && "active-nav"}`}>
                                        <span>Logout</span>
                                    </Link>
                                </li>
                            </ul>
                        </li> */}
                    </ul>

                </div>
            </div>
        </nav>
    );
};

export default Header;


// import React from 'react';
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import LOGO_01 from '../../images/LOGO_01.png';
// import '../header/Header.css';

// const Header = () => {
//     const navigate = useNavigate();
//     const headerClickHandler = e => {
//         e.preventDefault()
//         // navigate(-1);
//         navigate("/")
//     };
//     const location = useLocation();

//     return (
//         <nav className="navbar navbar-expand-lg  header__navbar sticky-top" >
//             <div className="container container-fluid" >
//                 <Link to="/" className={`navbar-brand`} >
//                     <div className="logo_img">
//                         <img src={LOGO_01} className="img-fluid" alt="LOGO_01" border="0" />
//                     </div>
//                 </Link>
//                 <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
//                     <span className="navbar-toggler-icon"></span>
//                 </button>
//                 <div className="collapse navbar-collapse navbar__justify" id="navbarNav">
//                     <span onClick={headerClickHandler}></span>

//                     <ul className="navbar-nav menu3">
//                         {/* <li className="nav-item">
//                             <Link to='/' className={`navbar-brand nav-link ${location.pathname === "/" && "active-nav"}`} aria-current="page"><span>Home</span></Link>
//                         </li> */}
//                         <li className="nav-item dropdown mx-0">
//                             <Link to='/' onClick={() => navigate("/")} className={`navbar-brand nav-link ${location.pathname === "" && "active-nav"}`} id="navbarDropdown13" role="button" data-bs-toggle="dropdown" aria-expanded="false" aria-current="page"><span>Home <i className="drop__icon__i fa-solid fa-angle-down"></i></span></Link>

//                             <ul className="dropdown-menu" aria-labelledby="navbarDropdown13">
//                                 {/* <li className="nav-item">
//                                     <Link to='/' className={`navbar-brand nav-link ${location.pathname === "/" && "active-nav"}`} >
//                                         <span>Home</span>
//                                     </Link>
//                                 </li> */}
//                                 <li className="nav-item">
//                                     <Link to='board-of-directors' className={`navbar-brand nav-link ${location.pathname === "/board-of-directors" && "active-nav"}`} >
//                                         <span>Board of Directors</span>
//                                     </Link>
//                                 </li>
//                                 {/* <li className="nav-item">
//                                     <Link to="committees" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/committees" && "active-nav"}`}>
//                                         <span>Committees</span>
//                                     </Link>
//                                 </li> */}
//                                 <li className="nav-item">
//                                     <Link to="advisors" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/advisors" && "active-nav"}`}>
//                                         <span>Advisors</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="secretariat" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/secretariat" && "active-nav"}`}>
//                                         <span>Secretariat</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="chairman-message" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/presidential-message" && "active-nav"}`}>
//                                         <span>Chairman Message</span>
//                                     </Link>
//                                 </li>
//                             </ul>
//                         </li>
//                         <li className="nav-item dropdown mx-0">
//                             <Link to='#' className={`navbar-brand nav-link ${location.pathname === "" && "active-nav"}`} id="navbarDropdown13" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span>Home Affairs <i className="drop__icon__i fa-solid fa-angle-down"></i></span></Link>

//                             <ul className="dropdown-menu" aria-labelledby="navbarDropdown13">
//                                 <li className="nav-item">
//                                     <Link to="police" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/ploice" && "active-nav"}`}>
//                                         <span>Police</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="tourist-police" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/tourist-police" && "active-nav"}`}>
//                                         <span>Tourist Police</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="immigration" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/immigration" && "active-nav"}`}>
//                                         <span>Immigration</span>
//                                     </Link>
//                                 </li>
//                             </ul>
//                         </li>
//                         <li className="nav-item dropdown mx-0">
//                             <Link to='#' className={`navbar-brand nav-link ${location.pathname === "" && "active-nav"}`} id="navbarDropdown12" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span>Membership <i className="drop__icon__i fa-solid fa-angle-down"></i></span></Link>
//                             <ul className="dropdown-menu" aria-labelledby="navbarDropdown12">
//                                 <li className="nav-item">
//                                     <Link to="member-list" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/member-list" && "active-nav"}`}>
//                                         <span>Member List</span>
//                                     </Link>
//                                 </li>
//                                 {/* <li className="nav-item">
//                                     <Link to="membership" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/membership" && "active-nav"}`}>
//                                         <span>Membership</span>
//                                     </Link>
//                                 </li> */}
//                                 <li className="nav-item">
//                                     <Link to="membership-criteria-fees" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/membership-criteria-fees" && "active-nav"}`}>
//                                         <span>Membership Criteria & Fees</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="membership-application" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/membership-application" && "active-nav"}`}>
//                                         <span>Membership Application</span>
//                                     </Link>
//                                 </li>
//                             </ul>
//                         </li>
//                         <li className="nav-item dropdown mx-0">
//                             <Link to='#' className={`navbar-brand nav-link ${location.pathname === "" && "active-nav"}`} id="navbarDropdown13" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span>Foriegn Mission <i className="drop__icon__i fa-solid fa-angle-down"></i></span></Link>

//                             <ul className="dropdown-menu" aria-labelledby="navbarDropdown13">
//                                 <li className="nav-item">
//                                     <Link to="ministry-info" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/foreign-mission-bangladesh" && "active-nav"}`}>
//                                         <span>Ministry Information</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to='/embassies' className={`navbar-brand dropdown-item nav-link ${location.pathname === "/embassies" && "active-nav"}`} >
//                                         <span>Embassies in Bangladesh</span>
//                                     </Link>
//                                 </li>
//                                 {/* <li className="nav-item">
//                                     <Link to="foreign-mission-bangladesh" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/foreign-mission-bangladesh" && "active-nav"}`}>
//                                         <span>Foreign Mission in Bangladesh</span>
//                                     </Link>
//                                 </li> */}
//                                 <li className="nav-item">
//                                     <Link to="bangladesh-mission-foreign-country" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/bangladesh-mission-foreign-country" && "active-nav"}`}>
//                                         <span>Bangladesh Mission in Foreign Country</span>
//                                     </Link>
//                                 </li>
//                             </ul>
//                         </li>
//                         <li className="nav-item dropdown mx-0">
//                             <Link to='#' className={`navbar-brand nav-link ${location.pathname === "" && "active-nav"}`} id="navbarDropdown14" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span>Transportation <i className="drop__icon__i fa-solid fa-angle-down"></i></span></Link>

//                             <ul className="dropdown-menu" aria-labelledby="navbarDropdown14">
//                                 <li className="nav-item">
//                                     <Link to='/all-airlines' className={`navbar-brand nav-link ${location.pathname === "/all-airlines" && "active-nav"}`} >
//                                         <span>All Airlines</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="accommodation" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/accommodation" && "active-nav"}`}>
//                                         <span>Accommodation</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="travel-agent-tour-operator" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/travel-agent-tour-operator" && "active-nav"}`}>
//                                         <span>Travel Agent & Tour Operator</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="food-beverage" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/food-beverage" && "active-nav"}`}>
//                                         <span>Food & Beverage</span>
//                                     </Link>
//                                 </li>
//                             </ul>
//                         </li>
//                         <li className="nav-item dropdown mx-0">
//                             <Link to='#' className={`navbar-brand nav-link ${location.pathname === "" && "active-nav"}`} id="navbarDropdown14" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span>Tourism <i className="drop__icon__i fa-solid fa-angle-down"></i></span></Link>

//                             <ul className="dropdown-menu" aria-labelledby="navbarDropdown14">
//                                 <li className="nav-item">
//                                     <Link to="tourism-spot" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/tourism-spot" && "active-nav"}`}>
//                                         <span>Tourism Spot</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="tourism-news" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/tourism-news" && "active-nav"}`}>
//                                         <span>Tourism News</span>
//                                     </Link>
//                                 </li>
//                             </ul>
//                         </li>




//                         {/* <li className="nav-item">
//                             <Link to='/tourism-spot' className={`navbar-brand nav-link ${location.pathname === "/tourism-spot" && "active-nav"}`} ><span>Tourism Spot</span></Link>
//                         </li> */}
//                         <li className="nav-item">
//                             <Link to='/festivals' className={`navbar-brand nav-link ${location.pathname === "/festivals" && "active-nav"}`} ><span>Festivals</span></Link>
//                         </li>
//                         <li className="nav-item">
//                             <Link to='/gallery' className={`navbar-brand nav-link ${location.pathname === "/gallery" && "active-nav"}`} ><span>Gallery</span></Link>
//                         </li>
//                         {/* <li className="nav-item">
//                             <Link to='/embassies' className={`navbar-brand nav-link ${location.pathname === "/embassies" && "active-nav"}`} ><span>Embassies</span></Link>
//                         </li> */}

//                         <li className="nav-item">
//                             <Link to='/about-bangladesh' className={`navbar-brand nav-link ${location.pathname === "/about-bangladesh" && "active-nav"}`}  ><span>Bangladesh</span></Link>
//                         </li>
//                         <li className="nav-item">
//                             <Link to='/contact' className={`navbar-brand nav-link ${location.pathname === "/contact" && "active-nav"}`}  ><span>Contact</span></Link>
//                         </li>
//                         {/* <li className="nav-item">
//                             <Link to='/login' className="nav-link btn_learn_more nav_link_pay_online">Login</Link>
//                         </li> */}
//                         {/* <li className="nav-item dropdown mx-0" id="drop_down">
//                             <Link to='#' className={`navbar-brand nav-link ${location.pathname === "" && "active-nav"}`} id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span className="pt-1"><i className="fa-solid fa-bars"></i></span></Link>

//                             <ul className="dropdown-menu tabs__drop" aria-labelledby="navbarDropdown1">
//                                 <li className="nav-item">
//                                     <Link to="forein-ministry" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/forein-ministry" && "active-nav"}`}>
//                                         <span>Foreign Ministry in Bangladesh</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="bangladesh-map" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/bangladesh-map" && "active-nav"}`}>
//                                         <span>Bangladesh Map</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="bangladesh-mission" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/bangladesh-mission" && "active-nav"}`}>
//                                         <span>Bangladesh Mission in Foreign Countries</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="ministry-info" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/ministry-info" && "active-nav"}`}>
//                                         <span>Ministry Information</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="members" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/members" && "active-nav"}`}>
//                                         <span>Members</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="gallery" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/gallery" && "active-nav"}`}>
//                                         <span>Gallery</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="about-bangladesh" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/about-bangladesh" && "active-nav"}`}>
//                                         <span>About Bangladesh</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="tourism-news" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/tourism-news" && "active-nav"}`}>
//                                         <span>Tourism News</span>
//                                     </Link>
//                                 </li>
//                             </ul>
//                         </li> */}


//                         <li className="nav-item dropdown mx-0">
//                             <Link to='#' className={`navbar-brand nav-link ${location.pathname === "" && "active-nav"}`} id="navbarDropdown1487" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span>Admin <i className="drop__icon__i fa-solid fa-angle-down"></i></span></Link>

//                             <ul className="dropdown-menu" aria-labelledby="navbarDropdown1487">
//                                 <li className="nav-item">
//                                     <Link to="/login" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/login" && "active-nav"}`}>
//                                         <span>Dashboard</span>
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="/logout" className={`navbar-brand dropdown-item nav-link ${location.pathname === "/logout" && "active-nav"}`}>
//                                         <span>Logout</span>
//                                     </Link>
//                                 </li>
//                             </ul>
//                         </li>
//                     </ul>

//                 </div>
//             </div>
//         </nav>
//     );
// };

// export default Header;