
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
// import '../dashboard/AdminDashboard.css';
import '../AppAdmin.css';
import Apd from '../boardOfDirectorsSetup/Apd';

const AddAssociateMemberListSetup = () => {


    const [inputField, setInputField] = useState({
        associate_member_list: "",
        title_name: "",
        organization_name: "",
        address: "",
        card_link: "",
        cell: "",
        email: "",
        tel: "",
        web_link: "",
        description: "",
        image: null,
    });

    const handleImageChange = (e) => {
        setInputField({
            ...inputField,
            image: e.target.files[0]
        })
    };

    const inputsChange = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });

    };

    const submitFormData = async (e) => {
        e.preventDefault();
        console.log("submitFormData: ", inputField);

        const formData = new FormData();
        formData.append("image", inputField.image, inputField.image.name);
        formData.append("associate_member_list", inputField.associate_member_list)
        formData.append("title_name", inputField.title_name)
        formData.append("organization_name", inputField.organization_name)
        formData.append("email", inputField.email)
        formData.append("address", inputField.address)
        formData.append("card_link", inputField.card_link)
        formData.append("web_link", inputField.web_link)
        formData.append("cell", inputField.cell)
        formData.append("tel", inputField.tel)
        formData.append("description", inputField.description)


        const url = "/add-member-list-associate";
        // console.log("mm", inputField.image, "mmm", inputField.image.name);
        try {
            let response = await axios.post(url, formData);
            if (response.status == 200) {
                alert("Are you Added  toData", formData);
                setInputField({
                    associate_member_list: "",
                    title_name: "",
                    organization_name: "",
                    address: "",
                    card_link: "",
                    cell: "",
                    email: "",
                    tel: "",
                    web_link: "",
                    description: "",
                    image: null,
                });
                document.getElementById("InputPhotoUpload").value = '';
            } else {
                alert("Failed")
            }
        } catch (error) {
            console.log(error);
        }
    };


    const inputPhotoUploadClose = () => {
        setInputField({ ...inputField, image: '' });
        document.getElementById("InputPhotoUpload").value = ''
    };





    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Add Associate Member List Setup
                        <Link to='/dashboard/member-list-associate-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        {/* <Apd /> */}
                        <form className="mt-1"
                            onSubmit={submitFormData}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <div className="row g-3 mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="inputGeneralMemberList" className="col-form-label col-form-label-sm d-block">
                                            Associate Member List Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm form__ptb " id="inputGeneralMemberList"
                                                name="associate_member_list"
                                                value={inputField.associate_member_list}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="inputTitleName" className="col-form-label col-form-label-sm d-block">
                                                Title Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm form__ptb " id="inputTitleName"
                                                name="title_name"
                                                value={inputField.title_name}
                                                onChange={inputsChange}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="inputOrganizationName" className="col-form-label col-form-label-sm d-block">
                                                Organization Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="inputOrganizationName"
                                                name="organization_name"
                                                value={inputField.organization_name}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputCardLink" className="col-form-label col-form-label-sm d-block " >
                                                Card Link <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputCardLink"
                                                name="card_link"
                                                value={inputField.card_link}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputWebLink" className="col-form-label col-form-label-sm d-block " >
                                                Web Link <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputWebLink"
                                                name="web_link"
                                                value={inputField.web_link}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputEmail" className="col-form-label col-form-label-sm d-block " >
                                                Email <span className="text-danger">*</span></label>

                                            <input type="email" className="form-control form-control-sm form__ptb " id="InputEmail"
                                                name="email"
                                                value={inputField.email}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputAddress" className="col-form-label col-form-label-sm d-block " >
                                                Address <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputAddress"
                                                name="address"
                                                value={inputField.address}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-6">
                                            <label htmlFor="InputCell" className="col-form-label col-form-label-sm d-block " >Cell <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputCell"
                                                name="cell"
                                                value={inputField.cell}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputTelephone" className="col-form-label col-form-label-sm d-block " >
                                                Telephone </label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputTelephone"
                                                name="tel"
                                                value={inputField.tel}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-2">
                                        <div className="col-md-6">
                                            <label htmlFor="DescriptionFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
                                                Description <span className="text-danger">*</span></label>
                                            <textarea className="form-control" id="DescriptionFormControlTextarea1" rows="3"
                                                name="description"
                                                value={inputField.description}
                                                onChange={inputsChange}
                                            ></textarea>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >
                                                Card Photo Upload <span className="text-danger">*</span></label>
                                            <input className="form-control form-control-sm form__ptb" id="InputPhotoUpload"
                                                type="file"
                                                // multiple
                                                name="image"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                            />
                                            <div className="pt-4">
                                                {inputField.image && (
                                                    <div className="d-flex input__img">
                                                        <img src={URL.createObjectURL(inputField.image)} alt="Preview" />

                                                        <span className="close__icon"
                                                            onClick={inputPhotoUploadClose}>
                                                            <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAssociateMemberListSetup;

