import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import '../dashboard/AdminDashboard.css';
import '../AppAdmin.css';
import Apd from '../boardOfDirectorsSetup/Apd';

const AddSlidebarImage = () => {

    const pageName = [
        {
            id: 1,
            page_name: 'Home Page',
            sectionNameById: 1,
        },
        {
            id: 2,
            page_name: 'All Airlines',
            sectionNameById: 2,
        },
        {
            id: 3,
            page_name: 'Travel Agent & Tour Operator',
            sectionNameById: 3,
        },
        {
            id: 4,
            page_name: 'Contact',
            sectionNameById: 4,
        }
    ];
    const sectionName = [
        {
            id: 1,
            section_name: 'Banner Home',
            countryId: 1,
        },
        {
            id: 2,
            section_name: 'Banner All Airlines',
            countryId: 2,
        },
        {
            id: 3,
            section_name: 'Banner Travel Agent & Tour Operator',
            countryId: 3,
        },
        {
            id: 4,
            section_name: 'Banner Contact',
            countryId: 4,
        }
    ];


    const handleImageChange = (e) => {
        setInputField({
            ...inputField,
            image: e.target.files[0]
        })
    };


    const [inputField, setInputField] = useState({
        page_name: "",
        section_name: "",
        title_name: "",
        subtitle_name: "",
        news_source: "",
        date: "",
        description: "",
        image: null,
    });

    const [filteredStates, setFilteredStates] = useState([]);

    const inputsHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
        if (e.target.name === 'page_name') {
            setFilteredStates(sectionName.filter(section => section.countryId === parseInt(e.target.value, 10)));
        }

    };

    const submitHandler = (e) => {
        e.preventDefault();
        console.log("submitHandler: ", inputField);
        setInputField({
            page_name: "",
            section_name: "",
            title_name: "",
            subtitle_name: "",
            news_source: "",
            date: "",
            description: "",
            image: null,
            
        })
    };

    const inputPhotoUploadClose = () => {
        setInputField({ ...inputField, image: '' });
        document.getElementById("InputPhotoUpload").value = ''
    };



    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Add Slidebar & Image
                        <Link to='/dashboard/slidebar-image-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        {/* <Apd /> */}
                        <form className="mt-1"
                            onSubmit={submitHandler}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <div className="row g-3 mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="inputPageName" className="col-form-label col-form-label-sm d-block">
                                                Page Name <span className="text-danger">*</span></label>
                                            {/* <select
                                                className="form-select form-select-sm" id="inputPageName"
                                                name="page_name"
                                                value={inputField.page_name}
                                                onChange={inputsHandler}
                                            >
                                                <option value='' disabled>
                                                    Choose a Page Name
                                                </option>
                                                {
                                                    pageName.map((category, index) => (
                                                        <option key={index} value={category.page_name}>
                                                            {category.page_name}
                                                        </option>
                                                    ))
                                                }
                                            </select> */}
                                            <select className="form-select form-select-sm" id="inputPageName"
                                                name="page_name" onChange={inputsHandler}>
                                                <option value="">Please select a Page Name</option>
                                                {pageName.map(page => (
                                                    <option key={page.id} value={page.id}>{page.page_name}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="inputSectionName" className="col-form-label col-form-label-sm d-block">
                                                Section Name <span className="text-danger">*</span></label>

                                            {/* <select
                                                className="form-select form-select-sm" id="inputSectionName"
                                                name="section_name"
                                                value={inputField.section_name}
                                                onChange={inputsHandler}
                                            >
                                                <option selected value='' disabled>
                                                    Choose a Section Name
                                                </option>
                                                {
                                                    sectionName.map((subcategory, index) => (
                                                        <option key={index} value={subcategory.section_name}>
                                                            {subcategory.section_name}
                                                        </option>
                                                    ))
                                                }
                                            </select> */}
                                            <select id="inputSectionName" className="form-select form-select-sm"
                                                name="section_name" onChange={inputsHandler}>
                                                <option value="">Please select a Section Name</option>
                                                {filteredStates.map(section => (
                                                    <option key={section.id} value={section.section_name}>{section.section_name}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputBannerTitle" className="col-form-label col-form-label-sm d-block " >
                                                Title Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputBannerTitle"
                                                name="title_name"
                                                value={inputField.title_name}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputSubTitle" className="col-form-label col-form-label-sm d-block " >Sub Title Name</label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputSubTitle"
                                                name="subtitle_name"
                                                value={inputField.subtitle_name}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputNewsSource" className="col-form-label col-form-label-sm d-block " >
                                                News Source <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputNewsSource"
                                                name="news_source"
                                                value={inputField.news_source}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputDate" className="col-form-label col-form-label-sm d-block " >
                                                Date <span className="text-danger">*</span></label>

                                            <input type="date" className="form-control form-control-sm form__ptb " id="InputDate"
                                                name="date"
                                                value={inputField.date}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div className="col-md-6">
                                            <label htmlFor="DescriptionFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
                                                Description <span className="text-danger">*</span></label>
                                            <textarea className="form-control" id="DescriptionFormControlTextarea1" rows="3"
                                                name="description"
                                                value={inputField.description}
                                                onChange={inputsHandler}
                                            ></textarea>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >Card Photo Upload</label>
                                            <input className="form-control form-control-sm form__ptb" id="InputPhotoUpload"
                                                type="file"
                                                // multiple
                                                accept="image/*"
                                                onChange={handleImageChange}
                                            />
                                            <div className="pt-4">
                                                {inputField.image && (
                                                    <div className="d-flex input__img">
                                                        <img src={URL.createObjectURL(inputField.image)} alt="Preview" />

                                                        <span className="close__icon"
                                                            onClick={inputPhotoUploadClose}>
                                                            <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSlidebarImage;



// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';


// const AddSlidebarImage = () => {

//     const categories = [
//         {
//             id: 1,
//             category_name: 'Home Page',
//             subcategories: [
//                 { subcategory_name: 'Our Events' },
//                 { subcategory_name: 'Tourism News' },
//                 { subcategory_name: 'TDAB Gallery' }
//             ]
//         },
//         {
//             id: 2,
//             category_name: 'All Airlines',
//             subcategories: [
//                 { subcategory_name: 'Banner Airlines' },
//                 { subcategory_name: 'dg Airlines' },
//             ]
//         },
//         {
//             id: 3,
//             category_name: 'Travel Agent & Tour Operator',
//             subcategories: [
//                 { subcategory_name: 'Banner Travel' },
//                 { subcategory_name: 'B Travel' },
//             ]
//         },
//         {
//             id: 4,
//             category_name: 'Contact',
//             subcategories: [
//                 { subcategory_name: 'Banner Contact' },
//                 { subcategory_name: 'ad Contact' },
//             ]
//         }
//     ];

//     const [selectedCategory, setSelectedCategory] = useState(null);
//     const [selectedSubcategory, setSelectedSubcategory] = useState([]);

// const [inputField, setInputField] = useState({
    
// })

//     return (
//         <div className="container pt-4">
//             <div className="card">
//                 <div className="card-header">
//                     <h4 className="header__text  pt-1">Add Slidebar & Image
//                         <Link to='/dashboard/slidebar-image-setup' className="back__btn  float-end"> Back</Link>
//                     </h4>
//                 </div>
//                 <div className="bg__from__color">
//                     <div className="card-body">
//                         <form className="mt-1">
//                             <div className="row g-0">
//                                 <div className="col-md-12">
//                                     <div className="row g-3 mb-3">
//                                         <div className="col-md-6">
//                                             <label htmlFor="inputPageName" className="col-form-label col-form-label-sm d-block">
//                                                 Page Name <span className="text-danger">*</span></label>
//                                             <select
//                                                 className="form-select form-select-sm" id="inputPageName"
//                                                 value={selectedCategory ? selectedCategory?.name : ''}
//                                                 onChange={e => {
//                                                     setSelectedCategory(categories[e.target.value]);
//                                                 }}
//                                             >
//                                                 <option value='' disabled>
//                                                     Choose a Page Name
//                                                 </option>
//                                                 {
//                                                     categories.map((category, index) => (
//                                                         <option key={category.category_name} value={index}>
//                                                             {category.category_name}
//                                                         </option>
//                                                     ))
//                                                 }
//                                             </select>
//                                         </div>

//                                         <div className="col-md-6">
//                                             <label htmlFor="inputSectionName" className="col-form-label col-form-label-sm d-block">
//                                                 Section Name <span className="text-danger">*</span></label>

//                                             <select
//                                                 className="form-select form-select-sm" id="inputSectionName"
//                                                 value={selectedSubcategory ? selectedSubcategory?.name : ''}
//                                                 onChange={e => setSelectedSubcategory(selectedCategory?.subcategories[e.target.value])}
//                                             >
//                                                 <option selected value='' disabled>
//                                                     Choose a Section Name
//                                                 </option>
//                                                 {
//                                                     selectedCategory?.subcategories?.map((subcategory, index) => (
//                                                         <option key={subcategory.subcategory_name} value={index}>
//                                                             {subcategory.subcategory_name}
//                                                         </option>
//                                                     ))
//                                                 }
//                                             </select>

//                                         </div>
//                                     </div>
//                                     <div className="row mb-2 d-flex">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputBannerTitle" className="col-form-label col-form-label-sm d-block " >Banner Title</label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputBannerTitle"
//                                                 name="max__prn__dose"
//                                             // value={regularMedicineEight.max__prn__dose}
//                                             // onChange={inputRegularMedicineChangeEight} 
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >Banner Photo Upload</label>
//                                             <input className="form-control form-control-sm form__ptb " id="InputPhotoUpload"
//                                                 type="file"
//                                                 multiple
//                                                 name="max__prn__dose"
//                                             // value={regularMedicineEight.max__prn__dose}
//                                             // onChange={inputRegularMedicineChangeEight} 
//                                             />
//                                         </div>
//                                     </div>

//                                 </div>
//                             </div>
//                             <div className="row">
//                                 <div className="col-12">
//                                     <div className="float-right pt-4 mr-0">
//                                         <button className="btn__save mx-3"
//                                         // onClick={saveMedicationChartPartOneData}
//                                         >
//                                             <b>Save</b>
//                                         </button>
//                                         <button className="btn__cancel"
//                                         // onClick={props.closeModal}
//                                         >
//                                             <b>Cancel</b>
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </form>

//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddSlidebarImage;