import React, { useEffect } from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../node_modules/bootstrap/dist/js/bootstrap.min.js"
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import NotFound from './components/notFound/NotFound';
import Contact from './components/contacts/Contact';
import Layouts from './components/layouts/Layouts';
import Home from './components/home/Home';
import Login from './components/adminDashboard/login/Login';
import BoardOfDirectors from "./components/home/BoardOfDirectors";
import TourismSpot from "./components/tourism/TourismSpot";
import Festivals from "./components/festivals/Festivals";
import Embassies from "./components/embassies/Embassies";
import Members from "./components/member/Members";
import Gallery from "./components/tabs/Gallery";
import AboutBangladesh from "./components/tabs/AboutBangladesh";
import TourismNews from "./components/tourism/TourismNews";
import AllAirlines from "./components/transfortation/AllAirlines";
import ForeinMinistry from './components/tabs/ForeinMinistry';
import BangladeshMap from './components/tabs/BangladeshMap';
import OurEvents from './components/home/OurEvents';
import MemberList from './components/member/MemberList';
import Membership from './components/member/Membership';
import MembershipCriteriaFees from './components/member/MembershipCriteriaFees';
import MembershipApplication from './components/member/MembershipApplication';
import MinistryInfo from './components/foreignMission/MinistryInfo';
import BMForeignCountry from './components/foreignMission/BMForeignCountry';
import ForeignMB from './components/foreignMission/ForeignMB';
import Transfortation from './components/transfortation/Transfortation';
import Committees from './components/home/Committees';
import Advisors from './components/home/Advisors';
import Secretariat from './components/home/Secretariat';
import ChairmanMessage from './components/home/ChairmanMessage';
import Police from './components/homeAffairs/Police';
import TouristPolice from './components/homeAffairs/TouristPolice';
import Immigration from './components/homeAffairs/Immigration';
import Accommodation from './components/transfortation/Accommodation';
import TravelAgentTourOperator from './components/transfortation/TravelAgentTourOperator';
import FoodBeverage from './components/transfortation/FoodBeverage';
import GoogleMap from './components/tabs/GoogleMap';
import FestivalsDetails from './components/festivals/FestivalsDetails';
import BoardOfDirectorsTree from './components/boardOfDirectors/BoardOfDirectorsTree';


import Dashboard from './components/adminDashboard/dashboard/Dashboard';
import SlidebarImage from './components/adminDashboard/slidebarImageSetup/SlidebarImage';
import AddSlidebarImage from './components/adminDashboard/slidebarImageSetup/AddSlidebarImage';
import EditSlidebarImage from './components/adminDashboard/slidebarImageSetup/EditSlidebarImage';
import CardsSetup from './components/adminDashboard/cardsSetup/CardsSetup';
import AddCardsSetup from './components/adminDashboard/cardsSetup/AddCardsSetup';
import EditCardsSetup from './components/adminDashboard/cardsSetup/EditCardsSetup';
import BoardOfDirectorsSetup from './components/adminDashboard/boardOfDirectorsSetup/BoardOfDirectorsSetup';
import AddBoardOfDirectorsSetup from './components/adminDashboard/boardOfDirectorsSetup/AddBoardOfDirectorsSetup';
import EditBoardOfDirectorsSetup from './components/adminDashboard/boardOfDirectorsSetup/EditBoardOfDirectorsSetup';
import SubHeaderSetup from './components/adminDashboard/homeSetup/SubHeaderSetup';
import NotFoundServer from './components/adminDashboard/NotFoundServer';
import Logout from './components/adminDashboard/logout/Logout';
import PrivateComponent from './components/adminDashboard/privateRoute/PrivateComponent';
import MemberListSetup from './components/adminDashboard/membershipSetup/MemberListSetup';
import AddMemberListSetup from './components/adminDashboard/membershipSetup/AddMemberListSetup';
import EditMemberListSetup from './components/adminDashboard/membershipSetup/EditMemberListSetup';
import AdvisorsSetup from './components/adminDashboard/advisorsSetup/AdvisorsSetup';
import AddAdvisorsSetup from './components/adminDashboard/advisorsSetup/AddAdvisorsSetup';
import EditAdvisorsSetup from './components/adminDashboard/advisorsSetup/EditAdvisorsSetup';
import SecretariatSetup from './components/adminDashboard/secretariatSetup/SecretariatSetup';
import AddSecretariatSetup from './components/adminDashboard/secretariatSetup/AddSecretariatSetup';
import EditSecretariatSetup from './components/adminDashboard/secretariatSetup/EditSecretariatSetup';
import ChairmanMessageSetup from './components/adminDashboard/chairmanMessageSetup/ChairmanMessageSetup';
import AddChairmanMessageSetup from './components/adminDashboard/chairmanMessageSetup/AddChairmanMessageSetup';
import EditChairmanMessageSetup from './components/adminDashboard/chairmanMessageSetup/EditChairmanMessageSetup';
import PoliceSetup from './components/adminDashboard/policeSetup/PoliceSetup';
import AddPoliceSetup from './components/adminDashboard/policeSetup/AddPoliceSetup';
import EditPoliceSetup from './components/adminDashboard/policeSetup/EditPoliceSetup';
import MinistryInfoSetup from './components/adminDashboard/ministryInfoSetup/MinistryInfoSetup';
import AddMinistryInfoSetup from './components/adminDashboard/ministryInfoSetup/AddMinistryInfoSetup';
import EditMinistryInfoSetup from './components/adminDashboard/ministryInfoSetup/EditMinistryInfoSetup';

import EmbassiesInBangladeshSetup from './components/adminDashboard/embassiesInBangladeshSetup/EmbassiesInBangladeshSetup';
import AddEmbassiesInBangladeshSetup from './components/adminDashboard/embassiesInBangladeshSetup/AddEmbassiesInBangladeshSetup';
import EditEmbassiesInBangladeshSetup from './components/adminDashboard/embassiesInBangladeshSetup/EditEmbassiesInBangladeshSetup';
import TravelAgentTourOperatorSetup from './components/adminDashboard/travelAgentTourOperatorSetup/TravelAgentTourOperatorSetup';
import AddTravelAgentTourOperatorSetup from './components/adminDashboard/travelAgentTourOperatorSetup/AddTravelAgentTourOperatorSetup';
import EditTravelAgentTourOperatorSetup from './components/adminDashboard/travelAgentTourOperatorSetup/EditTravelAgentTourOperatorSetup';
import AccommodationSetup from './components/adminDashboard/accommodationSetup/AccommodationSetup';
import AddAccommodationSetup from './components/adminDashboard/accommodationSetup/AddAccommodationSetup';
import EditAccommodationSetup from './components/adminDashboard/accommodationSetup/EditAccommodationSetup';
import FestivalsSetup from './components/adminDashboard/festivalsSetup/FestivalsSetup';
import AddFestivalsSetup from './components/adminDashboard/festivalsSetup/AddFestivalsSetup';
import EditFestivalsSetup from './components/adminDashboard/festivalsSetup/EditFestivalsSetup';
import AboutBangladeshSetup from './components/adminDashboard/aboutBangladeshSetup/AboutBangladeshSetup';
import AddAboutBangladeshSetup from './components/adminDashboard/aboutBangladeshSetup/AddAboutBangladeshSetup';
import EditAboutBangladeshSetup from './components/adminDashboard/aboutBangladeshSetup/EditAboutBangladeshSetup';
import ImmigrationSetup from './components/adminDashboard/immigrationSetup/ImmigrationSetup';
import AddImmigrationSetup from './components/adminDashboard/immigrationSetup/AddImmigrationSetup';
import EditImmigrationSetup from './components/adminDashboard/immigrationSetup/EditImmigrationSetup';
import TouristPoliceSetup from './components/adminDashboard/touristPoliceSetup/TouristPoliceSetup';
import AddTouristPoliceSetup from './components/adminDashboard/touristPoliceSetup/AddTouristPoliceSetup';
import EditTouristPoliceSetup from './components/adminDashboard/touristPoliceSetup/EditTouristPoliceSetup';
import BMForeignCountrySetup from './components/adminDashboard/bMForeignCountrySetup/BMForeignCountrySetup';
import AddBMForeignCountrySetup from './components/adminDashboard/bMForeignCountrySetup/AddBMForeignCountrySetup';
import EditBMForeignCountrySetup from './components/adminDashboard/bMForeignCountrySetup/EditBMForeignCountrySetup';
import GallerySetup from './components/adminDashboard/gallerySetup/GallerySetup';
import AddGallerySetup from './components/adminDashboard/gallerySetup/AddGallerySetup';
import EditGallerySetup from './components/adminDashboard/gallerySetup/EditGallerySetup';
import FoodBeverageSetup from './components/adminDashboard/foodBeverageSetup/FoodBeverageSetup';
import AddFoodBeverageSetup from './components/adminDashboard/foodBeverageSetup/AddFoodBeverageSetup';
import EditFoodBeverageSetup from './components/adminDashboard/foodBeverageSetup/EditFoodBeverageSetup';
import TourismSpotSetup from './components/adminDashboard/tourismSpotSetup/TourismSpotSetup';
import AddTourismSpotSetup from './components/adminDashboard/tourismSpotSetup/AddTourismSpotSetup';
import EditTourismSpotSetup from './components/adminDashboard/tourismSpotSetup/EditTourismSpotSetup';
import HomeBannerSetup from './components/adminDashboard/homeSetup/banner/HomeBannerSetup';
import AddHomeBannerSetup from './components/adminDashboard/homeSetup/banner/AddHomeBannerSetup';
import EditHomeBannerSetup from './components/adminDashboard/homeSetup/banner/EditHomeBannerSetup';
import AssociateMemberList from './components/member/AssociateMemberList';
import AssociateMemberListSetup from './components/adminDashboard/associateMemberListSetup/AssociateMemberListSetup';
import AddAssociateMemberListSetup from './components/adminDashboard/associateMemberListSetup/AddAssociateMemberListSetup';
import EditAssociateMemberListSetup from './components/adminDashboard/associateMemberListSetup/EditAssociateMemberListSetup';


// import ManagementSolution from './ManagementSolution';

function App() {


  //scroll top onClick in the new page
  const location = useLocation();
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }, [location]);


  return (

    <Routes>
      <Route path="/" element={<Layouts />}>
        <Route index element={<Home />}></Route>

        {/* <Route path="committees" element={<Committees />}></Route> */}
        <Route path="advisors" element={<Advisors />}></Route>
        <Route path="secretariat" element={<Secretariat />}></Route>
        <Route path="chairman-message" element={<ChairmanMessage />}></Route>

        <Route path="police" element={<Police />}></Route>
        <Route path="tourist-police" element={<TouristPolice />}></Route>
        <Route path="immigration" element={<Immigration />}></Route>

        <Route path="member-list" element={<MemberList />}></Route>
        <Route path="member-list-associate" element={<AssociateMemberList />}></Route>
        {/* <Route path="membership" element={<Membership />}></Route> */}
        <Route path="membership-criteria-fees" element={<MembershipCriteriaFees />}></Route>
        <Route path="membership-application" element={<MembershipApplication />}></Route>


        <Route path="ministry-info" element={<MinistryInfo />}></Route>
        <Route path="forein-ministry" element={<ForeinMinistry />}></Route>
        <Route path="bangladesh-mission-foreign-country" element={<BMForeignCountry />}></Route>
        <Route path="foreign-mission-bangladesh" element={<ForeignMB />}></Route>

        <Route path="all-airlines" element={<AllAirlines />}></Route>
        <Route path="accommodation" element={<Accommodation />}></Route>
        <Route path="travel-agent-tour-operator" element={<TravelAgentTourOperator />}></Route>
        <Route path="food-beverage" element={<FoodBeverage />}></Route>

        <Route path="transfortation" element={<Transfortation />}></Route>


        <Route path="our-events" element={<OurEvents />}></Route>

        {/* <Route path="board-of-directors" element={<BoardOfDirectorsTree />}></Route> */}
        <Route path="board-of-directors" element={<BoardOfDirectors />}></Route>
        <Route path="tourism-spot" element={<TourismSpot />}></Route>
        <Route path="tourism-news" element={<TourismNews />}></Route>
        <Route path="/tourism-news/:id" element={<TourismNews />}></Route>

        <Route path="festivals" element={<Festivals />}></Route>
        <Route path="festivals/:id" element={<FestivalsDetails />}></Route>
        <Route path="embassies" element={<Embassies />}></Route>

        <Route path="contact" element={<Contact />}></Route>
        <Route path="gallery" element={<Gallery />}></Route>
        <Route path="about-bangladesh" element={<AboutBangladesh />}></Route>

        {/* <Route path="bangladesh-map" element={<GoogleMap />}></Route> */}
        {/* <Route path="bangladesh-map" element={<BangladeshMap />}></Route> */}
        <Route path="members" element={<Members />}></Route>

        {/* <Route path="/management" element={<ManagementSolution />}> </Route> */}

        <Route path="*" element={<NotFound />} />
      </Route>


      {/* Backend Part */}
      <Route path="/adminlogin" element={<Login />}></Route>

      <Route element={<PrivateComponent />}>
        <Route path='/dashboard' element={<Dashboard />} >
          <Route path='slidebar-image-setup' element={<SlidebarImage />} />
          <Route path='add-slidebar-image-setup' element={<AddSlidebarImage />} />
          <Route path='edit-slidebar-image-setup/:id' element={<EditSlidebarImage />} />

          <Route path='cards-setup' element={<CardsSetup />} />
          <Route path='add-cards-setup' element={<AddCardsSetup />} />
          <Route path='edit-cards-setup/:id' element={<EditCardsSetup />} />

          <Route path="our-board-members-setup" element={<BoardOfDirectorsSetup />}></Route>
          <Route path="add-our-board-members-setup" element={<AddBoardOfDirectorsSetup />}></Route>
          <Route path="edit-our-board-members-setup/:id" element={<EditBoardOfDirectorsSetup />}></Route>

          <Route path="advisors-setup" element={<AdvisorsSetup />}></Route>
          <Route path="add-advisors-setup" element={<AddAdvisorsSetup />}></Route>
          <Route path="edit-advisors-setup/:id" element={<EditAdvisorsSetup />}></Route>

          <Route path="secretariat-setup" element={<SecretariatSetup />}></Route>
          <Route path="add-secretariat-setup" element={<AddSecretariatSetup />}></Route>
          <Route path="edit-secretariat-setup/:id" element={<EditSecretariatSetup />}></Route>

          <Route path="chairman-message-setup" element={<ChairmanMessageSetup />}></Route>
          <Route path="add-chairman-message-setup" element={<AddChairmanMessageSetup />}></Route>
          <Route path="edit-chairman-message-setup/:id" element={<EditChairmanMessageSetup />}></Route>

          <Route path="police-setup" element={<PoliceSetup />}></Route>
          <Route path="add-police-setup" element={<AddPoliceSetup />}></Route>
          <Route path="edit-police-setup/:id" element={<EditPoliceSetup />}></Route>

          <Route path="immigration-setup" element={<ImmigrationSetup />}></Route>
          <Route path="add-immigration-setup" element={<AddImmigrationSetup />}></Route>
          <Route path="edit-immigration-setup/:id" element={<EditImmigrationSetup />}></Route>

          <Route path="tourist-police-setup" element={<TouristPoliceSetup />}></Route>
          <Route path="add-tourist-police-setup" element={<AddTouristPoliceSetup />}></Route>
          <Route path="edit-tourist-police-setup/:id" element={<EditTouristPoliceSetup />}></Route>

          <Route path="ministry-info-setup" element={<MinistryInfoSetup />}></Route>
          <Route path="add-ministry-info-setup" element={<AddMinistryInfoSetup />}></Route>
          <Route path="edit-ministry-info-setup/:id" element={<EditMinistryInfoSetup />}></Route>

          <Route path="embassies-in-bangladesh-setup" element={<EmbassiesInBangladeshSetup />}></Route>
          <Route path="add-embassies-in-bangladesh-setup" element={<AddEmbassiesInBangladeshSetup />}></Route>
          <Route path="edit-embassies-in-bangladesh-setup/:id" element={<EditEmbassiesInBangladeshSetup />}></Route>

          <Route path="bmforeign-country-setup" element={<BMForeignCountrySetup />}></Route>
          <Route path="add-bmforeign-country-setup" element={<AddBMForeignCountrySetup />}></Route>
          <Route path="edit-bmforeign-country-setup/:id" element={<EditBMForeignCountrySetup />}></Route>

          <Route path="travel-agent-setup" element={<TravelAgentTourOperatorSetup />}></Route>
          <Route path="add-travel-agent-setup" element={<AddTravelAgentTourOperatorSetup />}></Route>
          <Route path="edit-travel-agent-setup/:id" element={<EditTravelAgentTourOperatorSetup />}></Route>

          <Route path="accommodation-setup" element={<AccommodationSetup />}></Route>
          <Route path="add-accommodation-setup" element={<AddAccommodationSetup />}></Route>
          <Route path="edit-accommodation-setup/:id" element={<EditAccommodationSetup />}></Route>

          <Route path="food-beverage-setup" element={<FoodBeverageSetup />}></Route>
          <Route path="add-food-beverage-setup" element={<AddFoodBeverageSetup />}></Route>
          <Route path="edit-food-beverage-setup/:id" element={<EditFoodBeverageSetup />}></Route>

          <Route path="home-banner-setup" element={<HomeBannerSetup />}></Route>
          <Route path="add-home-banner-setup" element={<AddHomeBannerSetup />}></Route>
          <Route path="edit-home-banner-setup/:id" element={<EditHomeBannerSetup />}></Route>

          <Route path="sub-header-setup" element={<SubHeaderSetup />}></Route>
          {/* <Route path="add-sub-header-setup" element={<AddSubHeaderSetup />}></Route>
        <Route path="edit-sub-header-setup/:id" element={<EditSubHeaderSetup />}></Route> */}

          <Route path="member-list-setup" element={<MemberListSetup />}></Route>
          <Route path="add-member-list-setup" element={<AddMemberListSetup />}></Route>
          <Route path="edit-member-list-setup/:id" element={<EditMemberListSetup />}></Route>

          <Route path="member-list-associate-setup" element={<AssociateMemberListSetup />}></Route>
          <Route path="add-member-list-associate-setup" element={<AddAssociateMemberListSetup />}></Route>
          <Route path="edit-member-list-associate-setup/:id" element={<EditAssociateMemberListSetup />}></Route>

          <Route path="tourism-spot-setup" element={<TourismSpotSetup />}></Route>
          <Route path="add-tourism-spot-setup" element={<AddTourismSpotSetup />}></Route>
          <Route path="edit-tourism-spot-setup/:id" element={<EditTourismSpotSetup />}></Route>

          <Route path="festivals-setup" element={<FestivalsSetup />}></Route>
          <Route path="add-festivals-setup" element={<AddFestivalsSetup />}></Route>
          <Route path="edit-festivals-setup/:id" element={<EditFestivalsSetup />}></Route>

          <Route path="gallery-setup" element={<GallerySetup />}></Route>
          <Route path="add-gallery-setup" element={<AddGallerySetup />}></Route>
          <Route path="edit-gallery-setup/:id" element={<EditGallerySetup />}></Route>

          <Route path="about-bangladesh-setup" element={<AboutBangladeshSetup />}></Route>
          <Route path="add-about-bangladesh-setup" element={<AddAboutBangladeshSetup />}></Route>
          <Route path="edit-about-bangladesh-setup/:id" element={<EditAboutBangladeshSetup />}></Route>

          <Route path="login" element={<Login />}></Route>
          <Route path="logout-dashboard" element={<Logout />}></Route>
          <Route path="*" element={<NotFoundServer />} />

        </Route>
      </Route>
    </Routes>

  );
}

export default App;