import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import '../dashboard/AdminDashboard.css';
import '../AppAdmin.css';

const AddEmbassiesInBangladeshSetup = () => {

    const [inputField, setInputField] = useState({
        title_name: "",
        web_link: "",
        email: "",
        address: "",
        phone: "",
        fax: "",
        office_hour: "",
        image: null,
    });


    const handleImageChange = (e) => {
        setInputField({
            ...inputField,
            image: e.target.files[0]
        })
    };

    const inputsHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        console.log("submitHandler: ", inputField);

        const formData = new FormData();
        formData.append("image", inputField.image, inputField.image.name);
        formData.append("title_name", inputField.title_name)
        formData.append("address", inputField.address)
        formData.append("web_link", inputField.web_link)
        formData.append("email", inputField.email)
        formData.append("fax", inputField.fax)
        formData.append("phone", inputField.phone)
        formData.append("office_hour", inputField.office_hour)

        const url = "/add-embassies-in-bangladesh";
        console.log("mm", inputField.image, "mmm", inputField.image.name);
        try {
            let response = await axios.post(url, formData);
            if (response.status == 200) {
                alert("Are you Added  toData", formData);
                setInputField({
                    title_name: "",
                    web_link: "",
                    email: "",
                    address: "",
                    phone: "",
                    fax: "",
                    office_hour: "",
                    image: null,
                })
                document.getElementById("InputPhotoUpload").value = '';
            } else {
                alert("Failed")
            }
        } catch (error) {
            console.log(error);
        }

    };

    const inputPhotoUploadClose = () => {
        setInputField({ ...inputField, image: '' });
        document.getElementById("InputPhotoUpload").value = ''
    };



    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Add Embassies In Bangladesh Setup
                        <Link to='/dashboard/embassies-in-bangladesh-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        {/* <Apd /> */}
                        <form className="mt-1" encType='multipart/form-data'
                            onSubmit={submitHandler}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">

                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputBannerTitle" className="col-form-label col-form-label-sm d-block " >
                                                Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputBannerTitle"
                                                name="title_name"
                                                value={inputField.title_name}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputWebLink" className="col-form-label col-form-label-sm d-block " >
                                                Web Link <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputWebLink"
                                                name="web_link"
                                                value={inputField.web_link}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputEmail" className="col-form-label col-form-label-sm d-block " >
                                                Email <span className="text-danger">*</span></label>
                                            <input type="email" className="form-control form-control-sm form__ptb " id="InputEmail"
                                                name="email"
                                                value={inputField.email}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputAddress" className="col-form-label col-form-label-sm d-block " >
                                                Address <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputAddress"
                                                name="address"
                                                value={inputField.address}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputPhone" className="col-form-label col-form-label-sm d-block " >
                                                Phone No. <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputPhone"
                                                name="phone"
                                                value={inputField.phone}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputMobileNo" className="col-form-label col-form-label-sm d-block " >
                                                Fax No. <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputFaxNo"
                                                name="fax"
                                                value={inputField.fax}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>

                                    <div class="row mb-2">
                                        <div className="col-md-6">
                                            <label htmlFor="InputOfficeAddress" className="col-form-label col-form-label-sm d-block " >
                                                Office Address <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputOfficeAddress"
                                                name="office_hour"
                                                value={inputField.office_hour}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >
                                                Card Photo Upload <span className="text-danger">*</span></label>
                                            <input className="form-control form-control-sm form__ptb" id="InputPhotoUpload"
                                                type="file"
                                                // multiple
                                                name="image"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                            />
                                            <div className="pt-4">
                                                {inputField.image && (
                                                    <div className="d-flex input__img">
                                                        <img src={URL.createObjectURL(inputField.image)} alt="Preview" />

                                                        <span className="close__icon"
                                                            onClick={inputPhotoUploadClose}>
                                                            <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEmbassiesInBangladeshSetup;

