import axios from 'axios';
import React, { useState, useEffect } from 'react';


const Immigration = () => {
  
    const [immigrationData, setImmigrationData] = useState([]);
    const fetchData = async () => {
        try {
            const res = await axios.get("/immigration");
            // console.log("res frontend:", res.data.data);
            setImmigrationData(res.data.data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    // Emergency Phone Numbers
    const filterimmigrationContactData = immigrationData.filter((empolyeeType) => {
        return empolyeeType.immigration_category === "Emergency Phone Numbers";
    });
    // console.log("filterimmigrationContactData: ", filterimmigrationContactData);

    // Emergency Email Address
    const filterImmigrationEmailAddresData = immigrationData.filter((empolyeeType) => {
        return empolyeeType.immigration_category === "Emergency Email Address";
    });
    // console.log("filterImmigrationEmailAddresData: ", filterImmigrationEmailAddresData);


    return (
        <div className="container mb-5">
            <h3 className="mb-3 text-center pt-2">
                <span className="header_text_normal_bm header_text_bold">Immi</span><span className="header_text_bold">gration</span>
            </h3>
            <div className="row g-3 pt-2 ">
                <div className="col-12">
                    <h5>Emergency Phone Numbers</h5>
                    <div className="d-flex justify-content-start">
                        <table class="table table-bordered member__list__table">
                            <thead>
                                <tr>
                                    <th className="th__sr">Sr. No.</th>
                                    <th>Immigration</th>
                                    <th>Contact</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filterimmigrationContactData.length > 0 && filterimmigrationContactData.map((item, i) => {
                                        return (
                                            <tr key={i} className="tr__body">
                                                <td>{i}.</td>
                                                <td>{item.title_name}</td>
                                                <td>{item.contact}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            <div className="row g-3 pt-2">
                <div className="col-12">
                    <h5>Emergency Email Address</h5>
                    <div className="d-flex justify-content-start">
                        <table class="table table-bordered member__list__table">
                            <thead>
                                <tr>
                                    <th className="th__sr">Sr. No.</th>
                                    <th>Immigration Port name</th>
                                    <th>Email Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filterImmigrationEmailAddresData.length > 0 && filterImmigrationEmailAddresData.map((item, i) => {
                                        return (
                                            <tr key={i} className="tr__body">
                                                <td>{i}.</td>
                                                <td>{item.title_name}</td>
                                                <td>{item.email}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Immigration;



// import React, { useState } from 'react';

// const Immigration = () => {
//     const [immigrationContact, setImmigrationContact] = useState([
//         { id: 1, title_name: "OC Immigration (HSIA)", contact: "+880-2-8914226, +88-01320005902" },
//         { id: 2, title_name: "OC Benapole Land Port", contact: "+88-01320006412" },
//         { id: 3, title_name: "Addl. SP Immigration (HSIA)", contact: "+88-01320005376" },
//         { id: 4, title_name: "SSP (Immigration Admin)", contact: "+88-01320005102" },
//         { id: 5, title_name: "SSP (Immigration, HSIA)", contact: "+88-01320005111" },
//         { id: 6, title_name: "SSP (Land & Sea Port East)", contact: "+88-01320005101" },
//         { id: 7, title_name: "SSP (Land & Sea Port West)", contact: "+88-01320005114" },
//         { id: 8, title_name: "SSP (Sylhet Airport)", contact: "+88-01320005107" },
//         { id: 9, title_name: "SSP (Chattagram Airport)", contact: "+88-01320005108" },
//         { id: 10, title_name: "DIG Immigration", contact: "+88-01320005006" },
//         { id: 11, title_name: "Additional DIG Immigration", contact: "+88-01320005035" },
//     ]);
//     const [immigrationEmailAddres, setImmigrationEmailAddres] = useState([
//         { id: 1, title_name: "OC Immigration", email: "ocimmigration@police.gov.bd" },
//         { id: 2, title_name: "Addl. SP Immigration", email: "addsp@immi.gov.bd" },
//         { id: 3, title_name: "SS Immigration", email: "ssimmisb@police.gov.bd" },
//     ]);

    
//     return (
//         <div className="container mb-5">
//             <h3 className="mb-3 text-center">
//                 <span className="header_text_normal_bm header_text_bold">Immi</span><span className="header_text_bold">gration</span>
//             </h3>
//             <div className="row g-3 pt-2 ">
//                 <div className="col-12">
//                     <h5>Emergency Phone Numbers</h5>
//                     <div className="d-flex justify-content-start">
//                         <table class="table table-bordered member__list__table">
//                             <thead>
//                                 <tr>
//                                     <th className="th__sr">Sr. No.</th>
//                                     <th>Immigration</th>
//                                     <th>Contact</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {
//                                     immigrationContact.length > 0 && immigrationContact.map((item, id) => {
//                                         return (
//                                             <tr key={id} className="tr__body">
//                                                 <td>{item.id}.</td>
//                                                 <td>{item.title_name}</td>
//                                                 <td>{item.contact}</td>
//                                             </tr>
//                                         )
//                                     })
//                                 }
//                             </tbody>
//                         </table>
//                     </div>

//                 </div>
//             </div>
//             <div className="row g-3 pt-2">
//                 <div className="col-12">
//                     <h5>Emergency Email Address</h5>
//                     <div className="d-flex justify-content-start">
//                         <table class="table table-bordered member__list__table">
//                             <thead>
//                                 <tr>
//                                     <th className="th__sr">Sr. No.</th>
//                                     <th>Immigration Port name</th>
//                                     <th>Contact</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {
//                                     immigrationEmailAddres.length > 0 && immigrationEmailAddres.map((item, id) => {
//                                         return (
//                                             <tr key={id} className="tr__body">
//                                                 <td>{item.id}.</td>
//                                                 <td>{item.title_name}</td>
//                                                 <td>{item.email}</td>
//                                             </tr>
//                                         )
//                                     })
//                                 }
//                             </tbody>
//                         </table>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Immigration;