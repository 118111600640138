import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import '../dashboard/AdminDashboard.css';
import '../AppAdmin.css';

const EditBMForeignCountrySetup = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [inputField, setInputField] = useState({
        continent_title: '',
        card_link: '',
        continent_area: [{ country_name: '', web_link: '' }],
    });



    const fetchData1 = async (id) => {
        try {
            const res = await axios.get(`/edit-bmforeign-country/${id}`)
            .then(res => {
                // res.data.data.continent_area
                console.log("res edit-bmforeign-country: ", res.data.data);
                setInputField(res.data.data);
            });
            
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData1(id);
    }, []);
    console.log("inputField: ", inputField);


 
    const handleInputChange = (event, index) => {
        const { name, value } = event.target;
        if (name === 'country_name' || name === 'web_link') {
            const continentArea = [...inputField.continent_area];
            continentArea[index] = { ...continentArea[index], [name]: value };
            setInputField({ ...inputField, continent_area: continentArea });
        } else {
            setInputField({ ...inputField, [name]: value });
        }
    };

    const updateFormData = async (event) => {
        event.preventDefault();
        console.log("inputField: ", inputField)
        const url = `/update-bmforeign-country/${id}`;
        axios.post(url, inputField)
            .then(response => {
                console.log(response, "edit dff");
                if (response.status === 200) {
                    inputField.continent_area.map((area, index) => {
                        axios.post(`/update-bmforeign-continent-area/${area.id}`, {
                            continent_area_id: response.data.bmf_country.id,
                            country_name: area.country_name,
                            web_link: area.web_link,
                        }).then(response => console.log("error", response))
                    })
                    alert("Are you Edit  to Data");
                    setInputField({
                        continent_title: '',
                        card_link: '',
                        continent_area: [{ country_name: '', web_link: '' }],
                    })
                } else {
                    alert("Failed")
                }
                navigate("/dashboard/bmforeign-country-setup");
            })
            .catch(error => {
                console.log(error);
            })
    };

    const addCountry = () => {
        setInputField({
            ...inputField,
            continent_area: [...inputField.continent_area, { country_name: '', web_link: '' }],
        });
    };

    const removeCountry = (index) => {
        const continentArea = [...inputField.continent_area];
        continentArea.splice(index, 1);
        setInputField({ ...inputField, continent_area: continentArea });
    };




    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Edit Bangladesh mission foreign country Setup
                        <Link to='/dashboard/bmforeign-country-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        <form onSubmit={updateFormData}>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label htmlFor="InputContinentTitle" className="col-form-label col-form-label-sm d-block" >Continent Name: <span className="text-danger">*</span></label>
                                    <input id="InputContinentTitle" className="form-control form-control-sm form__ptb "
                                        type="text"
                                        name="continent_title"
                                        value={inputField.continent_title}
                                        onChange={handleInputChange}
                                        required />
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="Inputcard_Link" className="col-form-label col-form-label-sm d-block">Card Link: <span className="text-danger">*</span></label>
                                    <input id="Inputcard_Link" className="form-control form-control-sm form__ptb "
                                        type="text"
                                        name="card_link"
                                        value={inputField.card_link}
                                        onChange={handleInputChange}
                                        required />
                                </div>
                            </div>
                            {inputField.continent_area.map((area, index) => (
                                <div className="row g-3" key={index}>
                                    <div className="col-md-6">
                                        <label htmlFor="InputCountryName" className="col-form-label col-form-label-sm d-block" >Country Name: <span className="text-danger">*</span></label>
                                        <input id="InputCountryName" className="form-control form-control-sm form__ptb "
                                            type="text"
                                            name="country_name"
                                            value={area.country_name}
                                            onChange={(event) => handleInputChange(event, index)}
                                            required />
                                    </div>
                                    <div className="col-md-5">
                                        <label htmlFor="InputWebLink" className="col-form-label col-form-label-sm d-block" >Web Link: <span className="text-danger">*</span></label>
                                        <input id="InputWebLink" className="form-control form-control-sm form__ptb "
                                            type="text"
                                            name="web_link"
                                            value={area.web_link}
                                            onChange={(event) => handleInputChange(event, index)}
                                            required />
                                    </div>
                                    <div className="col-md-1">
                                        {index > 0 && (
                                            <div className="btn__bmfc">
                                                <span className="add__btn__bmfc"
                                                    type="button"
                                                    onClick={() => removeCountry(index)}>
                                                    <i class="text-danger fa-regular fa-trash-can"></i>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-start pt-4 mr-0">
                                        <button className="btn__save"
                                            type="button"
                                            onClick={addCountry}
                                        >
                                            Add Country & Link
                                        </button>

                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditBMForeignCountrySetup;

// import axios from 'axios';
// import React, { useState } from 'react';
// import { useEffect } from 'react';
// import { Link, useNavigate, useParams } from 'react-router-dom';
// // import '../dashboard/AdminDashboard.css';
// import '../AppAdmin.css';

// const EditBMForeignCountrySetup = () => {
//     const navigate = useNavigate();
//     const { id } = useParams();



//     const [editInputField, setEditInputField] = useState({
//         continent_title: '',
//         card_link: '',
//         continent_area: [
//             {
//                 country_name: '',
//                 web_link: ''
//             }
//         ]
//     });
//     const handleContinentInputChange = (e) => {
//         setEditInputField({ ...editInputField, [e.target.name]: e.target.value });
//     };

//     const handleCountryInputChange = (e, index) => {
//         const { name, value } = e.target;
//         const area = [...editInputField.continent_area];
//         area[index][name] = value;
//         setEditInputField({ ...editInputField, continent_area: area });
//     };


//     const fetchData = async (id) => {
//         try {
//             const res = await axios.get(`/edit-bmforeign-country/${id}`);
//             console.log("res edit-bmforeign-country: ", res.data.data);
//             setEditInputField(res.data.data);
//         } catch (error) {
//             console.log(error);
//         }
//     }

//     useEffect(() => {
//         fetchData(id);
//     }, []);


//     const updateFormData = async (e) => {
//         e.preventDefault();
//         console.log("updateFormData: ", editInputField);


//         const url = `/update-bmforeign-country/${id}`;
//         axios.put(url, editInputField)
//             .then(response => {
//                 console.log(response, "edit dff");
//                 if (response.status === 200) {
//                     alert("Are you Edit  to Data");
//                     setEditInputField({
//                         continent_title: '',
//                         card_link: '',
//                         continent_area: [
//                             {
//                                 country_name: '',
//                                 web_link: ''
//                             }
//                         ]
//                     })
//                 } else {
//                     alert("Failed")
//                 }
//                 navigate("/dashboard/bmforeign-country-setup");
//             })
//             .catch(error => {
//                 console.log(error);
//             })

//     };




//     return (
//         <div className="container pt-4">
//             <div className="card">
//                 <div className="card-header">
//                     <h4 className="header__text  pt-1">Edit Bangladesh mission foreign country Setup
//                         <Link to='/dashboard/bmforeign-country-setup' className="back__btn  float-end"> Back</Link>
//                     </h4>
//                 </div>
//                 <div className="bg__from__color">
//                     <div className="card-body">
//                         <form onSubmit={updateFormData}>
//                             <div className="row g-3">
//                                 <div className="col-md-6">
//                                     <label htmlFor="InputContinentTitle" className="col-form-label col-form-label-sm d-block" >Continent Name: <span className="text-danger">*</span></label>
//                                     <input className="form-control form-control-sm form__ptb " id="InputContinentTitle"
//                                         type="text"
//                                         name="continent_title"
//                                         value={editInputField.continent_title}
//                                         // onChange={(e) => setContinent({ ...editInputField, continent_title: e.target.value })}
//                                         onChange={handleContinentInputChange}
//                                     />

//                                 </div>
//                                 <div className="col-md-6">

//                                     <label htmlFor="Inputcard_Link" className="col-form-label col-form-label-sm d-block">Card Link: <span className="text-danger">*</span></label>
//                                     <input className="form-control form-control-sm form__ptb " id="Inputcard_Link"
//                                         type="text"
//                                         name="card_link"
//                                         value={editInputField.card_link}
//                                         // onChange={(e) => setContinent({ ...editInputField, card_link: e.target.value })}
//                                         onChange={handleContinentInputChange}
//                                     />
//                                 </div>
//                             </div>


//                             {editInputField.continent_area.map((area, index) => (
//                                 <div key={index} className="row g-3">
//                                     <div className="col-md-6">
//                                         <label htmlFor="InputCountryName" className="col-form-label col-form-label-sm d-block" >Country Name: <span className="text-danger">*</span></label>
//                                         <input className="form-control form-control-sm form__ptb " id="InputCountryName"
//                                             type="text"
//                                             name="country_name"
//                                             value={area.country_name}
//                                             onChange={(e) => handleCountryInputChange(e, index)} />
//                                     </div>
//                                     <div className="col-md-6">
//                                         <label htmlFor="InputWebLink" className="col-form-label col-form-label-sm d-block" >Web Link: <span className="text-danger">*</span></label>
//                                         <input className="form-control form-control-sm form__ptb " id="InputWebLink"
//                                             type="text"
//                                             name="web_link"
//                                             value={area.web_link}
//                                             onChange={(e) => handleCountryInputChange(e, index)} />
//                                     </div>
//                                 </div>
//                             ))}

//                             <div className="row">
//                                 <div className="col-12">
//                                     <div className="float-right pt-4 mr-0">
//                                         <button type="submit" className="btn__save mx-3"
//                                         // onClick={saveMedicationChartPartOneData}
//                                         >
//                                             <b>Save</b>
//                                         </button>
//                                         <button className="btn__cancel"
//                                         // onClick={props.closeModal}
//                                         >
//                                             <b>Cancel</b>
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </form>

//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default EditBMForeignCountrySetup;

