import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderImage from "./SliderImage";



import tour_img from '../../images/tour_img.png';
import events_img1 from '../../images/events_img1.png';
import events_img2 from '../../images/events_img2.png';
import events_img3 from '../../images/events_img3.png';
import tourism_img1 from '../../images/tourism_img1.png';
import tourism_img2 from '../../images/tourism_img2.png';
import tourism_img3 from '../../images/tourism_img3.png';

import gallery_img1 from '../../images/gallery_img1.png';
import gallery_img2 from '../../images/gallery_img2.png';
import gallery_img3 from '../../images/gallery_img3.png';
import gallery_img4 from '../../images/gallery_img4.png';
import gallery_img5 from '../../images/gallery_img5.png';
import gallery_img6 from '../../images/gallery_img6.png';
import gallery_img7 from '../../images/gallery_img7.png';
import gallery_img8 from '../../images/gallery_img8.png';

import partners_img1 from '../../images/partners_img1.png';
import partners_img2 from '../../images/partners_img2.png';
import partners_img3 from '../../images/partners_img3.png';
import partners_img4 from '../../images/partners_img4.png';
import partners_img5 from '../../images/partners_img5.png';
import Gallery from '../tabs/Gallery';



function OurEventsNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

function OurEventsPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

function UpcomingEventsNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

function UpcomingEventsPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

function TourismNewsNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

function TourismNewsPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

function OurPartnersNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

function OurPartnersPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

const Home = () => {

    const [ourEvents, setOurEventts] = useState([
        {
            events_name: "TDAB New Board of Directors A Courtesy Meeting with Mr. JABED Ahmed (Additional Secretary) CEO Bangladesh Tourism Board .", events_description: "TDAB New Board of Directors A Courtesy Meeting with Mr. JABED Ahmed (Additional Secretary) CEO Bangladesh Tourism Board .",
            image: events_img1, events_date: "22-08-2022", id: 1
        },
        {
            events_name: "Pre-Budget Discussion", events_description: "জাতীয় রাজস্ব বোর্ডের (এনবিআর) চেয়ারম্যান জনাব আবু হেনা মো. রহমাতুল ইসলাম এর সভাপতিত্বে প্রাক বাজেট আলোচনায় উপস্থিত। যেখানে আগামী ২০২২-২৩ অর্থবছরের জাতীয় বাজেটের কর সংক্রান্ত বিষয়ে মতবিনিময় অনুষ্ঠিত...",
            image: events_img2, events_date: "22-08-2022", id: 2
        },
        {
            events_name: "TDAB Celebrate World Tourism Day 2017 & 20th Anniversary of TDAB.", events_description: "Tourism Developers Association of Bangladesh  (TDAB), Celebrate the World tourism Day every Year on 27th September, World Tourism Day 2017 presents a unique opportunity to raise awareness on...",
            image: events_img3, events_date: "22-08-2022", id: 3
        },
        {
            events_name: "Pre-Budget Discussion", events_description: "Tourism Developers Association of Bangladesh  (TDAB), Celebrate the World tourism Day every Year on 27th September, World Tourism Day 2017 presents a unique opportunity to raise awareness on...",
            image: events_img3, events_date: "22-08-2022", id: 4
        },
        {
            events_name: "Pre-Budget Discussion", events_description: "Tourism Developers Association of Bangladesh  (TDAB), Celebrate the World tourism Day every Year on 27th September, World Tourism Day 2017 presents a unique opportunity to raise awareness on...",
            image: events_img3, events_date: "22-08-2022", id: 5
        },
    ]);
    const ourEventsSlider = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        nextArrow: <OurEventsNextArrow />,
        prevArrow: <OurEventsPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };
    const [upcomingEvents, setUpcomingEventts] = useState([
        {
            events_name: "TDAB New Board of Directors A Courtesy Meeting with Mr. JABED Ahmed (Additional Secretary) CEO Bangladesh Tourism Board .", events_description: "TDAB New Board of Directors A Courtesy Meeting with Mr. JABED Ahmed (Additional Secretary) CEO Bangladesh Tourism Board .",
            image: events_img1, events_date: "22-08-2022", id: 1
        },
        {
            events_name: "Pre-Budget Discussion", events_description: "জাতীয় রাজস্ব বোর্ডের (এনবিআর) চেয়ারম্যান জনাব আবু হেনা মো. রহমাতুল ইসলাম এর সভাপতিত্বে প্রাক বাজেট আলোচনায় উপস্থিত। যেখানে আগামী ২০২২-২৩ অর্থবছরের জাতীয় বাজেটের কর সংক্রান্ত বিষয়ে মতবিনিময় অনুষ্ঠিত...",
            image: events_img2, events_date: "22-08-2022", id: 2
        },
        {
            events_name: "TDAB Celebrate World Tourism Day 2017 & 20th Anniversary of TDAB.", events_description: "Tourism Developers Association of Bangladesh  (TDAB), Celebrate the World tourism Day every Year on 27th September, World Tourism Day 2017 presents a unique opportunity to raise awareness on...",
            image: events_img3, events_date: "22-08-2022", id: 3
        },
        {
            events_name: "Pre-Budget Discussion", events_description: "Tourism Developers Association of Bangladesh  (TDAB), Celebrate the World tourism Day every Year on 27th September, World Tourism Day 2017 presents a unique opportunity to raise awareness on...",
            image: events_img3, events_date: "22-08-2022", id: 4
        },
        {
            events_name: "Pre-Budget Discussion", events_description: "Tourism Developers Association of Bangladesh  (TDAB), Celebrate the World tourism Day every Year on 27th September, World Tourism Day 2017 presents a unique opportunity to raise awareness on...",
            image: events_img3, events_date: "22-08-2022", id: 5
        },
    ]);
    const upcomingEventsSlider = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        nextArrow: <UpcomingEventsNextArrow />,
        prevArrow: <UpcomingEventsPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };

    const [tourismNews, setTourismNews] = useState([
        {
            user_name: "TDAB", tourism_name: "Biman Bangladesh Travel & Tourism Fair", tourism_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra posuere viverra viverra lectus eros. In eu amet, pellentesque quis mauris in nunc. Viverra posuere viverra viverra lectus eros. ",
            image: tourism_img1, tourism_date: "22-08-2022", id: 1
        },
        {
            user_name: "TDAB", tourism_name: "Urvelling Visit Nepat Year 2020 Campaign", tourism_description: "জাতীয় রাজস্ব বোর্ডের (এনবিআর) চেয়ারম্যান জনাব আবু হেনা মো. রহমাতুল ইসলাম এর সভাপতিত্বে প্রাক বাজেট আলোচনায় উপস্থিত। যেখানে আগামী ২০২২-২৩ অর্থবছরের জাতীয় বাজেটের কর সংক্রান্ত বিষয়ে মতবিনিময় অনুষ্ঠিত...",
            image: tourism_img2, tourism_date: "22-08-2022", id: 2
        },
        {
            user_name: "TDAB", tourism_name: "TDAB PICNIC 2021", tourism_description: "Tourism Developers Association of Bangladesh  (TDAB), Celebrate the World tourism Day every Year on 27th September, World Tourism Day 2017 presents a unique opportunity to raise awareness on...",
            image: tourism_img3, tourism_date: "22-08-2022", id: 3
        },
        {
            user_name: "TDAB", tourism_name: "Pre-Budget Discussion", tourism_description: "Tourism Developers Association of Bangladesh  (TDAB), Celebrate the World tourism Day every Year on 27th September, World Tourism Day 2017 presents a unique opportunity to raise awareness on...",
            image: tourism_img3, tourism_date: "22-08-2022", id: 4
        },
        {
            user_name: "TDAB", tourism_name: "Pre-Budget Discussion", tourism_description: "Tourism Developers Association of Bangladesh  (TDAB), Celebrate the World tourism Day every Year on 27th September, World Tourism Day 2017 presents a unique opportunity to raise awareness on...",
            image: tourism_img3, tourism_date: "22-08-2022", id: 5
        },

    ]);



    const tourismNewsSlider = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        nextArrow: <TourismNewsNextArrow />,
        prevArrow: <TourismNewsPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };

    const [galleryImage, setGalleryImage] = useState([
        { id: 1, image: gallery_img1 },
        { id: 2, image: gallery_img2 },
        { id: 3, image: gallery_img3 },
        { id: 4, image: gallery_img4 },
        { id: 5, image: gallery_img5 },
        { id: 6, image: gallery_img6 },
        { id: 7, image: gallery_img7 },
        { id: 8, image: gallery_img8 },
    ]);

    const [ourPartners, setOurPartners] = useState([
        { partners_img: partners_img1, id: 1 },
        { partners_img: partners_img2, id: 2 },
        { partners_img: partners_img3, id: 3 },
        { partners_img: partners_img4, id: 4 },
        { partners_img: partners_img5, id: 5 },
        { partners_img: partners_img1, id: 6 },
        { partners_img: partners_img2, id: 7 },
        { partners_img: partners_img3, id: 8 },
        { partners_img: partners_img4, id: 9 },

    ]);

    const ourPartnersSlider = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        nextArrow: <OurPartnersNextArrow />,
        prevArrow: <OurPartnersPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };


    return (
        <>
            <div className="bg__body">
                <SliderImage />
                <div className="container">
                    <div className="row g-3 pt-sm-5">
                        <div className="col-md-6">
                            <h3 className="mb-1">
                                <span className="header_text_normal">Welcome to</span>
                                <span className="header_text_bold"> Tourism Developers Association of Bangladesh (TDAB)</span>
                            </h3>
                            <p className="header_description pe-md-5">
                                Tourism Developers Association of Bangladesh (TDAB), was formed in the year of 1997. Tourism
                                Developers Association of Bangladesh (TDAB), was established for development of Tourism in
                                Bangladesh, Our organization working with government for development of the tourism sector in
                                Bangladesh. Our organization as an Industry Association Suggest Govt. For Act, rules regulations
                                guidelines and many more.
                            </p>
                            <div className="pt-0">
                                <a src="#" type="button" className="btn btn_read">
                                    <span>Read More</span>
                                    <span className="ms-2"><i className="fa-solid fa-arrow-right-long"></i></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="tour_img pt-2">
                                <img src={tour_img} className="img-fluid" alt="image" border="0" loading="lazy" />
                            </div>
                        </div>
                    </div>

                    <div className="row pt-4">
                        <div className="col-12">
                            <h3 className="mb-1 text-center">
                                <span className="header_text_normal_bm">Our</span>
                                <span className="header_text_bold"> Events</span>
                            </h3>
                            <div className="events_carousel pt-4 mb-3">
                                <Slider {...ourEventsSlider}>
                                    {
                                        ourEvents.length > 0 && ourEvents.map((item, id) => {
                                            return (
                                                <div key={id} className="row g-3">
                                                    <div className="col-md-12">
                                                        <Link to={`/tourism-news/${item.id}`} style={{ textDecoration: "none" }}>
                                                            <div className="events_carousel_box_card">
                                                                <div className="card">
                                                                    <img src={item.image} className="card-img-top" alt="image" loading="lazy" />
                                                                    <div className="card-body">
                                                                        <p className="events_carousel_date"><i className="fa-solid fa-calendar"></i> <span className="ms-2">{item.events_date}</span></p>
                                                                        <h6 className="events_carousel_card_title mb-2">{item.events_name}</h6>
                                                                        <p className="events_carousel_discription">{item.events_description}</p>
                                                                        <div className="pt-0">
                                                                            <a src="#" type="button" className="btn btn_read_carousel">
                                                                                <span>Read More</span>
                                                                                <span className="ms-2"><i className="fa-solid fa-arrow-right-long"></i></span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                            <div className="pt-5 text-center">
                                <Link to="our-events" type="button" className="btn btn_read">
                                    <span>Show All Events</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-12">
                            <h3 className="mb-1 text-center">
                                <span className="header_text_normal_bm">Upc</span><span className="header_text_normal">oming</span>
                                <span className="header_text_bold"> Events</span>
                            </h3>
                            <div className="events_carousel pt-4 mb-3">
                                <Slider {...upcomingEventsSlider}>
                                    {
                                        upcomingEvents.length > 0 && upcomingEvents.map((item, id) => {
                                            return (
                                                <div key={id} className="row g-3">
                                                    <div className="col-12">
                                                        <div className="card_bg">
                                                            <img src={item.image} className="card-img-top" alt="image" loading="lazy" />
                                                            <div className="p-3">
                                                                <div className="row g-0">
                                                                    <div className="col-3">
                                                                        <h6 className="header_event_card_month">SEP</h6>
                                                                        <p className="header_event_card_date">16</p>
                                                                    </div>
                                                                    <div className="col-9">
                                                                        <h6 className="upc_events_carousel_card_title">{item.events_name}</h6>
                                                                        <p className="upc_events_carousel_discription">{item.events_description}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                            <div className="pt-5 text-center">
                                <Link to="our-events" type="button" className="btn btn_read">
                                    <span>Show All Upcoming Events</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5 pt-4">
                        <div className="col-12">
                            <h3 className="mb-1 text-center">
                                <span className="header_text_normal_bm">Tour</span><span className="header_text_normal">ism</span>
                                <span className="header_text_bold"> News</span>
                            </h3>
                            <div className="tourism_carousel pt-4 mb-3">
                                <Slider {...tourismNewsSlider}>
                                    {
                                        tourismNews.length > 0 && tourismNews.map((item, id) => {
                                            return (
                                                <div key={id} className="row g-3">
                                                    <div className="col-md-12">
                                                        <Link to={`/tourism-news/${item.id}`} style={{ textDecoration: "none" }}>
                                                            <div className="tourism_carousel_box_card">
                                                                <div className="card">
                                                                    <img src={item.image} className="card-img-top" alt="image" loading="lazy" />
                                                                    <div className="card-body">
                                                                        <div className="d-flex justify-content-between">
                                                                            <p className="tourism_carousel_date">
                                                                                <span><i className="fa-regular fa-user"></i> </span>
                                                                                <span className="ms-2">{item.user_name}</span>
                                                                            </p>
                                                                            <p className="tourism_carousel_date">
                                                                                <span><i className="fa-solid fa-calendar"></i> </span>
                                                                                <span className="ms-2">{item.tourism_date}</span>
                                                                            </p>
                                                                        </div>
                                                                        <h6 className="tourism_carousel_card_title mb-2">{item.tourism_name}</h6>
                                                                        <p className="tourism_carousel_discription">{item.tourism_description}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>

                    <div className="row g-3 pt-3">
                        {/* <h3 className="mb-3 text-center">
                            <span className="header_text_normal_bm header_text_bold">Gall</span><span className="header_text_bold">ery</span>
                        </h3> */}
                        {/* 
                        {
                            galleryImage.length > 0 && galleryImage.map((item, id) => {
                                return (
                                    <div key={id} className="col-md-3">
                                        <div className="gallery_img">
                                            <img src={item.image} alt="" loading="lazy" />
                                        </div>
                                    </div>
                                )
                            })
                        } */}

                        <div className="col-md-12">
                            <Gallery />
                            <div className="text-center pt-3 mb-4">
                                <Link to="/gallery" type="button" className="btn btn_read">
                                    <span>Load More</span>
                                </Link>
                            </div>
                        </div>


                    </div>


                    <div className="row pt-4">
                        <div className="col-12">
                            <h3 className="mb-1 text-center">
                                <span className="header_text_normal_bm">Our</span>
                                <span className="header_text_bold"> Partners</span>
                            </h3>
                            <div className="partners_carousel pt-4 mb-5">
                                <Slider {...ourPartnersSlider}>
                                    {
                                        ourPartners.length > 0 && ourPartners.map((item, id) => {
                                            return (
                                                <div key={id} className="row g-2">
                                                    <div className="col-12">
                                                        <div className="partners_carousel_box_card">
                                                            <div className="card text-center">
                                                                <div className="d-flex justify-content-center pt-3">
                                                                    <img src={item.partners_img} className="card-img-top" alt="partner img" style={{ width: "100%", height: "90px", padding: "10px" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;


