import React from 'react';
import under_construction from "../../images/under_construction.jpg";


const ForeinMinistry = () => {
    return (
        <div>
            <div className="container pt-2 mb-5">
                <h1 className="text-center mb-3">Forein Ministry in Bangladesh</h1>
                <div className="text-center">
                    <img src={under_construction} alt="image" style={{ height: "360px", width: "60%" }} />
                </div>
            </div>
        </div>
    );
};

export default ForeinMinistry;