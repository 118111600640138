
import React from 'react';
import { Link } from 'react-router-dom';
import "./LeftSidebar.css";
import { Scrollbars } from 'react-custom-scrollbars-2';

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import under_construction from "../../images/under_construction.jpg";
import { useState } from 'react';


const LeftSidebar = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <>
            <Scrollbars style={{ height: 500, }}>
                <ul className="nav">
                    {/* <li className="nav-item nav__link__home">
                        <Link to="login" className="nav-link ">
                            <i className="icon-grid menu-icon"></i>
                            <span className="menu-title menu__title">Login</span>
                        </Link>
                    </li> */}
                    {/* <li className="nav-item nav__link__home">
                        <Link to="home" className="nav-link ">
                            <i className="icon-grid menu-icon"></i>
                            <i className="fa-solid fa-house"></i>
                            <span className="menu-title menu__title">Home</span>
                        </Link>
                    </li> */}

                    <li className="nav-item">
                        <Link to="slidebar-image-setup" className="nav-link">
                            <i className="icon-paper menu-icon"></i>
                            <span className="menu-title">Slidebar/Image</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="cards-setup" className="nav-link">
                            <i className="icon-paper menu-icon"></i>
                            <span className="menu-title">Cards</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="collapse" href="#home" aria-expanded="false" aria-controls="home">
                            {/* <i className="icon-layout menu-icon"></i> */}
                            <i className="fa-solid fa-house"></i>
                            <span className="menu-title menu__title">Home</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="home">
                            <ul className="nav flex-column sub-menu sub__menu">
                                <li className="nav-item">
                                    {/* <Link to="online-admission-process" className="nav-link nav-item">Online Application</Link> */}
                                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                    General settings
                                                </Typography> */}
                                            <Typography sx={{ color: 'text.secondary' }}>Home Page</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {/* <Typography>
                                                    Nulla facilisi.
                                                </Typography> */}

                                            <li className="nav-item">
                                                <Link to="home-banner-setup" className="nav-link nav-item">Banner</Link>
                                            </li>
                                            
                                            <li className="nav-item">
                                                <Link to="sub-header-setup" className="nav-link nav-item">Sub-Header</Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link to="#" className="nav-link nav-item">Our Events</Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link to="#" className="nav-link nav-item">TDAB Gallery</Link>
                                            </li>
                                        </AccordionDetails>
                                    </Accordion>
                                </li>
                                <li className="nav-item">
                                    <Link to="our-board-members-setup" className="nav-link">Board Of Director</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="advisors-setup" className="nav-link">Advisors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="secretariat-setup" className="nav-link">Secretariat</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="chairman-message-setup" className="nav-link">Chairman Message</Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link" data-toggle="collapse" href="#about-us" aria-expanded="false" aria-controls="about-us">
                            <i className="icon-grid menu-icon"></i>
                            <i className="fa-regular fa-address-card"></i>
                            <span className="menu-title menu__title">About Us</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="about-us">
                            <ul className="nav flex-column sub-menu sub__menu">
                                <h6 className="nav__item__title">
                                    <span>Banner</span>
                                </h6>
                                <li className="nav-item">
                                    <Link to="banner-title-about" className="nav-link nav-item">Title & Banner</Link>
                                </li>
                                <h6 className="nav__item__title">
                                    <span>About Our</span>
                                </h6>
                                <li className="nav-item">
                                    <Link to="about-our" className="nav-link nav-item">Title & Description</Link>
                                </li>
                                <h6 className="nav__item__title">
                                    <span>Our Mission</span>
                                </h6>
                                <li className="nav-item">
                                    <Link to="our-mission" className="nav-link nav-item">Title, Description, Image</Link>
                                </li>
                                <h6 className="nav__item__title">
                                    <span>Our Vision</span>
                                </h6>
                                <li className="nav-item">
                                    <Link to="our-vision" className="nav-link nav-item">Title, Description, Image</Link>
                                </li>
                            </ul>
                        </div>
                    </li> */}
                    {/* <li className="nav-item">
                        <Link to="/about-us" className="nav-link">
                            <i className="icon-paper menu-icon"></i>
                            <i className="fa-regular fa-address-card"></i>
                            <span className="menu-title menu__title">About Us</span>
                        </Link>
                    </li> */}

                    <li className="nav-item">
                        <a className="nav-link" data-toggle="collapse" href="#home-affairs" aria-expanded="false" aria-controls="home-affairs">
                            {/* <i className="icon-layout menu-icon"></i> */}
                            <i className="fa-solid fa-building-user"></i>
                            <span className="menu-title menu__title">Home Affairs</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="home-affairs">
                            <ul className="nav flex-column sub-menu sub__menu">
                                {/* <li className="nav-item">
                                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography sx={{ color: 'text.secondary' }}>Tourist Police</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <li className="nav-item">
                                                <Link to="#" className="nav-link nav-item">Tourist Police Headquarters</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="#" className="nav-link nav-item">Divisions</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="#" className="nav-link nav-item">Regions</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="#" className="nav-link nav-item">Zone</Link>
                                            </li>
                                        </AccordionDetails>
                                    </Accordion>
                                </li> */}

                                <li className="nav-item">
                                    <Link to="police-setup" className="nav-link">Police</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="tourist-police-setup" className="nav-link">Tourist Police</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="immigration-setup" className="nav-link">Immigration</Link>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" data-toggle="collapse" href="#membership" aria-expanded="false" aria-controls="membership">
                            {/* <i className="icon-grid menu-icon"></i> */}
                            <i className="fa-solid fa-user-tie"></i>
                            <span className="menu-title menu__title">Membership</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="membership">
                            <ul className="nav flex-column sub-menu sub__menu">
                                {/* <h6 className="nav__item__title">
                                     <span>Sub-header</span>
                                 </h6> */}
                                <li className="nav-item">
                                    <Link to="member-list-setup" className="nav-link nav-item">General Member</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="member-list-associate-setup" className="nav-link nav-item">Associate Member</Link>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" data-toggle="collapse" href="#forien-mission" aria-expanded="false" aria-controls="forien-mission">
                            {/* <i className="icon-grid menu-icon"></i> */}
                            <i className="fa-solid fa-user-tie"></i>
                            <span className="menu-title menu__title">Foriegn Mission</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="forien-mission">
                            <ul className="nav flex-column sub-menu sub__menu">
                                {/* <h6 className="nav__item__title">
                                     <span>Sub-header</span>
                                 </h6> */}
                                <li className="nav-item">
                                    <Link to="ministry-info-setup" className="nav-link nav-item">Ministry Information</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="embassies-in-bangladesh-setup" className="nav-link nav-item">Embassies in Bangladesh</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="bmforeign-country-setup" className="nav-link nav-item">Bangladesh Mission <br />of Foreign Country</Link>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" data-toggle="collapse" href="#transportation" aria-expanded="false" aria-controls="transportation">
                            {/* <i className="icon-grid menu-icon"></i> */}
                            <i className="fa-solid fa-user-tie"></i>
                            <span className="menu-title menu__title">Transportation</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="transportation">
                            <ul className="nav flex-column sub-menu sub__menu">
                                {/* <h6 className="nav__item__title">
                                     <span>Sub-header</span>
                                 </h6> */}
                                <li className="nav-item">
                                    <Link to="#" className="nav-link nav-item">All Airlines</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="accommodation-setup" className="nav-link nav-item">Accommodation</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="travel-agent-setup" className="nav-link nav-item">Travel Agent & <br />Tour Operator</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="food-beverage-setup" className="nav-link nav-item">Food & Beverage</Link>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" data-toggle="collapse" href="#tourism" aria-expanded="false" aria-controls="tourism">
                            {/* <i className="icon-grid menu-icon"></i> */}
                            <i className="fa-solid fa-user-tie"></i>
                            <span className="menu-title menu__title">Tourism</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="tourism">
                            <ul className="nav flex-column sub-menu sub__menu">
                                {/* <h6 className="nav__item__title">
                                     <span>Sub-header</span>
                                 </h6> */}
                                <li className="nav-item">
                                    <Link to="tourism-spot-setup" className="nav-link nav-item">Tourism Spot</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#" className="nav-link nav-item">Tourism News</Link>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="nav-item">
                        <Link to="festivals-setup" className="nav-link">
                            <i className="icon-paper menu-icon"></i>
                            <span className="menu-title">Festivals</span>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link to="gallery-setup" className="nav-link">
                            {/* <i className="icon-paper menu-icon"></i> */}
                            <i className="fa-solid fa-user-pen"></i>
                            <span className="menu-title menu__title">Gallery</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="about-bangladesh-setup" className="nav-link">
                            {/* <i className="icon-paper menu-icon"></i> */}
                            <i className="fa-solid fa-money-check-dollar"></i>
                            <span className="menu-title menu__title">Bangladesh</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="contact-setup" className="nav-link">
                            {/* <i className="icon-paper menu-icon"></i> */}
                            <i className="fa-solid fa-money-check-dollar"></i>
                            <span className="menu-title menu__title">Contact</span>
                        </Link>
                    </li>
         
                </ul>
            </Scrollbars>
        </>
    );
};

export default LeftSidebar;


// import React from 'react';
// import { Link } from 'react-router-dom';
// import "./LeftSidebar.css";
// import { Scrollbars } from 'react-custom-scrollbars-2';

// import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// // import under_construction from "../../images/under_construction.jpg";
// import { useState } from 'react';


// const LeftSidebar = () => {
//     const [expanded, setExpanded] = useState(false);

//     const handleChange = (panel) => (event, isExpanded) => {
//         setExpanded(isExpanded ? panel : false);
//     };
//     return (
//         <>
//             <Scrollbars style={{ height: 500, }}>
//                 <ul className="nav">
//                     {/* <li className="nav-item nav__link__home">
//                         <Link to="login" className="nav-link ">
//                             <i className="icon-grid menu-icon"></i>
//                             <span className="menu-title menu__title">Login</span>
//                         </Link>
//                     </li> */}
//                     {/* <li className="nav-item nav__link__home">
//                         <Link to="home" className="nav-link ">
//                             <i className="icon-grid menu-icon"></i>
//                             <i className="fa-solid fa-house"></i>
//                             <span className="menu-title menu__title">Home</span>
//                         </Link>
//                     </li> */}

//                     <li className="nav-item">
//                         <Link to="slidebar-image-setup" className="nav-link">
//                             <i className="icon-paper menu-icon"></i>
//                             <span className="menu-title">Slidebar/Image</span>
//                         </Link>
//                     </li>
//                     <li className="nav-item">
//                         <Link to="cards-setup" className="nav-link">
//                             <i className="icon-paper menu-icon"></i>
//                             <span className="menu-title">Cards</span>
//                         </Link>
//                     </li>
//                     <li className="nav-item">
//                         <a className="nav-link" data-toggle="collapse" href="#home" aria-expanded="false" aria-controls="home">
//                             {/* <i className="icon-layout menu-icon"></i> */}
//                             <i className="fa-solid fa-house"></i>
//                             <span className="menu-title menu__title">Home</span>
//                             <i className="menu-arrow"></i>
//                         </a>
//                         <div className="collapse" id="home">
//                             <ul className="nav flex-column sub-menu sub__menu">
//                                 <li className="nav-item">
//                                     {/* <Link to="online-admission-process" className="nav-link nav-item">Online Application</Link> */}
//                                     <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
//                                         <AccordionSummary
//                                             expandIcon={<ExpandMoreIcon />}
//                                             aria-controls="panel1bh-content"
//                                             id="panel1bh-header"
//                                         >
//                                             {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>
//                                                     General settings
//                                                 </Typography> */}
//                                             <Typography sx={{ color: 'text.secondary' }}>Home Page</Typography>
//                                         </AccordionSummary>
//                                         <AccordionDetails>
//                                             {/* <Typography>
//                                                     Nulla facilisi.
//                                                 </Typography> */}

//                                             <li className="nav-item">
//                                                 <Link to="sub-header-setup" className="nav-link nav-item">Sub-Header</Link>
//                                             </li>

//                                             <li className="nav-item">
//                                                 <Link to="#" className="nav-link nav-item">Our Events</Link>
//                                             </li>

//                                             <li className="nav-item">
//                                                 <Link to="#" className="nav-link nav-item">TDAB Gallery</Link>
//                                             </li>
//                                         </AccordionDetails>
//                                     </Accordion>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="our-board-members-setup" className="nav-link">Board Of Director</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="advisors-setup" className="nav-link">Advisors</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="secretariat-setup" className="nav-link">Secretariat</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="chairman-message-setup" className="nav-link">Chairman Message</Link>
//                                 </li>
//                             </ul>
//                         </div>
//                     </li>
//                     {/* <li className="nav-item">
//                         <a className="nav-link" data-toggle="collapse" href="#about-us" aria-expanded="false" aria-controls="about-us">
//                             <i className="icon-grid menu-icon"></i>
//                             <i className="fa-regular fa-address-card"></i>
//                             <span className="menu-title menu__title">About Us</span>
//                             <i className="menu-arrow"></i>
//                         </a>
//                         <div className="collapse" id="about-us">
//                             <ul className="nav flex-column sub-menu sub__menu">
//                                 <h6 className="nav__item__title">
//                                     <span>Banner</span>
//                                 </h6>
//                                 <li className="nav-item">
//                                     <Link to="banner-title-about" className="nav-link nav-item">Title & Banner</Link>
//                                 </li>
//                                 <h6 className="nav__item__title">
//                                     <span>About Our</span>
//                                 </h6>
//                                 <li className="nav-item">
//                                     <Link to="about-our" className="nav-link nav-item">Title & Description</Link>
//                                 </li>
//                                 <h6 className="nav__item__title">
//                                     <span>Our Mission</span>
//                                 </h6>
//                                 <li className="nav-item">
//                                     <Link to="our-mission" className="nav-link nav-item">Title, Description, Image</Link>
//                                 </li>
//                                 <h6 className="nav__item__title">
//                                     <span>Our Vision</span>
//                                 </h6>
//                                 <li className="nav-item">
//                                     <Link to="our-vision" className="nav-link nav-item">Title, Description, Image</Link>
//                                 </li>
//                             </ul>
//                         </div>
//                     </li> */}
//                     {/* <li className="nav-item">
//                         <Link to="/about-us" className="nav-link">
//                             <i className="icon-paper menu-icon"></i>
//                             <i className="fa-regular fa-address-card"></i>
//                             <span className="menu-title menu__title">About Us</span>
//                         </Link>
//                     </li> */}

//                     <li className="nav-item">
//                         <a className="nav-link" data-toggle="collapse" href="#home-affairs" aria-expanded="false" aria-controls="home-affairs">
//                             {/* <i className="icon-layout menu-icon"></i> */}
//                             <i className="fa-solid fa-building-user"></i>
//                             <span className="menu-title menu__title">Home Affairs</span>
//                             <i className="menu-arrow"></i>
//                         </a>
//                         <div className="collapse" id="home-affairs">
//                             <ul className="nav flex-column sub-menu sub__menu">
//                                 {/* <li className="nav-item">
//                                     <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
//                                         <AccordionSummary
//                                             expandIcon={<ExpandMoreIcon />}
//                                             aria-controls="panel1bh-content"
//                                             id="panel1bh-header"
//                                         >
//                                             <Typography sx={{ color: 'text.secondary' }}>Tourist Police</Typography>
//                                         </AccordionSummary>
//                                         <AccordionDetails>
//                                             <li className="nav-item">
//                                                 <Link to="#" className="nav-link nav-item">Tourist Police Headquarters</Link>
//                                             </li>
//                                             <li className="nav-item">
//                                                 <Link to="#" className="nav-link nav-item">Divisions</Link>
//                                             </li>
//                                             <li className="nav-item">
//                                                 <Link to="#" className="nav-link nav-item">Regions</Link>
//                                             </li>
//                                             <li className="nav-item">
//                                                 <Link to="#" className="nav-link nav-item">Zone</Link>
//                                             </li>
//                                         </AccordionDetails>
//                                     </Accordion>
//                                 </li> */}

//                                 <li className="nav-item">
//                                     <Link to="police-setup" className="nav-link">Police</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="tourist-police-setup" className="nav-link">Tourist Police</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="immigration-setup" className="nav-link">Immigration</Link>
//                                 </li>
//                             </ul>
//                         </div>
//                     </li>

//                     <li className="nav-item">
//                         <a className="nav-link" data-toggle="collapse" href="#membership" aria-expanded="false" aria-controls="membership">
//                             {/* <i className="icon-grid menu-icon"></i> */}
//                             <i className="fa-solid fa-user-tie"></i>
//                             <span className="menu-title menu__title">Membership</span>
//                             <i className="menu-arrow"></i>
//                         </a>
//                         <div className="collapse" id="membership">
//                             <ul className="nav flex-column sub-menu sub__menu">
//                                 {/* <h6 className="nav__item__title">
//                                      <span>Sub-header</span>
//                                  </h6> */}
//                                 <li className="nav-item">
//                                     <Link to="member-list-setup" className="nav-link nav-item">Member List</Link>
//                                 </li>
//                             </ul>
//                         </div>
//                     </li>

//                     <li className="nav-item">
//                         <a className="nav-link" data-toggle="collapse" href="#forien-mission" aria-expanded="false" aria-controls="forien-mission">
//                             {/* <i className="icon-grid menu-icon"></i> */}
//                             <i className="fa-solid fa-user-tie"></i>
//                             <span className="menu-title menu__title">Foriegn Mission</span>
//                             <i className="menu-arrow"></i>
//                         </a>
//                         <div className="collapse" id="forien-mission">
//                             <ul className="nav flex-column sub-menu sub__menu">
//                                 {/* <h6 className="nav__item__title">
//                                      <span>Sub-header</span>
//                                  </h6> */}
//                                 <li className="nav-item">
//                                     <Link to="ministry-info-setup" className="nav-link nav-item">Ministry Information</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="embassies-in-bangladesh-setup" className="nav-link nav-item">Embassies in Bangladesh</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="bmforeign-country-setup" className="nav-link nav-item">Bangladesh Mission <br />of Foreign Country</Link>
//                                 </li>
//                             </ul>
//                         </div>
//                     </li>

//                     <li className="nav-item">
//                         <a className="nav-link" data-toggle="collapse" href="#transportation" aria-expanded="false" aria-controls="transportation">
//                             {/* <i className="icon-grid menu-icon"></i> */}
//                             <i className="fa-solid fa-user-tie"></i>
//                             <span className="menu-title menu__title">Transportation</span>
//                             <i className="menu-arrow"></i>
//                         </a>
//                         <div className="collapse" id="transportation">
//                             <ul className="nav flex-column sub-menu sub__menu">
//                                 {/* <h6 className="nav__item__title">
//                                      <span>Sub-header</span>
//                                  </h6> */}
//                                 <li className="nav-item">
//                                     <Link to="#" className="nav-link nav-item">All Airlines</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="accommodation-setup" className="nav-link nav-item">Accommodation</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="travel-agent-setup" className="nav-link nav-item">Travel Agent & <br />Tour Operator</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="food-beverage-setup" className="nav-link nav-item">Food & Beverage</Link>
//                                 </li>
//                             </ul>
//                         </div>
//                     </li>

//                     <li className="nav-item">
//                         <a className="nav-link" data-toggle="collapse" href="#tourism" aria-expanded="false" aria-controls="tourism">
//                             {/* <i className="icon-grid menu-icon"></i> */}
//                             <i className="fa-solid fa-user-tie"></i>
//                             <span className="menu-title menu__title">Tourism</span>
//                             <i className="menu-arrow"></i>
//                         </a>
//                         <div className="collapse" id="tourism">
//                             <ul className="nav flex-column sub-menu sub__menu">
//                                 {/* <h6 className="nav__item__title">
//                                      <span>Sub-header</span>
//                                  </h6> */}
//                                 <li className="nav-item">
//                                     <Link to="#" className="nav-link nav-item">Tourism Spot</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="#" className="nav-link nav-item">Tourism News</Link>
//                                 </li>
//                             </ul>
//                         </div>
//                     </li>

//                     <li className="nav-item">
//                         <Link to="festivals-setup" className="nav-link">
//                             <i className="icon-paper menu-icon"></i>
//                             <span className="menu-title">Festivals</span>
//                         </Link>
//                     </li>

//                     <li className="nav-item">
//                         <Link to="#" className="nav-link">
//                             {/* <i className="icon-paper menu-icon"></i> */}
//                             <i className="fa-solid fa-user-pen"></i>
//                             <span className="menu-title menu__title">Gallery</span>
//                         </Link>
//                     </li>
//                     <li className="nav-item">
//                         <Link to="about-bangladesh-setup" className="nav-link">
//                             {/* <i className="icon-paper menu-icon"></i> */}
//                             <i className="fa-solid fa-money-check-dollar"></i>
//                             <span className="menu-title menu__title">Bangladesh</span>
//                         </Link>
//                     </li>
         
//                 </ul>
//             </Scrollbars>
//         </>
//     );
// };

// export default LeftSidebar;




// import React from 'react';
// import { Link } from 'react-router-dom';
// import "./LeftSidebar.css";
// import { Scrollbars } from 'react-custom-scrollbars-2';

// import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
// // import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// // import under_construction from "../../images/under_construction.jpg";
// import { useState } from 'react';


// const LeftSidebar = () => {
//     // const [expanded, setExpanded] = useState(false);

//     // const handleChange = (panel) => (event, isExpanded) => {
//     //     setExpanded(isExpanded ? panel : false);
//     // };
//     return (
//         <>
//             <Scrollbars style={{ height: 500, }}>
//                 <ul className="nav">
//                     {/* <li className="nav-item nav__link__home">
//                         <Link to="login" className="nav-link ">
//                             <i className="icon-grid menu-icon"></i>
//                             <span className="menu-title menu__title">Login</span>
//                         </Link>
//                     </li> */}
//                     {/* <li className="nav-item nav__link__home">
//                         <Link to="home" className="nav-link ">
//                             <i className="icon-grid menu-icon"></i>
//                             <i className="fa-solid fa-house"></i>
//                             <span className="menu-title menu__title">Home</span>
//                         </Link>
//                     </li> */}
//                     <li className="nav-item">
//                         <Link to="slidebar-image-setup" className="nav-link">
//                             <i className="icon-paper menu-icon"></i>
//                             <span className="menu-title">Slidebar/Image</span>
//                         </Link>
//                     </li>
//                     <li className="nav-item">
//                         <Link to="cards-setup" className="nav-link">
//                             <i className="icon-paper menu-icon"></i>
//                             <span className="menu-title">Cards</span>
//                         </Link>
//                     </li>
//                     <li className="nav-item">
//                         <a className="nav-link" data-toggle="collapse" href="#home" aria-expanded="false" aria-controls="home">
//                             {/* <i className="icon-grid menu-icon"></i> */}
//                             <i className="fa-solid fa-house"></i>
//                             <span className="menu-title menu__title">Home Page</span>
//                             <i className="menu-arrow"></i>
//                         </a>
//                         <div className="collapse" id="home">
//                             <ul className="nav flex-column sub-menu sub__menu">
//                                 {/* <h6 className="nav__item__title">
//                                     <span>Sub-header</span>
//                                 </h6> */}
//                                 <li className="nav-item">
//                                     <Link to="sub-header-setup" className="nav-link nav-item">Sub-Header</Link>
//                                 </li>

//                                 {/* <h6 className="nav__item__title">
//                                     <span>Our Events</span>
//                                 </h6> */}
//                                 <li className="nav-item">
//                                     <Link to="#" className="nav-link nav-item">Our Events</Link>
//                                 </li>

//                                 {/* <h6 className="nav__item__title">
//                                     <span>TDAB Gallery</span>
//                                 </h6> */}
//                                 <li className="nav-item">
//                                     <Link to="#" className="nav-link nav-item">TDAB Gallery</Link>
//                                 </li>
//                             </ul>
//                         </div>
//                     </li>



//                     <li className="nav-item">
//                         <Link to="our-board-members-setup" className="nav-link">
//                             <i className="fa-solid fa-user-tie menu-icon"></i>
//                             <span className="menu-title menu__title">Board Of Director</span>
//                         </Link>
//                     </li>
//                     <li className="nav-item">
//                         <Link to="advisors-setup" className="nav-link">
//                             <i className="fa-solid fa-user-tie menu-icon"></i>
//                             <span className="menu-title menu__title">Advisors</span>
//                         </Link>
//                     </li>
//                     <li className="nav-item">
//                         <Link to="secretariat-setup" className="nav-link">
//                             <i className="fa-solid fa-user-tie menu-icon"></i>
//                             <span className="menu-title menu__title">Secretariat</span>
//                         </Link>
//                     </li>


//                     <li className="nav-item">
//                         <a className="nav-link" data-toggle="collapse" href="#membership" aria-expanded="false" aria-controls="membership">
//                             {/* <i className="icon-grid menu-icon"></i> */}
//                             <i className="fa-solid fa-user-tie"></i>
//                             <span className="menu-title menu__title">Membership</span>
//                             <i className="menu-arrow"></i>
//                         </a>
//                         <div className="collapse" id="membership">
//                             <ul className="nav flex-column sub-menu sub__menu">
//                                 {/* <h6 className="nav__item__title">
//                                     <span>Sub-header</span>
//                                 </h6> */}
//                                 <li className="nav-item">
//                                     <Link to="member-list-setup" className="nav-link nav-item">Member List</Link>
//                                 </li>
//                             </ul>
//                         </div>
//                     </li>

//                     {/* <li className="nav-item">
//                         <Link to="bd-bangladesh" className="nav-link">
//                             <i className="icon-paper menu-icon"></i>
//                             <span className="menu-title">Bangladesh</span>
//                         </Link>
//                     </li> */}


//                     <li className="nav-item">
//                         <Link to="contact-us-setup" className="nav-link">
//                             {/* <i className="icon-paper menu-icon"></i> */}
//                             <i className="fa-solid fa-user-pen"></i>
//                             <span className="menu-title menu__title">Contact Us</span>
//                         </Link>
//                     </li>

//                 </ul>
//             </Scrollbars>
//         </>
//     );
// };

// export default LeftSidebar;


// import React from 'react';
// import { Link } from 'react-router-dom';
// import "./LeftSidebar.css";
// import { Scrollbars } from 'react-custom-scrollbars-2';

// import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// // import under_construction from "../../images/under_construction.jpg";
// import { useState } from 'react';


// const LeftSidebar = () => {
//     const [expanded, setExpanded] = useState(false);

//     const handleChange = (panel) => (event, isExpanded) => {
//         setExpanded(isExpanded ? panel : false);
//     };
//     return (
//         <>
//             <Scrollbars style={{ height: 500, }}>
//                 <ul className="nav">
//                     {/* <li className="nav-item nav__link__home">
//                         <Link to="login" className="nav-link ">
//                             <i className="icon-grid menu-icon"></i>
//                             <span className="menu-title menu__title">Login</span>
//                         </Link>
//                     </li> */}
//                     {/* <li className="nav-item nav__link__home">
//                         <Link to="home" className="nav-link ">
//                             <i className="icon-grid menu-icon"></i>
//                             <i className="fa-solid fa-house"></i>
//                             <span className="menu-title menu__title">Home</span>
//                         </Link>
//                     </li> */}
//                      <li className="nav-item">
//                         <Link to="slidebar-image" className="nav-link">
//                             <i className="icon-paper menu-icon"></i>
//                             <span className="menu-title">Sidebar/Image</span>
//                         </Link>
//                     </li>
//                     <li className="nav-item">
//                         <a className="nav-link" data-toggle="collapse" href="#home" aria-expanded="false" aria-controls="home">
//                             {/* <i className="icon-grid menu-icon"></i> */}
//                             <i className="fa-solid fa-house"></i>
//                             <span className="menu-title menu__title">Home Page</span>
//                             <i className="menu-arrow"></i>
//                         </a>
//                         <div className="collapse" id="home">
//                             <ul className="nav flex-column sub-menu sub__menu">
//                                 <h6 className="nav__item__title">
//                                     <span>Banner</span>
//                                 </h6>
//                                 <li className="nav-item">
//                                     <Link to="banner-title" className="nav-link nav-item">Title & Banner</Link>
//                                 </li>

//                                 <h6 className="nav__item__title">
//                                     <span>Welcome To</span>
//                                 </h6>
//                                 <li className="nav-item">
//                                     <Link to="welcome-to" className="nav-link nav-item">Title, Description, Image</Link>
//                                 </li>

//                                 <h6 className="nav__item__title">
//                                     <span>Why Choose</span>
//                                 </h6>
//                                 <li className="nav-item">
//                                     <Link to="why-choose" className="nav-link nav-item">Title, Description, Image</Link>
//                                 </li>

//                                 <h6 className="nav__item__title">
//                                     <span>Letest Event</span>
//                                 </h6>
//                                 <li className="nav-item">
//                                     <Link to="letest-event" className="nav-link nav-item">Title & Description</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="letest-event-card" className="nav-link nav-item">About Card Info</Link>
//                                 </li>

//                                 <h6 className="nav__item__title">
//                                     <span>Gallery</span>
//                                 </h6>
//                                 <li className="nav-item">
//                                     <Link to="gallery-home" className="nav-link nav-item">Gallery</Link>
//                                 </li>
//                             </ul>
//                         </div>
//                     </li>
//                     <li className="nav-item">
//                         <a className="nav-link" data-toggle="collapse" href="#about-us" aria-expanded="false" aria-controls="about-us">
//                             {/* <i className="icon-grid menu-icon"></i> */}
//                             <i className="fa-regular fa-address-card"></i>
//                             <span className="menu-title menu__title">About Us</span>
//                             <i className="menu-arrow"></i>
//                         </a>
//                         <div className="collapse" id="about-us">
//                             <ul className="nav flex-column sub-menu sub__menu">
//                                 <h6 className="nav__item__title">
//                                     <span>Banner</span>
//                                 </h6>
//                                 <li className="nav-item">
//                                     <Link to="banner-title-about" className="nav-link nav-item">Title & Banner</Link>
//                                 </li>

//                                 <h6 className="nav__item__title">
//                                     <span>About Our</span>
//                                 </h6>
//                                 <li className="nav-item">
//                                     <Link to="about-our" className="nav-link nav-item">Title & Description</Link>
//                                 </li>

//                                 <h6 className="nav__item__title">
//                                     <span>Our Mission</span>
//                                 </h6>
//                                 <li className="nav-item">
//                                     <Link to="our-mission" className="nav-link nav-item">Title, Description, Image</Link>
//                                 </li>

//                                 <h6 className="nav__item__title">
//                                     <span>Our Vision</span>
//                                 </h6>
//                                 <li className="nav-item">
//                                     <Link to="our-vision" className="nav-link nav-item">Title, Description, Image</Link>
//                                 </li>


//                             </ul>
//                         </div>
//                     </li>
//                     {/* <li className="nav-item">
//                         <Link to="/about-us" className="nav-link">
//                             <i className="icon-paper menu-icon"></i>
//                             <i className="fa-regular fa-address-card"></i>
//                             <span className="menu-title menu__title">About Us</span>
//                         </Link>
//                     </li> */}
//                     <li className="nav-item">
//                         <a className="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
//                             {/* <i className="icon-layout menu-icon"></i> */}
//                             <i className="fa-solid fa-building-user"></i>
//                             <span className="menu-title menu__title">Admission</span>
//                             <i className="menu-arrow"></i>
//                         </a>
//                         <div className="collapse" id="ui-basic">
//                             <ul className="nav flex-column sub-menu sub__menu">
//                                 <li className="nav-item">
//                                     {/* <Link to="online-admission-process" className="nav-link nav-item">Online Application</Link> */}
//                                     <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
//                                         <AccordionSummary
//                                             expandIcon={<ExpandMoreIcon />}
//                                             aria-controls="panel1bh-content"
//                                             id="panel1bh-header"
//                                         >
//                                             {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>
//                                                     General settings
//                                                 </Typography> */}
//                                             <Typography sx={{ color: 'text.secondary' }}>Online App. Process</Typography>
//                                         </AccordionSummary>
//                                         <AccordionDetails>
//                                             {/* <Typography>
//                                                     Nulla facilisi.
//                                                 </Typography> */}
//                                             <h6 className="nav__item__title">
//                                                 <span>Banner</span>
//                                             </h6>
//                                             <li className="nav-item">
//                                                 <Link to="banner-title-online-app-process" className="nav-link nav-item">Title & Banner</Link>
//                                             </li>

//                                             <h6 className="nav__item__title">
//                                                 <span>Online App Process</span>
//                                             </h6>
//                                             <li className="nav-item">
//                                                 <Link to="online-application-process" className="nav-link nav-item">Description</Link>
//                                             </li>
//                                         </AccordionDetails>
//                                     </Accordion>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="admission-form" className="nav-link">Admission Form</Link>
//                                 </li>
//                             </ul>
//                         </div>
//                     </li>
//                     <li className="nav-item">
//                         <a className="nav-link" data-toggle="collapse" href="#form-elements" aria-expanded="false" aria-controls="form-elements">
//                             {/* <i className="icon-columns menu-icon"></i> */}
//                             <i className="fa-solid fa-user-graduate"></i>
//                             <span className="menu-title menu__title">Academics</span>
//                             <i className="menu-arrow"></i>
//                         </a>
//                         <div className="collapse" id="form-elements">
//                             <ul className="nav flex-column sub-menu sub__menu">
//                                 {/* <h6 className="nav__item__title">
//                                     <span>Banner</span>
//                                 </h6>
//                                 <li className="nav-item">
//                                     <Link to="banner-title-about" className="nav-link nav-item">Title & Banner</Link>
//                                 </li>

//                                 <h6 className="nav__item__title">
//                                     <span>Early Years(EY)</span>
//                                 </h6>
//                                 <li className="nav-item">
//                                     <Link to="banner-title-about" className="nav-link nav-item">Title, Description, Image</Link>
//                                 </li>

//                                 <h6 className="nav__item__title">
//                                     <span>Primary Years(EY)</span>
//                                 </h6>
//                                 <li className="nav-item">
//                                     <Link to="banner-title-about" className="nav-link nav-item">Title, Description, Image</Link>
//                                 </li>

//                                 <h6 className="nav__item__title">
//                                     <span>Middle Years(EY)</span>
//                                 </h6>
//                                 <li className="nav-item">
//                                     <Link to="banner-title-about" className="nav-link nav-item">Title, Description, Image</Link>
//                                 </li> */}



//                                 <li className="nav-item">
//                                     <div className="">
//                                         <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
//                                             <AccordionSummary
//                                                 expandIcon={<ExpandMoreIcon />}
//                                                 aria-controls="panel1bh-content"
//                                                 id="panel1bh-header"
//                                             >
//                                                 {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>
//                                                     General settings
//                                                 </Typography> */}
//                                                 <Typography sx={{ color: 'text.secondary' }}>Academic Program</Typography>
//                                             </AccordionSummary>
//                                             <AccordionDetails>
//                                                 {/* <Typography>
//                                                     Nulla facilisi.
//                                                 </Typography> */}
//                                                 <h6 className="nav__item__title">
//                                                     <span>Banner</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="banner-title-academic-program" className="nav-link nav-item">Title & Banner</Link>
//                                                 </li>

//                                                 <h6 className="nav__item__title">
//                                                     <span>Early Years(EY)</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="early-years" className="nav-link nav-item">Title, Description, Image</Link>
//                                                 </li>

//                                                 <h6 className="nav__item__title">
//                                                     <span>Primary Years(EY)</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="primary-years" className="nav-link nav-item">Title, Description, Image</Link>
//                                                 </li>

//                                                 <h6 className="nav__item__title">
//                                                     <span>Middle Years(EY)</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="middle-years" className="nav-link nav-item">Title, Description, Image</Link>
//                                                 </li>
//                                             </AccordionDetails>
//                                         </Accordion>

//                                         <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
//                                             <AccordionSummary
//                                                 expandIcon={<ExpandMoreIcon />}
//                                                 aria-controls="panel2bh-content"
//                                                 id="panel2bh-header"
//                                             >
//                                                 {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>Users</Typography> */}
//                                                 <Typography sx={{ color: 'text.secondary' }}>Curriculum Overview</Typography>
//                                             </AccordionSummary>
//                                             <AccordionDetails>
//                                                 {/* <Typography>
//                                                     Donec placerat
//                                                 </Typography> */}
//                                                 <h6 className="nav__item__title">
//                                                     <span>Banner</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="banner-title-curriculum-overview" className="nav-link nav-item">Title & Banner</Link>
//                                                 </li>

//                                                 <h6 className="nav__item__title">
//                                                     <span>Curriculum Overview</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="curriculum-overview" className="nav-link nav-item">Title & Description</Link>
//                                                 </li>

//                                                 <h6 className="nav__item__title">
//                                                     <span>Eyfs</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="eyfs" className="nav-link nav-item">Title & Description</Link>
//                                                 </li>

//                                                 <h6 className="nav__item__title">
//                                                     <span>Areas of Learning</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="areas-of-learning" className="nav-link nav-item">Title & Description</Link>
//                                                 </li>

//                                                 <h6 className="nav__item__title">
//                                                     <span>Plsc</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="plsc" className="nav-link nav-item">Title & Description</Link>
//                                                 </li>

//                                                 <h6 className="nav__item__title">
//                                                     <span>Plsc Table</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="plsc-table" className="nav-link nav-item">Title Name</Link>
//                                                 </li>
//                                             </AccordionDetails>
//                                         </Accordion>

//                                         <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
//                                             <AccordionSummary
//                                                 expandIcon={<ExpandMoreIcon />}
//                                                 aria-controls="panel3bh-content"
//                                                 id="panel3bh-header"
//                                             >
//                                                 {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>
//                                                     Advanced settings
//                                                 </Typography> */}
//                                                 <Typography sx={{ color: 'text.secondary' }}>Outdoor Classroom</Typography>
//                                             </AccordionSummary>
//                                             <AccordionDetails>
//                                                 {/* <Typography>
//                                                     Nunc vitae orci ultricies
//                                                 </Typography> */}
//                                                 <h6 className="nav__item__title">
//                                                     <span>Banner</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="banner-title-outdoor-classNameroom" className="nav-link nav-item">Title & Banner</Link>
//                                                 </li>

//                                                 <h6 className="nav__item__title">
//                                                     <span>Real World Learning</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="real-world" className="nav-link nav-item">Title & Description</Link>
//                                                 </li>

//                                                 <h6 className="nav__item__title">
//                                                     <span>Gallery</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="gallery-outdoor-classNameroom" className="nav-link nav-item">Gallery</Link>
//                                                 </li>
//                                             </AccordionDetails>
//                                         </Accordion>

//                                         <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
//                                             <AccordionSummary
//                                                 expandIcon={<ExpandMoreIcon />}
//                                                 aria-controls="panel4bh-content"
//                                                 id="panel4bh-header"
//                                             >
//                                                 <Typography sx={{ width: '33%', flexShrink: 0 }}>Extra Carricular Activities</Typography>
//                                             </AccordionSummary>
//                                             <AccordionDetails>
//                                                 {/* <Typography>
//                                                     Nunc vitae orci ultricies
//                                                 </Typography> */}
//                                                 <h6 className="nav__item__title">
//                                                     <span>Banner</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="banner-title-extra-carricular" className="nav-link nav-item">Title & Banner</Link>
//                                                 </li>

//                                                 <h6 className="nav__item__title">
//                                                     <span>School Program</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="school-program" className="nav-link nav-item">Title & Description</Link>
//                                                 </li>

//                                                 <h6 className="nav__item__title">
//                                                     <span>After School Program</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="after-school-program" className="nav-link nav-item">Title & Description</Link>
//                                                 </li>
//                                             </AccordionDetails>
//                                         </Accordion>

//                                         <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
//                                             <AccordionSummary
//                                                 expandIcon={<ExpandMoreIcon />}
//                                                 aria-controls="panel5bh-content"
//                                                 id="panel5bh-header"
//                                             >
//                                                 <Typography sx={{ width: '33%', flexShrink: 0 }}>Syllabus of RDS</Typography>
//                                             </AccordionSummary>
//                                             <AccordionDetails>
//                                                 {/* <Typography>
//                                                     Nunc vitae orci ultricies
//                                                 </Typography> */}
//                                                 <h6 className="nav__item__title">
//                                                     <span>Banner</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="banner-title-syllabus" className="nav-link nav-item">Title & Banner</Link>
//                                                 </li>

//                                                 <h6 className="nav__item__title">
//                                                     <span>Syllabus Card</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="syllabus-card" className="nav-link nav-item">Title & Syllabus</Link>
//                                                 </li>
//                                             </AccordionDetails>
//                                         </Accordion>
//                                     </div>
//                                 </li>


//                                 {/* <li className="nav-item">
//                                     <Link to="academic-program" className="nav-link">Academic Program</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="curriculum-overview" className="nav-link">Curriculum Overview</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="outdoor-classNameroom" className="nav-link">Outdoor Classroom</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="extra-carricular-activities" className="nav-link">Extra Carricular Activities</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="syllabus" className="nav-link">Syllabus of RDS</Link>
//                                 </li> */}
//                             </ul>
//                         </div>
//                     </li>
//                     <li className="nav-item">
//                         <Link to="notice" className="nav-link">
//                             <i className="icon-paper menu-icon"></i>
//                             <span className="menu-title">Notice</span>
//                         </Link>
//                     </li>

//                     <li className="nav-item">
//                         <a className="nav-link" data-toggle="collapse" href="#charts" aria-expanded="false" aria-controls="charts">
//                             <i className="icon-bar-graph menu-icon"></i>
//                             <span className="menu-title">Info Center</span>
//                             <i className="menu-arrow"></i>
//                         </a>
//                         <div className="collapse" id="charts">
//                             <ul className="nav flex-column sub-menu sub__menu">
//                                 <li className="nav-item">
//                                     <div className="">
//                                         <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
//                                             <AccordionSummary
//                                                 expandIcon={<ExpandMoreIcon />}
//                                                 aria-controls="panel1bh-content"
//                                                 id="panel1bh-header"
//                                             >
//                                                 {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>
//                                                     General settings
//                                                 </Typography> */}
//                                                 <Typography sx={{ color: 'text.secondary' }}>News & Event</Typography>
//                                             </AccordionSummary>
//                                             <AccordionDetails>
//                                                 {/* <Typography>
//                                                     Nulla facilisi.
//                                                 </Typography> */}
//                                                 <h6 className="nav__item__title">
//                                                     <span>Banner</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="banner-title-news-event" className="nav-link nav-item">Title & Banner</Link>
//                                                 </li>

//                                                 <h6 className="nav__item__title">
//                                                     <span>Letest News Events</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="letest-news-events" className="nav-link nav-item">Title & Description</Link>
//                                                 </li>
//                                                 <li className="nav-item">
//                                                     <Link to="letest-news-event-card" className="nav-link nav-item">About Card Info</Link>
//                                                 </li>

//                                                 <h6 className="nav__item__title">
//                                                     <span>Gallery</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="gallery-news-event" className="nav-link nav-item">Gallery</Link>
//                                                 </li>
//                                             </AccordionDetails>
//                                         </Accordion>

//                                         <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
//                                             <AccordionSummary
//                                                 expandIcon={<ExpandMoreIcon />}
//                                                 aria-controls="panel2bh-content"
//                                                 id="panel2bh-header"
//                                             >
//                                                 {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>Users</Typography> */}
//                                                 <Typography sx={{ color: 'text.secondary' }}>Gallery</Typography>
//                                             </AccordionSummary>
//                                             <AccordionDetails>
//                                                 {/* <Typography>
//                                                     Donec placerat
//                                                 </Typography> */}
//                                                 <h6 className="nav__item__title">
//                                                     <span>Gallery</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="gallery" className="nav-link nav-item">Gallery</Link>
//                                                 </li>
//                                             </AccordionDetails>
//                                         </Accordion>

//                                         <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
//                                             <AccordionSummary
//                                                 expandIcon={<ExpandMoreIcon />}
//                                                 aria-controls="panel3bh-content"
//                                                 id="panel3bh-header"
//                                             >
//                                                 {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>
//                                                     Advanced settings
//                                                 </Typography> */}
//                                                 <Typography sx={{ color: 'text.secondary' }}>Alumni</Typography>
//                                             </AccordionSummary>
//                                             <AccordionDetails>
//                                                 {/* <Typography>
//                                                     Nunc vitae orci ultricies
//                                                 </Typography> */}
//                                                 <h6 className="nav__item__title">
//                                                     <span>Banner</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="banner-title-alumni" className="nav-link nav-item">Title & Banner</Link>
//                                                 </li>

//                                                 <h6 className="nav__item__title">
//                                                     <span>Alumni Description</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="alumni-description" className="nav-link nav-item">Description</Link>
//                                                 </li>
//                                             </AccordionDetails>
//                                         </Accordion>

//                                         <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
//                                             <AccordionSummary
//                                                 expandIcon={<ExpandMoreIcon />}
//                                                 aria-controls="panel4bh-content"
//                                                 id="panel4bh-header"
//                                             >
//                                                 <Typography sx={{ width: '33%', flexShrink: 0 }}>School Calendar</Typography>
//                                             </AccordionSummary>
//                                             <AccordionDetails>
//                                                 {/* <Typography>
//                                                     Nunc vitae orci ultricies
//                                                 </Typography> */}
//                                                 <h6 className="nav__item__title">
//                                                     <span>Banner</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="banner-title-school-calendar" className="nav-link nav-item">Title & Banner</Link>
//                                                 </li>

//                                                 <h6 className="nav__item__title">
//                                                     <span>School Calendar Card</span>
//                                                 </h6>
//                                                 <li className="nav-item">
//                                                     <Link to="school-calendar-card" className="nav-link nav-item">Title & Description</Link>
//                                                 </li>
//                                             </AccordionDetails>
//                                         </Accordion>
//                                     </div>
//                                 </li>


//                                 <li className="nav-item">
//                                     <Link to="news-event" className="nav-link">News & Event</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="gallery" className="nav-link">Gallery</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="alumni" className="nav-link">Alumni</Link>
//                                 </li>
//                                 <li className="nav-item">
//                                     <Link to="school-calendar" className="nav-link">School Calendar</Link>
//                                 </li>
//                             </ul>
//                         </div>
//                     </li>
//                     <li className="nav-item">
//                         <Link to="contact-us" className="nav-link">
//                             {/* <i className="icon-paper menu-icon"></i> */}
//                             <i className="fa-solid fa-user-pen"></i>
//                             <span className="menu-title menu__title">Contact Us</span>
//                         </Link>
//                     </li>
//                     <li className="nav-item">
//                         <Link to="pay-online" className="nav-link">
//                             {/* <i className="icon-paper menu-icon"></i> */}
//                             <i className="fa-solid fa-money-check-dollar"></i>
//                             <span className="menu-title menu__title">Payment Online</span>
//                         </Link>
//                     </li>
//                     {/* <li className="nav-item">
//                     <a className="nav-link" data-toggle="collapse" href="#tables" aria-expanded="false" aria-controls="tables">
//                         <i className="icon-grid-2 menu-icon"></i>
//                         <span className="menu-title">Tables</span>
//                         <i className="menu-arrow"></i>
//                     </a>
//                     <div className="collapse" id="tables">
//                         <ul className="nav flex-column sub-menu">
//                             <li className="nav-item"> <a className="nav-link" href="pages/tables/basic-table.html">Basic table</a></li>
//                         </ul>
//                     </div>
//                 </li>
//                 <li className="nav-item">
//                     <a className="nav-link" data-toggle="collapse" href="#icons" aria-expanded="false" aria-controls="icons">
//                         <i className="icon-contract menu-icon"></i>
//                         <span className="menu-title">Icons</span>
//                         <i className="menu-arrow"></i>
//                     </a>
//                     <div className="collapse" id="icons">
//                         <ul className="nav flex-column sub-menu">
//                             <li className="nav-item"> <a className="nav-link" href="pages/icons/mdi.html">Mdi icons</a></li>
//                         </ul>
//                     </div>
//                 </li>
//                 <li className="nav-item">
//                     <a className="nav-link" data-toggle="collapse" href="#auth" aria-expanded="false" aria-controls="auth">
//                         <i className="icon-head menu-icon"></i>
//                         <span className="menu-title">User Pages</span>
//                         <i className="menu-arrow"></i>
//                     </a>
//                     <div className="collapse" id="auth">
//                         <ul className="nav flex-column sub-menu">
//                             <li className="nav-item"> <a className="nav-link" href="pages/samples/login.html"> Login </a></li>
//                             <li className="nav-item"> <a className="nav-link" href="pages/samples/register.html"> Register </a></li>
//                         </ul>
//                     </div>
//                 </li>
//                 <li className="nav-item">
//                     <a className="nav-link" data-toggle="collapse" href="#error" aria-expanded="false" aria-controls="error">
//                         <i className="icon-ban menu-icon"></i>
//                         <span className="menu-title">Error pages</span>
//                         <i className="menu-arrow"></i>
//                     </a>
//                     <div className="collapse" id="error">
//                         <ul className="nav flex-column sub-menu">
//                             <li className="nav-item"> <a className="nav-link" href="pages/samples/error-404.html"> 404 </a></li>
//                             <li className="nav-item"> <a className="nav-link" href="pages/samples/error-500.html"> 500 </a></li>
//                         </ul>
//                     </div>
//                 </li>
//                 <li className="nav-item">
//                     <a className="nav-link" href="pages/documentation/documentation.html">
//                         <i className="icon-paper menu-icon"></i>
//                         <span className="menu-title">Documentation</span>
//                     </a>
//                 </li> */}
//                 </ul>
//             </Scrollbars>
//         </>
//     );
// };

// export default LeftSidebar;