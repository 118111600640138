import axios from 'axios';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Link } from 'react-router-dom';


const TouristPoliceSetup = () => {

    const [sortedData, setSortedData] = useState([]);

    const fetchData = async () => {
        try {
            const res = await axios.get("/tourist-police");
            console.log("res tourist-police:", res.data.data);

            setSortedData(res.data.data);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchData();
    }, []);
    // const sortedData = [
    //     { id: 1, card_title: 'Card Title', card_desc: 'World Tourism Day 2017 presents a unique opportunity to raise awareness on', date: "12-2-2023", imageURL: about__img },
    //     { id: 2, card_title: 'Baran', card_desc: 'World Tourism Day 2017 presents a unique opportunity to raise awareness on', date: "12-2-2023", imageURL: about__img },
    //     { id: 3, card_title: 'Baran', card_desc: 'World Tourism Day 2017 presents a unique opportunity to raise awareness on', date: "12-2-2023", imageURL: about__img },
    // ];

    // delete
    const deleteTouristPoliceById = (id) => {

        const url = `/delete-tourist-police/${id}`;
        axios.delete(url)
            .then(res => {
                if (res.data.status === 200) {
                    alert("Are you deleting data in this table ?");
                    fetchData();
                } else {
                    alert("deleted failed")
                }
            })
            .catch(error => {
                console.log(error);
            })

    }



    const columns = [
        {
            title: "SL", field: "", render: (row) => <div>{row.tableData.id + 1}</div>,

            width: "20 !important",
        },
        {
            title: 'Tourist Police Category',
            field: 'tourist_police_category',

            cellStyle: {
                marginLeft: 50,
                width: 400
            },
        },
        {
            title: 'Rank Name',
            field: 'rank',

            cellStyle: {
                marginLeft: 50,
                width: 400
            },
        },
        {
            title: 'Designation',
            field: 'designation',

            cellStyle: {
                marginLeft: 50,
                width: 300
            },
        },
        {
            title: 'Email',
            field: 'email',

            cellStyle: {
                marginLeft: 50,
                width: 300
            },
        },
        {
            title: 'Contact',
            field: 'contact',

            cellStyle: {
                marginLeft: 50,
                width: 200
            },
        },
        {
            title: 'Unit Address',
            field: 'unit_address',
            render: (rowData) => (
                // <Scrollbars style={{ maxHeight: 130, minWidth: 150 }}>
                <div class="setup-card">
                    <div class="scroll-sidebar g-doc-scroll">
                        <p>{rowData.unit_address}</p>
                    </div>
                </div>
                // </Scrollbars>
            ),
        },
        {
            title: "Action",
            render: (rowData) =>
                <div>
                    {/* <Link to={`/add-home`} class="btn btn-success btn-sm action-btn"><i class="fas fa-save"></i></Link>&nbsp; */}
                    <Link to={`/dashboard/edit-tourist-police-setup/${rowData.id}`} class="btn btn-info btn-sm action-btn"><i class="fas fa-edit"></i></Link>&nbsp;
                    <button onClick={() => deleteTouristPoliceById(rowData.id)} className="btn btn-danger btn-sm action-btn"> <i class="fas fa-trash"></i> </button>
                    {/* <button className="btn btn-danger btn-sm action-btn"> <i class="fas fa-trash"></i> </button> */}
                </div>
        }
    ];

    // console.log(global.imageURL);

    return (
        <>
            <div className="container pt-4">
                <div className="card">
                    <div className="card-header">
                        <h4 className="header__text  pt-1">Tourist Police Setup
                            <Link to='/dashboard/add-tourist-police-setup' className="back__btn  float-end"> Add Tourist Police </Link>
                        </h4>
                    </div>

                    <div className="bg__from__color">
                        <div className="pt-1">
                            <div className="card-body maertial__table">
                                <MaterialTable
                                    columns={columns}
                                    data={sortedData}
                                    // isLoading={loading == true ? true : false}
                                    options={{
                                        search: true,
                                        // filtering: filter,
                                        showTitle: false,
                                        searchFieldAlignment: "left",
                                        actionsColumnIndex: -1,
                                        pageSize: 10,
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions: [5, 10, 20, 50, 100],

                                        // headerStyle: {
                                        //     padding: 6,
                                        //     textAlign: 'center',
                                        // },

                                        // cellStyle: {
                                        //     textAlign: 'center',
                                        //   },

                                        // cellStyle: {
                                        //     backgroundColor: '#039be5',
                                        //     color: '#FFF'
                                        //   },

                                    }}
                                // style={{
                                //     marginTop:'850px'
                                //       }
                                //     }

                                // other props
                                // actions={[
                                //     {
                                //         icon: 'save',
                                //         tooltip: 'Save User',
                                //         onClick: (event, rowData) => alert("You saved " + rowData.name)
                                //     },
                                //     {
                                //         icon: 'edit',
                                //         tooltip: 'Edit User',
                                //         onClick: (event, rowData) => alert("You want to edit " + rowData.name),
                                //         //   disabled: rowData.birthYear < 2000
                                //     },
                                //     rowData => ({
                                //         icon: 'delete',
                                //         tooltip: 'Delete User',
                                //         onClick: (event, rowData) => alert("You want to delete " + rowData.name),
                                //         //   disabled: rowData.birthYear < 2000
                                //     })
                                // ]}


                                // Action Overriding Preview
                                // components={{
                                //     Action: props => (
                                //       <button
                                //         onClick={(event) => props.action.onClick(event, props.data)}
                                //         color="primary"
                                //         variant="contained"
                                //         style={{textTransform: 'none'}}
                                //         size="small"
                                //       >
                                //         My Button
                                //       </button>
                                //     ),
                                //   }}

                                />


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TouristPoliceSetup;