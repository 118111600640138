import React from 'react'
import { useState } from 'react';
import "./Login.css";
import { useNavigate } from 'react-router-dom';


const Login = () => {

  const [user, setUser] = useState({
    email: "",
    password: "",
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log("name, value:", name, value);
    setUser({
      ...user,
      [name]: value,
    })
  }
  const usr = "admin@tdabbd.com"
  const pass = "admin@123"
  const navigate = useNavigate();
  const submitHandler = (e) => {
    e.preventDefault();
  
    if(usr === user.email && pass === user.password){
      navigate('/dashboard')
      localStorage.setItem("user", JSON.stringify(user));
    }else {
      alert("Invalid Credentials")
    }
    
  }
console.log(user)
  return (
    <>
      <div className="body__login">
        <div class="main__page">
          <input type="checkbox" id="chk" aria-hidden="true" />
          {/* {console.log("User: ", user)} */}
          <h4 className="admin__txt">Only Access for Administration</h4>
          {/* <div class="signup">
            <Registration />
          </div> */}

          <div class="login">
            <form className="login__form" onSubmit={submitHandler}>
              <label>Login</label>
              {/* {console.log("User: ", user)}  */}
              <div className="input__container">
                <i className="fa fa-envelope icon"></i>
                <input
                  placeholder="Email"
                  type="email"
                  name="email"
                  value={user.email}
                  onChange={handleChange}
                  required=""
                />
              </div>
              <div className="input__container">
              <i class="fas fa-unlock-keyhole icon"></i>
                <input
                  placeholder="Password"
                  type="password"
                  name="password"
                  value={user.password}
                  onChange={handleChange}
                  required=""
                />
              </div>
              <button type="submit">Login</button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login;
