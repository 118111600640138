import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { Slide } from 'react-slideshow-image';
import under_construction from "../../images/under_construction.jpg";

import thirdFive from "../../images/thirdFive.jpeg";
import thirdFive1 from "../../images/thirdFive1.jpeg";
import thirdFive2 from "../../images/thirdFive2.jpeg";
import thirdFive3 from "../../images/thirdFive3.jpeg";
import { imageURL } from '../../imageURL/imageURL';



const Gallery = () => {
    
    const [galleryImages, setGalleryImages] = useState([]);

    const fetchData = async () => {
        try {
            const res = await axios.get("/gallery");
            // console.log("gallery data: ", res.data.data);
            setGalleryImages(res.data.data);
        } catch (error) {
            console.log(error)
        }
    };
    useEffect(() => {
        fetchData();
    }, []);



    const [modalGalleryIsOpen, setModalGalleryIsOpen] = useState(false);
    function openModalGallery() {
        // alert("mmm")
        setModalGalleryIsOpen(true);
    }
    function closeModalGallery(e) {
        setModalGalleryIsOpen(false);

    }
    const responsiveSettings = [
        {
            breakpoint: 800,
            autoplay: "true",
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ];
    const [arr, setArr] = useState([]);

    return (
        <>
            <div className="container pt-2 mb-5">
                <h3 className="mb-3 text-center">
                    <span className="header_text_normal_bm header_text_bold">TDAB</span><span className="header_text_bold"> Gallery</span>
                </h3>
                <div className="row g-3 gx-md-4 pt-4">
                    {
                        galleryImages !==undefined && galleryImages?.map((item, id) => {
                            return (
                                <div key={id} className="col-md-3">
                                    <div className="img__area">
                                        <img src={`${imageURL}/images/${item?.image}`} alt="image" className="img__size" loading='lazy'/>
                                        <div className="img__overlay" type="button" onClick={() => { openModalGallery(); setArr(item) }}>
                                            More Images <br />Click Now
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                    <Modal
                        isOpen={modalGalleryIsOpen}
                        onRequestClose={closeModalGallery}
                        className="mymodal"
                        overlayClassName="myoverlay"
                        contentLabel="Example Modal"
                    >
                        <div className='card-body modal__body'>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <span className='float-end' style={{ fontSize: "20px", cursor: "pointer" }} onClick={closeModalGallery}><i className="fa-solid fa-xmark close_btn"></i></span>
                                        <h6 className="header__consultation__text">Images</h6>
                                    </div>
                                </div>
                                <Slide slidesToScroll={2} slidesToShow={1} indicators={true} responsive={responsiveSettings}>
                                    {
                                        arr.length === undefined && arr.images.map((img, id) => <div key={id} className="slide_img">
                                            <img src={`${imageURL}/images/${img?.image}`} alt="Pic" loading='lazy'/>
                                        </div>
                                        )
                                    }
                                </Slide>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
         
        </>
    );
};

export default Gallery;




// import React, { useState } from 'react';
// import Modal from 'react-modal';
// import { Slide } from 'react-slideshow-image';
// import under_construction from "../../images/under_construction.jpg";

// import thirdFive from "../../images/thirdFive.jpeg";
// import thirdFive1 from "../../images/thirdFive1.jpeg";
// import thirdFive2 from "../../images/thirdFive2.jpeg";
// import thirdFive3 from "../../images/thirdFive3.jpeg";



// const Gallery = () => {
//     const [galleryImages, setGalleryImages] = useState([
//         {
//             id: 1, imgs: under_construction, multipleImg: [
//                 { id: 1, image: thirdFive, caption: "slide" },
//                 { id: 2, image: under_construction, caption: "slide" },
//                 { id: 3, image: under_construction, caption: "slide" },
//             ]
//         },
//         {
//             id: 2, imgs: under_construction, multipleImg: [
//                 { id: 1, image: thirdFive1, caption: "slide" },
//                 { id: 2, image: under_construction, caption: "slide" },
//                 { id: 3, image: under_construction, caption: "slide" },
//             ]
//         },
//         {
//             id: 3, imgs: under_construction, multipleImg: [
//                 { id: 1, image: thirdFive2, caption: "slide" },
//                 { id: 2, image: under_construction, caption: "slide" },
//                 { id: 3, image: under_construction, caption: "slide" },
//             ]
//         },
//         {
//             id: 4, imgs: under_construction, multipleImg: [
//                 { id: 1, image: thirdFive3, caption: "slide" },
//                 { id: 2, image: under_construction, caption: "slide" },
//                 { id: 3, image: under_construction, caption: "slide" },
//             ]
//         },
//         {
//             id: 5, imgs: under_construction, multipleImg: [
//                 { id: 1, image: under_construction, caption: "slide" },
//                 { id: 2, image: under_construction, caption: "slide" },
//                 { id: 3, image: under_construction, caption: "slide" },
//             ]
//         },
//         {
//             id: 6, imgs: under_construction, multipleImg: [
//                 { id: 1, image: under_construction, caption: "slide" },
//                 { id: 2, image: under_construction, caption: "slide" },
//                 { id: 3, image: under_construction, caption: "slide" },
//             ]
//         },
//         {
//             id: 7, imgs: under_construction, multipleImg: [
//                 { id: 1, image: under_construction },
//                 { id: 2, image: under_construction },
//                 { id: 3, image: under_construction },
//             ]
//         },
//         {
//             id: 8, imgs: under_construction, multipleImg: [
//                 { id: 1, image: under_construction },
//                 { id: 2, image: under_construction },
//                 { id: 3, image: under_construction },
//             ]
//         },
//         {
//             id: 9, imgs: under_construction, multipleImg: [
//                 { id: 1, image: under_construction },
//                 { id: 2, image: under_construction },
//                 { id: 3, image: under_construction },
//             ]
//         },
//     ]);


//     const [modalGalleryIsOpen, setModalGalleryIsOpen] = useState(false);
//     function openModalGallery() {
//         // alert("mmm")
//         setModalGalleryIsOpen(true);
//     }
//     function closeModalGallery(e) {
//         setModalGalleryIsOpen(false);

//     }
//     const responsiveSettings = [
//         {
//             breakpoint: 800,
//             autoplay: "true",
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1
//             }
//         },
//         {
//             breakpoint: 500,
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1
//             }
//         }
//     ];
//     const [arr, setArr] = useState([]);

//     return (
//         <>
//             <div className="container pt-2 mb-5">
//                 <h3 className="mb-3 text-center">
//                     <span className="header_text_normal_bm header_text_bold">TDAB</span><span className="header_text_bold"> Gallery</span>
//                 </h3>
//                 <div className="row g-3 pt-4">
//                     {
//                         galleryImages.length > 0 && galleryImages.map((item, id) => {
//                             return (
//                                 <div key={id} className="col-md-3">
//                                     <div className="img__area">
//                                         <img src={item.imgs} alt="image" className="img__size" />
//                                         <div className="img__overlay" type="button" onClick={() => { openModalGallery(); setArr(item.multipleImg) }}>
//                                             More Images <br />Click Now
//                                         </div>
//                                     </div>

//                                 </div>
//                             )
//                         })
//                     }
//                     <Modal
//                         isOpen={modalGalleryIsOpen}
//                         onRequestClose={closeModalGallery}
//                         className="mymodal"
//                         overlayClassName="myoverlay"
//                         contentLabel="Example Modal"
//                     >
//                         <div className='card-body modal__body'>
//                             <div className="container">
//                                 <div className="row">
//                                     <div className="col-12">
//                                         <span className='float-end' style={{ fontSize: "20px", cursor: "pointer" }} onClick={closeModalGallery}><i className="fa-solid fa-xmark close_btn"></i></span>
//                                         <h6 className="header__consultation__text">Images</h6>
//                                     </div>
//                                 </div>
//                                 <Slide slidesToScroll={2} slidesToShow={1} indicators={true} responsive={responsiveSettings}>
//                                     {
//                                         arr.length > 0 && arr.map((item, id) => <div key={id} className="slide_img">
//                                             <img src={item.image} alt="image" />
//                                         </div>
//                                         )
//                                     }
//                                 </Slide>
//                             </div>
//                         </div>
//                     </Modal>
//                 </div>
//             </div>
         
//         </>
//     );
// };

// export default Gallery;