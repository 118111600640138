import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import axios from 'axios';

import redison_img from "../../images/redison_img.jpg";
import room_img from "../../images/room_img.jpg";
import room_img2 from "../../images/room_img2.jpg";
import { imageURL } from '../../imageURL/imageURL';
import PracticeData from './PracticeData';



const Festivals = () => {  

    const [festivalsList, setFestivalsList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');


    const fetchData = async () => {
        try {
            const res = await axios.get("/festivals");
            // console.log("festivals data: ", res.data.data);
            setFestivalsList(res.data.data);
        } catch (error) {
            console.log(error)
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const festivalsAlphabeticallyFilteringData = festivalsList.sort((a, b) => a.title_name !== b.title_name ? a.title_name < b.title_name ? -1 : 1 : 0);
    // console.log("festivalsAlphabeticallyFilteringData", festivalsAlphabeticallyFilteringData);
    // // Alternative/another way
    // const festivalsAlphabeticallyFilteringData = dataList.sort((a, b) => a.title_name.localeCompare(b.title_name, 'es', {sensitivity: 'base'}))
    // console.log("festivalsAlphabeticallyFilteringData", festivalsAlphabeticallyFilteringData);


    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        const searchList = festivalsAlphabeticallyFilteringData.filter((item) => {
            return item.title_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });
        setFestivalsList(searchList);

    }

    return (
        <div className="container mb-5">

            {/* <PracticeData /> */}

            <h3 className="mb-1 text-center">
                <span className="header_text_normal_bm">Fest</span><span className="header_text_normal">ivals</span>
            </h3>
            <div className="row g-3 pt-4">
                <div className="col-md-6">
                    <div className="row mb-3">
                        <label htmlFor="inputSearch" className="col-sm-2 col-form-label col-form-label-sm">Search</label>
                        <div className="col-sm-10">
                            <div className="accom__search" id="inputSearch">
                                <TextField
                                    fullWidth
                                    id="standard-bare"
                                    variant="outlined"
                                    placeholder="Search"
                                    title_name="search"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <IconButton>
                                                <SearchOutlined />
                                            </IconButton>
                                        ),
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-3 pt-2 d-flex justify-content-center">
                {
                    festivalsAlphabeticallyFilteringData!==undefined && festivalsAlphabeticallyFilteringData.map((item, id) => {
                        return (
                            <div key={id} className="col-md-4">
                                <div className="festivals__card">
                                    <div className="card">
                                        <div className="festivals__card__img">
                                            <img src={`${imageURL}/images/festivals_folder/${item.image}`} className="card-img-top" alt="images" loading='lazy'/>
                                        </div>
                                        <div className="card-body mb-1">
                                            <h6 className="festivals__card__title">{item.title_name}</h6>
                                            <h6 className="festivals__card__subtitle">{item.source_name}</h6>
                                            <small>{item.date}</small>
                                            <p className="pt-2">
                                                <span className="festivals__card__discription" >{item.description.substring(0, 150)}</span>
                                                <Link to={`/festivals/${item.id}`} style={{ textDecoration: "none" }} >
                                                    <span className="text-primary"> ...Read more</span>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <div className="row pt-5">
                    <div className="col-md-12">
                        <div className="btn__accom__more text-center">
                            <button type="button" className="btn btn__more">Load More</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Festivals;


// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { TextField, IconButton } from '@material-ui/core';
// import { SearchOutlined } from '@material-ui/icons';

// import redison_img from "../../images/redison_img.jpg";
// import room_img from "../../images/room_img.jpg";
// import room_img2 from "../../images/room_img2.jpg";

// const festivalsList = [
//     {
//         id: 1, image: redison_img, title_name: "Shaheed Day (21-Feb-2022)", source_name: "Daily Star",
//         date: "23-Decmber-2022",
//         description: "World Tourism Day 2017 presents a unique opportunity to raise awareness on the contribution of sustainable tourism to development among public and private sector decision-makers and the international community, while mobilising all stakeholders to work together in making tourism a catalyst for positive change, says the United Nations World Tourism Organization (UNWTO). The government and different organizations have chalked out weeklong programs to celebrate the World Tourism Day with the global theme ‘Sustainable Tourism – a Tool for Development’. Habib Ali Syed Chairman of Tourism Developers Association of Bangladesh (TDAB) also participate the rally organized by TDAB in the Capital on Wednesday along with TDAB BOD & Members. Md. Zamiul Ahmed Former Chairman of TDAB also Present at The Rally. Civil Aviation and Tourism Minister Rashed Khan Menon, Chairman of Bangladesh Parjatan Corporation (BPC) AKhtaruzzaman Khan Kabir and CEO of BTB Dr. Md Nasir Uddin and secretary to civil aviation and tourism ministry were present at the Rally at Dhaka today.",
//     },
//     {
//         id: 2, image: redison_img, title_name: "Radisson Blu Dhaka Water Garden", source_name: "Dainik Inkilab",
//         date: "23-Decmber-2022",
//         description: "World Tourism Day 2017 presents a unique opportunity to raise awareness on the contribution of sustainable tourism to development among public and private sector decision-makers and the international community, while mobilising all stakeholders to work together in making tourism a catalyst for positive change, says the United Nations World Tourism Organization (UNWTO). The government and different organizations have chalked out weeklong programs to celebrate the World Tourism Day with the global theme ‘Sustainable Tourism – a Tool for Development’. Habib Ali Syed Chairman of Tourism Developers Association of Bangladesh (TDAB) also participate the rally organized by TDAB in the Capital on Wednesday along with TDAB BOD & Members. Md. Zamiul Ahmed Former Chairman of TDAB also Present at The Rally. Civil Aviation and Tourism Minister Rashed Khan Menon, Chairman of Bangladesh Parjatan Corporation (BPC) AKhtaruzzaman Khan Kabir and CEO of BTB Dr. Md Nasir Uddin and secretary to civil aviation and tourism ministry were present at the Rally at Dhaka today.",
//     },
//     {
//         id: 3, image: redison_img, title_name: "Sheik Mujibur Birthday Celebration", source_name: "Independent",
//         date: "23-Decmber-2022",
//         description: "World Tourism Day 2017 presents a unique opportunity to raise awareness on the contribution of sustainable tourism to development among public and private sector decision-makers and the international community, while mobilising all stakeholders to work together in making tourism a catalyst for positive change, says the United Nations World Tourism Organization (UNWTO). The government and different organizations have chalked out weeklong programs to celebrate the World Tourism Day with the global theme ‘Sustainable Tourism – a Tool for Development’. Habib Ali Syed Chairman of Tourism Developers Association of Bangladesh (TDAB) also participate the rally organized by TDAB in the Capital on Wednesday along with TDAB BOD & Members. Md. Zamiul Ahmed Former Chairman of TDAB also Present at The Rally. Civil Aviation and Tourism Minister Rashed Khan Menon, Chairman of Bangladesh Parjatan Corporation (BPC) AKhtaruzzaman Khan Kabir and CEO of BTB Dr. Md Nasir Uddin and secretary to civil aviation and tourism ministry were present at the Rally at Dhaka today.",
//     },
//     {
//         id: 4, image: redison_img, title_name: "Radisson Blu Dinajpur Water Garden", source_name: "Dinajpur",
//         date: "23-Decmber-2022",
//         description: "World Tourism Day 2017 presents a unique opportunity to raise awareness on the contribution of sustainable tourism to development among public and private sector decision-makers and the international community, while mobilising all stakeholders to work together in making tourism a catalyst for positive change, says the United Nations World Tourism Organization (UNWTO). The government and different organizations have chalked out weeklong programs to celebrate the World Tourism Day with the global theme ‘Sustainable Tourism – a Tool for Development’. Habib Ali Syed Chairman of Tourism Developers Association of Bangladesh (TDAB) also participate the rally organized by TDAB in the Capital on Wednesday along with TDAB BOD & Members. Md. Zamiul Ahmed Former Chairman of TDAB also Present at The Rally. Civil Aviation and Tourism Minister Rashed Khan Menon, Chairman of Bangladesh Parjatan Corporation (BPC) AKhtaruzzaman Khan Kabir and CEO of BTB Dr. Md Nasir Uddin and secretary to civil aviation and tourism ministry were present at the Rally at Dhaka today.",
//     },
// ];

// const Festivals = () => {

   

//     const festivalsListFilteringData = festivalsList.sort((a, b) => a.title_name !== b.title_name ? a.title_name < b.title_name ? -1 : 1 : 0);
//     // console.log("festivalsListFilteringData", festivalsListFilteringData);
//     // // Alternative/another way
//     // const festivalsListFilteringData = dataList.sort((a, b) => a.title_name.localeCompare(b.title_name, 'es', {sensitivity: 'base'}))
//     // console.log("festivalsListFilteringData", festivalsListFilteringData);

//     const [festivalsListFilter, setFestivalsListFilter] = useState(festivalsListFilteringData);
//     const [searchQuery, setSearchQuery] = useState('');

//     const handleSearch = (e) => {
//         const query = e.target.value;
//         setSearchQuery(query);

//         const searchList = festivalsListFilteringData.filter((item) => {
//             return item.title_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
//         });
//         setFestivalsListFilter(searchList);

//     }

//     return (
//         <div className="container mb-5">
//             <h3 className="mb-1 text-center">
//                 <span className="header_text_normal_bm">Fest</span><span className="header_text_normal">ivals</span>
//             </h3>
//             <div className="row g-3 pt-4">
//                 <div className="col-md-6">
//                     <div className="row mb-3">
//                         <label htmlFor="inputSearch" className="col-sm-2 col-form-label col-form-label-sm">Search</label>
//                         <div className="col-sm-10">
//                             <div className="accom__search" id="inputSearch">
//                                 <TextField
//                                     fullWidth
//                                     id="standard-bare"
//                                     variant="outlined"
//                                     placeholder="Search"
//                                     title_name="search"
//                                     value={searchQuery}
//                                     onChange={handleSearch}
//                                     InputProps={{
//                                         startAdornment: (
//                                             <IconButton>
//                                                 <SearchOutlined />
//                                             </IconButton>
//                                         ),
//                                     }}

//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="row g-3 pt-2 d-flex justify-content-center">
//                 {
//                     festivalsListFilter.length > 0 && festivalsListFilter.map((item, id) => {
//                         return (
//                             <div key={id} className="col-md-4">
//                                 <div className="festivals__card">
//                                     <div className="card">
//                                         <div className="festivals__card__img">
//                                             <img src={item.image} className="card-img-top" alt="images" />
//                                         </div>
//                                         <div className="card-body mb-1">
//                                             <h6 className="festivals__card__title">{item.title_name}</h6>
//                                             <h6 className="festivals__card__subtitle">{item.source_name}</h6>
//                                             <small>{item.date}</small>
//                                             <p className="pt-2">
//                                                 <span className="festivals__card__discription" >{item.description.substring(0, 150)}</span>
//                                                 <Link to={`/festivals/${item.id}`} style={{ textDecoration: "none" }} >
//                                                     <span className="text-primary"> ...Read more</span>
//                                                 </Link>
//                                             </p>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         )
//                     })
//                 }
//                 <div className="row pt-5">
//                     <div className="col-md-12">
//                         <div className="btn__accom__more text-center">
//                             <button type="button" className="btn btn__more">Load More</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Festivals;