import React, { useRef, useState } from 'react';
import "./MemberApplication.css"
// import PDF from "./PDF.pdf";
import TDABMembershipFormUpdate2023ForWebsite from "./TDABMembershipFormUpdate2023ForWebsite.pdf";


const MembershipApplication = () => {
    // const [selectedFile, setSelectedFile] = useState();
    // const [isSelected, setIsSelected] = useState(false);

    // const changeHandler = (event) => {
    //     setSelectedFile(event.target.files[0]);
    //     setIsSelected(true);
    // };

    // const handleSubmission = () => {

    // };


    // drag state
    const [dragActive, setDragActive] = useState(false);
    // ref
    const inputRef = useRef(null);

    // handle drag events
    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile(e.dataTransfer.files);
        }
    };

    // triggers when file is selected with click
    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };


    const handleFile = (files) => {
        // alert("Number of files: " + files.length);
        console.log("Number of files: " + files.length);

    }


    return (
        <>

            <div className="container mb-5">
                {/* <h3 className="mb-3 text-center">
                    <span className="header_text_normal_bm header_text_bold">Mem</span><span className="header_text_bold">bership</span>
                    <span> Application</span>
                </h3> */}
                <center>
                    <iframe
                        src={TDABMembershipFormUpdate2023ForWebsite}
                        style={{
                            frameBorder: "0",
                            scrolling: "auto",
                            height: "520px",
                            width: "100%"
                        }}
                    >
                    </iframe>
                </center>
                {/* <div>
                    <input type="file" name="file" onChange={changeHandler} />
                    <div>
                        <button onClick={handleSubmission}>Submit</button>
                    </div>
                </div> */}


                <h5 className="text-center pt-4"><b>Above the Application form Fill up Then Upload File:</b></h5>
                <div className="page">
                    <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                        <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                            <div>
                                <p>Drag and drop your file here or</p>
                                <button className="upload-button" onClick={onButtonClick}>Upload a file</button>
                            </div>
                        </label>
                        {
                            dragActive &&
                            <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>
                        }
                    </form>
                </div>
            </div>
        </>
    );
};

export default MembershipApplication;
