import React from 'react';

const NotFoundServer = () => {
    return (
        <div className="container">
            <h2 className="text-muted text-center pt-3">Pages Not Found 404</h2>
        </div>
    );
};

export default NotFoundServer;