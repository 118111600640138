import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Scrollbars from 'react-custom-scrollbars-2';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import about__img from '../../../images/about__img.png';
import student from '../../../images/student.jpg';
import parent from '../../../images/parent.jpg';
import { imageURL } from '../../../imageURL/imageURL';

const SecretariatSetup = () => {

    const [sortedData, setSortedData] = useState([]);

    const fetchData = async () => {
        try {
            const res = await axios.get("/secretariat");
            console.log("Secretariat res: ", res.data.secretariat_data);
            setSortedData(res.data.secretariat_data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    // const sortedData = [
    //     { id: 1, name: "barn", title_name: 'Card Title', designation_name: "dfgfh", card_desc: 'World Tourism Day 2017 presents a unique opportunity to raise awareness on', date: "12-2-2023", imageURL: student },
    //     { id: 2, name: "barn", title_name: 'Baran', designation_name: "dfgfh", card_desc: 'World Tourism Day 2017 presents a unique opportunity to raise awareness on', date: "12-2-2023", imageURL: parent },
    //     { id: 3, name: "barn", title_name: 'Baran', designation_name: "dfgfh", card_desc: 'World Tourism Day 2017 presents a unique opportunity to raise awareness on', date: "12-2-2023", imageURL: parent },
    // ];


    // delete
    const deleteSecretariatById = (id) => {
        const url = `/delete-secretariat/${id}`;
        axios.delete(url)
            .then(res => {
                if (res.data.status === 200) {
                    alert("Are you deleting data in this table ?");
                    fetchData();
                } else {
                    alert("deleted failed")
                }
            })
            .catch(error => {
                console.log(error);
            })

    }
    const columns = [
        {
            title: "SL", field: "", render: (row) => <div>{row.tableData.id + 1}</div>,

            width: "20 !important",
        },
        {
            title: 'Image',
            field: 'image',
            render: (rowData) => (<img src={`${imageURL}/images/secretariat_folder/${rowData.image}`} style={{ width: "35px", height: "37px" }} />),

            cellStyle: {
                marginLeft: 50,
                paddingLeft: 30,
                width: 400
            },
        },
        {
            title: 'Title',
            field: 'title_name',

            cellStyle: {
                marginLeft: 50,
                width: 400
            },
        },
        {
            title: 'Designation',
            field: 'designation_name',

            cellStyle: {
                marginLeft: 50,
                width: 200
            },
        },
        {
            title: 'Organization Name',
            field: 'organization_name',

            cellStyle: {
                marginLeft: 50,
                width: 200
            },
        },
        {
            title: 'Position of Organization',
            field: 'position_of_org',

            cellStyle: {
                marginLeft: 50,
                width: 200
            },
        },
        {
            title: 'Web Link',
            field: 'web_link',

            cellStyle: {
                marginLeft: 50,
                width: 200
            },
        },
        {
            title: 'Email',
            field: 'email',

            cellStyle: {
                marginLeft: 50,
                width: 300
            },
        },
        {
            title: 'Address',
            field: 'address',
            render: (rowData) => (
                // <Scrollbars style={{ maxHeight: 120, maxWidth: 300 }}>
                <div class="setup-card">
                    <div class="scroll-sidebar g-doc-scroll">
                        <p>{rowData.address}</p>
                    </div>
                </div>
                // </Scrollbars>
            )
        },
        {
            title: 'Description',
            field: 'description',
            render: (rowData) => (
                // <Scrollbars style={{ maxHeight: 120, maxWidth: 300 }}>
                <div class="setup-card">
                    <div class="scroll-sidebar g-doc-scroll">
                        <p>{rowData.description}</p>
                    </div>
                </div>
                // </Scrollbars>
            )

        },
        {
            title: "Action",
            render: (rowData) =>
                <div>
                    {/* <Link to={`/add-home`} class="btn btn-success btn-sm action-btn"><i class="fas fa-save"></i></Link>&nbsp; */}
                    <Link to={`/dashboard/edit-secretariat-setup/${rowData.id}`} class="btn btn-info btn-sm action-btn"><i class="fas fa-edit"></i></Link>&nbsp;
                    <button onClick={() => deleteSecretariatById(rowData.id)} className="btn btn-danger btn-sm action-btn"> <i class="fas fa-trash"></i> </button>
                    {/* <button className="btn btn-danger btn-sm action-btn"> <i class="fas fa-trash"></i> </button> */}
                </div>
        }
    ];



    return (
        <>
            <div className="container pt-4">
                <div className="card">
                    <div className="card-header">
                        <h4 className="header__text  pt-1">Secretariat Setup
                            <Link to='/dashboard/add-secretariat-setup' className="back__btn  float-end"> Add Secretariat </Link>
                        </h4>
                    </div>

                    <div className="bg__from__color">
                        <div className="pt-1">
                            <div className="card-body maertial__table">
                                <MaterialTable
                                    columns={columns}
                                    data={sortedData}
                                    // isLoading={loading == true ? true : false}
                                    options={{
                                        search: true,
                                        // filtering: filter,
                                        showTitle: false,
                                        searchFieldAlignment: "left",
                                        actionsColumnIndex: -1,
                                        pageSize: 10,
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions: [5, 10, 20, 50, 100],

                                        // headerStyle: {
                                        //     padding: 6,
                                        //     textAlign: 'center',
                                        // },

                                        // cellStyle: {
                                        //     textAlign: 'center',
                                        //   },

                                        // cellStyle: {
                                        //     backgroundColor: '#039be5',
                                        //     color: '#FFF'
                                        //   },

                                    }}
                                // style={{
                                //     marginTop:'850px'
                                //       }
                                //     }

                                // other props
                                // actions={[
                                //     {
                                //         icon: 'save',
                                //         tooltip: 'Save User',
                                //         onClick: (event, rowData) => alert("You saved " + rowData.name)
                                //     },
                                //     {
                                //         icon: 'edit',
                                //         tooltip: 'Edit User',
                                //         onClick: (event, rowData) => alert("You want to edit " + rowData.name),
                                //         //   disabled: rowData.birthYear < 2000
                                //     },
                                //     rowData => ({
                                //         icon: 'delete',
                                //         tooltip: 'Delete User',
                                //         onClick: (event, rowData) => alert("You want to delete " + rowData.name),
                                //         //   disabled: rowData.birthYear < 2000
                                //     })
                                // ]}


                                // Action Overriding Preview
                                // components={{
                                //     Action: props => (
                                //       <button
                                //         onClick={(event) => props.action.onClick(event, props.data)}
                                //         color="primary"
                                //         variant="contained"
                                //         style={{textTransform: 'none'}}
                                //         size="small"
                                //       >
                                //         My Button
                                //       </button>
                                //     ),
                                //   }}

                                />


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SecretariatSetup;