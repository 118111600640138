import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { imageURL } from '../../../imageURL/imageURL';
// import '../dashboard/AdminDashboard.css';
import '../AppAdmin.css';
import Apd from '../boardOfDirectorsSetup/Apd';

const EditAccommodationSetup = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [editInputField, setEditInputField] = useState({
        hotel_name: "",
        place_name: "",
        description: "",
        card_image: "",
        image_list: [],
        cell: "",
        price: "",
        email: "",
        address: "",
    });


    const inputsHandler = (e) => {
        setEditInputField({ ...editInputField, [e.target.name]: e.target.value });
    };

    const fetchData = async (id) => {
        try {
            const res = await axios.get(`/edit-accommodation/${id}`);
            console.log("edit-accommodation: ", res)
            setEditInputField(res.data.data)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData(id);
    }, []);

    const handleSingleImageChange = (e) => {
        setEditInputField({
            ...editInputField,
            card_image: e.target.files[0],
        })
    };
    const handleMultipleImageChange = (e) => {
        const existingImg = [...editInputField.image_list];
        const newImg = e.target.files;
        console.log(newImg, "dd")
        Array.from(newImg).forEach(img => {
            existingImg.push(img)
        })
        setEditInputField({
            ...editInputField,
            image_list: existingImg,
        })
    };



    const updateFormData = async (e) => {
        e.preventDefault();
        console.log("updateFormData: ", editInputField);

        const formData = new FormData();
        // formData.append("card_image", typeof(editInputField.card_image) === "object" ? editInputField.card_image: editInputField.card_image.name);
        formData.append("card_image", editInputField.card_image);
        Array.from(editInputField.image_list).forEach(item => {
            formData.append("image_list", item)
        })
        formData.append("hotel_name", editInputField.hotel_name)
        formData.append("place_name", editInputField.place_name)
        formData.append("email", editInputField.email)
        formData.append("address", editInputField.address)
        formData.append("cell", editInputField.cell)
        formData.append("price", editInputField.price)
        formData.append("description", editInputField.description)

        const url = `/update-accommodation/${id}`;
        // console.log("card_image", editInputField.card_image, "card_image m", editInputField.card_image.name);
        // console.log("image_list", editInputField.image_list, "image_list m", editInputField.image_list.name);
        // console.log("update-accommodation", editInputField.image, "mmm", editInputField.image.name);
        try {
            let res = await axios.put(url, formData);
            console.log("update-accommodation: ", res)
            if (res.status == 200) {
                alert("Successfull", formData);
                setEditInputField({
                    hotel_name: "",
                    place_name: "",
                    description: "",
                    card_image: "",
                    image_list: [],
                    cell: "",
                    price: "",
                    email: "",
                    address: "",

                });
                document.getElementById("InputPhotoUpload").value = '';
                navigate("/dashboard/accommodation-setup");
            } else {
                alert("Failed")
            }
        } catch (error) {
            console.log(error);
        }
    };


    const inputPhotoUploadClose = () => {
        setEditInputField({ ...editInputField, card_image: '', image_list: [] });
        document.getElementById("InputPhotoUpload").value = ''
    };
    const multipleImageRemove = (index) => {
        setEditInputField({
            ...editInputField,
            image_list: editInputField.image_list.filter((item, i) => i !== index),
        });
    };
    console.log(editInputField, "editInputField")

    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Edit Accommodation Setup
                        <Link to='/dashboard/accommodation-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        {/* <Apd /> */}
                        <form className="mt-1" encType='multipart/form-data'
                            onSubmit={updateFormData}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">

                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InpuHotelName" className="col-form-label col-form-label-sm d-block " >
                                                Hotel Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InpuHotelName"
                                                name="hotel_name"
                                                value={editInputField.hotel_name}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputPlaceName" className="col-form-label col-form-label-sm d-block " >
                                                Place Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputPlaceName"
                                                name="place_name"
                                                value={editInputField.place_name}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputPrice" className="col-form-label col-form-label-sm d-block " >
                                                Price <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputPrice"
                                                name="price"
                                                value={editInputField.price}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputCell" className="col-form-label col-form-label-sm d-block " >
                                                Cell No. <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputCell"
                                                name="cell"
                                                value={editInputField.cell}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputEmail" className="col-form-label col-form-label-sm d-block " >
                                                Email <span className="text-danger">*</span></label>
                                            <input type="email" className="form-control form-control-sm form__ptb " id="InputEmail"
                                                name="email"
                                                value={editInputField.email}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputAddress" className="col-form-label col-form-label-sm d-block " >
                                                Address <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputAddress"
                                                name="address"
                                                value={editInputField.address}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="DescriptionFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
                                                Description <span className="text-danger">*</span></label>
                                            <textarea className="form-control" id="DescriptionFormControlTextarea1" rows="5"
                                                name="description"
                                                value={editInputField.description}
                                                onChange={inputsHandler}
                                            ></textarea>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >
                                                Card Photo Upload <span className="text-danger">*</span></label>
                                            <input className="form-control form-control-sm form__ptb" id="InputPhotoUpload"
                                                type="file"
                                                // multiple
                                                name="card_image"
                                                accept="image/*"
                                                onChange={handleSingleImageChange}
                                            />
                                            <div className="pt-4">
                                                {editInputField.card_image && (
                                                    <div className="d-flex input__img">
                                                        {
                                                            typeof (editInputField.card_image) === 'string' &&
                                                            <img src={`${imageURL}/${editInputField.card_image}`} alt="Preview" />

                                                        }
                                                        {
                                                            typeof (editInputField.card_image) === 'object' &&
                                                            <img src={URL.createObjectURL(editInputField.card_image)} alt="Preview" />
                                                        }
                                                        <span className="close__icon"
                                                            onClick={inputPhotoUploadClose}>
                                                            <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row mb-2">
                                        <div className="col-md-6">
                                            <label htmlFor="InputModalPhotoUpload" className="col-form-label col-form-label-sm d-block" >
                                                Modal Photo Upload <span className="text-danger">*</span></label>
                                            <input className="form-control form-control-sm form__ptb" id="InputPhotoUpload"
                                                type="file"
                                                multiple
                                                name="image_list"
                                                accept="image/*"
                                                onChange={handleMultipleImageChange}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="pt-4">
                                                <div className="row g-3">
                                                    {Array.from(editInputField.image_list).map((item, i) => {
                                                        return (
                                                            <div key={i} className="col-md-2">
                                                                <div className="d-flex input__img">
                                                                    {
                                                                        typeof (item) === 'string' &&
                                                                        <img src={`${imageURL}/${item}`} alt="Preview" />

                                                                    }
                                                                    {
                                                                        typeof (item) === 'object' &&
                                                                        <img src={URL.createObjectURL(item)} alt="Preview" />
                                                                    }
                                                                    <span className="close__icon"
                                                                        onClick={() => multipleImageRemove(i)}>
                                                                        <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditAccommodationSetup;

// import axios from 'axios';
// import React, { useEffect, useState } from 'react';
// import { Link, useNavigate, useParams } from 'react-router-dom';
// import { imageURL } from '../../../imageURL/imageURL';
// // import '../dashboard/AdminDashboard.css';
// import '../AppAdmin.css';
// import Apd from '../boardOfDirectorsSetup/Apd';

// const EditAccommodationSetup = () => {
//     const navigate = useNavigate();
//     const { id } = useParams();

//     const [editInputField, setEditInputField] = useState({
//         hotel_name: "",
//         place_name: "",
//         description: "",
//         card_image: "",
//         image_list: [],
//         cell: "",
//         price: "",
//         email: "",
//         address: "",
//     });


//     const inputsHandler = (e) => {
//         setEditInputField({ ...editInputField, [e.target.name]: e.target.value });
//     };

//     const fetchData = async (id) => {
//         try {
//             const res = await axios.get(`/edit-accommodation/${id}`);
//             console.log("edit-accommodation: ", res)
//             setEditInputField(res.data.data)
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     useEffect(() => {
//         fetchData(id);
//     }, []);

//     // const [imageNew, setImageNew] = useState();

//     // const handleImageChange = (e) => {
//     //     setEditInputField({
//     //         ...editInputField,
//     //         image: e.target.files[0]
//     //     });
//     //     setImageNew(e.target.files[0])
//     // };


//     const handleSingleImageChange = (e) => {
//         setEditInputField({
//             ...editInputField,
//             card_image: e.target.files[0],
//         })
//     };
//     const handleMultipleImageChange = (e) => {
//         const existingImg = [...editInputField.image_list];
//         const newImg = e.target.files;
//         console.log(newImg, "dd")
//         Array.from(newImg).forEach(img => {
//             existingImg.push(img)
//         })
//         setEditInputField({
//             ...editInputField,
//             image_list: existingImg,
//         })
//     };



//     const updateFormData = async (e) => {
//         e.preventDefault();
//         console.log("updateFormData: ", editInputField);


//         const formData = new FormData();
//         formData.append("card_image", editInputField.card_image, editInputField.card_image.name);
//         Array.from(editInputField.image_list).forEach(item => {
//             formData.append("image_list", item)
//         })
//         formData.append("hotel_name", editInputField.hotel_name)
//         formData.append("place_name", editInputField.place_name)
//         formData.append("email", editInputField.email)
//         formData.append("address", editInputField.address)
//         formData.append("cell", editInputField.cell)
//         formData.append("price", editInputField.price)
//         formData.append("description", editInputField.description)


//         // const formData = new FormData();
//         // formData.append("title_name", editInputField.title_name)
//         // formData.append("designation_name", editInputField.designation_name)
//         // formData.append("organization_name", editInputField.organization_name)
//         // formData.append("position_of_org", editInputField.position_of_org)
//         // formData.append("email", editInputField.email)
//         // formData.append("address", editInputField.address)
//         // formData.append("web_link", editInputField.web_link)
//         // formData.append("phone", editInputField.phone)
//         // formData.append("mobile", editInputField.mobile)
//         // formData.append("description", editInputField.description)
//         // formData.append("image", editInputField.image, editInputField.image.name);
//         // formData.append("image", imageNew ? imageNew : editInputField.image, editInputField.image.name);

//         const url = `/update-accommodation/${id}`;
//         console.log("card_image", editInputField.card_image, "card_image m", editInputField.card_image.name);
//         console.log("image_list", editInputField.image_list, "image_list m", editInputField.image_list.name);
//         // console.log("update-accommodation", editInputField.image, "mmm", editInputField.image.name);
//         try {
//             let res = await axios.put(url, formData);
//             console.log("update-accommodation: ", res)
//             if (res.status == 200) {
//                 alert("Successfull", formData);
//                 setEditInputField({
//                     hotel_name: "",
//                     place_name: "",
//                     description: "",
//                     card_image: "",
//                     image_list: [],
//                     cell: "",
//                     price: "",
//                     email: "",
//                     address: "",

//                 });
//                 document.getElementById("InputPhotoUpload").value = '';
//                 navigate("/dashboard/accommodation-setup");
//             } else {
//                 alert("Failed")
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     };


//     // const inputPhotoUploadClose = () => {
//     //     setEditInputField({ ...editInputField, image: '' });
//     //     document.getElementById("InputPhotoUpload").value = '';
//     //     setImageNew();
//     // };

//     const inputPhotoUploadClose = () => {
//         setEditInputField({ ...editInputField, card_image: '', image_list: [] });
//         document.getElementById("InputPhotoUpload").value = ''
//     };
//     const multipleImageRemove = (index) => {
//         setEditInputField({
//             ...editInputField,
//             image_list: editInputField.image_list.filter((item, i) => i !== index),
//         });
//     };
//     console.log(editInputField, "editInputField")

//     return (
//         <div className="container pt-4">
//             <div className="card">
//                 <div className="card-header">
//                     <h4 className="header__text  pt-1">Edit Accommodation Setup
//                         <Link to='/dashboard/accommodation-setup' className="back__btn  float-end"> Back</Link>
//                     </h4>
//                 </div>
//                 <div className="bg__from__color">
//                     <div className="card-body">
//                         {/* <Apd /> */}
//                         <form className="mt-1" encType='multipart/form-data'
//                             onSubmit={updateFormData}
//                         >
//                             <div className="row g-0">
//                                 <div className="col-md-12">

//                                     <div className="row mb-2 d-flex">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InpuHotelName" className="col-form-label col-form-label-sm d-block " >
//                                                 Hotel Name <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InpuHotelName"
//                                                 name="hotel_name"
//                                                 value={editInputField.hotel_name}
//                                                 onChange={inputsHandler}
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputPlaceName" className="col-form-label col-form-label-sm d-block " >
//                                                 Place Name <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputPlaceName"
//                                                 name="place_name"
//                                                 value={editInputField.place_name}
//                                                 onChange={inputsHandler}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div className="row mb-2 d-flex">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputPrice" className="col-form-label col-form-label-sm d-block " >
//                                                 Price <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputPrice"
//                                                 name="price"
//                                                 value={editInputField.price}
//                                                 onChange={inputsHandler}
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputCell" className="col-form-label col-form-label-sm d-block " >
//                                                 Cell No. <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputCell"
//                                                 name="cell"
//                                                 value={editInputField.cell}
//                                                 onChange={inputsHandler}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div className="row mb-2 d-flex">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputEmail" className="col-form-label col-form-label-sm d-block " >
//                                                 Email <span className="text-danger">*</span></label>
//                                             <input type="email" className="form-control form-control-sm form__ptb " id="InputEmail"
//                                                 name="email"
//                                                 value={editInputField.email}
//                                                 onChange={inputsHandler}
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputAddress" className="col-form-label col-form-label-sm d-block " >
//                                                 Address <span className="text-danger">*</span></label>
//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputAddress"
//                                                 name="address"
//                                                 value={editInputField.address}
//                                                 onChange={inputsHandler}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div className="row mb-2 d-flex">
//                                         <div className="col-md-6">
//                                             <label htmlFor="DescriptionFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
//                                                 Description <span className="text-danger">*</span></label>
//                                             <textarea className="form-control" id="DescriptionFormControlTextarea1" rows="5"
//                                                 name="description"
//                                                 value={editInputField.description}
//                                                 onChange={inputsHandler}
//                                             ></textarea>
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >
//                                                 Card Photo Upload <span className="text-danger">*</span></label>
//                                             <input className="form-control form-control-sm form__ptb" id="InputPhotoUpload"
//                                                 type="file"
//                                                 // multiple
//                                                 name="card_image"
//                                                 accept="image/*"
//                                                 onChange={handleSingleImageChange}
//                                             />
//                                             <div className="pt-4">
//                                                 {editInputField.card_image && (
//                                                     <div className="d-flex input__img">
//                                                         {
//                                                             typeof (item) === 'string' &&
//                                                             <img src={`${imageURL}/${editInputField.card_image}`} alt="Preview" />

//                                                         }
//                                                         {
//                                                             typeof (item) === 'object' &&
//                                                             <img src={URL.createObjectURL(editInputField.card_image)} alt="Preview" />
//                                                         }
//                                                         <span className="close__icon"
//                                                             onClick={inputPhotoUploadClose}>
//                                                             <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
//                                                     </div>
//                                                 )}
//                                             </div>
//                                         </div>
//                                     </div>


//                                     <div class="row mb-2">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputModalPhotoUpload" className="col-form-label col-form-label-sm d-block" >
//                                                 Modal Photo Upload <span className="text-danger">*</span></label>
//                                             <input className="form-control form-control-sm form__ptb" id="InputPhotoUpload"
//                                                 type="file"
//                                                 multiple
//                                                 name="image_list"
//                                                 accept="image/*"
//                                                 onChange={handleMultipleImageChange}
//                                             />
//                                         </div>
//                                         <div className="col-md-12">
//                                             <div className="pt-4">
//                                                 <div className="row g-3">
//                                                     {Array.from(editInputField.image_list).map((item, i) => {
//                                                         return (
//                                                             <div key={i} className="col-md-2">
//                                                                 <div className="d-flex input__img">
//                                                                     {
//                                                                         typeof (item) === 'string' &&
//                                                                         <img src={`${imageURL}/${item}`} alt="Preview" />

//                                                                     }
//                                                                     {
//                                                                         typeof (item) === 'object' &&
//                                                                         <img src={URL.createObjectURL(item)} alt="Preview" />
//                                                                     }
//                                                                     <span className="close__icon"
//                                                                         onClick={() => multipleImageRemove(i)}>
//                                                                         <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
//                                                                 </div>
//                                                             </div>
//                                                         )
//                                                     }
//                                                     )}
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>

//                                 </div>
//                             </div>
//                             <div className="row">
//                                 <div className="col-12">
//                                     <div className="float-right pt-4 mr-0">
//                                         <button type="submit" className="btn__save mx-3"
//                                         // onClick={saveMedicationChartPartOneData}
//                                         >
//                                             <b>Save</b>
//                                         </button>
//                                         <button className="btn__cancel"
//                                         // onClick={props.closeModal}
//                                         >
//                                             <b>Cancel</b>
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </form>

//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default EditAccommodationSetup;