import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { imageURL } from '../../../imageURL/imageURL';
// import '../dashboard/AdminDashboard.css';
import '../AppAdmin.css';
import Apd from '../boardOfDirectorsSetup/Apd';

const EditBoardOfDirectorsSetup = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [editInputField, setEditInputField] = useState({
        designation_category: "",
        title_name: "",
        rank_name: "",
        org_name: "",
        web_link: "",
        email: "",
        position_of_org: "",
        address: "",
        cell: "",
        mobile: "",
        description: "",
        image: null,
    });

   


    const inputsHandler = (e) => {
        setEditInputField({ ...editInputField, [e.target.name]: e.target.value });
    };

    const fetchData = async (id) => {
        try {
            const response = await axios.get(`/edit-board-of-directors/${id}`);
            console.log("response edit-board-of-directors: ", response.data.directors);
            setEditInputField(response.data.directors);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData(id);
    }, []);

    const [imageNew, setImageNew] = useState()
    const handleImageChange = (e) => {
        setEditInputField({
            ...editInputField,
            image: e.target.files[0]
        });
        setImageNew( e.target.files[0])
    };
    const updateFormData = async (e) => {
        e.preventDefault();
        console.log("updateFormData: ", editInputField);
     
        const formData = new FormData();
        formData.append("designation_category", editInputField.designation_category)
        formData.append("title_name", editInputField.title_name)
        formData.append("rank_name", editInputField.rank_name)
        formData.append("org_name", editInputField.org_name)
        formData.append("position_of_org", editInputField.position_of_org)
        formData.append("email", editInputField.email)
        formData.append("address", editInputField.address)
        formData.append("web_link", editInputField.web_link)
        formData.append("cell", editInputField.cell)
        formData.append("mobile", editInputField.mobile)
        formData.append("description", editInputField.description)
        // formData.append("image", editInputField.image, editInputField.image.name);
        formData.append("image", imageNew ? imageNew : editInputField.image);
        

        const url = `/update-board-of-directors/${id}`;
        // console.log("update-board-of-directors", editInputField.image, "mmm", editInputField.image.name);
        try {
            let response = await axios.post(url, formData);
            if (response.status == 200) {
                alert("Successfull", formData);
                setEditInputField({
                    designation_category: "",
                    title_name: "",
                    rank_name: "",
                    org_name: "",
                    web_link: "",
                    email: "",
                    position_of_org: "",
                    address: "",
                    cell: "",
                    mobile: "",
                    description: "",
                    image: null,
        
                });
                document.getElementById("InputPhotoUpload").value = '';
                navigate("/dashboard/our-board-members-setup");
            } else {
                alert("Failed")
            }
        } catch (error) {
            console.log(error);
        }
    };


    const inputPhotoUploadClose = () => {
        setEditInputField({ ...editInputField, image: '' });
        document.getElementById("InputPhotoUpload").value = '';
        setImageNew();
       
    };



    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Edit Board Of Directors Setup
                        <Link to='/dashboard/our-board-members-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        {/* <Apd /> */}
                        <form className="mt-1" encType='multipart/form-data'
                            onSubmit={updateFormData}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <div className="row g-3 mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="inputDesignationCategory" className="col-form-label col-form-label-sm d-block">
                                                Designation Category <span className="text-danger">*</span></label>
                                            <select id="inputDesignationCategory" className="form-select form-select-sm"
                                                name="designation_category" 
                                                value={editInputField?.designation_category}
                                                onChange={inputsHandler}>
                                                <option value="">Please select a Designation</option>
                                                <option value="Chairman">Chairman</option>
                                                <option value="SeniorViceChairman">Senior Vice Chairman</option>
                                                <option value="ViceChairman">Vice Chairman</option>
                                                <option value="Director">Director</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputDesignationName" className="col-form-label col-form-label-sm d-block " >
                                                Designation Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputDesignationName"
                                                name="rank_name"
                                                value={editInputField.rank_name}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputBannerTitle" className="col-form-label col-form-label-sm d-block " >
                                                Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputBannerTitle"
                                                name="title_name"
                                                value={editInputField.title_name}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputOrganizationName" className="col-form-label col-form-label-sm d-block " >
                                                Organization Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputOrganizationName"
                                                name="org_name"
                                                value={editInputField.org_name}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputPositionofOrganization" className="col-form-label col-form-label-sm d-block " >
                                                Position of Organization <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputPositionofOrganization"
                                                name="position_of_org"
                                                value={editInputField.position_of_org}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputWebLink" className="col-form-label col-form-label-sm d-block " >
                                                Web Link </label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputWebLink"
                                                name="web_link"
                                                value={editInputField.web_link}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputEmail" className="col-form-label col-form-label-sm d-block " >
                                                Email <span className="text-danger">*</span></label>
                                            <input type="email" className="form-control form-control-sm form__ptb " id="InputEmail"
                                                name="email"
                                                value={editInputField.email}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputAddress" className="col-form-label col-form-label-sm d-block " >
                                                Address <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputAddress"
                                                name="address"
                                                value={editInputField.address}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputCell" className="col-form-label col-form-label-sm d-block " >
                                                Cell </label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputCell"
                                                name="cell"
                                                value={editInputField.cell}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputMobileNo" className="col-form-label col-form-label-sm d-block " >
                                                Mobile No <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputMobileNo"
                                                name="mobile"
                                                value={editInputField.mobile}
                                                onChange={inputsHandler}
                                            />
                                        </div>

                                    </div>
                                    <div class="row mb-2">
                                        <div className="col-md-6">
                                            <label htmlFor="DescriptionFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
                                                Description <span className="text-danger">*</span></label>
                                            <textarea className="form-control" id="DescriptionFormControlTextarea1" rows="3"
                                                name="description"
                                                value={editInputField.description}
                                                onChange={inputsHandler}
                                            ></textarea>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >
                                                Card Photo Upload <span className="text-danger">*</span></label>
                                            <input className="form-control form-control-sm form__ptb" id="InputPhotoUpload"
                                                type="file"
                                                // multiple
                                                name="image"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                            />
                                            <div className="pt-4">
                                                {imageNew && (
                                                    <div className="d-flex input__img">
                                                       <img src={URL.createObjectURL(imageNew)} alt="Preview" />
                                                        <span className="close__icon"
                                                            onClick={inputPhotoUploadClose}>
                                                            <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                    </div>
                                                )}
                                                {!imageNew &&  editInputField.image && (
                                                    <div className="d-flex input__img">
                                                        <img src={ `${imageURL}/images/board_of_directors/${editInputField.image}`} alt="Preview" />
                                                        <span className="close__icon"
                                                            onClick={inputPhotoUploadClose}>
                                                            <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditBoardOfDirectorsSetup;



// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';


// const EditBoardOfDirectorsSetup = () => {

//     const categories = [
//         {
//             id: 1,
//             name: 'Home Page',
//             subcategories: [
//                 { name: 'Our Events' },
//                 { name: 'Tourism News' },
//                 { name: 'TDAB Gallery' }
//             ]
//         },
//         {
//             id: 2,
//             name: 'All Airlines',
//             subcategories: [
//                 { name: 'Banner' },
//             ]
//         },
//         {
//             id: 3,
//             name: 'Travel Agent & Tour Operator',
//             subcategories: [
//                 { name: 'Banner' },
//             ]
//         },
//         {
//             id: 4,
//             name: 'Contact',
//             subcategories: [
//                 { name: 'Banner' },
//             ]
//         }
//     ];

//     const [selectedCategory, setSelectedCategory] = useState(null);
//     const [selectedSubcategory, setSelectedSubcategory] = useState([]);



//     return (
//         <div className="container pt-4">
//             <div className="card">
//                 <div className="card-header">
//                     <h4 className="header__text  pt-1">Edit Board Of Directors Setup
//                         <Link to='/dashboard/our-board-members-setup' className="back__btn  float-end"> Back</Link>
//                     </h4>
//                 </div>
//                 <div className="bg__from__color">
//                     <div className="card-body">
//                         <form className="mt-1">
//                             <div className="row g-0">
//                                 <div className="col-md-12">
//                                     <div className="row g-3 mb-3">
//                                         <div className="col-md-6">
//                                             <label htmlFor="inputPageName" className="col-form-label col-form-label-sm d-block">
//                                                 Page Name <span className="text-danger">*</span></label>
//                                             <select
//                                                 className="form-select form-select-sm" id="inputPageName"
//                                                 value={selectedCategory ? selectedCategory?.name : ''}
//                                                 onChange={e => {
//                                                     setSelectedCategory(categories[e.target.value]);
//                                                 }}
//                                             >
//                                                 <option value='' disabled>
//                                                     Choose a Page Name
//                                                 </option>
//                                                 {
//                                                     categories.map((category, index) => (
//                                                         <option key={category.name} value={index}>
//                                                             {category.name}
//                                                         </option>
//                                                     ))
//                                                 }
//                                             </select>
//                                         </div>

//                                         <div className="col-md-6">
//                                             <label htmlFor="inputSectionName" className="col-form-label col-form-label-sm d-block">
//                                                 Section Name <span className="text-danger">*</span></label>

//                                             <select
//                                                 className="form-select form-select-sm" id="inputSectionName"
//                                                 value={selectedSubcategory ? selectedSubcategory?.name : ''}
//                                                 onChange={e => setSelectedSubcategory(selectedCategory?.subcategories[e.target.value])}
//                                             >
//                                                 <option selected value='' disabled>
//                                                     Choose a Section Name
//                                                 </option>
//                                                 {
//                                                     selectedCategory?.subcategories?.map((subcategory, index) => (
//                                                         <option key={subcategory.name} value={index}>
//                                                             {subcategory.name}
//                                                         </option>
//                                                     ))
//                                                 }
//                                             </select>

//                                         </div>
//                                     </div>
//                                     <div className="row mb-2 d-flex">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputBannerTitle" className="col-form-label col-form-label-sm d-block " >
//                                                 Title Name <span className="text-danger">*</span></label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputBannerTitle"
//                                                 name="max__prn__dose"
//                                             // value={regularMedicineEight.max__prn__dose}
//                                             // onChange={inputRegularMedicineChangeEight}
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputSubTitle" className="col-form-label col-form-label-sm d-block " >Sub Title Name</label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputSubTitle"
//                                                 name="max__prn__dose"
//                                             // value={regularMedicineEight.max__prn__dose}
//                                             // onChange={inputRegularMedicineChangeEight}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div className="row mb-2 d-flex">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputDate" className="col-form-label col-form-label-sm d-block " >
//                                                 Date <span className="text-danger">*</span></label>

//                                             <input type="date" className="form-control form-control-sm form__ptb " id="InputDate"
//                                                 name="max__prn__dose"
//                                             // value={regularMedicineEight.max__prn__dose}
//                                             // onChange={inputRegularMedicineChangeEight}
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >
//                                                 Photo Upload <span className="text-danger">*</span></label>
//                                             <input className="form-control form-control-sm form__ptb " id="InputPhotoUpload"
//                                                 type="file"
//                                                 multiple
//                                                 name="max__prn__dose"
//                                             // value={regularMedicineEight.max__prn__dose}
//                                             // onChange={inputRegularMedicineChangeEight}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div class="row mb-2">
//                                         <div className="col-12">
//                                             <label htmlFor="DescriptionFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
//                                                 Description <span className="text-danger">*</span></label>
//                                             <textarea className="form-control" id="DescriptionFormControlTextarea1" rows="3"></textarea>
//                                         </div>
//                                     </div>

//                                 </div>
//                             </div>
//                             <div className="row">
//                                 <div className="col-12">
//                                     <div className="float-right pt-4 mr-0">
//                                         <button className="btn__save mx-3"
//                                         // onClick={saveMedicationChartPartOneData}
//                                         >
//                                             <b>Save</b>
//                                         </button>
//                                         <button className="btn__cancel"
//                                         // onClick={props.closeModal}
//                                         >
//                                             <b>Cancel</b>
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </form>

//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default EditBoardOfDirectorsSetup;