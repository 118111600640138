import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import '../dashboard/AdminDashboard.css';
import '../AppAdmin.css';

const AddAccommodationSetup = () => {

    const [inputField, setInputField] = useState({
        hotel_name: "",
        place_name: "",
        description: "",
        card_image: "",
        image_list: [],
        cell: "",
        price: "",
        email: "",
        address: "",
    });


    const handleSingleImageChange = (e) => {
        setInputField({
            ...inputField,
            card_image: e.target.files[0],
        })
    };
    const handleMultipleImageChange = (e) => {
        setInputField({
            ...inputField,
            image_list: e.target.files,
        })
    };

    const inputsHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        console.log("submitHandler: ", inputField);

        const formData = new FormData();
        formData.append("card_image", inputField.card_image, inputField.card_image.name);
        Array.from(inputField.image_list).forEach(item => {
            formData.append("image_list", item)
        })
        formData.append("hotel_name", inputField.hotel_name)
        formData.append("place_name", inputField.place_name)
        formData.append("email", inputField.email)
        formData.append("address", inputField.address)
        formData.append("cell", inputField.cell)
        formData.append("price", inputField.price)
        formData.append("description", inputField.description)

        const url = "/add-accommodation";
        console.log("card_image", inputField.card_image, "card_image m", inputField.card_image.name);
        console.log("image_list", inputField.image_list, "image_list m", inputField.image_list.name);
        try {
            let response = await axios.post(url, formData);
            if (response.status == 201) {
                alert("Are you Added  toData", formData);
                setInputField({
                    hotel_name: "",
                    place_name: "",
                    description: "",
                    card_image: "",
                    image_list: [],
                    cell: "",
                    price: "",
                    email: "",
                    address: "",
                })
                document.getElementById("InputPhotoUpload").value = '';
            } else {
                alert("Failed")
            }
        } catch (error) {
            console.log(error);
        }

    };

    const inputPhotoUploadClose = () => {
        setInputField({ ...inputField, card_image: '', image_list: [] });
        document.getElementById("InputPhotoUpload").value = ''
    };



    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Add Accommodation Setup
                        <Link to='/dashboard/accommodation-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        {/* <Apd /> */}
                        <form className="mt-1" encType='multipart/form-data'
                            onSubmit={submitHandler}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">

                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InpuHotelName" className="col-form-label col-form-label-sm d-block " >
                                                Hotel Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InpuHotelName"
                                                name="hotel_name"
                                                value={inputField.hotel_name}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputPlaceName" className="col-form-label col-form-label-sm d-block " >
                                                Place Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputPlaceName"
                                                name="place_name"
                                                value={inputField.place_name}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputPrice" className="col-form-label col-form-label-sm d-block " >
                                                Price <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputPrice"
                                                name="price"
                                                value={inputField.price}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputCell" className="col-form-label col-form-label-sm d-block " >
                                                Cell No. <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputCell"
                                                name="cell"
                                                value={inputField.cell}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputEmail" className="col-form-label col-form-label-sm d-block " >
                                                Email <span className="text-danger">*</span></label>
                                            <input type="email" className="form-control form-control-sm form__ptb " id="InputEmail"
                                                name="email"
                                                value={inputField.email}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputAddress" className="col-form-label col-form-label-sm d-block " >
                                                Address <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputAddress"
                                                name="address"
                                                value={inputField.address}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="DescriptionFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
                                                Description <span className="text-danger">*</span></label>
                                            <textarea className="form-control" id="DescriptionFormControlTextarea1" rows="5"
                                                name="description"
                                                value={inputField.description}
                                                onChange={inputsHandler}
                                            ></textarea>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >
                                                Card Photo Upload <span className="text-danger">*</span></label>
                                            <input className="form-control form-control-sm form__ptb" id="InputPhotoUpload"
                                                type="file"
                                                // multiple
                                                name="card_image"
                                                accept="image/*"
                                                onChange={handleSingleImageChange}
                                            />
                                            <div className="pt-4">
                                                {inputField.card_image && (
                                                    <div className="d-flex input__img">
                                                        <img src={URL.createObjectURL(inputField.card_image)} alt="Preview" />

                                                        <span className="close__icon"
                                                            onClick={inputPhotoUploadClose}>
                                                            <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-2">
                                        <div className="col-md-6">
                                            <label htmlFor="InputModalPhotoUpload" className="col-form-label col-form-label-sm d-block" >
                                                Modal Photo Upload <span className="text-danger">*</span></label>
                                            <input className="form-control form-control-sm form__ptb" id="InputPhotoUpload"
                                                type="file"
                                                multiple
                                                name="image_list"
                                                accept="image/*"
                                                onChange={handleMultipleImageChange}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="pt-4">
                                                <div className="row g-3">
                                                    {Array.from(inputField.image_list).map(item => {
                                                        return (
                                                            <div className="col-md-2">
                                                                <div className="d-flex input__img">
                                                                    <img src={item ? URL.createObjectURL(item) : null} alt="Preview" />
                                                                    <span className="close__icon"
                                                                        onClick={inputPhotoUploadClose}>
                                                                        <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAccommodationSetup;