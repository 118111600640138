import React, { useEffect, useState } from 'react';
import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import Flag_of_Australia from "../../images/Flag_of_Australia.webp";
import Flag_of_Iran from "../../images/Flag_of_Iran.png";
import axios from 'axios';
import { imageURL } from '../../imageURL/imageURL';


const Embassies = () => {


    const [embassiesData, setEmbassiesData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const embassiesArrFilteringData = embassiesData.sort((a, b) => a.title_name !== b.title_name ? a.title_name < b.title_name ? -1 : 1 : 0);
    // console.log("embassiesArringData", embassiesArringData);
    // // Alternative/another way
    // const embassiesArringData = dataList.sort((a, b) => a.title_name.localeCompare(b.title_name, 'es', {sensitivity: 'base'}))
    // console.log("embassiesArringData", embassiesArringData);


    const fetchData = async () => {
        try {
            const res = await axios.get("/embassies-in-bangladesh");
            console.log("embassies-in-bangladesh res: ", res);
            setEmbassiesData(res.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    //Search list of objects
    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        const searchList = embassiesData.filter((item) => {
            return item.title_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });

        setEmbassiesData(searchList);
    };

    return (
        <div className="container mb-5">
            <h3 className="mb-1 text-center">
                <span className="header_text_normal_bm">Emba</span><span className="header_text_normal">ssies </span>
                <span>in Bangladesh</span>
            </h3>
            <div className="row g-3 pt-4">
                <div className="col-md-6">
                    <div className="row mb-3">
                        <label htmlFor="inputSearch" className="col-sm-2 col-form-label col-form-label-sm">Search</label>
                        <div className="col-sm-10">
                            <div className="accom__search" id="inputSearch">
                                <TextField
                                    fullWidth
                                    id="standard-bare"
                                    variant="outlined"
                                    placeholder="Search"
                                    title_name="search"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <IconButton>
                                                <SearchOutlined />
                                            </IconButton>
                                        ),
                                    }}

                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-3 gx-md-4 pt-2 d-flex justify-content-center">
                {
                    embassiesData.length > 0 && embassiesData.map((embaItem, id) => {
                        return (
                            <div key={id} className="col-md-3">
                                <a href={`${embaItem.web_link}`} target="_blank" style={{ textDecoration: "none" }}>
                                    <div className="embassies__card">
                                        <div className="card">
                                            <h5 className="embassies__title pt-3">{embaItem.title_name}</h5>
                                            <div className="embassies__img pt-1">
                                                <img src={`${imageURL}/images/embassies_folder/${embaItem.image}`} alt="image" />
                                            </div>
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <p class="embassies__desc me-2"><i class="fas fa-house-chimney"></i></p>
                                                    <p className="embassies__desc">{embaItem.address}</p>
                                                </div>
                                                <div className="d-flex">
                                                    <p class="embassies__desc me-2"><i class="fas fa-phone"></i></p>
                                                    <p class="embassies__desc">{embaItem.phone}</p>
                                                </div>
                                                <div className="d-flex">
                                                    <p class="embassies__desc me-2"><i class="fas fa-fax"></i></p>
                                                    <p class="embassies__desc">{embaItem.fax}</p>
                                                </div>
                                                <div className="d-flex">
                                                    <p class="embassies__desc me-2"><i class="fas fa-envelope"></i></p>
                                                    <p class="embassies__desc">{embaItem.email}</p>
                                                </div>
                                                <div className="d-flex">
                                                    <p class="embassies__desc me-2"><i class="fa-solid fa-clock"></i></p>
                                                    <p class="embassies__desc">{embaItem.office_hour}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default Embassies;
// import React, { useEffect, useState } from 'react';
// import { TextField, IconButton } from '@material-ui/core';
// import { SearchOutlined } from '@material-ui/icons';
// import Flag_of_Australia from "../../images/Flag_of_Australia.webp";
// import Flag_of_Iran from "../../images/Flag_of_Iran.png";
// import axios from 'axios';

// const embassiesArr = [
//     {
//         id: 1, title_name: "Republic of Ausralia", image: Flag_of_Australia, web_link: "https://en.wikipedia.org/wiki/Australia",
//         address: "House No 10, Road No.9, Baridhara, Gulshan", phone: "+880 2 989 5994", fax: "+880 2 988 4767", email: "dhaka@mfa.af", office_hour: "Sunday to Thursday: 9:00 am - 4:00 pm",
//         desc: "Free Republic of Liberland is a sovereign state located between Croatia and Serbia. It is a 7 km² land referred to as “Gornja Siga”. The founder and elected head of state is President Vit Jedlicka. Liberland is a constitutional republic with elements of direct democracy. The state has two Vice Presidents and 5 Ministers. The language is English. The Liberland merit is the currency of Liberland. The country’s motto is: To live and let live."
//     },
//     {
//         id: 2, title_name: "Republic of Iran", image: Flag_of_Iran, web_link: "https://en.wikipedia.org/wiki/Iran",
//         address: "House No 10, Road No.9, Baridhara, Gulshan", phone: "+880 2 989 5994", fax: "+880 2 988 4767", email: "dhaka@mfa.af", office_hour: "Sunday to Thursday: 9:00 am - 4:00 pm",
//         desc: "Free Republic of Liberland is a sovereign state located between Croatia and Serbia. It is a 7 km² land referred to as “Gornja Siga”. The founder and elected head of state is President Vit Jedlicka. Liberland is a constitutional republic with elements of direct democracy. The state has two Vice Presidents and 5 Ministers. The language is English. The Liberland merit is the currency of Liberland. The country’s motto is: To live and let live."
//     },
//     {
//         id: 3, title_name: "Republic of Ausralia", image: Flag_of_Australia, web_link: "https://en.wikipedia.org/wiki/Australia",
//         address: "House No 10, Road No.9, Baridhara, Gulshan", phone: "+880 2 989 5994", fax: "+880 2 988 4767", email: "dhaka@mfa.af", office_hour: "Sunday to Thursday: 9:00 am - 4:00 pm",
//         desc: "Free Republic of Liberland is a sovereign state located between Croatia and Serbia. It is a 7 km² land referred to as “Gornja Siga”. The founder and elected head of state is President Vit Jedlicka. Liberland is a constitutional republic with elements of direct democracy. The state has two Vice Presidents and 5 Ministers. The language is English. The Liberland merit is the currency of Liberland. The country’s motto is: To live and let live."
//     },
//     {
//         id: 4, title_name: "Republic of Iran", image: Flag_of_Iran, web_link: "https://en.wikipedia.org/wiki/Iran",
//         address: "House No 10, Road No.9, Baridhara, Gulshan", phone: "+880 2 989 5994", fax: "+880 2 988 4767", email: "dhaka@mfa.af", office_hour: "Sunday to Thursday: 9:00 am - 4:00 pm",
//         desc: "Free Republic of Liberland is a sovereign state located between Croatia and Serbia. It is a 7 km² land referred to as “Gornja Siga”. The founder and elected head of state is President Vit Jedlicka. Liberland is a constitutional republic with elements of direct democracy. The state has two Vice Presidents and 5 Ministers. The language is English. The Liberland merit is the currency of Liberland. The country’s motto is: To live and let live."
//     },
// ];


// const Embassies = () => {

//     const embassiesArrFilteringData = embassiesArr.sort((a, b) => a.title_name !== b.title_name ? a.title_name < b.title_name ? -1 : 1 : 0);
//     // console.log("embassiesArrFilteringData", embassiesArrFilteringData);
//     // // Alternative/another way
//     // const embassiesArrFilteringData = dataList.sort((a, b) => a.title_name.localeCompare(b.title_name, 'es', {sensitivity: 'base'}))
//     // console.log("embassiesArrFilteringData", embassiesArrFilteringData);

//     const [embassiesDataFilter, setEmbassiesDataFilter] = useState(embassiesArrFilteringData);
//     const [searchQuery, setSearchQuery] = useState('');



//     //Search list of objects
//     const handleSearch = (event) => {
//         const query = event.target.value;
//         setSearchQuery(query);

//         const searchList = embassiesArrFilteringData.filter((item) => {
//             return item.title_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
//         });

//         setEmbassiesDataFilter(searchList);
//     };

//     return (
//         <div className="container mb-5">
//             <h3 className="mb-1 text-center">
//                 <span className="header_text_normal_bm">Emba</span><span className="header_text_normal">ssies </span>
//                 <span>in Bangladesh</span>
//             </h3>
//             <div className="row g-3 pt-4">
//                 <div className="col-md-6">
//                     <div className="row mb-3">
//                         <label htmlFor="inputSearch" className="col-sm-2 col-form-label col-form-label-sm">Search</label>
//                         <div className="col-sm-10">
//                             <div className="accom__search" id="inputSearch">
//                                 <TextField
//                                     fullWidth
//                                     id="standard-bare"
//                                     variant="outlined"
//                                     placeholder="Search"
//                                     title_name="search"
//                                     value={searchQuery}
//                                     onChange={handleSearch}
//                                     InputProps={{
//                                         startAdornment: (
//                                             <IconButton>
//                                                 <SearchOutlined />
//                                             </IconButton>
//                                         ),
//                                     }}

//                                 />

//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="row g-3 gx-md-4 pt-2 d-flex justify-content-center">
//                 {
//                     embassiesDataFilter.length > 0 && embassiesDataFilter.map((embaItem, id) => {
//                         return (
//                             <div key={id} className="col-md-3">
//                                 <a href={embaItem.web_link} target="_blank" style={{ textDecoration: "none" }}>
//                                     <div className="embassies__card">
//                                         <div className="card">
//                                             <h5 className="embassies__title pt-3">{embaItem.title_name}</h5>
//                                             <div className="embassies__img pt-1">
//                                                 <img src={embaItem.image} alt="image" />
//                                             </div>
//                                             <div className="card-body">
//                                                 <div className="d-flex">
//                                                     <p class="embassies__desc me-2"><i class="fas fa-house-chimney"></i></p>
//                                                     <p className="embassies__desc">{embaItem.address}</p>
//                                                 </div>
//                                                 <div className="d-flex">
//                                                     <p class="embassies__desc me-2"><i class="fas fa-phone"></i></p>
//                                                     <p class="embassies__desc">{embaItem.phone}</p>
//                                                 </div>
//                                                 <div className="d-flex">
//                                                     <p class="embassies__desc me-2"><i class="fas fa-fax"></i></p>
//                                                     <p class="embassies__desc">{embaItem.fax}</p>
//                                                 </div>
//                                                 <div className="d-flex">
//                                                     <p class="embassies__desc me-2"><i class="fas fa-envelope"></i></p>
//                                                     <p class="embassies__desc">{embaItem.email}</p>
//                                                 </div>
//                                                 <div className="d-flex">
//                                                     <p class="embassies__desc me-2"><i class="fa-solid fa-clock"></i></p>
//                                                     <p class="embassies__desc">{embaItem.office_hour}</p>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </a>
//                             </div>
//                         )
//                     })
//                 }
//             </div>
//         </div>
//     );
// };

// export default Embassies;