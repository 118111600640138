import React, { useState } from 'react';

const MembershipCriteriaFees = () => {
    const [membershipCriteria, setMembershipCriteria] = useState([
        { id: 1, title_name: "Trade License with renewal/Payment page" },
        { id: 2, title_name: "Proprietorship/Partnership deed/company memorandum." },
        { id: 3, title_name: "TIN Certificate" },
        { id: 4, title_name: "Recent Photograph (2 copy)." },
        { id: 5, title_name: "National ID card Copy." },
        { id: 6, title_name: "Pay order or cheque in favor of 'Tourism Developers Association of Bangladesh.'" },
        { id: 7, title_name: "Visiting Card." },
    ]);

    const [generalMemberListData, setGeneralMemberListData] = useState([
        { id: 1, title: "Application Fee", price: "1,000/-" },
        { id: 2, title: "New Membership Fee", price: "15,000/-" },
        { id: 3, title: "Yearly Fee", price: "5,000/-" },
        // { id: 5, total_title: "Total", total_price: "16,000/-" },
    ]);
    const [associateMemberListData, setAssociateMemberListData] = useState([
        { id: 1, title: "Application Fee", price: "1,000/-" },
        { id: 2, title: "New Membership Fee", price: "10,000/-" },
        { id: 3, title: "Yearly Fee", price: "3,000/-" },
        // { id: 5, total_title: "Total", total_price: "16,000/-" },
    ]);


    return (
        <div className="container pt-2 mb-5">
            <h3 className="mb-3 text-center">
                <span className="header_text_normal_bm header_text_bold">Mem</span><span className="header_text_bold">bership </span>
                <span> Criteria & Fees</span>
            </h3>
            <div className="row g-3">
                <div className="col-12">
                    <div className="">
                        <h5 className="mb-2">
                            <span className="header_text_bold"><b>Membership Criteria:</b></span>
                        </h5>
                        <p className="header__description">
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maxime, rerum praesentium placeat incidunt voluptatum sint dolorem! Consequuntur perspiciatis, quas vel ab, facilis dolorem aliquam dolore sint doloribus beatae accusamus accusantium.
                        </p>
                        <p className="header__description">
                            Please read this article to know if you or your organization are complying with the provisions of the Digital Commerce Guidelines 2021 of the Government of the People's Republic of Bangladesh. And apply for membership by updating issues.
                        </p>
                    </div>
                    <div className="">
                        <h5 className="mb-2">
                            <span className="header_text_bold"><b>Required Documents with Application:</b></span>
                        </h5>
                        <p className="header__description">
                            For membership, enrollment businesses need to submit the following documents:
                        </p>
                        <ul className="mem__criteria">
                            {
                                membershipCriteria.length > 0 && membershipCriteria.map((item, id) => {
                                    return (
                                        <li>
                                            <span className="text-danger">*</span>
                                            <span className="ms-2"><b>{item.title_name}:</b> </span>
                                            <span><small> Scan copy of color image</small></span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="pt-2">
                        <h5 className="mb-2">
                            <span className="header_text_bold"><b>General Membership Fee:</b></span>
                        </h5>
                        <p className="header__description">
                            Interested membership applicants will have to pay
                        </p>
                        <table class="table table-bordered membership__table">
                            <thead>
                                <tr>
                                    <th className="th__sr">Type</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    generalMemberListData.length !== 0 && generalMemberListData.map((item, id) => {
                                        return (
                                            <tr key={id} className="tr__body">
                                                <td>{item.title} <b>{item.total_title}</b></td>
                                                <td>Tk. {item.price} <b>{item.total_price}</b></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="">
                        <h5 className="mb-2">
                            <span className="header_text_bold"><b>Associate Membership Fee:</b></span>
                        </h5>
                        <p className="header__description">
                            Interested membership applicants will have to pay
                        </p>
                        <table class="table table-bordered membership__table">
                            <thead>
                                <tr>
                                    <th className="th__sr">Type</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    associateMemberListData.length !== 0 && associateMemberListData.map((item, id) => {
                                        return (
                                            <tr key={id} className="tr__body">
                                                <td>{item.title} <b>{item.total_title}</b></td>
                                                <td>Tk. {item.price} <b>{item.total_price}</b></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MembershipCriteriaFees;