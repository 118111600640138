import React from 'react';

const GoogleMap = () => {
    return (
        <>
            <h3 className="text-center">
                <span className="header_text_normal_bm">Ban</span><span className="header_text_normal">gladesh</span>
                <span className="header_text_bold"> Map</span>
            </h3>
            <div className="pt-3 mb-2">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3723282.3211463373!2d87.86778575450046!3d24.31101101034337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b8b087026b81%3A0x8fa563bbdd5904c2!2sDhaka%2C%20Bangladesh!5e0!3m2!1sen!2snl!4v1670929856973!5m2!1sen!2snl" width="600" height="450" style={{ width: "100%", height: "400px", frameborder: "0" }} allowfullscreen="" loading="lazy"></iframe>
            </div>
        </>
    );
};

export default GoogleMap;