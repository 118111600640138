import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { imageURL } from '../../../imageURL/imageURL';
// import '../dashboard/AdminDashboard.css';
import '../AppAdmin.css';

const EditAssociateMemberListSetup = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [editInputField, setEditInputField] = useState({
        associate_member_list: "",
        title_name: "",
        organization_name: "",
        address: "",
        card_link: "",
        cell: "",
        email: "",
        description: "",
        tel: "",
        web_link: "",
        image: null,
    });

    const inputsChange = (e) => {
        setEditInputField({ ...editInputField, [e.target.name]: e.target.value });

    };

    const fetchData = async (id) => {
        try {
            const response = await axios.get(`/edit-member-list-associate/${id}`);
            console.log("response:", response.data.data);
            setEditInputField(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchData(id);
    }, []);

    const [imageNew, setImageNew] = useState();

    const handleImageChange = (e) => {
        setEditInputField({
            ...editInputField,
            image: e.target.files[0]
        });
        setImageNew(e.target.files[0])
    };

    const updateFormData = async (e) => {
        e.preventDefault();
        console.log("updateFormData: ", editInputField);
        const formData = new FormData();
        formData.append("associate_member_list", editInputField.associate_member_list)
        formData.append("title_name", editInputField.title_name)
        formData.append("organization_name", editInputField.organization_name)
        formData.append("email", editInputField.email)
        formData.append("address", editInputField.address)
        formData.append("card_link", editInputField.card_link)
        formData.append("web_link", editInputField.web_link)
        formData.append("cell", editInputField.cell)
        formData.append("tel", editInputField.tel)
        formData.append("description", editInputField.description)
        // formData.append("image", editInputField.image, editInputField.image.name);
        // formData.append("image", imageNew ? imageNew : editInputField.image, editInputField.image.name);
        formData.append("image", imageNew ? imageNew : editInputField.image);

        const url = `/update-member-list-associate/${id}`;
        // console.log("update-member-list-associate", editInputField.image, "mmm", editInputField.image.name);
        try {
            let res = await axios.post(url, formData);
            console.log("update-member-list-associate: ", res)
            if (res.status == 200) {
                alert("Successfull", formData);
                setEditInputField({
                    associate_member_list: "",
                    title_name: "",
                    organization_name: "",
                    address: "",
                    card_link: "",
                    cell: "",
                    email: "",
                    description: "",
                    tel: "",
                    web_link: "",
                    image: null,

                });
                document.getElementById("InputPhotoUpload").value = '';
                navigate("/dashboard/member-list-associate-setup");
            } else {
                alert("Failed")
            }
        } catch (error) {
            console.log(error);
        }

        // const url = `/update-member-list-associate/${id}`;
        // axios.put(url, editInputField)
        //     .then(response => {
        //         console.log(response, "edit dff");
        //         if (response.status === 200) {
        //             alert("Are you Edit  to Data");
        //             setEditInputField({
        //                 title_name: "",
        //                 organization_name: "",
        //                 address: "",
        //                 card_link: "",
        //                 cell: "",
        //                 email: "",
        //                 fax: "",
        //                 tel: "",
        //                 web_link: "",
        //             });
        //         } else {
        //             alert("Failed")
        //         }
        //         navigate("/dashboard/member-list-associate-setup");
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     })
    };


    const inputPhotoUploadClose = () => {
        setEditInputField({ ...editInputField, image: '' });
        document.getElementById("InputPhotoUpload").value = '';
        setImageNew();

    };



    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Edit Associate Member List Setup
                        <Link to='/dashboard/member-list-associate-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        <form className="mt-1"
                            onSubmit={updateFormData}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <div className="row g-3 mb-3">
                                       
                                        <div className="col-md-6">
                                            <label htmlFor="inputGeneralMemberList" className="col-form-label col-form-label-sm d-block">
                                            Associate Member List Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm form__ptb " id="inputGeneralMemberList"
                                                name="associate_member_list"
                                                value={editInputField.associate_member_list}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                       
                                        <div className="col-md-6">
                                            <label htmlFor="inputTitleName" className="col-form-label col-form-label-sm d-block">
                                                Title Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm form__ptb " id="inputTitleName"
                                                name="title_name"
                                                value={editInputField.title_name}
                                                onChange={inputsChange}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="inputOrganizationName" className="col-form-label col-form-label-sm d-block">
                                                Organization Name <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="inputOrganizationName"
                                                name="organization_name"
                                                value={editInputField.organization_name}
                                                onChange={inputsChange}
                                            />

                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputCardLink" className="col-form-label col-form-label-sm d-block " >
                                                Card Link <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputCardLink"
                                                name="card_link"
                                                value={editInputField.card_link}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputWebLink" className="col-form-label col-form-label-sm d-block " >
                                                Web Link <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputWebLink"
                                                name="web_link"
                                                value={editInputField.web_link}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputEmail" className="col-form-label col-form-label-sm d-block " >
                                                Email <span className="text-danger">*</span></label>

                                            <input type="email" className="form-control form-control-sm form__ptb " id="InputEmail"
                                                name="email"
                                                value={editInputField.email}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputAddress" className="col-form-label col-form-label-sm d-block " >
                                                Address <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputAddress"
                                                name="address"
                                                value={editInputField.address}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div className="col-md-6">
                                            <label htmlFor="InputCell" className="col-form-label col-form-label-sm d-block " >Cell <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputCell"
                                                name="cell"
                                                value={editInputField.cell}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputTelephone" className="col-form-label col-form-label-sm d-block " >
                                                Telephone <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputTelephone"
                                                name="tel"
                                                value={editInputField.tel}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div className="col-md-6">
                                            <label htmlFor="DescriptionFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
                                                Description <span className="text-danger">*</span></label>
                                            <textarea className="form-control" id="DescriptionFormControlTextarea1" rows="3"
                                                name="description"
                                                value={editInputField.description}
                                                onChange={inputsChange}
                                            ></textarea>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >
                                                Card Photo Upload <span className="text-danger">*</span></label>
                                            <input className="form-control form-control-sm form__ptb" id="InputPhotoUpload"
                                                type="file"
                                                // multiple
                                                name="image"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                            />
                                            <div className="pt-4">
                                                {imageNew && (
                                                    <div className="d-flex input__img">
                                                        <img src={URL.createObjectURL(imageNew)} alt="Preview" />
                                                        <span className="close__icon"
                                                            onClick={inputPhotoUploadClose}>
                                                            <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                    </div>
                                                )}
                                                {!imageNew && editInputField.image && (
                                                    <div className="d-flex input__img">
                                                        <img src={`${imageURL}/images/associate_member_list_folder/${editInputField.image}`} alt="Preview" />
                                                        <span className="close__icon"
                                                            onClick={inputPhotoUploadClose}>
                                                            <i style={{ cursor: "pointer" }} className="fa-solid fa-xmark"></i></span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditAssociateMemberListSetup;

