import React, { useEffect, useState } from 'react';
import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import axios from 'axios';

import TravelAgen_img from "../../images/TravelAgen_img.jpg";


const TravelAgentTourOperator = () => {

    const fetchData = async () => {
        try {
            const res = await axios.get("/travel-agent");
            console.log("travel-agent res: ", res);
            setTravelAgent(res.data.data);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);


    const [travelAgent, setTravelAgent] = useState([]);
    const [searchQuery, setSearchQuery] =useState('');

    const travelAgentAlphabeticallyFilterData = travelAgent.sort((a, b) => a.title_name !== b.title_name ? a.title_name < b.title_name ? -1 : 1 : 0);
    // console.log( "travelAgentFilter", travelAgentAlphabeticallyFilterData);
    
    const handleSearch =(e)=> {
        const query = e.target.value;
        setSearchQuery(query);

        const queryList = travelAgent.filter((item) =>{
            return item.title_name.toLowerCase().indexOf(query.toLowerCase()) !==-1
        });
        setTravelAgent(queryList);
    }

    return (
        <div className="bg__body">
            <div className="banner_img">
                <img src={TravelAgen_img} className="img-fluid" alt="TravelAgen_img" />
                <div className="banner_img_text">
                    <h3 className="text-white">Travel Agent & Tour Operator</h3>
                </div>
            </div>
            <div className="container mb-5 pt-4" >
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-end">
                            <div className="row mb-3">
                                <label htmlFor="inputSearch" className="col-sm-3 col-form-label col-form-label-sm">Search</label>
                                <div className="col-sm-9">
                                    <div className="rb_search" id="inputSearch">
                                        <TextField
                                            fullWidth
                                            id="standard-bare"
                                            variant="outlined"
                                            placeholder="Search"
                                            title_name="search"
                                            value={searchQuery}
                                            onChange={handleSearch}
                                            InputProps={{
                                                startAdornment: (
                                                    <IconButton>
                                                        <SearchOutlined />
                                                    </IconButton>
                                                ),
                                            }}

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row g-3 gx-md-4 d-flex justify-content-center">
                    {
                        travelAgentAlphabeticallyFilterData!==undefined && travelAgentAlphabeticallyFilterData.map((item, id) => {
                            return (
                                <div key={id} className="col-md-4">
                                    <div class="police__card">
                                        <div class="card">
                                            <h6 class="police__title pt-3 mx-2">{item.title_name}</h6>
                                            <div class="card-body">
                                                <div className="d-flex">
                                                    <p class="police__desc me-2"><i class="fas fa-house-chimney"></i></p>
                                                    <p class="police__desc">{item.address}</p>
                                                </div>
                                                <div className="d-flex">
                                                    <p class="police__desc me-2"><i class="fas fa-phone"></i></p>
                                                    <p class="police__desc">{item.phone}</p>
                                                </div>
                                                <div className="d-flex">
                                                    <p class="police__desc me-2"><i className="fa-solid fa-envelope"></i></p>
                                                    <p class="police__desc">{item.email}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <div className="pt-4 d-flex justify-content-center mb-1">
                            <nav className="allroom_pagination" aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item px-1">
                                        <a className="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true">&lt;</span>
                                        </a>
                                    </li>
                                    <li className="page-item px-1"><a className="page-link link_no" href="#">1</a></li>
                                    <li className="page-item px-1"><a className="page-link link_no" href="#">2</a></li>
                                    <li className="page-item px-1"><a className="page-link link_no" href="#">...</a></li>
                                    <li className="page-item px-1"><a className="page-link link_no" href="#">3</a></li>
                                    <li className="page-item px-1">
                                        <a className="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true">&gt;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TravelAgentTourOperator;



// import React, { useState } from 'react';
// import { TextField, IconButton } from '@material-ui/core';
// import { SearchOutlined } from '@material-ui/icons';

// import TravelAgen_img from "../../images/TravelAgen_img.jpg";

// const travelAgent = [
//     {
//         id: 1, title_name: "Air Arabia Airlines", phone: "013374754", email: "hfffj232@gmail.com", address: "Jahangir Tower (ground Floor)10, KaziNazrul lslam Avenue, Karwan Bazar, Dhaka"
//     },
//     {
//         id: 2, title_name: "Iran Airlines", phone: "013374754", email: "hfffj232@gmail.com", address: "Jahangir Tower (ground Floor)10, KaziNazrul lslam Avenue, Karwan Bazar, Dhaka"
//     },
//     {
//         id: 3, title_name: "Qater Airlines", phone: "013374754", email: "hfffj232@gmail.com", address: "Jahangir Tower (ground Floor)10, KaziNazrul lslam Avenue, Karwan Bazar, Dhaka"
//     },
//     {
//         id: 4, title_name: "Bangladesh Airlines", phone: "013374754", email: "hfffj232@gmail.com", address: "Jahangir Tower (ground Floor)10, KaziNazrul lslam Avenue, Karwan Bazar, Dhaka"
//     },
//     {
//         id: 5, title_name: "Pakistan Airlines", phone: "013374754", email: "hfffj232@gmail.com", address: "Jahangir Tower (ground Floor)10, KaziNazrul lslam Avenue, Karwan Bazar, Dhaka"
//     },
// ];




// const TravelAgentTourOperator = () => {
//     const travelAgentAlphabeticallyFilterData = travelAgent.sort((a, b) => a.title_name !== b.title_name ? a.title_name < b.title_name ? -1 : 1 : 0);
//     // console.log( "travelAgentFilter", travelAgentAlphabeticallyFilterData);
    
//     const [travelAgentFilter, setTravelAgentFilter] = useState(travelAgentAlphabeticallyFilterData);
//     const [searchQuery, setSearchQuery] =useState('');

//     const handleSearch =(e)=> {
//         const query = e.target.value;
//         setSearchQuery(query);

//         const queryList = travelAgent.filter((item) =>{
//             return item.title_name.toLowerCase().indexOf(query.toLowerCase()) !==-1
//         });
//         setTravelAgentFilter(queryList);
//     }

//     return (
//         <div className="bg__body">
//             <div className="banner_img">
//                 <img src={TravelAgen_img} className="img-fluid" alt="TravelAgen_img" />
//                 <div className="banner_img_text">
//                     <h3 className="text-white">Travel Agent & Tour Operator</h3>
//                 </div>
//             </div>
//             <div className="container mb-5 pt-4" >
//                 <div className="row">
//                     <div className="col-12">
//                         <div className="d-flex justify-content-end">
//                             <div className="row mb-3">
//                                 <label htmlFor="inputSearch" className="col-sm-3 col-form-label col-form-label-sm">Search</label>
//                                 <div className="col-sm-9">
//                                     <div className="rb_search" id="inputSearch">
//                                         <TextField
//                                             fullWidth
//                                             id="standard-bare"
//                                             variant="outlined"
//                                             placeholder="Search"
//                                             title_name="search"
//                                             value={searchQuery}
//                                             onChange={handleSearch}
//                                             InputProps={{
//                                                 startAdornment: (
//                                                     <IconButton>
//                                                         <SearchOutlined />
//                                                     </IconButton>
//                                                 ),
//                                             }}

//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <div className="row g-3 gx-md-4 d-flex justify-content-center">
//                     {
//                         travelAgentFilter.length > 0 && travelAgentFilter.map((item, id) => {
//                             return (
//                                 <div key={id} className="col-md-3">
//                                     <div class="police__card">
//                                         <div class="card">
//                                             <h6 class="police__title pt-3 mx-2">{item.title_name}</h6>
//                                             <div class="card-body">
//                                                 <div className="d-flex">
//                                                     <p class="police__desc me-2"><i class="fas fa-house-chimney"></i></p>
//                                                     <p class="police__desc">{item.address}</p>
//                                                 </div>
//                                                 <div className="d-flex">
//                                                     <p class="police__desc me-2"><i class="fas fa-phone"></i></p>
//                                                     <p class="police__desc">{item.phone}</p>
//                                                 </div>
//                                                 <div className="d-flex">
//                                                     <p class="police__desc me-2"><i className="fa-solid fa-envelope"></i></p>
//                                                     <p class="police__desc">{item.email}</p>
//                                                 </div>

//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             )
//                         })
//                     }
//                 </div>

//                 <div className="row pt-5">
//                     <div className="col-12">
//                         <div className="pt-4 d-flex justify-content-center mb-1">
//                             <nav className="allroom_pagination" aria-label="Page navigation example">
//                                 <ul className="pagination">
//                                     <li className="page-item px-1">
//                                         <a className="page-link" href="#" aria-label="Previous">
//                                             <span aria-hidden="true">&lt;</span>
//                                         </a>
//                                     </li>
//                                     <li className="page-item px-1"><a className="page-link link_no" href="#">1</a></li>
//                                     <li className="page-item px-1"><a className="page-link link_no" href="#">2</a></li>
//                                     <li className="page-item px-1"><a className="page-link link_no" href="#">...</a></li>
//                                     <li className="page-item px-1"><a className="page-link link_no" href="#">3</a></li>
//                                     <li className="page-item px-1">
//                                         <a className="page-link" href="#" aria-label="Next">
//                                             <span aria-hidden="true">&gt;</span>
//                                         </a>
//                                     </li>
//                                 </ul>
//                             </nav>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default TravelAgentTourOperator;
