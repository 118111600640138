import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const EditSlidebarImage = () => {

    const categories = [
        {
            id: 1,
            category_name: 'Home Page',
            subcategories: [
                { subcategory_name: 'Our Events' },
                { subcategory_name: 'Tourism News' },
                { subcategory_name: 'TDAB Gallery' }
            ]
        },
        {
            id: 2,
            category_name: 'All Airlines',
            subcategories: [
                { subcategory_name: 'Banner Airlines' },
                { subcategory_name: 'dg Airlines' },
            ]
        },
        {
            id: 3,
            category_name: 'Travel Agent & Tour Operator',
            subcategories: [
                { subcategory_name: 'Banner Travel' },
                { subcategory_name: 'B Travel' },
            ]
        },
        {
            id: 4,
            category_name: 'Contact',
            subcategories: [
                { subcategory_name: 'Banner Contact' },
                { subcategory_name: 'ad Contact' },
            ]
        }
    ];

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState([]);

    const [inputField, setInputField] = useState({
        banner_title: "",
        banner_image: "",
    });

    const inputsHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value })
    }

    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Edit Slidebar & Image
                        <Link to='/dashboard/slidebar-image-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        <form className="mt-1">
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <div className="row g-3 mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="inputPageName" className="col-form-label col-form-label-sm d-block">
                                                Page Name <span className="text-danger">*</span></label>
                                            <select
                                                className="form-select form-select-sm" id="inputPageName"
                                                value={selectedCategory ? selectedCategory?.name : ''}
                                                onChange={e => {
                                                    setSelectedCategory(categories[e.target.value]);
                                                }}
                                            >
                                                <option value='' disabled>
                                                    Choose a Page Name
                                                </option>
                                                {
                                                    categories.map((category, index) => (
                                                        <option key={category.category_name} value={index}>
                                                            {category.category_name}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="inputSectionName" className="col-form-label col-form-label-sm d-block">
                                                Section Name <span className="text-danger">*</span></label>

                                            <select
                                                className="form-select form-select-sm" id="inputSectionName"
                                                value={selectedSubcategory ? selectedSubcategory?.name : ''}
                                                onChange={e => setSelectedSubcategory(selectedCategory?.subcategories[e.target.value])}
                                            >
                                                <option selected value='' disabled>
                                                    Choose a Section Name
                                                </option>
                                                {
                                                    selectedCategory?.subcategories?.map((subcategory, index) => (
                                                        <option key={subcategory.subcategory_name} value={index}>
                                                            {subcategory.subcategory_name}
                                                        </option>
                                                    ))
                                                }
                                            </select>

                                        </div>
                                    </div>
                                    <div className="row mb-2 d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor="InputBannerTitle" className="col-form-label col-form-label-sm d-block " >Banner Title</label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputBannerTitle"
                                                name="banner_title"
                                                value={inputField.banner_title}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >Banner Photo Upload</label>
                                            <input className="form-control form-control-sm form__ptb " id="InputPhotoUpload"
                                                type="file"
                                                // multiple
                                                name="banner_image"
                                                value={inputField.banner_image}
                                                onChange={inputsHandler}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditSlidebarImage;


// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// // import Select from 'react-select';


// const EditSlidebarImage = () => {

//     const categories = [
//         {
//             name: 'Home Page',
//             subcategories: [
//                 { name: 'Our Events' },
//                 { name: 'Tourism News' },
//                 { name: 'TDAB Gallery' }
//             ]
//         },
//         {
//             name: 'All Airlines',
//             subcategories: [
//                 { name: 'Banner' },
//             ]
//         },
//         {
//             name: 'Travel Agent & Tour Operator',
//             subcategories: [
//                 { name: 'Banner' },
//             ]
//         },
//         {
//             name: 'Contact',
//             subcategories: [
//                 { name: 'Banner' },
//             ]
//         }
//     ];

//     const [selectedCategory, setSelectedCategory] = useState(null);
//     const [selectedSubcategory, setSelectedSubcategory] = useState([]);

//     return (
//         <div className="container pt-4">
//             <div className="card">
//                 <div className="card-header">
//                     <h4 className="header__text  pt-1">Edit Slidebar & Image
//                         <Link to='/dashboard/slidebar-image-setup' className="back__btn  float-end"> Back</Link>
//                     </h4>
//                 </div>
//                 <div className="bg__from__color">
//                     <div className="card-body">
//                         <form className="mt-1">
//                             <div className="row g-0">
//                                 <div className="col-md-12">
//                                     <div className="row g-3 mb-3">
//                                         <div className="col-md-6">
//                                             <label htmlFor="inputPageName" className="col-form-label col-form-label-sm d-block">
//                                                 Page Name <span className="text-danger">*</span></label>
//                                             <select
//                                                 className="form-select form-select-sm" id="inputPageName"
//                                                 value={selectedCategory ? selectedCategory?.name : ''}
//                                                 onChange={e => {
//                                                     setSelectedCategory(categories[e.target.value]);
//                                                 }}
//                                             >
//                                                 <option value='' disabled>
//                                                     Choose a Page Name
//                                                 </option>
//                                                 {
//                                                     categories.map((category, index) => (
//                                                         <option key={category.name} value={index}>
//                                                             {category.name}
//                                                         </option>
//                                                     ))
//                                                 }
//                                             </select>
//                                         </div>

//                                         <div className="col-md-6">
//                                             <label htmlFor="inputSectionName" className="col-form-label col-form-label-sm d-block">
//                                                 Section Name <span className="text-danger">*</span></label>

//                                             <select
//                                                 className="form-select form-select-sm" id="inputSectionName"
//                                                 value={selectedSubcategory ? selectedSubcategory?.name : ''}
//                                                 onChange={e => setSelectedSubcategory(selectedCategory?.subcategories[e.target.value])}
//                                             >
//                                                 <option selected value='' disabled>
//                                                     Choose a Section Name
//                                                 </option>
//                                                 {
//                                                     selectedCategory?.subcategories?.map((subcategory, index) => (
//                                                         <option key={subcategory.name} value={index}>
//                                                             {subcategory.name}
//                                                         </option>
//                                                     ))
//                                                 }
//                                             </select>

//                                         </div>
//                                     </div>
//                                     <div className="row mb-2 d-flex">
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputBannerTitle" className="col-form-label col-form-label-sm d-block " >Banner Title</label>

//                                             <input type="text" className="form-control form-control-sm form__ptb " id="InputBannerTitle"
//                                                 name="max__prn__dose"
//                                             // value={regularMedicineEight.max__prn__dose}
//                                             // onChange={inputRegularMedicineChangeEight}
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <label htmlFor="InputPhotoUpload" className="col-form-label col-form-label-sm d-block" >Banner Photo Upload</label>
//                                             <input className="form-control form-control-sm form__ptb " id="InputPhotoUpload"
//                                                 type="file"
//                                                 multiple
//                                                 name="max__prn__dose"
//                                             // value={regularMedicineEight.max__prn__dose}
//                                             // onChange={inputRegularMedicineChangeEight}
//                                             />
//                                         </div>
//                                     </div>

//                                 </div>
//                             </div>
//                             <div className="row">
//                                 <div className="col-12">
//                                     <div className="float-right pt-4 mr-0">
//                                         <button className="btn__save mx-3"
//                                         // onClick={saveMedicationChartPartOneData}
//                                         >
//                                             <b>Save</b>
//                                         </button>
//                                         <button className="btn__cancel"
//                                         // onClick={props.closeModal}
//                                         >
//                                             <b>Cancel</b>
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </form>

//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default EditSlidebarImage;
