
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Slide } from 'react-slideshow-image';
import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { imageURL } from '../../imageURL/imageURL';



const MinistryInfo = () => {

    const [ministryInfo, setMinistryInfo] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const fetchData = async () => {
        try {
            const res = await axios.get("/ministry-info");
            console.log("ministry-info res: ", res);
            setMinistryInfo(res.data.data);
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        const searchList = ministryInfo.filter((item) => {
            return item.title_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });
        setMinistryInfo(searchList);
    }



    return (
        <div className="container pt-3 mb-5">
            <h3 className="mb-3 text-center">
                <span className="header_text_normal_bm header_text_bold">Mini</span><span className="header_text_bold">stry Information</span>
            </h3>

            <div className="row g-3 pt-4">
                <div className="col-md-6">
                    <div className="row mb-3">
                        <label htmlFor="inputSearch" className="col-sm-2 col-form-label col-form-label-sm">Search</label>
                        <div className="col-sm-10">
                            <div className="accom__search" id="inputSearch">
                                <TextField
                                    fullWidth
                                    id="standard-bare"
                                    variant="outlined"
                                    placeholder="Search"
                                    title_name="search"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <IconButton>
                                                <SearchOutlined />
                                            </IconButton>
                                        ),
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-3 pt-2 d-flex justify-content-center">
                {
                    ministryInfo.length > 0 && ministryInfo.map((item, id) => {
                        return (
                            <div key={id} className="col-md-3">
                                <div className="memberlist__card">
                                    <div className="memberlist__card__img">
                                        <img src={`${imageURL}/images/ministry_info_folder/${item.image}`} className="card-img-top" alt="image" loading="lazy" />
                                    </div>
                                    <div className="card-body p-2 pt-3">
                                        <h6 className="memberlist__card__title">{item.title_name}</h6>
                                        <p className="memberlist__card__discription">{item.designation}</p>
                                    </div>
                                    <div className="memberlist__card__overlay" type="button">
                                        <a href={`${item.web_link}`} target="_blank" style={{ textDecoration: "none" }}>
                                            View Details <br />Click Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                <div className="row pt-5">
                    <div className="col-md-12">
                        <div className="btn__accom__more text-center">
                            <button type="button" className="btn btn__more">Load More</button>
                        </div>
                    </div>
                </div>


            </div >
        </div >
    );
};

export default MinistryInfo;


// import React, { useState } from 'react';
// import Modal from 'react-modal';
// import { Slide } from 'react-slideshow-image';
// import { TextField, IconButton } from '@material-ui/core';
// import { SearchOutlined } from '@material-ui/icons';

// import BogaVhobon__img from '../../images/BogaVhobon__img.jpg';
// import captionTea__img from '../../images/captionTea__img.jpg';
// import Cabinet_img from '../../images/Cabinet_img.jpg';
// import Cabinet2_img from '../../images/Cabinet2_img.jpg';
// import PMO_img from '../../images/PMO_img.jpg';
// import caption__img from '../../images/caption__img.jpg';
// import { Link } from 'react-router-dom';


// const ministryInfo = [
//     {
//         image: BogaVhobon__img, id: 1,
//         title_name: "President’s Office", card_link: "http://www.bangabhaban.gov.bd/",
//         desc: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",

//     },
//     {
//         image: PMO_img, id: 2,
//         title_name: "Prime Minister’s Office", card_link: "https://pmo.gov.bd/",
//         desc: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",

//     },
//     {
//         image: Cabinet2_img, id: 3,
//         title_name: "Cabinet Division", card_link: "http://www.cabinet.gov.bd/",
//         desc: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",

//     },
//     {
//         image: Cabinet_img, id: 4,
//         title_name: "Ministry of Establishment", card_link: "http://www.bhcdelhi.org/govt.htm",
//         desc: "Combine nature, culture, and heritage on this five day tour of Bangladesh. Guides will lead you on this adventure as you deepen your appreciation of Bangladeshi traditions. Start in the city of Dhaka to appreciate the nation's monuments to political life, such as the national museum and the local university. Tour highlights include Srimangal - the tea capital of Bangladesh, and Lawachara National Park, where you'll explore tropical rainforests.",

//     },

// ]


// const MinistryInfo = () => {

//     const [ministryInfoFilter, setMinistryInfoFilter] = useState(ministryInfo);
//     const [searchQuery, setSearchQuery] = useState('')

//     const handleSearch = (e) => {
//         const query = e.target.value;
//         setSearchQuery(query);

//         const searchList = ministryInfo.filter((item) => {
//             return item.title_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
//         });
//         setMinistryInfoFilter(searchList);
//     }



//     return (
//         <div className="container pt-2 mb-5">
//             <h3 className="mb-3 text-center">
//                 <span className="header_text_normal_bm header_text_bold">Mini</span><span className="header_text_bold">stry Information</span>
//             </h3>

//             <div className="row g-3 pt-4">
//                 <div className="col-md-6">
//                     <div className="row mb-3">
//                         <label htmlFor="inputSearch" className="col-sm-2 col-form-label col-form-label-sm">Search</label>
//                         <div className="col-sm-10">
//                             <div className="accom__search" id="inputSearch">
//                                 <TextField
//                                     fullWidth
//                                     id="standard-bare"
//                                     variant="outlined"
//                                     placeholder="Search"
//                                     title_name="search"
//                                     value={searchQuery}
//                                     onChange={handleSearch}
//                                     InputProps={{
//                                         startAdornment: (
//                                             <IconButton>
//                                                 <SearchOutlined />
//                                             </IconButton>
//                                         ),
//                                     }}

//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="row g-3 pt-2 d-flex justify-content-center">
//                 {
//                     ministryInfoFilter.length > 0 && ministryInfoFilter.map((item, id) => {
//                         return (
//                             <div key={id} className="col-md-3">
//                                 <div className="memberlist__card">
//                                     <div className="memberlist__card__img">
//                                         <img src={item.image} className="card-img-top" alt="image" loading="lazy" />
//                                     </div>
//                                     <div className="card-body p-2 pt-3">
//                                         <h6 className="memberlist__card__title">{item.title_name}</h6>
//                                         <p className="memberlist__card__discription">{item.designation}</p>
//                                     </div>
//                                     <div className="memberlist__card__overlay" type="button">
//                                         <a href={item.card_link} target="_blank" style={{ textDecoration: "none" }}>
//                                             View Details <br />Click Now
//                                         </a>
//                                     </div>
//                                 </div>
//                             </div>
//                         )
//                     })
//                 }

//                 <div className="row pt-5">
//                     <div className="col-md-12">
//                         <div className="btn__accom__more text-center">
//                             <button type="button" className="btn btn__more">Load More</button>
//                         </div>
//                     </div>
//                 </div>


//             </div >
//         </div >
//     );
// };

// export default MinistryInfo;



// import React, { useState } from 'react';


// const MinistryInfo = () => {
//     const [memberListData, setmemberListData] = useState([
//         { id: 1, title: "President’s Office", card_link: "http://www.bangabhaban.gov.bd/", },
//         { id: 2, title: "Prime Minister’s Office", card_link: "http://www.pmo.gov.bd/",},
//         { id: 3, title: "Cabinet Division", card_link: "http://www.cabinet.gov.bd/",},
//         { id: 4, title: "Ministry of Establishment", card_link: "http://www.bhcdelhi.org/govt.htm", },
//     ]);

//     return (
//         <div className="container mb-5">
//             <h3 className="mb-3 text-center">
//                 <span className="header_text_normal_bm header_text_bold">Mini</span><span className="header_text_bold">stry Information</span>
//             </h3>
//             <div className="row g-3 pt-2 d-flex justify-content-center">
//                 <div className="col-9">
//                     <table class="table table-bordered member__list__table">
//                         <thead>
//                             <tr>
//                                 <th className="th__sr">Sr. No.</th>
//                                 <th>Information</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {
//                                 memberListData.length > 0 && memberListData.map((item, id) => {
//                                     return (
//                                         <tr key={id} className="tr__body">
//                                             <td>{item.id}.</td>
//                                             <td>
//                                                 <a href={item.card_link} target="_blank" className="text-decoration-none mini__title">
//                                                     {item.title}
//                                                 </a>
//                                             </td>
//                                         </tr>
//                                     )
//                                 })
//                             }
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default MinistryInfo;