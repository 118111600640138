import React from 'react';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';

import student from '../../../images/student.jpg';
import parent from '../../../images/parent.jpg';

const SubHeaderSetup = () => {


    const sortedData = [
        { id: 1, name: "barn", card_title: 'Card Title', card_desc: 'World Tourism Day 2017 presents a unique opportunity to raise awareness on', date: "12-2-2023", imageURL: student },
        { id: 2, name: "barn", card_title: 'Baran', card_desc: 'World Tourism Day 2017 presents a unique opportunity to raise awareness on', date: "12-2-2023", imageURL: parent },
        { id: 3, name: "barn", card_title: 'Baran', card_desc: 'World Tourism Day 2017 presents a unique opportunity to raise awareness on', date: "12-2-2023", imageURL: parent },
    ];
    const columns = [
        {
            title: "SL", field: "", render: (row) => <div>{row.tableData.id + 1}</div>,

            width: "20 !important",
        },
        {
            title: 'Card Image',
            field: 'name',
            render: (rowData) => (<img src={rowData.imageURL} style={{ width: "35px", height: "37px" }} />),

            cellStyle: {
                marginLeft: 50,
                paddingLeft: 30,
                width: 400
            },
        },
        {
            title: 'Card Title',
            field: 'card_title',

            cellStyle: {
                marginLeft: 50,
                width: 400
            },
        },
        {
            title: 'Card Description',
            field: 'card_desc',

            cellStyle: {
                marginLeft: 50,
                width: 700
            },
        },
        {
            title: 'Date',
            field: 'date',

            cellStyle: {
                marginLeft: 50,
                width: 200
            },
        },
        {
            title: "Action",
            render: (rowData) =>
                <div>
                    {/* <Link to={`/add-home`} class="btn btn-success btn-sm action-btn"><i class="fas fa-save"></i></Link>&nbsp; */}
                    <Link to={`/edit-cards-setup/${rowData.id}`} class="btn btn-info btn-sm action-btn"><i class="fas fa-edit"></i></Link>&nbsp;
                    {/* <button onClick={(e) => deleteStudentsPersonalInfo(e, rowData.id)} className="btn btn-danger btn-sm action-btn"> <i class="fas fa-trash"></i> </button> */}
                    <button className="btn btn-danger btn-sm action-btn"> <i class="fas fa-trash"></i> </button>
                </div>
        }
    ];



    return (
        <>
            <div className="container pt-4">
                <div className="card">
                    <div className="card-header">
                        <h4 className="header__text  pt-1">Setup
                            <Link to='/add-cards-setup' className="back__btn  float-end"> Add Cards </Link>
                        </h4>
                    </div>

                    <div className="bg__from__color">
                        <div className="pt-1">
                            <div className="card-body maertial__table">
                                <MaterialTable
                                    columns={columns}
                                    data={sortedData}
                                    // isLoading={loading == true ? true : false}
                                    options={{
                                        search: true,
                                        // filtering: filter,
                                        showTitle: false,
                                        searchFieldAlignment: "left",
                                        actionsColumnIndex: -1,
                                        pageSize: 10,
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions: [5, 10, 20, 50, 100],

                                        // headerStyle: {
                                        //     padding: 6,
                                        //     textAlign: 'center',
                                        // },

                                        // cellStyle: {
                                        //     textAlign: 'center',
                                        //   },

                                        // cellStyle: {
                                        //     backgroundColor: '#039be5',
                                        //     color: '#FFF'
                                        //   },

                                    }}
                                // style={{
                                //     marginTop:'850px'
                                //       }
                                //     }

                                // other props
                                // actions={[
                                //     {
                                //         icon: 'save',
                                //         tooltip: 'Save User',
                                //         onClick: (event, rowData) => alert("You saved " + rowData.name)
                                //     },
                                //     {
                                //         icon: 'edit',
                                //         tooltip: 'Edit User',
                                //         onClick: (event, rowData) => alert("You want to edit " + rowData.name),
                                //         //   disabled: rowData.birthYear < 2000
                                //     },
                                //     rowData => ({
                                //         icon: 'delete',
                                //         tooltip: 'Delete User',
                                //         onClick: (event, rowData) => alert("You want to delete " + rowData.name),
                                //         //   disabled: rowData.birthYear < 2000
                                //     })
                                // ]}


                                // Action Overriding Preview
                                // components={{
                                //     Action: props => (
                                //       <button
                                //         onClick={(event) => props.action.onClick(event, props.data)}
                                //         color="primary"
                                //         variant="contained"
                                //         style={{textTransform: 'none'}}
                                //         size="small"
                                //       >
                                //         My Button
                                //       </button>
                                //     ),
                                //   }}

                                />


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubHeaderSetup;