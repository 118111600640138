import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import '../dashboard/AdminDashboard.css';
import '../AppAdmin.css';

const EditTravelAgentTourOperatorSetup = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [editInputField, setEditInputField] = useState({
        title_name: "",
        address: "",
        phone: "",
        email: "",
    });

    const inputsChange = (e) => {
        setEditInputField({ ...editInputField, [e.target.name]: e.target.value });

    };

    const fetchData = async (id) => {
        try {
            const response = await axios.get(`/edit-travel-agent/${id}`);
            console.log("response:", response.data.data);
            setEditInputField(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchData(id);
    }, []);

    const updateFormData = (e) => {
        e.preventDefault();
        console.log("updateFormData: ", editInputField);

        const url = `/update-travel-agent/${id}`;
        axios.post(url, editInputField)
            .then(response => {
                console.log(response, "edit dff");
                if (response.status === 200) {
                    alert("Are you Edit  to Data");
                    setEditInputField({
                        title_name: "",
                        address: "",
                        phone: "",
                        email: "",
                    });
                } else {
                    alert("Failed")
                }
                navigate("/dashboard/travel-agent-setup");
            })
            .catch(error => {
                console.log(error);
            })
    };


    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Edit Travel Agent & Tour Operator Setup
                        <Link to='/dashboard/travel-agent-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        <form className="mt-1"
                            onSubmit={updateFormData}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <div className="row g-3 mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="inputTitleName" className="col-form-label col-form-label-sm d-block">
                                                Title Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm form__ptb " id="inputTitleName"
                                                name="title_name"
                                                value={editInputField.title_name}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputPhone" className="col-form-label col-form-label-sm d-block " >Phone <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputPhone"
                                                name="phone"
                                                value={editInputField.phone}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                    </div>


                                    <div class="row mb-2">
                                        <div className="col-md-6">
                                            <label htmlFor="AddressFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
                                                Address <span className="text-danger">*</span></label>
                                            <textarea className="form-control" id="AddressFormControlTextarea1" rows="3"
                                                name="address"
                                                value={editInputField.address}
                                                onChange={inputsChange}
                                            ></textarea>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputEmail" className="col-form-label col-form-label-sm d-block " >Email <span className="text-danger">*</span></label>
                                            <input type="email" className="form-control form-control-sm form__ptb " id="InputEmail"
                                                name="email"
                                                value={editInputField.email}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditTravelAgentTourOperatorSetup;