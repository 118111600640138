
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
// import '../dashboard/AdminDashboard.css';
import '../AppAdmin.css';

const AddTravelAgentTourOperatorSetup = () => {


    const [inputField, setInputField] = useState({
        title_name: "",
        address: "",
        phone: "",
        email: "",
    });

    const inputsChange = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    };

    const submitFormData = (e) => {
        e.preventDefault();
        console.log("submitFormData: ", inputField);

        const url = "/add-travel-agent";
        axios.post(url, inputField)
            .then(response => {
                console.log(response, "dff");
                if (response.status === 200) {
                    alert("Are you Added  to Data");
                    setInputField({
                        title_name: "",
                        address: "",
                        phone: "",
                        email: "",
                    });
                } else {
                    alert("Failed")
                }
            })
            .catch(error => {
                console.log(error);
            })
    };

    // const submitFormData = (e) => {
    //     e.preventDefault();
    //     console.log("submitFormData: ", inputField);
    //     fetchData();
    // };
    // const [data, setData] = useState([]);
    // const fetchData = async () => {
    //     try {
    //         const response = await axios.post("/travel-agent", inputField);
    //         console.log("response:", response);
    //         setData(response.data);
    //         setInputField({
    //             title_name: "",
    //             organization_name: "",
    //             address: "",
    //             card_link: "",
    //             phone: "",
    //             email: "",
    //             fax: "",
    //             tel: "",
    //             web_link: "",
    //         });
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }





    return (
        <div className="container pt-4">
            <div className="card">
                <div className="card-header">
                    <h4 className="header__text  pt-1">Add Travel Agent & Tour Operator Setup
                        <Link to='/dashboard/travel-agent-setup' className="back__btn  float-end"> Back</Link>
                    </h4>
                </div>
                <div className="bg__from__color">
                    <div className="card-body">
                        {/* <Apd /> */}
                        <form className="mt-1"
                            onSubmit={submitFormData}
                        >
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <div className="row g-3 mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="inputTitleName" className="col-form-label col-form-label-sm d-block">
                                                Title Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm form__ptb " id="inputTitleName"
                                                name="title_name"
                                                value={inputField.title_name}
                                                onChange={inputsChange}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="InputPhone" className="col-form-label col-form-label-sm d-block " >Phone <span className="text-danger">*</span></label>

                                            <input type="text" className="form-control form-control-sm form__ptb " id="InputPhone"
                                                name="phone"
                                                value={inputField.phone}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-2">
                                        <div className="col-md-6">
                                            <label htmlFor="AddressFormControlTextarea1" className="col-form-label col-form-label-sm d-block">
                                                Address <span className="text-danger">*</span></label>
                                            <textarea className="form-control" id="AddressFormControlTextarea1" rows="3"
                                                name="address"
                                                value={inputField.address}
                                                onChange={inputsChange}
                                            ></textarea>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="InputEmail" className="col-form-label col-form-label-sm d-block " >Email <span className="text-danger">*</span></label>

                                            <input type="email" className="form-control form-control-sm form__ptb " id="InputEmail"
                                                name="email"
                                                value={inputField.email}
                                                onChange={inputsChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="float-right pt-4 mr-0">
                                        <button type="submit" className="btn__save mx-3"
                                        // onClick={saveMedicationChartPartOneData}
                                        >
                                            <b>Save</b>
                                        </button>
                                        <button className="btn__cancel"
                                        // onClick={props.closeModal}
                                        >
                                            <b>Cancel</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddTravelAgentTourOperatorSetup;

