import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Bangladesh_map_img from "../../images/Bangladesh_map_img.webp";
import { imageURL } from '../../imageURL/imageURL';

const AboutBangladesh = () => {

    const [aboutBangladesh, setAboutBangladesh] = useState([])
    const fetchData = async () => {
        try {
            const res = await axios.get("/about-bangladesh");
            console.log("about-bangladesh", res);
            setAboutBangladesh(res.data.data)
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        fetchData();
    }, [])


    return (
        <div className="container pt-2 mb-5">
            <h3 className="mb-1 text-center">
                <span className="header_text_normal_bm">Abou</span><span className="header_text_normal">t</span>
                <span> Bangladesh</span>
            </h3>
            <div className="row g-3 pt-4">
                <div className="col-12">
                    <div className="about__bang__img pt-2  d-flex justify-content-center">
                        <img src={`${imageURL}/images/about_bangla_folder/${aboutBangladesh[0]?.image}`} className="card-img-top" alt="images" />
                    </div>
                </div>
                <div className="col-12">
                    <p className="news__desc pt-2">{aboutBangladesh[0]?.description.slice(0, 1205)}</p>
                    <p className="news__desc pt-2">{aboutBangladesh[0]?.description.slice(1206, 5000)}</p>
                </div>
            </div>
        </div>
    );
};

export default AboutBangladesh;