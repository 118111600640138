import React from 'react';

const Members = () => {
    return (
        <div className="container">
            <h3 className="text-center mb-3">Tourism News</h3>
            <div className="row g-3">
                <table class="table table-bordered ">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Clark</td>
                                <td>Kent</td>
                                <td>clarkkent@mail.com</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Peter</td>
                                <td>Parker</td>
                                <td>peterparker@mail.com</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>John</td>
                                <td>Carter</td>
                                <td>johncarter@mail.com</td>
                            </tr>
                        </tbody>
                    </table>
            </div>
        </div>
    );
};

export default Members;