import React, { useEffect, useState } from 'react';
import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import Modal from 'react-modal';
import axios from 'axios';
import { imageURL } from '../../imageURL/imageURL';

// const memberList = [
//     { id: 1, title_name: "Shahadat Feroz Shikder", organization_name: "Kushiara Tourism & Logistics", card_link: "https://tourist.police.gov.bd/officers", cell: "01711 535121", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Kushiara Tourism & Logistics C/P: M. Zamiul Ahmed House # 37, Road # 3, Sector # 13, Uttara, Dhaka – 1230" },
//     { id: 2, title_name: "Md. Khaled Hussain", organization_name: "Meghna Village (Holiday Resort)", card_link: "https://tourist.police.gov.bd/officers", cell: "9675471, 9675472", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Meghna Village (Holiday Resort) C/P: Md. Ashraful Karim Ibrahim Mansion (4th floor) 11. Purana Paltan, Dhaka-1000." },
//     { id: 3, title_name: "Mr. Sayem Mohd. Hasan", organization_name: "Sundarban Tourism Plus", card_link: "https://tourist.police.gov.bd/officers", cell: "01712203336(Office) 01552308849", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Sundarban Tourism Plus C/P: Syed Habib Ali 50, Mymensingh Road, (8 link road), 2nd floor, Bangla Motor, Dhaka-1205." },
//     { id: 4, title_name: "Engr. Mohd. Atiqul Hoque", organization_name: "Kushiara Tourism & Logistics", card_link: "https://tourist.police.gov.bd/officers", cell: "01711 535121", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Kushiara Tourism & Logistics C/P: M. Zamiul Ahmed House # 37, Road # 3, Sector # 13, Uttara, Dhaka – 1230" },
//     { id: 5, title_name: "Md. Khaled Hussain", organization_name: "Meghna Village (Holiday Resort)", card_link: "https://tourist.police.gov.bd/officers", cell: "9675471, 9675472", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Meghna Village (Holiday Resort) C/P: Md. Ashraful Karim Ibrahim Mansion (4th floor) 11. Purana Paltan, Dhaka-1000." },
//     { id: 6, title_name: "Md. Khaled Hussain", organization_name: "Sundarban Tourism Plus", card_link: "https://tourist.police.gov.bd/officers", cell: "01712203336(Office) 01552308849", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Sundarban Tourism Plus C/P: Syed Habib Ali 50, Mymensingh Road, (8 link road), 2nd floor, Bangla Motor, Dhaka-1205." },
// ];



const MemberList = () => {
    const [modalMemberListIsOpen, setModalMemberListIsOpen] = useState(false);
    function openModalMemberList() {
        // alert("mmm")
        setModalMemberListIsOpen(true);
    }
    function closeModalMemberList(e) {
        setModalMemberListIsOpen(false);

    }
    const [arrMemberList, setArrMemberList] = useState([]);
    // console.log("arr:", arrMemberList);

    const [memberList, setMemberList] = useState([]);
    const fetchData = async () => {
        try {
            const response = await axios.get("/member-list");
            // console.log("response frontend:", response.data.data);
            setMemberList(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchData();
        // setMemberListDataFilter(memberListFilteringData);
    }, []);


    // console.log("memberList", memberList);
    // const memberListFilteringData1 = memberList;
    // console.log("memberListFilteringData1", memberListFilteringData1);

    const memberListFilteringData = memberList.sort((a, b) => a.title_name !== b.title_name ? a.title_name < b.title_name ? -1 : 1 : 0);
    // const [memberListDataFilter, setMemberListDataFilter] = useState(memberListFilteringData);

    console.log("memberListFiltering sort Data: ", memberListFilteringData);
    // console.log("memberListDataFilter: ", memberListDataFilter);

    // // Alternative/another way
    // const memberListFilteringData = dataList.sort((a, b) => a.title_name.localeCompare(b.title_name, 'es', {sensitivity: 'base'}))
    // console.log("memberListFilteringData", memberListFilteringData);

    // const [searchQuery, setSearchQuery] = useState('');
    // const handleSearch = (e) => {
    //     const query = e.target.value;
    //     setSearchQuery(query);

    //     const queryList = memberListFilteringData.filter((item) => {
    //         return item.organization_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    //     });
    //     setMemberListDataFilter(queryList);
    // }

    const [searchQuery, setSearchQuery] = useState('');
    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        const queryList = memberList.filter((item) => {
            return item.organization_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 || item.title_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });
        setMemberList(queryList);
    }

    return (
        <div className="container mb-5" >
            <h3 className="mb-3 text-center pt-2">
                <span className="header_text_normal_bm header_text_bold">Gen</span><span className="header_text_bold">eral Member List</span>
            </h3>
            <div className="row pt-3">
                <div className="col-md-6">
                    <div className="row mb-3">
                        <label htmlFor="inputSearch" className="col-sm-2 col-form-label col-form-label-sm">Search</label>
                        <div className="col-sm-10">
                            <div className="accom__search" id="inputSearch">
                                <TextField
                                    fullWidth
                                    id="standard-bare"
                                    variant="outlined"
                                    placeholder="Search"
                                    organization_name="search"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <IconButton>
                                                <SearchOutlined />
                                            </IconButton>
                                        ),
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row g-3 gx-md-4 d-flex justify-content-center">
                {
                    // memberListDataFilter.length > 0 && memberListDataFilter.map((item, id) => {
                    memberListFilteringData.length > 0 && memberListFilteringData.map((item, id) => {
                        return (
                            <div key={id} className="col-md-4">
                                <div class="member__list__card"
                                  onClick={() => {
                                    openModalMemberList();
                                    setArrMemberList(item);
                                }}
                                >
                                    <div class="card">
                                        <div className="member__list__card__img  pt-3">
                                            <img src={`${imageURL}/images/member_list_folder/${item.image}`} className="card-img-top" alt="images" />
                                        </div>
                                        <h6 class="member__list__title mx-2 pt-3 ">{item.title_name}</h6>
                                        <h5 class="member__list__subtitle mx-2">{item.organization_name}</h5>
                                        <h5 class="member__list__subtitle mx-2">{item.general_member_list}</h5>
                                        <div class="card-body">
                                            <div className="d-flex">
                                                <p class="member__list__desc me-2"><i class="fas fa-house-chimney"></i></p>
                                                <p class="member__list__desc">{item.address}</p>
                                            </div>
                                            <div className="d-flex">
                                                <p class="member__list__desc me-2"><i class="fas fa-phone"></i></p>
                                                <p class="member__list__desc">{item.cell}</p>
                                            </div>
                                            <div className="d-flex">
                                                <p class="member__list__desc me-2"><i class="fa-solid fa-blender-phone"></i></p>
                                                <p class="member__list__desc">{item.tel}</p>
                                            </div>
                                            <div className="d-flex">
                                                <p class="member__list__desc me-2"><i className="fa-solid fa-envelope"></i></p>
                                                <p class="member__list__desc">{item.email}</p>
                                            </div>
                                            <div className="d-flex">
                                                <p class="member__list__desc me-2"><i class="fa-solid fa-globe"></i></p>
                                                <p class="member__list__desc">Web: <a href={item.card_link} target="_blank" className="text-decoration-none">{item.web_link}</a></p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                    <Modal
                        isOpen={modalMemberListIsOpen}
                        onRequestClose={closeModalMemberList}
                        className="c__card__mymodal"
                        overlayClassName="c__card__myoverlay"
                        contentLabel="Example Modal"
                    >
                        <div className='modal__body'>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <span className='float-end' style={{ fontSize: "20px", cursor: "pointer" }} onClick={closeModalMemberList}><i className="fa-solid fa-xmark close_btn"></i></span>
                                        <h6 className="header__consultation__text">Member List Member</h6>
                                    </div>
                                </div>
                                <div className="row g-2 pt-2">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row g-3 pt-1">
                                                    <div className="col-md-4 pe-md-3">
                                                        <div className="our__c__card__img">
                                                            <img src={`${imageURL}/images/member_list_folder/${arrMemberList.image}`} className="card-img-top" alt="images" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <h6 className=" c__card__title"><b>Organigation: </b>{arrMemberList.organization_name}</h6>
                                                        <h6 className="c__card__subtitle">{arrMemberList.title_name}</h6>
                                                        <h6 className="c__card__subtitle">{arrMemberList.general_member_list}</h6>
                                                        <p className="c__card__disc pt-2"><b>Cell: </b>{arrMemberList.cell}</p>
                                                        <p className="c__card__disc"><b>Telephone: </b>{arrMemberList.tel}</p>
                                                        <p className="c__card__disc"><b>Email Address: </b>{arrMemberList.email}</p>
                                                        <p className="c__card__disc"><b>Web Link: </b>{arrMemberList.web_link}</p>
                                                        <p className="c__card__disc"><b>Address: </b>{arrMemberList.address}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <p className="cmodal__card__disc g-doc-scroll">{arrMemberList.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
            </div>

            <div className="row pt-5">
                <div className="col-12">
                    <div className="pt-4 d-flex justify-content-center mb-1">
                        <nav className="allroom_pagination" aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className="page-item px-1">
                                    <a className="page-link" href="#" aria-label="Previous">
                                        <span aria-hidden="true">&lt;</span>
                                    </a>
                                </li>
                                <li className="page-item px-1"><a className="page-link link_no" href="#">1</a></li>
                                <li className="page-item px-1"><a className="page-link link_no" href="#">2</a></li>
                                <li className="page-item px-1"><a className="page-link link_no" href="#">...</a></li>
                                <li className="page-item px-1"><a className="page-link link_no" href="#">3</a></li>
                                <li className="page-item px-1">
                                    <a className="page-link" href="#" aria-label="Next">
                                        <span aria-hidden="true">&gt;</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemberList;


// import React, { useState } from 'react';
// import { TextField, IconButton } from '@material-ui/core';
// import { SearchOutlined } from '@material-ui/icons';
// import { useEffect } from 'react';
// import axios from 'axios';

// // const memberList = [
// //     { id: 1, title_name: "Shahadat Feroz Shikder", organization_name: "Kushiara Tourism & Logistics", card_link: "https://tourist.police.gov.bd/officers", cell: "01711 535121", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Kushiara Tourism & Logistics C/P: M. Zamiul Ahmed House # 37, Road # 3, Sector # 13, Uttara, Dhaka – 1230" },
// //     { id: 2, title_name: "Md. Khaled Hussain", organization_name: "Meghna Village (Holiday Resort)", card_link: "https://tourist.police.gov.bd/officers", cell: "9675471, 9675472", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Meghna Village (Holiday Resort) C/P: Md. Ashraful Karim Ibrahim Mansion (4th floor) 11. Purana Paltan, Dhaka-1000." },
// //     { id: 3, title_name: "Mr. Sayem Mohd. Hasan", organization_name: "Sundarban Tourism Plus", card_link: "https://tourist.police.gov.bd/officers", cell: "01712203336(Office) 01552308849", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Sundarban Tourism Plus C/P: Syed Habib Ali 50, Mymensingh Road, (8 link road), 2nd floor, Bangla Motor, Dhaka-1205." },
// //     { id: 4, title_name: "Engr. Mohd. Atiqul Hoque", organization_name: "Kushiara Tourism & Logistics", card_link: "https://tourist.police.gov.bd/officers", cell: "01711 535121", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Kushiara Tourism & Logistics C/P: M. Zamiul Ahmed House # 37, Road # 3, Sector # 13, Uttara, Dhaka – 1230" },
// //     { id: 5, title_name: "Md. Khaled Hussain", organization_name: "Meghna Village (Holiday Resort)", card_link: "https://tourist.police.gov.bd/officers", cell: "9675471, 9675472", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Meghna Village (Holiday Resort) C/P: Md. Ashraful Karim Ibrahim Mansion (4th floor) 11. Purana Paltan, Dhaka-1000." },
// //     { id: 6, title_name: "Md. Khaled Hussain", organization_name: "Sundarban Tourism Plus", card_link: "https://tourist.police.gov.bd/officers", cell: "01712203336(Office) 01552308849", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Sundarban Tourism Plus C/P: Syed Habib Ali 50, Mymensingh Road, (8 link road), 2nd floor, Bangla Motor, Dhaka-1205." },
// // ];



// const MemberList = () => {

//     const [memberList, setMemberList] = useState([]);
//     const fetchData = async () => {
//         try {
//             const response = await axios.get("/member-list");
//             // console.log("response frontend:", response.data.data);
//             setMemberList(response.data.data);
//         } catch (error) {
//             console.error(error);
//         }
//     }

//     useEffect(() => {
//         fetchData();
//         // setMemberListDataFilter(memberListFilteringData);
//     }, []);
//     // console.log("memberList", memberList);
//     // const memberListFilteringData1 = memberList;
//     // console.log("memberListFilteringData1", memberListFilteringData1);

//     const memberListFilteringData = memberList.sort((a, b) => a.title_name !== b.title_name ? a.title_name < b.title_name ? -1 : 1 : 0);
//     // const [memberListDataFilter, setMemberListDataFilter] = useState(memberListFilteringData);

//     console.log("memberListFilteringData: ", memberListFilteringData);
//     // console.log("memberListDataFilter: ", memberListDataFilter);

//     // // Alternative/another way
//     // const memberListFilteringData = dataList.sort((a, b) => a.title_name.localeCompare(b.title_name, 'es', {sensitivity: 'base'}))
//     // console.log("memberListFilteringData", memberListFilteringData);

//     // const [searchQuery, setSearchQuery] = useState('');
//     // const handleSearch = (e) => {
//     //     const query = e.target.value;
//     //     setSearchQuery(query);

//     //     const queryList = memberListFilteringData.filter((item) => {
//     //         return item.organization_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
//     //     });
//     //     setMemberListDataFilter(queryList);
//     // }

//     const [searchQuery, setSearchQuery] = useState('');
//     const handleSearch = (e) => {
//         const query = e.target.value;
//         setSearchQuery(query);

//         const queryList = memberList.filter((item) => {
//             return item.organization_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
//         });
//         setMemberList(queryList);
//     }

//     return (
//         <div className="container mb-5" >
//             <h3 className="mb-3 text-center pt-2">
//                 <span className="header_text_normal_bm header_text_bold">Mem</span><span className="header_text_bold">ber List</span>
//             </h3>
//             <div className="row pt-3">
//                 <div className="col-md-6">
//                     <div className="row mb-3">
//                         <label htmlFor="inputSearch" className="col-sm-2 col-form-label col-form-label-sm">Search</label>
//                         <div className="col-sm-10">
//                             <div className="accom__search" id="inputSearch">
//                                 <TextField
//                                     fullWidth
//                                     id="standard-bare"
//                                     variant="outlined"
//                                     placeholder="Search"
//                                     organization_name="search"
//                                     value={searchQuery}
//                                     onChange={handleSearch}
//                                     InputProps={{
//                                         startAdornment: (
//                                             <IconButton>
//                                                 <SearchOutlined />
//                                             </IconButton>
//                                         ),
//                                     }}

//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="row g-3 gx-md-4 d-flex justify-content-center">
//                 {
//                     // memberListDataFilter.length > 0 && memberListDataFilter.map((item, id) => {
//                     memberListFilteringData.length > 0 && memberListFilteringData.map((item, id) => {
//                         return (
//                             <div key={id} className="col-md-4">
//                                 <div class="member__list__card">
//                                     <div class="card">
//                                         <h6 class="member__list__title pt-3 mx-2">{item.organization_name}</h6>
//                                         <h6 class="member__list__subtitle mx-2">{item.title_name}</h6>
//                                         <div class="card-body">
//                                             <div className="d-flex">
//                                                 <p class="member__list__desc me-2"><i class="fas fa-house-chimney"></i></p>
//                                                 <p class="member__list__desc">{item.address}</p>
//                                             </div>
//                                             <div className="d-flex">
//                                                 <p class="member__list__desc me-2"><i class="fas fa-phone"></i></p>
//                                                 <p class="member__list__desc">{item.cell}</p>
//                                             </div>
//                                             <div className="d-flex">
//                                                 <p class="member__list__desc me-2"><i class="fa-solid fa-blender-phone"></i></p>
//                                                 <p class="member__list__desc">{item.tel}</p>
//                                             </div>
//                                             <div className="d-flex">
//                                                 <p class="member__list__desc me-2"><i class="fa-solid fa-fax"></i></p>
//                                                 <p class="member__list__desc">{item.fax}</p>
//                                             </div>
//                                             <div className="d-flex">
//                                                 <p class="member__list__desc me-2"><i className="fa-solid fa-envelope"></i></p>
//                                                 <p class="member__list__desc">{item.email}</p>
//                                             </div>
//                                             <div className="d-flex">
//                                                 <p class="member__list__desc me-2"><i class="fa-solid fa-globe"></i></p>
//                                                 <p class="member__list__desc">Web: <a href={item.card_link} target="_blank" className="text-decoration-none">{item.web_link}</a></p>
//                                             </div>

//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         )
//                     })
//                 }
//             </div>

//             <div className="row pt-5">
//                 <div className="col-12">
//                     <div className="pt-4 d-flex justify-content-center mb-1">
//                         <nav className="allroom_pagination" aria-label="Page navigation example">
//                             <ul className="pagination">
//                                 <li className="page-item px-1">
//                                     <a className="page-link" href="#" aria-label="Previous">
//                                         <span aria-hidden="true">&lt;</span>
//                                     </a>
//                                 </li>
//                                 <li className="page-item px-1"><a className="page-link link_no" href="#">1</a></li>
//                                 <li className="page-item px-1"><a className="page-link link_no" href="#">2</a></li>
//                                 <li className="page-item px-1"><a className="page-link link_no" href="#">...</a></li>
//                                 <li className="page-item px-1"><a className="page-link link_no" href="#">3</a></li>
//                                 <li className="page-item px-1">
//                                     <a className="page-link" href="#" aria-label="Next">
//                                         <span aria-hidden="true">&gt;</span>
//                                     </a>
//                                 </li>
//                             </ul>
//                         </nav>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default MemberList;




// import React, { useState } from 'react';
// import { TextField, IconButton } from '@material-ui/core';
// import { SearchOutlined } from '@material-ui/icons';

// const memberList = [
//     { id: 1, title_name: "Shahadat Feroz Shikder", organization_name: "Kushiara Tourism & Logistics", card_link: "https://tourist.police.gov.bd/officers", cell: "01711 535121", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Kushiara Tourism & Logistics C/P: M. Zamiul Ahmed House # 37, Road # 3, Sector # 13, Uttara, Dhaka – 1230" },
//     { id: 2, title_name: "Md. Khaled Hussain", organization_name: "Meghna Village (Holiday Resort)", card_link: "https://tourist.police.gov.bd/officers", cell: "9675471, 9675472", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Meghna Village (Holiday Resort) C/P: Md. Ashraful Karim Ibrahim Mansion (4th floor) 11. Purana Paltan, Dhaka-1000." },
//     { id: 3, title_name: "Mr. Sayem Mohd. Hasan", organization_name: "Sundarban Tourism Plus", card_link: "https://tourist.police.gov.bd/officers", cell: "01712203336(Office) 01552308849", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Sundarban Tourism Plus C/P: Syed Habib Ali 50, Mymensingh Road, (8 link road), 2nd floor, Bangla Motor, Dhaka-1205." },
//     { id: 4, title_name: "Engr. Mohd. Atiqul Hoque", organization_name: "Kushiara Tourism & Logistics", card_link: "https://tourist.police.gov.bd/officers", cell: "01711 535121", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Kushiara Tourism & Logistics C/P: M. Zamiul Ahmed House # 37, Road # 3, Sector # 13, Uttara, Dhaka – 1230" },
//     { id: 5, title_name: "Md. Khaled Hussain", organization_name: "Meghna Village (Holiday Resort)", card_link: "https://tourist.police.gov.bd/officers", cell: "9675471, 9675472", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Meghna Village (Holiday Resort) C/P: Md. Ashraful Karim Ibrahim Mansion (4th floor) 11. Purana Paltan, Dhaka-1000." },
//     { id: 6, title_name: "Md. Khaled Hussain", organization_name: "Sundarban Tourism Plus", card_link: "https://tourist.police.gov.bd/officers", cell: "01712203336(Office) 01552308849", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Sundarban Tourism Plus C/P: Syed Habib Ali 50, Mymensingh Road, (8 link road), 2nd floor, Bangla Motor, Dhaka-1205." },
// ];


// const MemberList = () => {
//     const memberListFilteringData = memberList.sort((a, b) => a.title_name !== b.title_name ? a.title_name < b.title_name ? -1 : 1 : 0);
//     // console.log("memberListFilteringData", memberListFilteringData);
//     // // Alternative/another way
//     // const memberListFilteringData = dataList.sort((a, b) => a.title_name.localeCompare(b.title_name, 'es', {sensitivity: 'base'}))
//     // console.log("memberListFilteringData", memberListFilteringData);

//     const [memberListDataFilter, setMemberListDataFilter] = useState(memberListFilteringData);
//     const [searchQuery, setSearchQuery] = useState('');

//     const handleSearch = (e) => {
//         const query = e.target.value;
//         setSearchQuery(query);

//         const queryList = memberListFilteringData.filter((item) => {
//             return item.organization_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
//         });
//         setMemberListDataFilter(queryList);
//     }

//     return (
//         <div className="container mb-5" >
//             <h3 className="mb-3 text-center">
//                 <span className="header_text_normal_bm header_text_bold">Mem</span><span className="header_text_bold">ber List</span>
//             </h3>
//             <div className="row pt-3">
//                 <div className="col-md-6">
//                     <div className="row mb-3">
//                         <label htmlFor="inputSearch" className="col-sm-2 col-form-label col-form-label-sm">Search</label>
//                         <div className="col-sm-10">
//                             <div className="accom__search" id="inputSearch">
//                                 <TextField
//                                     fullWidth
//                                     id="standard-bare"
//                                     variant="outlined"
//                                     placeholder="Search"
//                                     organization_name="search"
//                                     value={searchQuery}
//                                     onChange={handleSearch}
//                                     InputProps={{
//                                         startAdornment: (
//                                             <IconButton>
//                                                 <SearchOutlined />
//                                             </IconButton>
//                                         ),
//                                     }}

//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="row g-3 gx-md-4 d-flex justify-content-center">
//                 {
//                     memberListDataFilter.length > 0 && memberListDataFilter.map((item, id) => {
//                         return (
//                             <div key={id} className="col-md-4">
//                                 <div class="member__list__card">
//                                     <div class="card">
//                                         <h6 class="member__list__title pt-3 mx-2">{item.organization_name}</h6>
//                                         <h6 class="member__list__subtitle mx-2">{item.title_name}</h6>
//                                         <div class="card-body">
//                                             <div className="d-flex">
//                                                 <p class="member__list__desc me-2"><i class="fas fa-house-chimney"></i></p>
//                                                 <p class="member__list__desc">{item.address}</p>
//                                             </div>
//                                             <div className="d-flex">
//                                                 <p class="member__list__desc me-2"><i class="fas fa-phone"></i></p>
//                                                 <p class="member__list__desc">{item.cell}</p>
//                                             </div>
//                                             <div className="d-flex">
//                                                 <p class="member__list__desc me-2"><i class="fa-solid fa-blender-phone"></i></p>
//                                                 <p class="member__list__desc">{item.tel}</p>
//                                             </div>
//                                             <div className="d-flex">
//                                                 <p class="member__list__desc me-2"><i class="fa-solid fa-fax"></i></p>
//                                                 <p class="member__list__desc">{item.fax}</p>
//                                             </div>
//                                             <div className="d-flex">
//                                                 <p class="member__list__desc me-2"><i className="fa-solid fa-envelope"></i></p>
//                                                 <p class="member__list__desc">{item.email}</p>
//                                             </div>
//                                             <div className="d-flex">
//                                                 <p class="member__list__desc me-2"><i class="fa-solid fa-globe"></i></p>
//                                                 <p class="member__list__desc">Web: <a href={item.card_link} target="_blank" className="text-decoration-none">{item.web_link}</a></p>
//                                             </div>

//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         )
//                     })
//                 }
//             </div>

//             <div className="row pt-5">
//                 <div className="col-12">
//                     <div className="pt-4 d-flex justify-content-center mb-1">
//                         <nav className="allroom_pagination" aria-label="Page navigation example">
//                             <ul className="pagination">
//                                 <li className="page-item px-1">
//                                     <a className="page-link" href="#" aria-label="Previous">
//                                         <span aria-hidden="true">&lt;</span>
//                                     </a>
//                                 </li>
//                                 <li className="page-item px-1"><a className="page-link link_no" href="#">1</a></li>
//                                 <li className="page-item px-1"><a className="page-link link_no" href="#">2</a></li>
//                                 <li className="page-item px-1"><a className="page-link link_no" href="#">...</a></li>
//                                 <li className="page-item px-1"><a className="page-link link_no" href="#">3</a></li>
//                                 <li className="page-item px-1">
//                                     <a className="page-link" href="#" aria-label="Next">
//                                         <span aria-hidden="true">&gt;</span>
//                                     </a>
//                                 </li>
//                             </ul>
//                         </nav>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default MemberList;



// // import React, { useState } from 'react';
// // import under_construction from "../../images/under_construction.jpg";

// // const MemberList = () => {

// //     const [memberListData, setMemberListData] = useState([
// //         { id: 1, title: "Kushiara Tourism & Logistics", card_link: "https://tourist.police.gov.bd/officers", cell: "01711 535121", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Kushiara Tourism & Logistics C/P: M. Zamiul Ahmed House # 37, Road # 3, Sector # 13, Uttara, Dhaka – 1230" },
// //         { id: 2, title: "Meghna Village (Holiday Resort)", card_link: "https://tourist.police.gov.bd/officers", cell: "9675471, 9675472", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Meghna Village (Holiday Resort) C/P: Md. Ashraful Karim Ibrahim Mansion (4th floor) 11. Purana Paltan, Dhaka-1000." },
// //         { id: 3, title: "Sundarban Tourism Plus", card_link: "https://tourist.police.gov.bd/officers", cell: "01712203336(Office) 01552308849", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Sundarban Tourism Plus C/P: Syed Habib Ali 50, Mymensingh Road, (8 link road), 2nd floor, Bangla Motor, Dhaka-1205." },
// //         { id: 4, title: "Kushiara Tourism & Logistics", card_link: "https://tourist.police.gov.bd/officers", cell: "01711 535121", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Kushiara Tourism & Logistics C/P: M. Zamiul Ahmed House # 37, Road # 3, Sector # 13, Uttara, Dhaka – 1230" },
// //         { id: 5, title: "Meghna Village (Holiday Resort)", card_link: "https://tourist.police.gov.bd/officers", cell: "9675471, 9675472", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Meghna Village (Holiday Resort) C/P: Md. Ashraful Karim Ibrahim Mansion (4th floor) 11. Purana Paltan, Dhaka-1000." },
// //         { id: 6, title: "Sundarban Tourism Plus", card_link: "https://tourist.police.gov.bd/officers", cell: "01712203336(Office) 01552308849", email: "ktl@agni.com, zamiulahmed54@gmail.com", fax: "880-2-8932887", tel: "8932008", web_link: "www.kushiaratourism.com", address: "Sundarban Tourism Plus C/P: Syed Habib Ali 50, Mymensingh Road, (8 link road), 2nd floor, Bangla Motor, Dhaka-1205." },
// //     ]);



// //     return (
// //         <div className="container mb-4">
// //             <h3 className="mb-3 text-center">
// //                 <span className="header_text_normal_bm header_text_bold">Mem</span><span className="header_text_bold">ber List</span>
// //             </h3>
// //             <div className="row g-3 pt-2">
// //                 <table class="table table-bordered member__list__table">
// //                     <thead>
// //                         <tr>
// //                             <th className="th__sr">Sr. No.</th>
// //                             <th>Information</th>
// //                         </tr>
// //                     </thead>
// //                     <tbody>
// //                         {
// //                             memberListData.length > 0 && memberListData.map((item, id) => {
// //                                 return (
// //                                     <tr key={id} className="tr__body">
// //                                         <td>{item.id}.</td>
// //                                         <td>
// //                                             <p><b>{item.title}</b></p>
// //                                             <p>Cell: {item.cell}</p>
// //                                             <p>Tel: {item.tel}</p>
// //                                             <p>Fax: {item.fax}</p>
// //                                             <p>Address: {item.address}</p>
// //                                             <p>Web: <a href={item.card_link} target="_blank" className="text-decoration-none">{item.web_link}</a></p>
// //                                         </td>
// //                                     </tr>
// //                                 )
// //                             })
// //                         }
// //                     </tbody>
// //                 </table>
// //             </div>
// //         </div>
// //     );
// // };

// // export default MemberList;