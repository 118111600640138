import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';


const BMForeignCountry = () => {
    const [bMFData, setBMFData] = useState([]);

    console.log("bMFData", bMFData);
    const fetchData = async () => {
        try {
            const res = await axios.get("/bmforeign-country");
            console.log("bmforeign-country res :", res.data.data);
            setBMFData(res.data.data);

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    // const bMFDataUniqueArr = [...new Map(bMFData.map(v => [JSON.stringify([v.continent_title]), v])).values()]
    // console.log("bMFDataUniqueArr", bMFDataUniqueArr);

    return (
        <div className="container mb-5">
            <h3 className="mb-3 text-center pt-3">
                <span className="header_text_normal_bm header_text_bold">Bangla</span><span className="header_text_bold">desh</span>
                <span> Mission of Foreign Country</span>
            </h3>
            <div className="row g-3 pt-2">
                <div className="col-12">
                    <div className="d-flex justify-content-center">
                        <table className="table table-bordered bmf__table">
                            <thead>
                                <tr>
                                    <th className="th__sr">Sr. No.</th>
                                    <th className="th__continent">Continent</th>
                                    <th>Country</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    bMFData.length > 0 && bMFData.map((item, id) => {
                                        return (
                                            <tr key={id} className="tr__body">
                                                <td>{item.id}.</td>
                                                {/* <td rowspan={item.continent_area.length}> */}
                                                <td>
                                                    <a href={item.card_link} target="_blank" className="text-decoration-none mini__continent_title">
                                                        {item.continent_title}
                                                    </a>
                                                </td>
                                                <td className="td__country">
                                                    <ul className="bmf__criteria">
                                                        {
                                                            item.continent_area !== undefined && item.continent_area.map((itemCountry, id) =>
                                                            // console.log("object", itemCountry)
                                                                <li key={id}>
                                                                    <a href={itemCountry.web_link} target="_blank" className="text-decoration-none">
                                                                        {itemCountry.country_name}
                                                                    </a>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </td>

                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default BMForeignCountry;


// import React, { useState } from 'react';
// const BMForeignCountry = () => {
//     const [bMFData, setBMFData] = useState([
//         {
//             id: 1, continent_title: "East Asia & Asia Pacific",
//             continent_area: [
//                 {
//                     id: 1, country_name: "Iran", web_link: "https://brasilia.mofa.gov.bd/",
//                 },
//                 {
//                     id: 2, country_name: "India", web_link: "https://brasilia.mofa.gov.bd/",
//                 },
//                 {
//                     id: 3, country_name: "Bangladesh", web_link: "https://brasilia.mofa.gov.bd/",
//                 },
//             ],
//             card_link: "http://www.mofa.gov.bd/site/page/61b437f3-2832-40e2-aa03-2fc8a866a2d0",
//         },
//         {
//             id: 2, continent_title: "Americas",
//             continent_area: [
//                 {
//                     id: 1, country_name: "America", web_link: "https://brasilia.mofa.gov.bd/",
//                 },
//                 {
//                     id: 2, country_name: "Canada", web_link: "https://brasilia.mofa.gov.bd/",
//                 },
//                 {
//                     id: 3, country_name: "Maxico", web_link: "https://brasilia.mofa.gov.bd/",
//                 },
//             ],
//             card_link: "http://www.mofa.gov.bd/site/page/44e63982-1355-4d8d-af4c-2a273f91e81d",
//         },
//         {
//             id: 1, continent_title: "Africa",
//             continent_area: [
//                 {
//                     id: 1, country_name: "Morroco", web_link: "https://brasilia.mofa.gov.bd/",
//                 },
//                 {
//                     id: 2, country_name: "Afria", web_link: "https://brasilia.mofa.gov.bd/",
//                 },
//                 {
//                     id: 3, country_name: "Nigeria", web_link: "https://brasilia.mofa.gov.bd/",
//                 },
//             ],
//             card_link: "http://www.mofa.gov.bd/site/page/a9ecfe76-222b-4c2b-9532-b8d4edb3d3aa",
//         },
//         {
//             id: 4, continent_title: "East Europe and CIS",
//             continent_area: [
//                 {
//                     id: 1, country_name: "Italy", web_link: "https://brasilia.mofa.gov.bd/",
//                 },
//                 {
//                     id: 2, country_name: "Germany", web_link: "https://brasilia.mofa.gov.bd/",
//                 },
//                 {
//                     id: 3, country_name: "Francee", web_link: "https://brasilia.mofa.gov.bd/",
//                 },
//             ],
//             card_link: "http://www.mofa.gov.bd/site/page/bab55453-ac2c-4b73-ad8d-cdebed461212",
//         },
//     ]);

//     return (
//         <div className="container mb-5">
//             <h3 className="mb-3 text-center">
//                 <span className="header_text_normal_bm header_text_bold">Bangla</span><span className="header_text_bold">desh</span>
//                 <span> Mission of Foreign Country</span>
//             </h3>
//             <div className="row g-3 pt-2">
//                 <div className="col-12">
//                     <div className="d-flex justify-content-center">
//                         <table className="table table-bordered bmf__table">
//                             <thead>
//                                 <tr>
//                                     <th className="th__sr">Sr. No.</th>
//                                     <th className="th__continent">Continent</th>
//                                     <th>Country</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {
//                                     bMFData.length > 0 && bMFData.map((item, id) => {
//                                         return (
//                                             <tr key={id} className="tr__body">
//                                                 <td>{item.id}.</td>
//                                                 {/* <td rowspan={item.continent_area.length}> */}
//                                                 <td>
//                                                     <a href={item.card_link} target="_blank" className="text-decoration-none mini__continent_title">
//                                                         {item.continent_title}
//                                                     </a>
//                                                 </td>
//                                                 <td className="td__country">
//                                                     <ul className="bmf__criteria">
//                                                         {
//                                                             item.continent_area !== undefined && item.continent_area.map((itemCountry, id) =>
//                                                                 <li key={id}>
//                                                                     <a href={itemCountry.web_link} target="_blank" className="text-decoration-none">
//                                                                         {itemCountry.country_name}
//                                                                     </a>
//                                                                 </li>
//                                                             )
//                                                         }
//                                                     </ul>
//                                                 </td>

//                                             </tr>
//                                         )
//                                     })
//                                 }
//                             </tbody>
//                         </table>
//                     </div>

//                 </div>
//             </div>
//         </div>
//     );
// };

// export default BMForeignCountry;